// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.adminContractCard {
    display: flex;
    align-items: center;
    justify-content: center;
    display: grid;
    grid-template-columns: repeat(2, 2fr);
    grid-gap: 20px;
}

.addContractCard {
    width: 100%;
    height: 100%;
    border-radius: 10px;
    border: 2px dashed #E4E4E7;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 30px;
}

.addContractCard p {
    color: #71717A;
    font-size: 15px;
    font-weight: 500;

}`, "",{"version":3,"sources":["webpack://./src/Components/AdminScreen/AdminContract/AdminContract.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;IACb,qCAAqC;IACrC,cAAc;AAClB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;IACnB,0BAA0B;IAC1B,aAAa;IACb,mBAAmB;IACnB,uBAAuB;IACvB,aAAa;AACjB;;AAEA;IACI,cAAc;IACd,eAAe;IACf,gBAAgB;;AAEpB","sourcesContent":[".adminContractCard {\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    display: grid;\n    grid-template-columns: repeat(2, 2fr);\n    grid-gap: 20px;\n}\n\n.addContractCard {\n    width: 100%;\n    height: 100%;\n    border-radius: 10px;\n    border: 2px dashed #E4E4E7;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n    padding: 30px;\n}\n\n.addContractCard p {\n    color: #71717A;\n    font-size: 15px;\n    font-weight: 500;\n\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
