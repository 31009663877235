// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.foot {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.foot h4 {
  font-size: 0.875rem;
  line-height: 1.25rem;
  color: var(--textColor);
}

.footDesc {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.footDesc p {
  text-decoration: underline;
  text-underline-offset: 4px;
  font-size: 0.75rem;
  line-height: 1rem;
  color: var(--textColor);
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  cursor: pointer;
}

.footDesc h6 {
  font-size: 0.75rem;
  color: var(--textColor);
}`, "",{"version":3,"sources":["webpack://./src/Components/Reusable/Terms&Conditions/Foot.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;EACpB,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EAGE,0BAA0B;EAC1B,0BAA0B;EAI1B,kBAAkB;EAClB,iBAAiB;EACjB,uBAAuB;EACvB,mBAAmB;EACnB,oBAAoB;EACpB,eAAe;AARjB;;AAWA;EACE,kBAAkB;EAClB,uBAAuB;AACzB","sourcesContent":[".foot {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n}\n\n.foot h4 {\n  font-size: 0.875rem;\n  line-height: 1.25rem;\n  color: var(--textColor);\n}\n\n.footDesc {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.footDesc p {\n  font-size: 0.75rem;\n  line-height: 1rem;\n  text-decoration: underline;\n  text-underline-offset: 4px;\n}\n\n.footDesc p {\n  font-size: 0.75rem;\n  line-height: 1rem;\n  color: var(--textColor);\n  margin-left: 0.5rem;\n  margin-right: 0.5rem;\n  cursor: pointer;\n}\n\n.footDesc h6 {\n  font-size: 0.75rem;\n  color: var(--textColor);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
