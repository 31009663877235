/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import "./HireMainComponent.css";
import DashHead from "../../Reusable/DashBoardReusable/DashHead/DashHead";
import Avatar from "react-avatar";
import { FaRegCircleXmark } from "react-icons/fa6";
import { FiPlusCircle } from "react-icons/fi";
import { storeAction } from "../../../Store/Store";
import { useDispatch, useSelector } from "react-redux";
import Skilllist from "../../../assests/skillsJSON.json";
import { CiSearch } from "react-icons/ci";
import { FaAngleDown } from "react-icons/fa";
import search from "../../../assests/search.png";
import Pagination from "../../NewCandidateFlow/Pagination";
import { getDocument } from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker";
import { FiBriefcase } from "react-icons/fi";
import Dragoption from "../../Candidate/CandidateSignUp/CandidateRegistration/Dragoption";
import axios from "axios";
import MobileCandidateProfile from "../../MobileScreens/MobileCandidateProfile/MobileCandidateProfile";
import CandidateProfileCard from "../../Reusable/CandidateProfileCard/CandidateProfileCard";

const HireMainComponent = () => {
  const dispatch = useDispatch();
  const token = useSelector((store) => store.token);
  const userid = useSelector((store) => store.userid);
  const alluserdata = useSelector((store) => store.alluserdata);

  const inputref = useRef("");

  const [card, setCard] = useState("profile1");
  const [isPage, setIsPage] = useState("page1");
  const [formdata, setformdata] = useState({
    project_name: "",
    duration: "",
    budget: "",
    amount: "",
    description: "",
    skills_required: "",
  });
  const [formdataerror, setformdataerror] = useState({
    project_name: false,
    duration: false,
    budget: false,
    amount: false,
    description: false,
    skills_required: false,
  });
  const [loader, setloader] = useState(false);
  const [skillData, setSkillData] = useState([]);
  const [locationData, setLocationData] = useState([]);

  const handlechange = async (e) => {
    const { name, value } = e.target;
    setformdata((values) => ({ ...values, [name]: value }));
  };
  useEffect(() => {
    GetallTeam();
  }, []);
  const GetallTeam = async () => {
    try {
      const response = await axios.get(`https://api.hirein5.com/search_object`);
      setSkillData(response.data.skills.filter((skill) => skill.trim() !== ""));
      setLocationData(
        response.data.cities.filter((city) => city.trim() !== "")
      );
    } catch (error) {
      console.error("Error fetching skill data:", error);
    }
    var getalldata = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/hireTeam/${userid}`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
  };

  const createTaam = async () => {
    setformdataerror({
      project_name: false,
      duration: false,
      budget: false,
      amount: false,
      description: false,
      skills_required: false,
    });
    if (formdata.project_name.length == 0) {
      setformdataerror((values) => ({ ...values, project_name: true }));
    } else if (formdata.duration.length == 0) {
      setformdataerror((values) => ({ ...values, duration: true }));
    } else if (formdata.budget.length == 0) {
      setformdataerror((values) => ({ ...values, budget: true }));
    } else if (formdata.amount.length == 0) {
      setformdataerror((values) => ({ ...values, amount: true }));
    } else if (formdata.description.length == 0) {
      setformdataerror((values) => ({ ...values, description: true }));
    } else if (skill.length == 0) {
      setformdataerror((values) => ({ ...values, skills_required: true }));
    } else {
      setloader(true);
      setformdataerror({
        project_name: false,
        duration: false,
        budget: false,
        amount: false,
        description: false,
        skills_required: false,
      });
      var obj = {
        project_name: formdata.project_name,
        duration: formdata.duration,
        budget: formdata.budget,
        amount: formdata.amount,
        description: formdata.description,
        skills_required: skill.toString(),
        client_id: userid,
      };
      var craetedata = await axios
        .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/hireTeam/`, obj, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (craetedata.id !== undefined && craetedata.id !== null) {
        setloader(false);
      } else {
        setloader(false);
      }
    }
  };
  const cardHandler = (teamIndex, roleIndex, profileIndex) => {
    const updatedTeams = teamlist.map((team, tIndex) => {
      if (tIndex === teamIndex) {
        const updatedRoles = team.roles.map((role, rIndex) => {
          if (rIndex === roleIndex) {
            const updatedProfiles = role.profiles.map((profile, pIndex) => {
              // Set `active: true` only for the profile with the given `profileIndex`, others will be `active: false`
              return {
                ...profile,
                active: profile.id === profileIndex, // Set active based on condition
              };
            });
            return {
              ...role,
              profiles: updatedProfiles, // Update profiles in the role
            };
          }
          return role;
        });
        return {
          ...team,
          roles: updatedRoles, // Update roles in the team
        };
      }
      return team;
    });

    // Update the teamlist with the new data
    setteamlist(updatedTeams);

    // Update the selectedTeam if it matches the current team
    if (teamlist[teamIndex].name === selectedTeam.name) {
      setSelectedTeam((prevState) => ({
        ...prevState,
        roles: prevState.roles.map((role, rIndex) =>
          rIndex === roleIndex
            ? {
                ...role,
                profiles: role.profiles.map((profile, pIndex) => ({
                  ...profile,
                  active: profile.id === profileIndex, // Set active for the selected profile, false for others
                })),
              }
            : role
        ),
      }));
    }
  };

  const [dropDownList, setdropDownList] = useState([]);
  const [dropDownOpen, setdropDownClose] = useState(dropDownList);
  const [dropDown, setdropDown] = useState("");
  const [recordsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [allcandidatedata, setallcandidatedata] = useState([
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ]);
  const [skill, setskill] = useState([]);
  const [responsibilitydata, setresponsibilitydata] = useState(null);
  const [newresponsibilitydata, setnewresponsibilitydata] = useState("");
  const [teamlist, setteamlist] = useState([
    {
      name: "Web App Development",
      roles: [
        {
          role: "Project Manager",
          responsibility:
            "Oversees the entire project, manages timelines, coordinates team efforts, ensures that the project stays within scope and budget, and serves as a communication link between stakeholders and the development team.",
          jobDescription: "Responsible for building Manual Testing...",
          skill: "Strong organizational, communication, and leadership skills.	",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
          numberOfRoles: "3",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        {
          role: "UI/UX Designer",
          responsibility:
            "Focuses on user interface (UI) design and user experience (UX). They create wireframes, mockups, and prototypes, ensuring that the web application is userfriendly, intuitive, and visually appealing.",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "Graphic design, prototyping, UX research, and knowledge of design tools (e.g., Figma, Adobe XD).",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          numberOfRoles: "3",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        {
          role: "FrontEnd Developer",
          responsibility:
            "Implements the web application’s visual elements. They translate the UI/UX designs into code using technologies like HTML, CSS, and JavaScript, ensuring that the application is responsive and functions correctly across different devices and browsers.",
          jobDescription: "Responsible for building Manual Testing...",
          skill: "HTML, CSS, JavaScript, and front-end frameworks.",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Front-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=2e3c372f-bc8a-48ca-b1b4-930854554aef",
          numberOfRoles: "3",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        {
          role: "BackEnd Developer",
          responsibility:
            "Implements the web application’s visual elements. They translate the UI/UX designs into code using technologies like HTML, CSS, and JavaScript, ensuring that the application is responsive and functions correctly across different devices and browsers.",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "Server-side languages (e.g., Node.js, Python, Ruby, Java, PHP), databases (SQL, NoSQL), and RESTful API design.",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Back-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=381b1558-0698-4875-88ef-388bb709ef3c",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        {
          role: "FullStack Developer (Optional)",
          responsibility:
            "Capable of handling both frontend and backend development. They can bridge the gap between UI design and serverside implementation, providing versatility to the development process.",
          jobDescription: "Responsible for building Manual Testing...",
          skill: "A combination of front-end and back-end technologies.",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Full-Stack%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=b24b1480-8210-42bf-8637-a66d3a0c60b1",
          numberOfRoles: "3",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        {
          role: "Database Administrator (DBA)",
          responsibility:
            "Manages the database systems, ensures data integrity, optimizes queries, and handles data migration, backups, and recovery processes. They ensure that the database is scalable and secure.",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "Database Design, SQL Proficiency, Performance Tuning, Backup & Recovery,Database Security, DBMS Familiarity, Data Migration",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Database%20Administrator.docx.pdf?alt=media&token=2fcbb24e-dd8d-47f8-a2e5-b60e6279653b",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        {
          role: "DevOps Engineer",
          responsibility:
            "Focuses on the deployment, scaling, and management of the application infrastructure. They set up continuous integration/continuous deployment (CI/CD) pipelines, automate processes, and ensure that the web application is scalable and secure.",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "Cloud platforms (AWS, Azure, GCP), CI/CD tools (Jenkins, GitLab CI), and containerization (Docker, Kubernetes).",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_DevOps%20Engineer_Web%20App%20Development.docx.pdf?alt=media&token=98e47f7e-242b-4c0e-9476-9c0277e889c6",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        {
          role: "Test (QA) Engineer",
          responsibility:
            "Tests the application to identify bugs, usability issues, and performance bottlenecks. They ensure that the application meets the required standards and functions as expected across different environments.",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "Familiarity with testing frameworks (e.g., Selenium, Jest), and knowledge of test automation.",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_QA%20Engineer%20-Tester_Web%20App%20Development.docx.pdf?alt=media&token=09acc7cc-7e68-4f66-af87-4c627997f0c1",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        {
          role: "Security Specialist",
          responsibility:
            "Ensures the security of the web application by identifying vulnerabilities, implementing security protocols, and conducting penetration testing. They focus on protecting the application from potential threats.",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "Vulnerability Assessment Tools, Programming Languages, Security Frameworks and Standards, Web Technologies, Authentication and Authorization Protocols, Cloud Security, Security Testing Techniques, Incident Response Tools,Network Security, Secure Development Practices",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Security%20Specialist_Web%20App%20Development.docx.pdf?alt=media&token=29cbbbc8-c55d-4a0b-aea3-d36c8cebb538",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        // {
        //   role: "Content Writer/Copywriter",
        //   responsibility:
        //     "Creates and curates the content for the web application. They ensure that the content is clear, engaging, and aligned with the brand’s voice and SEO best practices.",
        //   jobDescription: "Responsible for building Manual Testing...",
        //   skill:
        //     "Clear and Concise Writing, SEO Knowledge, Adaptability, Familiarity with Web Technologies, User Experience (UX) Writing, Market Research, Competitor Analysis, Storytelling, Brand Voice Development",
        //   numberOfRoles: "3",
        //   url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_CONTENT%20WRITER.docx.pdf?alt=media&token=5221e14a-9508-4e60-9865-e454de038b3c",
        //   location: "India",
        //   yearsOfExperience: "2",
        //   availability: "next month",
        //   modeOfEngagement: "Full-time",
        //   workingHours: "Flexible with working hours",
        //   // projectDuration: 0,
        //   gender: "",
        //   country: "",
        //   city: "",
        //   budgetPerRole: "1000",
        // browse:"",
        //   profiles: [],
        // },
        {
          role: "Business Analyst",
          responsibility:
            "Analyzes business needs and translates them into technical requirements. They work closely with stakeholders to ensure that the web application aligns with business objectives.",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "Requirements gathering, process mapping, and strong communication skills.",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Business%20Analyst.docx.pdf?alt=media&token=9f2155e8-fe06-4427-8dd3-35c0cab54d82",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        {
          role: "Product Owner",
          responsibility:
            "Represents the stakeholders and endusers, prioritizes features, and ensures that the development team is working on the most valuable aspects of the application. They manage the product backlog and help with decisionmaking.	",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "Agile Methodologies, Product Management Tools, User Story Development, Web Technologies, Data Analysis, Market Research, UI/UX Principles, Stakeholder Management, Basic Technical Understanding",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Product%20Owner%20_Web%20App.docx.pdf?alt=media&token=84236fff-c442-42d0-9b77-cc2c2e1b9c58",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        {
          role: "System Architect",
          responsibility:
            "Designs the overall architecture of the web application, making key decisions about the tech stack, frameworks, and infrastructure. They ensure that the application is scalable, maintainable, and aligned with the business goals.",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "Architectural Frameworks, Cloud Platforms, Web Technologies, API Design and Management, Database Management, DevOps Practices, Security Architecture, Performance Optimization, System Integration, Monitoring and Logging",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_System%20Architect%20_Web%20App.docx.pdf?alt=media&token=788c0bdb-fec9-4eec-a986-faab13d78f34",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
      ],
    },
    // {
    //   name: "Mobile App Development",
    //   roles: [
    //     {
    //       role: "Project Manager",
    //       responsibility:
    //         "Oversees the entire project, manages timelines, coordinates team efforts, ensures that the project stays within scope and budget, and serves as a communication link between stakeholders and the development team.",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "Project management methodologies (Agile, Scrum), communication, and team coordination.",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
    //       numberOfRoles: "3",
    //       location: "India",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //       budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       role: "UI/UX Designer",
    //       responsibility:
    //         "Focuses on user interface (UI) design and user experience (UX). They create wireframes, mockups, and prototypes, ensuring that the web application is userfriendly, intuitive, and visually appealing.",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "Graphic design, user experience (UX) research, wireframing, prototyping,and using design tools like Sketch, Figma, or Adobe XD.",
    //       numberOfRoles: "3",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
    //       location: "India",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //       budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       role: "FrontEnd Developer",
    //       responsibility:
    //         "Implements the web application’s visual elements. They translate the UI/UX designs into code using technologies like HTML, CSS, and JavaScript, ensuring that the application is responsive and functions correctly across different devices and browsers.",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "HTML/CSS, JavaScript, Mobile Frameworks, Responsive Design, UI/UX Principles, Version Control, API Integration, Performance Optimization, Testing Frameworks, Development Tools",
    //       numberOfRoles: "3",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Front-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=2e3c372f-bc8a-48ca-b1b4-930854554aef",
    //       location: "India",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //       budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       role: "BackEnd Developer",
    //       responsibility:
    //         "Implements the web application’s visual elements. They translate the UI/UX designs into code using technologies like HTML, CSS, and JavaScript, ensuring that the application is responsive and functions correctly across different devices and browsers.",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "Proficiency in server-side languages (Node.js, Python, Ruby, PHP, Java),knowledge of databases (SQL, NoSQL), and experience with cloud services (AWS,Azure, Google Cloud).",
    //       numberOfRoles: "3",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Back-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=381b1558-0698-4875-88ef-388bb709ef3c",
    //       location: "India",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //       budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       role: "FullStack Developer (Optional)",
    //       responsibility:
    //         "Capable of handling both frontend and backend development. They can bridge the gap between UI design and serverside implementation, providing versatility to the development process.",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "HTML/CSS, JavaScript, Mobile Frameworks, Server-Side Languages, Back-End Frameworks, SQL Databases, NoSQL Databases, RESTful Services, GraphQL, Mobile Development Tools, Version Control, Deployment Knowledge, Debugging, Performance Optimization, Mobile Security",
    //       numberOfRoles: "3",
    //       location: "India",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Full-Stack%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=b24b1480-8210-42bf-8637-a66d3a0c60b1",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       role: "Database Administrator (DBA)",
    //       responsibility:
    //         "Manages the database systems, ensures data integrity, optimizes queries, and handles data migration, backups, and recovery processes. They ensure that the database is scalable and secure.",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "Database Design, SQL Proficiency, Performance Tuning, Backup & Recovery,Database Security, DBMS Familiarity, Data Migration",
    //       numberOfRoles: "3",
    //       location: "India",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Database%20Administrator.docx.pdf?alt=media&token=2fcbb24e-dd8d-47f8-a2e5-b60e6279653b",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       role: "DevOps Engineer",
    //       responsibility:
    //         "Focuses on the deployment, scaling, and management of the application infrastructure. They set up continuous integration/continuous deployment (CI/CD) pipelines, automate processes, and ensure that the web application is scalable and secure.",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "Cloud platforms (AWS, Azure, GCP), CI/CD tools (Jenkins, GitLab CI), and containerization (Docker, Kubernetes).",
    //       numberOfRoles: "3",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_DevOps%20Engineer_Web%20App%20Development.docx.pdf?alt=media&token=98e47f7e-242b-4c0e-9476-9c0277e889c6",
    //       location: "India",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       role: "Test (QA) Engineer",
    //       responsibility:
    //         "Tests the application to identify bugs, usability issues, and performance bottlenecks. They ensure that the application meets the required standards and functions as expected across different environments.",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "Knowledge of testing frameworks, automated testing tools, and mobile-specific testing practices. Familiarity with manual and automated testing processes.",
    //       numberOfRoles: "3",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_QA%20Engineer%20-Tester_Web%20App%20Development.docx.pdf?alt=media&token=09acc7cc-7e68-4f66-af87-4c627997f0c1",
    //       location: "India",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       role: "Security Specialist",
    //       responsibility:
    //         "Ensures the security of the web application by identifying vulnerabilities, implementing security protocols, and conducting penetration testing. They focus on protecting the application from potential threats.",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "Mobile Security Tools, Mobile Development Frameworks, Programming Languages, Security Standards and Frameworks, Authentication and Authorization, Threat Modeling and Risk Assessment, Incident Response, Secure Coding Practices, Monitoring and Logging, Network Security,",
    //       numberOfRoles: "3",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Security%20Specialist_Web%20App%20Development.docx.pdf?alt=media&token=29cbbbc8-c55d-4a0b-aea3-d36c8cebb538",
    //       location: "India",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       role: "Content Writer/Copywriter",
    //       responsibility:
    //         "Creates and curates the content for the web application. They ensure that the content is clear, engaging, and aligned with the brand’s voice and SEO best practices.",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "Clear and Concise Writing, SEO Knowledge, Adaptability, Familiarity with Web Technologies, User Experience (UX) Writing, Market Research, Competitor Analysis",
    //       numberOfRoles: "3",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_CONTENT%20WRITER.docx.pdf?alt=media&token=5221e14a-9508-4e60-9865-e454de038b3c",
    //       location: "India",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       role: "Business Analyst",
    //       responsibility:
    //         "Analyzes business needs and translates them into technical requirements. They work closely with stakeholders to ensure that the web application aligns with business objectives.",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "Requirement analysis, process modeling, and stakeholder communication.",
    //       numberOfRoles: "3",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Business%20Analyst.docx.pdf?alt=media&token=9f2155e8-fe06-4427-8dd3-35c0cab54d82",
    //       location: "India",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       role: "Product Owner",
    //       responsibility:
    //         "Represents the stakeholders and endusers, prioritizes features, and ensures that the development team is working on the most valuable aspects of the application. They manage the product backlog and help with decisionmaking.	",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "Agile Methodologies, User Story Development, Roadmapping, Market Research and Analysis, Understanding of Mobile Technologies, API Knowledge, Analytics and Metrics, User Experience (UX) Principles, Stakeholder Management, Problem-Solving Skills",
    //       numberOfRoles: "3",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Product%20Owner%20_Web%20App.docx.pdf?alt=media&token=84236fff-c442-42d0-9b77-cc2c2e1b9c58",
    //       location: "India",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       role: "System Architect",
    //       responsibility:
    //         "Designs the overall architecture of the web application, making key decisions about the tech stack, frameworks, and infrastructure. They ensure that the application is scalable, maintainable, and aligned with the business goals.",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "Mobile Development Frameworks, Architectural Frameworks, Backend Technologies, Database Management, Cloud Platforms, DevOps Practices, Security Architecture, Performance Optimization, User Experience (UX) Considerations, Monitoring and Logging",
    //       numberOfRoles: "3",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_System%20Architect%20_Web%20App.docx.pdf?alt=media&token=788c0bdb-fec9-4eec-a986-faab13d78f34",
    //       location: "India",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //   ],
    // },
    // {
    //   name: "Web Development",
    //   roles: [
    //     {
    //       role: "Project Manager",
    //       responsibility:
    //         "Oversees the entire project, manages timelines, coordinates team efforts, ensures that the project stays within scope and budget, and serves as a communication link between stakeholders and the development team.",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "Web Development Knowledge,content management systems,Stakeholder Management,Project Planning & Management",
    //       numberOfRoles: "3",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
    //       location: "India",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       role: "UI/UX Designer",
    //       responsibility:
    //         "Focuses on user interface (UI) design and user experience (UX). They create wireframes, mockups, and prototypes, ensuring that the web application is userfriendly, intuitive, and visually appealing.",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "User Research and Analysis,Wireframing and Prototyping,Visual Design",
    //       numberOfRoles: "3",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
    //       location: "India",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       role: "FrontEnd Developer",
    //       responsibility:
    //         "Implements the web application’s visual elements. They translate the UI/UX designs into code using technologies like HTML, CSS, and JavaScript, ensuring that the application is responsive and functions correctly across different devices and browsers.",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "HTML5, CSS3, JavaScript, frameworks like React, Angular, or Vue.js, knowledge of responsive design principles (e.g., Bootstrap or CSS Grid).",
    //       numberOfRoles: "3",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Front-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=2e3c372f-bc8a-48ca-b1b4-930854554aef",
    //       location: "India",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       role: "BackEnd Developer",
    //       responsibility:
    //         "Implements the web application’s visual elements. They translate the UI/UX designs into code using technologies like HTML, CSS, and JavaScript, ensuring that the application is responsive and functions correctly across different devices and browsers.",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "Programming languages such as Node.js, Python, Ruby, or PHP, and databases like MySQL, MongoDB, or PostgreSQL.",
    //       numberOfRoles: "3",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Back-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=381b1558-0698-4875-88ef-388bb709ef3c",
    //       location: "India",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       role: "FullStack Developer (Optional)",
    //       responsibility:
    //         "Capable of handling both frontend and backend development. They can bridge the gap between UI design and serverside implementation, providing versatility to the development process.",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "Front-End Developmen&tBack-End DevelopmenT,Database Management,Agile Development Methodologies,API Design and Architecture",
    //       numberOfRoles: "3",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Full-Stack%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=b24b1480-8210-42bf-8637-a66d3a0c60b1",
    //       location: "India",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       role: "Database Administrator (DBA)",
    //       responsibility:
    //         "Manages the database systems, ensures data integrity, optimizes queries, and handles data migration, backups, and recovery processes. They ensure that the database is scalable and secure.",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "Database Design and Architecture,SQL,Database Scaling and High Availability,Backup and recovery,Database Performance Tuning",
    //       numberOfRoles: "3",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Database%20Administrator.docx.pdf?alt=media&token=2fcbb24e-dd8d-47f8-a2e5-b60e6279653b",
    //       location: "India",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       role: "DevOps Engineer",
    //       responsibility:
    //         "Focuses on the deployment, scaling, and management of the application infrastructure. They set up continuous integration/continuous deployment (CI/CD) pipelines, automate processes, and ensure that the web application is scalable and secure.",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "Knowledge of cloud platforms (AWS, Azure, Google Cloud), CI/CD pipelines, version control systems (Git), and server management.",
    //       numberOfRoles: "3",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_DevOps%20Engineer_Web%20App%20Development.docx.pdf?alt=media&token=98e47f7e-242b-4c0e-9476-9c0277e889c6",
    //       location: "India",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       role: "Test (QA) Engineer",
    //       responsibility:
    //         "Tests the application to identify bugs, usability issues, and performance bottlenecks. They ensure that the application meets the required standards and functions as expected across different environments.",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "Knowledge of testing frameworks (Selenium, Cypress), manual testing, and understanding of responsive testing tools.",
    //       numberOfRoles: "3",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_QA%20Engineer%20-Tester_Web%20App%20Development.docx.pdf?alt=media&token=09acc7cc-7e68-4f66-af87-4c627997f0c1",
    //       location: "India",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       role: "Security Specialist",
    //       responsibility:
    //         "Ensures the security of the web application by identifying vulnerabilities, implementing security protocols, and conducting penetration testing. They focus on protecting the application from potential threats.",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "Web Application Security Fundamentals,VAPT,Authentication and Authorization,Encryption and Data Protection,SDLC,Firewall and Intrusion Detection,API AND CLOUD Security",
    //       numberOfRoles: "3",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Security%20Specialist_Web%20App%20Development.docx.pdf?alt=media&token=29cbbbc8-c55d-4a0b-aea3-d36c8cebb538",
    //       location: "India",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       role: "Content Writer/Copywriter",
    //       responsibility:
    //         "Creates and curates the content for the web application. They ensure that the content is clear, engaging, and aligned with the brand’s voice and SEO best practices.",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "Content Writing,SEO,CMS,UX Writing and Microcopy,Social Media and Content Distribution,Content Strategy and Planning",
    //       numberOfRoles: "3",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_CONTENT%20WRITER.docx.pdf?alt=media&token=5221e14a-9508-4e60-9865-e454de038b3c",
    //       location: "India",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       role: "Business Analyst",
    //       responsibility:
    //         "Analyzes business needs and translates them into technical requirements. They work closely with stakeholders to ensure that the web application aligns with business objectives.",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "Requirements gathering, process mapping, and strong communication skills.",
    //       numberOfRoles: "3",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Business%20Analyst.docx.pdf?alt=media&token=9f2155e8-fe06-4427-8dd3-35c0cab54d82",
    //       location: "India",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       role: "Product Owner",
    //       responsibility:
    //         "Represents the stakeholders and endusers, prioritizes features, and ensures that the development team is working on the most valuable aspects of the application. They manage the product backlog and help with decisionmaking.	",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "Product Vision and Strategy,. Requirement Gathering and Definition,Backlog ManagemenT, Stakeholder Management,Agile and Scrum Methodologies,Data-Driven Decision Making",
    //       numberOfRoles: "3",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Product%20Owner%20_Web%20App.docx.pdf?alt=media&token=84236fff-c442-42d0-9b77-cc2c2e1b9c58",
    //       location: "India",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       role: "System Architect",
    //       responsibility:
    //         "Designs the overall architecture of the web application, making key decisions about the tech stack, frameworks, and infrastructure. They ensure that the application is scalable, maintainable, and aligned with the business goals.",
    //       jobDescription: "Responsible for building Manual Testing...",
    //       skill:
    //         "System Design and Architecture,DevOps and CI/CD,Front-End and Back-End Technologies,Process Mapping and Workflow Design,Agile Methodologie,Wireframing and Prototyping,Stakeholder Management",
    //       numberOfRoles: "3",
    //       url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_System%20Architect%20_Web%20App.docx.pdf?alt=media&token=788c0bdb-fec9-4eec-a986-faab13d78f34",
    //       location: "India",
    //       yearsOfExperience: "2",
    //       availability: "next month",
    //       modeOfEngagement: "Full-time",
    //       workingHours: "Flexible with working hours",
    //       // projectDuration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budgetPerRole: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //   ],
    // },
    {
      name: "Website Development",
      roles: [
        {
          role: "Project Manager",
          responsibility:
            "Oversees the entire web development process, from gathering requirements to delivery. Ensures that the project stays on track, within budget, and meets deadlines.	",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "Web Development Knowledge,content management systems,Stakeholder Management,Project Planning & Management",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        {
          role: "UI/UX Designer",
          responsibility:
            "Focuses on user interface (UI) and user experience (UX) design. They create wireframes, prototypes, and mockups to ensure the website is intuitive, aesthetically pleasing, and easy to navigate on various devices.",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "User Research and Analysis,Wireframing and Prototyping,Visual Design	",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        {
          role: "FrontEnd Developer",
          responsibility:
            "Implements the website’s design using HTML, CSS, and JavaScript. They focus on ensuring the site is responsive, interactive, and compatible across different devices and browsers.",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "HTML5, CSS3, JavaScript, frameworks like React, Angular, or Vue.js, knowledge of responsive design principles (e.g., Bootstrap or CSS Grid).",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Front-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=2e3c372f-bc8a-48ca-b1b4-930854554aef",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        {
          role: "BackEnd Developer",
          responsibility:
            "Works on the server side of the website, building databases, APIs, and server-side logic. They ensure that the website functions properly, processes data, and interacts with the front-end seamlessly.",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "Programming languages such as Node.js, Python, Ruby, or PHP, and databases like MySQL, MongoDB, or PostgreSQL.",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Back-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=381b1558-0698-4875-88ef-388bb709ef3c",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        {
          role: "FullStack Developer (Optional)",
          responsibility:
            "A full-stack developer has the skills to work on both the front-end and back-end, allowing for a more integrated development process. They can manage the website as a whole and streamline communication between the two areas.",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "Front-End Developmen&tBack-End DevelopmenT,Database Management,Agile Development Methodologies,API Design and Architecture",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Full-Stack%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=b24b1480-8210-42bf-8637-a66d3a0c60b1",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },

        {
          role: "DevOps Engineer",
          responsibility:
            "	Focuses on the deployment, management, and scaling of the website. They ensure that the site is secure, efficiently hosted, and runs smoothly under different traffic loads.",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "Knowledge of cloud platforms (AWS, Azure, Google Cloud), CI/CD pipelines, version control systems (Git), and server management.",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_DevOps%20Engineer_Web%20App%20Development.docx.pdf?alt=media&token=98e47f7e-242b-4c0e-9476-9c0277e889c6",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        {
          role: "Test (QA) Engineer",
          responsibility:
            "Ensures that the website is free of bugs and performs well across different devices, browsers, and conditions. They conduct manual and automated testing for functionality, usability, security, and performance.",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "Knowledge of testing frameworks (Selenium, Cypress), manual testing, and understanding of responsive testing tools.",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_QA%20Engineer%20-Tester_Web%20App%20Development.docx.pdf?alt=media&token=09acc7cc-7e68-4f66-af87-4c627997f0c1",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        {
          role: "Security Specialist",
          responsibility:
            "Ensures that the website is secure from threats like hacking, malware, and data breaches. They set up SSL certificates, firewalls, and securitypatches.",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "Web Application Security Fundamentals,VAPT,Authentication and Authorization,Encryption and Data Protection,SDLC,Firewall and Intrusion Detection,API AND CLOUD Security",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Security%20Specialist_Web%20App%20Development.docx.pdf?alt=media&token=29cbbbc8-c55d-4a0b-aea3-d36c8cebb538",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },

        {
          role: "Graphic Designer/Illustrator",
          responsibility:
            "Creates custom graphics, logos, icons, and other visual elements. They ensure that the visual elements align with the website’s branding and are responsive across devices.",
          jobDescription: "Responsible for building Manual Testing...",
          skill: "",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_System%20Architect%20_Web%20App.docx.pdf?alt=media&token=788c0bdb-fec9-4eec-a986-faab13d78f34",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
      ],
    },
    {
      name: "Mobile App Development",
      roles: [
        {
          role: "Project Manager",
          responsibility:
            "Oversees the entire project, manages timelines, coordinates team efforts, ensures that the project stays within scope and budget, and serves as a communication link between stakeholders and the development team.",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "Project management methodologies (Agile, Scrum), communication, and team coordination.",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
          numberOfRoles: "3",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        {
          role: "UI/UX Designer",
          responsibility:
            "Focuses on user interface (UI) design and user experience (UX). They create wireframes, mockups, and prototypes, ensuring that the web application is userfriendly, intuitive, and visually appealing.",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "Graphic design, user experience (UX) research, wireframing, prototyping, and using design tools like Sketch, Figma, or Adobe XD.",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        {
          role: "Mobile App Developer",
          responsibility:
            "Writes the code to build the application. Depending on the platform (iOS, Android, or cross-platform), different types of developers are required: - iOS Developer: Specializes in building apps for Apple’s iOS platform using Swift or Objective-C. - Android Developer: Specializes in building apps for Android devices using Kotlin or Java. - Cross-Platform Developer: Builds apps that run on both iOS and Android using frameworks like Flutter or React Native.",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "Proficiency in platform-specific programming languages (Swift, Kotlin, Java) or cross-platform frameworks (Flutter, React Native).",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        {
          role: "BackEnd Developer",
          responsibility:
            "Manages the server-side of the app, including databases, APIs, and server logic that supports the app’s functionality. They handle data storage, authentication, and communication between the app and the server.",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "Proficiency in server-side languages (Node.js, Python, Ruby, PHP, Java), knowledge of databases (SQL, NoSQL), and experience with cloud services (AWS, Azure, Google Cloud).",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        {
          role: "Test (QA) Engineer",
          responsibility:
            "Tests the application to identify bugs, usability issues, and performance bottlenecks. They ensure that the application meets the required standards and functions as expected across different environments.",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "Knowledge of testing frameworks, automated testing tools, and mobile- specific testing practices. Familiarity with manual and automated testing processes.",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        {
          role: "DevOps Engineer",
          responsibility:
            "Focuses on the deployment, scaling, and management of the application infrastructure. They set up continuous integration/continuous deployment (CI/CD) pipelines, automate processes, and ensure that the web application is scalable and secure.",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "Experience with CI/CD tools (Jenkins, GitLab CI), cloud platforms (AWS,Azure, Google Cloud), and containerization (Docker, Kubernetes).",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        {
          role: "Business Analyst",
          responsibility:
            "Analyzes business needs and translates them into technical requirements. They work closely with stakeholders to ensure that the web application aligns with business objectives.",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "Requirement analysis, process modeling, and stakeholder communication.",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        {
          role: "Product Manager",
          responsibility:
            "Represents the stakeholders and endusers, prioritizes features, and ensures that the development team is working on the most valuable aspects of the application. They manage the product backlog and help with decision making.",
          jobDescription: "Responsible for building Manual Testing...",
          skill: "Market research, product strategy, and business acumen.",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
        {
          role: "Security Specialist",
          responsibility:
            "Ensures the security of the mobile application by identifying vulnerabilities, implementing security protocols, and conducting penetration testing. They focus on protecting the application from potential threats.",
          jobDescription: "Responsible for building Manual Testing...",
          skill:
            "Mobile Security Tools, Mobile Development Frameworks, Programming Languages, Security Standards and Frameworks, Authentication and Authorization, Threat Modeling and Risk Assessment, Incident Response, Secure Coding Practices, Monitoring and Logging, Network Security.",
          numberOfRoles: "3",
          url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          yearsOfExperience: "2",
          availability: "next month",
          modeOfEngagement: "Full-time",
          workingHours: "Flexible with working hours",
          // projectDuration: 0,
          gender: "",
          country: "",
          city: "",
          budgetPerRole: "1000",
          browse: "",
          profiles: [],
        },
      ],
    },
  ]);

  const [jobdesdata, setjobdesdata] = useState("");
  const [gender, setgender] = useState("");
  const [country, setcountry] = useState("");
  const [city, setcity] = useState("");
  const [searchdata, setsearchdata] = useState(null);

  const overlayHandlerNew = (teamIndex, roleIndex, field, value, data) => {
    setresponsibilitydata({
      teamIndex: teamIndex,
      roleIndex: roleIndex,
      field: field,
      data: data,
    });
    dispatch(storeAction.isPopUpHander(value));
  };
  const overlayHandlerNew1 = (
    teamIndex,
    roleIndex,
    field,
    value,
    data1,
    data2,
    data3
  ) => {
    setresponsibilitydata({
      teamIndex: teamIndex,
      roleIndex: roleIndex,
      field: field,
      data1: data1,
      data2: data2,
      data3: data3,
    });
    dispatch(storeAction.isPopUpHander(value));
  };
  const changeresponsibility = () => {
    if (newresponsibilitydata.length !== 0) {
      const { teamIndex, roleIndex, field } = responsibilitydata;
      const updatedTeams = teamlist.map((team, tIndex) => {
        if (tIndex === teamIndex) {
          const updatedRoles = team.roles.map((role, rIndex) => {
            if (rIndex === roleIndex) {
              return {
                ...role,
                [field]: newresponsibilitydata,
              };
            }
            return role;
          });

          return {
            ...team,
            roles: updatedRoles,
          };
        }
        return team;
      });
      setteamlist(updatedTeams);

      if (teamlist[teamIndex].name === selectedTeam.name) {
        setSelectedTeam((prevState) => ({
          ...prevState,
          roles: prevState.roles.map((role, rIndex) =>
            rIndex === roleIndex
              ? { ...role, [field]: newresponsibilitydata }
              : role
          ),
        }));
      }
      dispatch(storeAction.isPopUpHander());
    } else {
      alert("Please Enter Responsibility...");
    }
  };

  const changeJobdescription = () => {
    if (jobdesdata.length !== 0) {
      const { teamIndex, roleIndex, field } = responsibilitydata;
      const updatedTeams = teamlist.map((team, tIndex) => {
        if (tIndex === teamIndex) {
          const updatedRoles = team.roles.map((role, rIndex) => {
            if (rIndex === roleIndex) {
              return {
                ...role,
                jobDescription: jobdesdata,
              };
            }
            return role;
          });

          return {
            ...team,
            roles: updatedRoles,
          };
        }
        return team;
      });
      setteamlist(updatedTeams);

      if (teamlist[teamIndex].name === selectedTeam.name) {
        setSelectedTeam((prevState) => ({
          ...prevState,
          roles: prevState.roles.map((role, rIndex) =>
            rIndex === roleIndex
              ? { ...role, jobDescription: jobdesdata }
              : role
          ),
        }));
      }
      dispatch(storeAction.isPopUpHander());
      setjobdesdata("");
    } else {
      alert("Please Enter Job Description...");
    }
  };
  const changefilter = () => {
    // Validate fields
    if (gender.length === 0) {
      alert("Please Select Gender");
      return; // Exit the function if validation fails
    }
    if (country.length === 0) {
      alert("Please Select Nationality");
      return; // Exit the function if validation fails
    }
    if (city.length === 0) {
      alert("Please Select Location");
      return; // Exit the function if validation fails
    }

    const { teamIndex, roleIndex, field } = responsibilitydata;

    // Update the team list immutably
    const updatedTeams = teamlist.map((team, tIndex) => {
      if (tIndex === teamIndex) {
        const updatedRoles = team.roles.map((role, rIndex) => {
          if (rIndex === roleIndex) {
            return {
              ...role,
              gender: gender, // Update gender
              country: country, // Update country
              city: city, // Update city
              [field]: jobdesdata || "", // Update based on field, default to empty string if jobdesdata is falsy
            };
          }
          return role;
        });

        return {
          ...team,
          roles: updatedRoles,
        };
      }
      return team;
    });

    // Set updated team list
    setteamlist(updatedTeams);
    setgender(gender);
    setcity(city);
    setcountry(country);

    // Synchronize selectedTeam if applicable
    if (selectedTeam && teamlist[teamIndex].name === selectedTeam.name) {
      const updatedSelectedRoles = selectedTeam.roles.map((role, rIndex) =>
        rIndex === roleIndex
          ? { ...role, gender, country, city, [field]: jobdesdata || "" }
          : role
      );

      setSelectedTeam((prevState) => ({
        ...prevState,
        roles: updatedSelectedRoles,
      }));
    }

    // Dispatch any necessary actions
    dispatch(storeAction.isPopUpHander());

    // Clear job description data
    setjobdesdata("");
  };

  const exitOverlayHandler = () => {
    dispatch(storeAction.isPopUpHander());
  };
  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });

  async function dropDownHandler() {
    const inputvalue = inputref.current.value.toLowerCase();
    setdropDown("");
    if (inputvalue.length !== 0) {
      var skillarrray = Skilllist;
      const uniqueSkills = Array.from(
        new Set(skillarrray.map((skill) => skill.Skill))
      );
      const inputvalueLower = inputvalue.toLowerCase();
      const matchingSkills = uniqueSkills.filter(
        (skill) =>
          typeof skill === "string" &&
          skill.toLowerCase().includes(inputvalueLower)
      );
      setdropDownClose(matchingSkills);
      setdropDown(inputvalue.length > 0 && matchingSkills.length > 0);
    } else {
      setdropDown(inputvalue.length > 0);
    }
  }

  function filterdata1() {
    setdropDown(!dropDown);
  }

  function getdata(event) {
    const getvalue = event;
    setdropDown(false);
    const updatedItems = skill.includes(getvalue)
      ? skill.filter((data) => data !== getvalue)
      : [...skill, getvalue];
    setskill(updatedItems);
    inputref.current.value = "";
  }

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords =
    allcandidatedata.length !== 0
      ? allcandidatedata.slice(indexOfFirstRecord, indexOfLastRecord)
      : [];
  const nPages = Math.ceil(allcandidatedata.length / recordsPerPage);
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  const [selectedTeam, setSelectedTeam] = useState(teamlist[0]);
  const [teamindex, setteamindex] = useState(0);

  const ButtonHandler = (teamName, index) => {
    const team = teamlist.find((team) => team.name === teamName);
    setSelectedTeam(team);
    setteamindex(index);
  };

  const addRole = (teamIndex) => {
    const newRole = {
      role: "",
      responsibility: "",
      jobDescription: "",
      numberOfRoles: 1,
      location: "",
      yearsOfExperience: "",
      availability: "",
      modeOfEngagement: "",
      workingHours: "",
      projectDuration: "",
      gender: "",
      country: "",
      city: "",
      budgetPerRole: "",
      profiles: [],
    };

    const updatedTeams = teamlist.map((team, index) => {
      if (index === teamIndex) {
        return { ...team, roles: [...team.roles, newRole] };
      }
      return team;
    });
    setSelectedTeam((prevState) => ({
      ...prevState,
      roles: [...prevState.roles, newRole],
    }));

    setteamlist(updatedTeams);
  };

  const removeRole = (teamIndex, roleIndex) => {
    const updatedTeams = teamlist.map((team, index) => {
      if (index === teamIndex) {
        return {
          ...team,
          roles: team.roles.filter((_, i) => i !== roleIndex),
        };
      }
      return team;
    });

    setteamlist(updatedTeams);
    setSelectedTeam((prevState) => ({
      ...prevState,
      roles: prevState.roles.filter((_, i) => i !== roleIndex),
    }));
  };

  const handleChangeNew = (teamIndex, roleIndex, field, value) => {
    // Update the team list with the new role values
    const updatedTeams = teamlist.map((team, tIndex) => {
      if (tIndex === teamIndex) {
        const updatedRoles = team.roles.map((role, rIndex) => {
          if (rIndex === roleIndex) {
            return {
              ...role,
              [field]: value, // Update only the specific field (role, responsibility, etc.)
            };
          }
          return role;
        });

        return {
          ...team,
          roles: updatedRoles, // Update the roles in the team
        };
      }
      return team;
    });

    // Update the teamlist and selected team if it matches the currently selected team
    setteamlist(updatedTeams);

    if (teamlist[teamIndex].name === selectedTeam.name) {
      setSelectedTeam((prevState) => ({
        ...prevState,
        roles: prevState.roles.map((role, rIndex) =>
          rIndex === roleIndex ? { ...role, [field]: value } : role
        ),
      }));
    }
  };
  const fileInputRef = useRef(null);

  const uploadHandler = () => {
    fileInputRef.current.click();
  };
  const getTextFromPage = async (page) => {
    const textContent = await page.getTextContent();
    const textItems = textContent.items.map((item) => item.str);
    return textItems.join(" ");
  };
  const handlePdfUpload = (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      const reader = new FileReader();
      reader.onload = async () => {
        const typedArray = new Uint8Array(reader.result);
        const pdf = await getDocument(typedArray).promise;
        let extractedText = "";
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const pageText = await getTextFromPage(page);
          extractedText += pageText + "\n";
        }
        setjobdesdata(extractedText);
        await parseQuery(extractedText);
      };

      reader.readAsArrayBuffer(file);
    } else {
      alert("Please upload a valid PDF file.");
    }
  };

  const parseQuery = async (text) => {
    const qualificationPatterns = [
      /React\s+Developer/i,
      /Java\s+Developer/i,
      /Frontend\s+Developer/i,
      /Backend\s+Developer/i,
      /Full\s+Stack\s+Developer/i,
      /Software\s+Engineer/i,
      /JavaScript\s+Developer/i,
      /Python\s+Developer/i,
      /Data\s+Scientist/i,
      /DevOps\s+Engineer/i,
      /Product\s+Manager/i,
      /Technical\s+Lead/i,
      /(Senior\s+\w+\s+\w+)/i,
      /(Backend\s+Engineer)/i,
      /(\w+\s+\w+\s+Engineer)/i,
      /(Frontend\s+Developer)/i,
      /(Full\s+Stack\s+Developer)/i,
      /(React\s+Developer)/i,
      /(\w+\s+\w+\s+Developer)/i,
      /(\w+\s+Manager)/i,
      /(\w+\s+Lead)/i,
      /Blockchain\s+Developer/i,
      /Blockchain\s+Engineer/i,
      /Game\s+Developer/i,
      /VR\s+Developer/i,
      /AR\s+Developer/i,
      /Embedded\s+Systems\s+Engineer/i,
      /Hardware\s+Engineer/i,
      /Robotics\s+Engineer/i,
      /IoT\s+Engineer/i,
      /Systems\s+Administrator/i,
      /Database\s+Administrator/i,
      /QA\s+Engineer/i,
      /QA\s+Tester/i,
      /Automation\s+Tester/i,
      /Performance\s+Tester/i,
      /API\s+Tester/i,
      /Test\s+Automation\s+Engineer/i,
      /Security\s+Engineer/i,
      /Cyber\s+Security\s+Engineer/i,
      /Information\s+Security\s+Engineer/i,
      /Cloud\s+Security\s+Engineer/i,
      /Security\s+Architect/i,
      /Security\s+Consultant/i,
      /Penetration\s+Tester/i,
      /Ethical\s+Hacker/i,
      /Vulnerability\s+Analyst/i,
      /SOC\s+Analyst/i,
      /Network\s+Security\s+Engineer/i,
      /Application\s+Security\s+Engineer/i,
      /Product\s+Manager/i,
      /Project\s+Manager/i,
      /Program\s+Manager/i,
      /Technical\s+Lead/i,
      /Engineering\s+Manager/i,
      /Team\s+Lead/i,
      /CTO/i,
      /Chief\s+Technology\s+Officer/i,
      /VP\s+of\s+Engineering/i,
      /Director\s+of\s+Engineering/i,
      /Technical\s+Project\s+Manager/i,
      /Operations\s+Manager/i,
      /Product\s+Owner/i,
      /Scrum\s+Master/i,
      /Data\s+Scientist/i,
      /Data\s+Engineer/i,
      /Data\s+Analyst/i,
      /Machine\s+Learning\s+Engineer/i,
      /AI\s+Engineer/i,
      /Deep\s+Learning\s+Engineer/i,
      /NLP\s+Engineer/i,
      /Big\s+Data\s+Engineer/i,
      /Data\s+Architect/i,
      /Business\s+Intelligence\s+Engineer/i,
      /BI\s+Developer/i,
      /BI\s+Analyst/i,
      /ETL\s+Developer/i,
      /Data\s+Warehouse\s+Engineer/i,
      /ML\s+Engineer/i,
      /AI\s+Researcher/i,
      /Computer\s+Vision\s+Engineer/i,
      /Cloud\s+Engineer/i,
      /DevOps\s+Engineer/i,
      /AWS\s+Engineer/i,
      /Azure\s+Engineer/i,
      /GCP\s+Engineer/i,
      /Site\s+Reliability\s+Engineer/i,
      /Infrastructure\s+Engineer/i,
      /Kubernetes\s+Engineer/i,
      /Docker\s+Engineer/i,
      /Cloud\s+Architect/i,
      /Platform\s+Engineer/i,
      /Cloud\s+Consultant/i,
      /CI\/CD\s+Engineer/i,
      /Automation\s+Engineer/i,
      /Cloud\s+Operations\s+Engineer/i,
      /Linux\s+Engineer/i,
      /Unix\s+Engineer/i,
      /React\s+Developer/i,
      /Angular\s+Developer/i,
      /Vue\s+Developer/i,
      /Java\s+Developer/i,
      /Node\s+Developer/i,
      /JavaScript\s+Developer/i,
      /TypeScript\s+Developer/i,
      /Python\s+Developer/i,
      /PHP\s+Developer/i,
      /Ruby\s+Developer/i,
      /C#\s+Developer/i,
      /Frontend\s+Developer/i,
      /Backend\s+Developer/i,
      /Full\s+Stack\s+Developer/i,
      /Software\s+Engineer/i,
      /Frontend\s+Engineer/i,
      /Backend\s+Engineer/i,
      /Mobile\s+Developer/i,
      /iOS\s+Developer/i,
      /Android\s+Developer/i,
      /Flutter\s+Developer/i,
      /Swift\s+Developer/i,
      /Kotlin\s+Developer/i,
      /Go\s+Developer/i,
      /Rust\s+Developer/i,
      /Scala\s+Developer/i,
      /Full\s+Stack\s+Engineer/i,
      /Tech\s+Lead/i,
      /Frontend\s+Architect/i,
      /Backend\s+Architect/i,
      /Web\s+Developer/i,
      /UI\s+Developer/i,
      /UX\s+Developer/i,
      /UI \/UX\s+Designer/i,
      /HTML\s+Developer/i,
      /CSS\s+Developer/i,
      /Sass\s+Developer/i,
      /Bootstrap\s+Developer/i,
      /WordPress\s+Developer/i,
      /Shopify\s+Developer/i,
      /Magento\s+Developer/i,
      /Drupal\s+Developer/i,
      /Joomla\s+Developer/i,
      /CMS\s+Developer/i,
      /QA\s+Engineer/i,
      /QA\s+Tester/i,
      /Automation\s+Tester/i,
      /Performance\s+Tester/i,
      /API\s+Tester/i,
      /Test\s+Automation\s+Engineer/i,
      /Manual\s+Tester/i,
      /Functional\s+Tester/i,
      /Non-Functional\s+Tester/i,
      /Load\s+Tester/i,
      /Stress\s+Tester/i,
      /Performance\s+Engineer/i,
      /Software\s+Tester/i,
      /Web\s+Tester/i,
      /Mobile\s+Tester/i,
      /Game\s+Tester/i,
      /UI\s+Tester/i,
      /UX\s+Tester/i,
      /End-to-End\s+Tester/i,
      /Regression\s+Tester/i,
      /Exploratory\s+Tester/i,
      /Integration\s+Tester/i,
      /System\s+Tester/i,
      /Acceptance\s+Tester/i,
      /User\s+Acceptance\s+Tester/i,
      /Black\s+Box\s+Tester/i,
      /White\s+Box\s+Tester/i,
      /Gray\s+Box\s+Tester/i,
      /Smoke\s+Tester/i,
      /Sanity\s+Tester/i,
      /Security\s+Tester/i,
      /Penetration\s+Tester/i,
      /Vulnerability\s+Tester/i,
      /Ethical\s+Hacker/i,
      /Cyber\s+Security\s+Tester/i,
      /Network\s+Security\s+Tester/i,
      /Application\s+Security\s+Tester/i,
      /Cloud\s+Security\s+Tester/i,
      /Database\s+Tester/i,
      /Data\s+Warehouse\s+Tester/i,
      /ETL\s+Tester/i,
      /Big\s+Data\s+Tester/i,
      /Data\s+Integrity\s+Tester/i,
      /Data\s+Validation\s+Tester/i,
      /Performance\s+Load\s+Tester/i,
      /Scalability\s+Tester/i,
      /Reliability\s+Tester/i,
      /Availability\s+Tester/i,
      /Resilience\s+Tester/i,
      /Disaster\s+Recovery\s+Tester/i,
      /Compliance\s+Tester/i,
      /Usability\s+Tester/i,
      /Compatibility\s+Tester/i,
      /Cross-Browser\s+Tester/i,
      /Cross-Platform\s+Tester/i,
      /Localization\s+Tester/i,
      /Internationalization\s+Tester/i,
      /Accessibility\s+Tester/i,
      /Mobile\s+App\s+Tester/i,
      /Web\s+App\s+Tester/i,
      /Desktop\s+App\s+Tester/i,
      /API\s+Automation\s+Tester/i,
      /UI\s+Automation\s+Tester/i,
      /Mobile\s+Automation\s+Tester/i,
      /Selenium\s+Tester/i,
      /Cypress\s+Tester/i,
      /Appium\s+Tester/i,
      /JUnit\s+Tester/i,
      /TestNG\s+Tester/i,
      /Postman\s+Tester/i,
      /SoapUI\s+Tester/i,
      /LoadRunner\s+Tester/i,
      /JMeter\s+Tester/i,
      /Katalon\s+Studio\s+Tester/i,
      /Robot\s+Framework\s+Tester/i,
      /Tosca\s+Tester/i,
      /QTP\s+Tester/i,
      /UFT\s+Tester/i,
      /Ranorex\s+Tester/i,
      /Protractor\s+Tester/i,
      /TestComplete\s+Tester/i,
      /JIRA\s+Tester/i,
      /ALM\s+Tester/i,
      /Quality\s+Center\s+Tester/i,
      /Zephyr\s+Tester/i,
      /Xray\s+Tester/i,
      /qTest\s+Tester/i,
      /TestRail\s+Tester/i,
      /SpiraTest\s+Tester/i,
      /Cucumber\s+Tester/i,
      /Gherkin\s+Tester/i,
      /BDD\s+Tester/i,
      /TDD\s+Tester/i,
      /ATDD\s+Tester/i,
      /Agile\s+Tester/i,
      /Scrum\s+Tester/i,
      /DevOps\s+Tester/i,
      /CI\/CD\s+Tester/i,
      /Continuous\s+Testing\s+Engineer/i,
      /Build\s+Verification\s+Tester/i,
      /Test\s+Data\s+Engineer/i,
      /Test\s+Analyst/i,
      /Test\s+Architect/i,
      /Test\s+Lead/i,
      /Test\s+Manager/i,
      /Test\s+Coordinator/i,
      /QA\s+Coordinator/i,
      /QA\s+Lead/i,
      /QA\s+Manager/i,
      /Automation\s+Lead/i,
      /Automation\s+Manager/i,
      /Performance\s+Lead/i,
      /Performance\s+Manager/i,
      /Security\s+Lead/i,
      /Security\s+Manager/i,
      /Penetration\s+Lead/i,
      /Penetration\s+Manager/i,
      /API\s+Lead/i,
      /API\s+Manager/i,
      /Selenium\s+Lead/i,
      /Selenium\s+Manager/i,
      /Appium\s+Lead/i,
      /Appium\s+Manager/i,
      /Mobile\s+Test\s+Lead/i,
      /Mobile\s+Test\s+Manager/i,
      /Web\s+Test\s+Lead/i,
      /Web\s+Test\s+Manager/i,
      /QA\s+Specialist/i,
      /Test\s+Automation\s+Specialist/i,
      /Performance\s+Testing\s+Specialist/i,
      /Load\s+Testing\s+Specialist/i,
      /Functional\s+Testing\s+Specialist/i,
      /Non-Functional\s+Testing\s+Specialist/i,
      /Security\s+Testing\s+Specialist/i,
      /Database\s+Testing\s+Specialist/i,
      /Mobile\s+Testing\s+Specialist/i,
      /Web\s+Testing\s+Specialist/i,
      /Accessibility\s+Testing\s+Specialist/i,
      /Regression\s+Testing\s+Specialist/i,
      /API\s+Testing\s+Specialist/i,
      /Automation\s+Consultant/i,
      /Performance\s+Consultant/i,
      /Security\s+Consultant/i,
      /QA\s+Consultant/i,
      /Test\s+Automation\s+Consultant/i,
      /Mobile\s+Test\s+Consultant/i,
      /Web\s+Test\s+Consultant/i,
      /QA\s+Advisor/i,
      /Test\s+Automation\s+Advisor/i,
      /Performance\s+Advisor/i,
      /Security\s+Advisor/i,
      /QA\s+Director/i,
      /Test\s+Automation\s+Director/i,
      /Performance\s+Director/i,
      /Security\s+Director/i,
      /QA\s+VP/i,
      /Test\s+Automation\s+VP/i,
      /Performance\s+VP/i,
      /Security\s+VP/i,
      /Head\s+of\s+QA/i,
      /Head\s+of\s+Automation\s+Testing/i,
      /Head\s+of\s+Performance\s+Testing/i,
      /Head\s+of\s+Security\s+Testing/i,
      /Principal\s+QA\s+Engineer/i,
      /Principal\s+Automation\s+Engineer/i,
      /Principal\s+Performance\s+Engineer/i,
      /Principal\s+Security\s+Engineer/i,
      /Senior\s+QA\s+Engineer/i,
      /Senior\s+Automation\s+Engineer/i,
      /Senior\s+Performance\s+Engineer/i,
      /Senior\s+Security\s+Engineer/i,
      /Lead\s+QA\s+Engineer/i,
      /Lead\s+Automation\s+Engineer/i,
      /Lead\s+Performance\s+Engineer/i,
      /Lead\s+Security\s+Engineer/i,
      /Junior\s+QA\s+Engineer/i,
      /Junior\s+Automation\s+Engineer/i,
      /Junior\s+Performance\s+Engineer/i,
      /Junior\s+Security\s+Engineer/i,
      /Software\s+Development\s+Engineer\s+in\s+Test/i,
      /SDET/i,
      /SDET\s+Engineer/i,
      /Performance\s+Tester\s+SDET/i,
      /Automation\s+SDET/i,
      /Functional\s+SDET/i,
      /Security\s+SDET/i,
      /Mobile\s+SDET/i,
      /API\s+SDET/i,
      /Test\s+Strategy\s+Architect/i,
      /Test\s+Planning\s+Architect/i,
      /Test\s+Tooling\s+Architect/i,
      /Test\s+Automation\s+Architect/i,
      /Performance\s+Test\s+Architect/i,
      /Test\s+Environment\s+Manager/i,
      /Test\s+Environment\s+Engineer/i,
      /Test\s+Environment\s+Coordinator/i,
      /Test\s+Data\s+Manager/i,
      /Test\s+Data\s+Lead/i,
      /Test\s+Data\s+Engineer/i,
      /Quality\s+Assurance\s+Analyst/i,
      /QA\s+Analyst/i,
      /Test\s+Automation\s+Analyst/i,
      /Performance\s+Analyst/i,
      /Security\s+Analyst/i,
      /Software\s+Testing\s+Analyst/i,
      /Test\s+Automation\s+Lead/i,
      /Lead\s+Software\s+Tester/i,
      /Senior\s+Software\s+Tester/i,
      /Mobile\s+App\s+QA\s+Engineer/i,
      /Mobile\s+App\s+Tester/i,
      /Web\s+Application\s+QA\s+Engineer/i,
      /UI\/UX\s+Tester/i,
      /E2E\s+Tester/i,
      /SAP\s+Tester/i,
      /Salesforce\s+QA\s+Engineer/i,
      /SAP\s+Testing\s+Consultant/i,
      /Test\s+Lab\s+Manager/i,
      /Test\s+Lab\s+Engineer/i,
      /Defect\s+Manager/i,
      /Test\s+Defect\s+Manager/i,
      /Test\s+Defect\s+Engineer/i,
      /Incident\s+Manager/i,
      /Release\s+Manager/i,
      /QA\s+Release\s+Manager/i,
      /Regression\s+Lead/i,
      /Build\s+Manager/i,
      /QA\s+Build\s+Manager/i,
      /Software\s+Quality\s+Engineer/i,
      /QA\s+Technician/i,
      /Automation\s+Technician/i,
      /Performance\s+Technician/i,
      /Security\s+Technician/i,
      /QA\s+Specialist/i,
      /Test\s+Coordinator/i,
      /Integration\s+Test\s+Lead/i,
      /Integration\s+Test\s+Engineer/i,
      /API\s+Test\s+Engineer/i,
      /Mobile\s+Test\s+Lead/i,
      /Mobile\s+Test\s+Engineer/i,
      /Software\s+Developer/i,
      /Software\s+Engineer/i,
      /Full\s+Stack\s+Developer/i,
      /Front\s+End\s+Developer/i,
      /Back\s+End\s+Developer/i,
      /Mobile\s+Developer/i,
      /iOS\s+Developer/i,
      /Android\s+Developer/i,
      /Java\s+Developer/i,
      /Python\s+Developer/i,
      /JavaScript\s+Developer/i,
      /Node\s+JS\s+Developer/i,
      /React\s+Developer/i,
      /Angular\s+Developer/i,
      /Vue\s+JS\s+Developer/i,
      /PHP\s+Developer/i,
      /C#\s+Developer/i,
      /Go\s+Developer/i,
      /Ruby\s+Developer/i,
      /Swift\s+Developer/i,
      /Kotlin\s+Developer/i,
      /Game\s+Developer/i,
      /Blockchain\s+Developer/i,
      /DevOps\s+Engineer/i,
      /Site\s+Reliability\s+Engineer/i,
      /Cloud\s+Engineer/i,
      /Data\s+Engineer/i,
      /AI\/ML\s+Engineer/i,
      /Machine\s+Learning\s+Engineer/i,
      /Deep\s+Learning\s+Engineer/i,
      /Data\s+Scientist/i,
      /Data\s+Analyst/i,
      /Business\s+Analyst/i,
      /System\s+Analyst/i,
      /Product\s+Manager/i,
      /Project\s+Manager/i,
      /Scrum\s+Master/i,
      /Agile\s+Coach/i,
      /Network\s+Engineer/i,
      /Systems\s+Administrator/i,
      /IT\s+Support/i,
      /Helpdesk\s+Technician/i,
      /Network\s+Administrator/i,
      /Database\s+Administrator/i,
      /Security\s+Analyst/i,
      /Cybersecurity\s+Specialist/i,
      /IT\s+Manager/i,
      /System\s+Engineer/i,
      /Cloud\s+Architect/i,
      /Infrastructure\s+Engineer/i,
      /Technical\s+Writer/i,
      /UX\s+Designer/i,
      /UI\s+Designer/i,
      /Graphic\s+Designer/i,
      /Web\s+Designer/i,
      /Game\s+Designer/i,
      /Game\s+Tester/i,
      /Application\s+Support/i,
      /Release\s+Manager/i,
      /IT\s+Consultant/i,
      /ERP\s+Consultant/i,
      /SAP\s+Consultant/i,
      /Accountant/i,
      /Financial\s+Analyst/i,
      /Auditor/i,
      /Tax\s+Consultant/i,
      /Payroll\s+Specialist/i,
      /Bookkeeper/i,
      /Finance\s+Manager/i,
      /CFO/i,
      /Investment\s+Analyst/i,
      /Risk\s+Analyst/i,
      /Doctor/i,
      /Nurse/i,
      /Surgeon/i,
      /Pharmacist/i,
      /Medical\s+Technologist/i,
      /Healthcare\s+Administrator/i,
      /Dentist/i,
      /Physiotherapist/i,
      /Veterinarian/i,
      /Radiologist/i,
      /Marketing\s+Manager/i,
      /Digital\s+Marketer/i,
      /SEO\s+Specialist/i,
      /Content\s+Writer/i,
      /Social\s+Media\s+Manager/i,
      /Sales\s+Executive/i,
      /Account\s+Executive/i,
      /Sales\s+Manager/i,
      /Business\s+Development\s+Manager/i,
      /Product\s+Marketing\s+Manager/i,
      /Brand\s+Manager/i,
      /HR\s+Manager/i,
      /HR\s+Specialist/i,
      /HR\s+Assistant/i,
      /Recruiter/i,
      /Talent\s+Acquisition/i,
      /Office\s+Administrator/i,
      /Administrative\s+Assistant/i,
      /Office\s+Manager/i,
      /Operations\s+Manager/i,
      /Supply\s+Chain\s+Manager/i,
      /Logistics\s+Coordinator/i,
      /Warehouse\s+Manager/i,
      /Procurement\s+Specialist/i,
      /Inventory\s+Manager/i,
      /Customer\s+Service\s+Representative/i,
      /Call\s+Center\s+Agent/i,
      /Support\s+Specialist/i,
      /Customer\s+Success\s+Manager/i,
      /Technical\s+Support\s+Specialist/i,
      /Mechanical\s+Engineer/i,
      /Electrical\s+Engineer/i,
      /Civil\s+Engineer/i,
      /Industrial\s+Engineer/i,
      /Manufacturing\s+Engineer/i,
      /Quality\s+Control\s+Engineer/i,
      /Plant\s+Manager/i,
      /Maintenance\s+Technician/i,
      /Production\s+Manager/i,
      /Lawyer/i,
      /Paralegal/i,
      /Legal\s+Assistant/i,
      /Legal\s+Advisor/i,
      /Corporate\s+Counsel/i,
      /Compliance\s+Officer/i,
      /Teacher/i,
      /Professor/i,
      /Instructor/i,
      /Tutor/i,
      /School\s+Administrator/i,
      /Education\s+Consultant/i,
      /Curriculum\s+Developer/i,
      /Artist/i,
      /Photographer/i,
      /Graphic\s+Designer/i,
      /Animator/i,
      /Video\s+Editor/i,
      /Illustrator/i,
      /Fashion\s+Designer/i,
      /Interior\s+Designer/i,
      /Architect/i,
      /Hotel\s+Manager/i,
      /Travel\s+Agent/i,
      /Event\s+Planner/i,
      /Concierge/i,
      /Chef/i,
      /Catering\s+Manager/i,
      /Restaurant\s+Manager/i,
      /Retail\s+Manager/i,
      /Store\s+Manager/i,
      /Cashier/i,
      /Sales\s+Assistant/i,
      /Visual\s+Merchandiser/i,
      /Real\s+Estate\s+Agent/i,
      /Property\s+Manager/i,
      /Real\s+Estate\s+Broker/i,
      /Truck\s+Driver/i,
      /Delivery\s+Driver/i,
      /Fleet\s+Manager/i,
      /Pilot/i,
      /Airline\s+Captain/i,
      /Train\s+Conductor/i,
      /Journalist/i,
      /News\s+Reporter/i,
      /Public\s+Relations\s+Specialist/i,
      /Communications\s+Manager/i,
      /Copywriter/i,
      /Editor/i,
      /Content\s+Manager/i,
      /Construction\s+Manager/i,
      /Construction\s+Worker/i,
      /Site\s+Supervisor/i,
      /Electrician/i,
      /Plumber/i,
      /Carpenter/i,
      /Welder/i,
      /CEO/i,
      /CFO/i,
      /COO/i,
      /CTO/i,
      /Chief\s+Marketing\s+Officer/i,
      /VP\s+of\s+Sales/i,
      /VP\s+of\s+Engineering/i,
      /VP\s+of\s+Operations/i,
      /Director\s+of\s+Marketing/i,
      /Director\s+of\s+Operations/i,
      /Bank\s+Teller/i,
      /Loan\s+Officer/i,
      /Financial\s+Advisor/i,
      /Investment\s+Banker/i,
      /Credit\s+Analyst/i,
      /Bartender/i,
      /Hotel\s+Receptionist/i,
      /Tour\s+Guide/i,
      /Travel\s+Consultant/i,
      /Freelancer/i,
      /Entrepreneur/i,
      /Consultant/i,
      /Advisor/i,
      /Technician/i,
      /Specialist/i,
      /Software\s+Developer/i,
      /Software\s+Engineer/i,
      /Full\s+Stack\s+Developer/i,
      /Frontend\s+Developer/i,
      /Backend\s+Developer/i,
      /Mobile\s+Developer/i,
      /Web\s+Developer/i,
      /iOS\s+Developer/i,
      /Android\s+Developer/i,
      /React\s+Developer/i,
      /Angular\s+Developer/i,
      /Vue\s+JS\s+Developer/i,
      /Java\s+Developer/i,
      /Python\s+Developer/i,
      /Node\s+JS\s+Developer/i,
      /PHP\s+Developer/i,
      /C#\s+Developer/i,
      /Ruby\s+Developer/i,
      /Perl\s+Developer/i,
      /Go\s+Developer/i,
      /Rust\s+Developer/i,
      /Swift\s+Developer/i,
      /Kotlin\s+Developer/i,
      /SaaS\s+Developer/i,
      /WordPress\s+Developer/i,
      /Drupal\s+Developer/i,
      /Magento\s+Developer/i,
      /DevOps\s+Engineer/i,
      /DevSecOps\s+Engineer/i,
      /Site\s+Reliability\s+Engineer/i,
      /Cloud\s+Engineer/i,
      /Cloud\s+Architect/i,
      /AWS\s+Engineer/i,
      /Azure\s+Engineer/i,
      /GCP\s+Engineer/i,
      /Kubernetes\s+Administrator/i,
      /Docker\s+Engineer/i,
      /Infrastructure\s+Engineer/i,
      /Network\s+Engineer/i,
      /System\s+Administrator/i,
      /Linux\s+Administrator/i,
      /Windows\s+Administrator/i,
      /Database\s+Administrator/i,
      /Storage\s+Engineer/i,
      /Backup\s+Engineer/i,
      /IT\s+Support\s+Specialist/i,
      /IT\s+Operations\s+Manager/i,
      /Network\s+Security\s+Engineer/i,
      /Firewall\s+Engineer/i,
      /Cybersecurity\s+Analyst/i,
      /Security\s+Engineer/i,
      /Security\s+Architect/i,
      /Penetration\s+Tester/i,
      /Ethical\s+Hacker/i,
      /Information\s+Security\s+Specialist/i,
      /Incident\s+Response\s+Analyst/i,
      /SOC\s+Analyst/i,
      /Chief\s+Information\s+Security\s+Officer/i,
      /Data\s+Privacy\s+Officer/i,
      /Vulnerability\s+Analyst/i,
      /Threat\s+Intelligence\s+Analyst/i,
      /Identity\s+and\s+Access\s+Management\s+(IAM)\s+Engineer/i,
      /Security\s+Operations\s+Center\s+(SOC)\s+Manager/i,
      /Data\s+Scientist/i,
      /Data\s+Engineer/i,
      /Data\s+Analyst/i,
      /Machine\s+Learning\s+Engineer/i,
      /AI\s+Engineer/i,
      /Deep\s+Learning\s+Engineer/i,
      /Big\s+Data\s+Engineer/i,
      /Data\s+Architect/i,
      /Business\s+Intelligence\s+(BI)\s+Developer/i,
      /Data\s+Warehouse\s+Engineer/i,
      /ETL\s+Developer/i,
      /Data\s+Modeler/i,
      /NLP\s+Engineer/i,
      /Artificial\s+Intelligence\s+(AI)\s+Engineer/i,
      /Machine\s+Learning\s+(ML)\s+Engineer/i,
      /Computer\s+Vision\s+Engineer/i,
      /Natural\s+Language\s+Processing\s+(NLP)\s+Engineer/i,
      /AI\s+Researcher/i,
      /AI\s+Architect/i,
      /Data\s+Scientist\s+AI/i,
      /QA\s+Engineer/i,
      /QA\s+Analyst/i,
      /Test\s+Automation\s+Engineer/i,
      /Manual\s+Tester/i,
      /Automation\s+Tester/i,
      /Performance\s+Tester/i,
      /Load\s+Tester/i,
      /Penetration\s+Tester/i,
      /Quality\s+Assurance\s+(QA)\s+Lead/i,
      /Software\s+Tester/i,
      /Security\s+Tester/i,
      /IT\s+Manager/i,
      /CTO/i,
      /Chief\s+Technology\s+Officer/i,
      /IT\s+Project\s+Manager/i,
      /Technical\s+Lead/i,
      /IT\s+Director/i,
      /Technology\s+Consultant/i,
      /IT\s+Consultant/i,
      /Agile\s+Coach/i,
      /Scrum\s+Master/i,
      /Technical\s+Program\s+Manager/i,
      /Database\s+Administrator/i,
      /Database\s+Architect/i,
      /MySQL\s+DBA/i,
      /PostgreSQL\s+DBA/i,
      /MongoDB\s+Administrator/i,
      /Oracle\s+DBA/i,
      /SQL\s+Developer/i,
      /NoSQL\s+Developer/i,
      /Data\s+Migration\s+Specialist/i,
      /Data\s+Recovery\s+Specialist/i,
      /ERP\s+Consultant/i,
      /SAP\s+Consultant/i,
      /Salesforce\s+Developer/i,
      /Salesforce\s+Administrator/i,
      /Dynamics\s+365\s+Consultant/i,
      /Oracle\s+ERP\s+Consultant/i,
      /Workday\s+Consultant/i,
      /ServiceNow\s+Consultant/i,
      /Helpdesk\s+Technician/i,
      /IT\s+Support\s+Specialist/i,
      /Technical\s+Support\s+Engineer/i,
      /Desktop\s+Support/i,
      /IT\s+Operations\s+Analyst/i,
      /Systems\s+Analyst/i,
      /IT\s+Coordinator/i,
      /Technical\s+Support\s+Specialist/i,
      /Cloud\s+Architect/i,
      /AWS\s+Architect/i,
      /Azure\s+Architect/i,
      /Cloud\s+Consultant/i,
      /AWS\s+Engineer/i,
      /Azure\s+Engineer/i,
      /GCP\s+Engineer/i,
      /Virtualization\s+Engineer/i,
      /VMware\s+Engineer/i,
      /Cloud\s+Security\s+Engineer/i,
      /UX\s+Designer/i,
      /UI\s+Designer/i,
      /Product\s+Designer/i,
      /Interaction\s+Designer/i,
      /Visual\s+Designer/i,
      /Graphic\s+Designer/i,
      /UX\s+Researcher/i,
      /UI\s+Developer/i,
      /User\s+Experience\s+(UX)\s+Architect/i,
      /Blockchain\s+Developer/i,
      /Solidity\s+Developer/i,
      /Ethereum\s+Developer/i,
      /Blockchain\s+Architect/i,
      /Smart\s+Contract\s+Developer/i,
      /Crypto\s+Engineer/i,
      /IoT\s+Engineer/i,
      /IoT\s+Developer/i,
      /AR\s+Developer/i,
      /VR\s+Developer/i,
      /AR\/VR\s+Engineer/i,
      /Wearable\s+Tech\s+Engineer/i,
      /Robotics\s+Engineer/i,
      /Automation\s+Engineer/i,
      /Control\s+Systems\s+Engineer/i,
      /RPA\s+(Robotic\s+Process\s+Automation)\s+Developer/i,
      /RPA\s+Consultant/i,
      /Robotics\s+Software\s+Engineer/i,
      /Game\s+Developer/i,
      /Game\s+Designer/i,
      /Unity\s+Developer/i,
      /Unreal\s+Engine\s+Developer/i,
      /Game\s+Tester/i,
      /API\s+Developer/i,
      /Integration\s+Developer/i,
      /Solution\s+Architect/i,
      /Enterprise\s+Architect/i,
      /Firmware\s+Engineer/i,
      /Embedded\s+Systems\s+Engineer/i,
      /Technical\s+Writer/i,
      /Open\s+Source\s+Developer/i,
      /Algorithm\s+Engineer/i,
      /VR\/AR\s+Developer/i,
      /Digital\s+Transformation\s+Specialist/i,
      /Tech\s+Evangelist/i,
    ];

    let qualification = "";
    for (const pattern of qualificationPatterns) {
      const match = text.match(pattern);
      if (match) {
        qualification = match[0];
        break;
      }
    }

    const experienceMatch = text.match(
      /(\d+)\s*(?:\+\s*)?(?:years?|yrs?)\s*(?:-\s*(\d+)\s*(?:years?|yrs?)\s*)?(?:experience|hands-on experience)?/i
    );

    let experience = "";
    if (experienceMatch) {
      const minExperience = experienceMatch[1] ? `${experienceMatch[1]}` : "";
      const maxExperience = experienceMatch[2]
        ? `to ${experienceMatch[2]}`
        : "";

      const minOrMax = text.match(
        /(?:minimum|at\sleast|over|more\s+than|max\s*)/i
      );

      if (minOrMax) {
        experience = minOrMax[0] + " " + minExperience + " " + maxExperience;
      } else {
        experience = minExperience + " " + maxExperience;
      }
      experience = experience.replace(/\s*years?\s*/i, "").trim();
    }

    const skills = [];
    skillData.forEach((skill) => {
      const escapedSkill = skill.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      const regex = new RegExp(`\\b${escapedSkill}\\b`, "i");
      if (regex.test(text)) skills.push(skill);
    });
    const skillsString = skills.join(", ");

    let location = "";
    for (const city of locationData) {
      const regex = new RegExp(`\\b${city}\\b`, "i");
      const match = text.match(regex);
      if (match) {
        location = match[0];
        break;
      }
    }
    setsearchdata({
      qualification: qualification || "",
      experience: experience || "",
      location: location || "",
      skillsString: skillsString || "",
    });
  };
  const [browseloading, setbrowseloading] = useState(false);
  const [roleindexvalue, setroleindexvalue] = useState(null);

  const BrowseBtn = async (teamIndex, roleIndex) => {
    setbrowseloading(true);
    setroleindexvalue(roleIndex);

    // Update the specific team and role in the teamlist
    const updatedTeams = teamlist.map((team, tIndex) => {
      if (tIndex === teamIndex) {
        const updatedRoles = team.roles.map((role, rIndex) => {
          if (rIndex === roleIndex) {
            return { ...role, browse: "true" };
          }
          return role;
        });

        return { ...team, roles: updatedRoles };
      }
      return team;
    });

    // Update the teamlist in state
    setteamlist(updatedTeams);

    // If the team being updated is the selected team, update the selectedTeam state
    if (teamlist[teamIndex].name === selectedTeam.name) {
      setSelectedTeam((prevState) => ({
        ...prevState,
        roles: prevState.roles.map((role, rIndex) =>
          rIndex === roleIndex ? { ...role, browse: "true" } : role
        ),
      }));
    }
    const updateddata = await Promise.all(
      updatedTeams.map(async (team, tIndex) => {
        if (tIndex === teamIndex) {
          const updatedRoles = [...team.roles];
          const roleToUpdate = updatedRoles[roleIndex];

          const newobj = {
            search: {
              qualification: roleToUpdate.role,
              experience: roleToUpdate.yearsOfExperience,
              location: roleToUpdate.location,
              skills: roleToUpdate.skill,
            },
          };
          const candidatedata = await axios
            .post(`https://api.hirein5.com/admin/search/`, newobj, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            })
            .then((res) => {
              return res.data;
            });

          if (candidatedata.data.length !== 0) {
            updatedRoles[roleIndex].profiles = candidatedata.data.slice(0, 3);
          }

          return { ...team, roles: updatedRoles };
        }
        return team;
      })
    );

    // Set the updated team data and selected team
    setteamlist(updateddata);
    setSelectedTeam(updateddata[teamIndex]);

    // Final state updates after data fetching is complete
    setbrowseloading(false);
    setroleindexvalue(null);
  };

  const pageHandler = async (event, id) => {
    setIsPage("page1");
  };
  return (
    <div className="paddingLeft90">
      {isPage == "page1" && (
        <>
          <div className="HireMainComponent paddingRight90">
            <DashHead
              head="Hire a Team"
              desc="Search and find the right talent for your company. If you need help in shortlisting candidates or do not find a relevent skill match"
              highLight="Contact Us"
              descClass="dashBoardMainHeadDescBetween"
            />
            <div className="HireMainComponentFlex">
              <div className="HireMainComponentLeft">
                <h2>Project Details</h2>
                <div className="HireMainComponentLeftContent">
                  <h3>Project name</h3>
                  <input
                    className="HireMainComponentLeftContentInput"
                    type="text"
                    name="project_name"
                    onChange={handlechange}
                    defaultValue={formdata.project_name}
                  />
                  {formdataerror.project_name && (
                    <p className="text-red-500 font-medium py-2 text-sm">
                      Please Enter Project name
                    </p>
                  )}
                </div>
                <div className="HireMainComponentLeftContent">
                  <h3>Project Duration (In Months)</h3>
                  <input
                    className="HireMainComponentLeftContentInput"
                    type="text"
                    name="duration"
                    onChange={handlechange}
                    defaultValue={formdata.duration}
                  />
                  {formdataerror.duration && (
                    <p className="text-red-500 font-medium py-2 text-sm">
                      Please Enter Project Duration
                    </p>
                  )}
                </div>
                <div className="HireMainComponentLeftContent">
                  <h3>Monthly Project Budget</h3>
                  <div className="flex">
                    <select
                      name="budget"
                      onChange={handlechange}
                      defaultValue={formdata.budget}
                    >
                      <option value="">Select</option>
                      <option value="INR">INR</option>
                      <option value="USD">USD</option>
                      <option value="JPY">JPY</option>
                      <option value="SGD">SGD</option>
                      <option value="GBP">GBP</option>
                      <option value="AED">AED</option>
                      <option value="CAD">CAD</option>
                      <option value="SAR">SAR</option>
                    </select>

                    <input
                      className="HireMainComponentLeftContentInput HireMainComponentLeftContentInput1"
                      type="text"
                      name="amount"
                      onChange={handlechange}
                      defaultValue={formdata.amount}
                    />
                  </div>
                  {formdataerror.budget && (
                    <p className="text-red-500 font-medium py-2 text-sm">
                      Please Select Amount Type
                    </p>
                  )}
                  {formdataerror.amount && (
                    <p className="text-red-500 font-medium py-2 text-sm ml-48">
                      Please Enter Monthly Project Budget
                    </p>
                  )}
                </div>
                <div className="HireMainComponentLeftContent">
                  <h3>Project Description</h3>
                  <textarea
                    className="HireMainComponentLeftContentInput"
                    name="description"
                    onChange={handlechange}
                    defaultValue={formdata.description}
                  ></textarea>
                  {formdataerror.description && (
                    <p className="text-red-500 font-medium py-2 text-sm">
                      Please Enter Project Description
                    </p>
                  )}
                </div>
                <div className="HireMainComponentLeftContent">
                  <h3>Technical Skills Required</h3>
                  <div className="searchBar">
                    <CiSearch />
                    <input
                      type="text"
                      ref={inputref}
                      onChange={dropDownHandler}
                    />
                    <FaAngleDown onClick={filterdata1} />
                  </div>
                  {formdataerror.skills_required && (
                    <p className="text-red-500 font-medium py-2 text-sm">
                      Please Select Technical Skills Required
                    </p>
                  )}
                  {dropDown && (
                    <div className="searchContent1">
                      <div className="searchContent">
                        {dropDownOpen.map((option, index) => (
                          <h3
                            onClick={() => {
                              getdata(option);
                            }}
                            key={index}
                          >
                            {option}
                          </h3>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
                <Dragoption skill={skill} getdata={getdata} />
                {loader == true ? (
                  <button className="savedisbale buttonWidth100 marginLeft0 marginTop10">
                    Please Wait...
                  </button>
                ) : (
                  <button
                    className="save buttonWidth100 marginLeft0 marginTop10"
                    onClick={createTaam}
                  >
                    Create Team
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="HireTeamTable paddingRight90">
            <div className="HireTeamTableContent">
              <div className="HireTeamTableTop">
                <button className="hireTableButton">Add a Role</button>

                {/* Display the team names */}
                {teamlist.length !== 0
                  ? teamlist.map((data, index) => (
                      <h1
                        onClick={() => ButtonHandler(data.name, index)}
                        className={
                          selectedTeam?.name === data.name
                            ? "HireTeamTableToph1Active"
                            : "HireTeamTableToph1"
                        }
                        key={index}
                      >
                        {data.name}
                      </h1>
                    ))
                  : null}

                <h1
                  onClick={() => ButtonHandler("Customized Team")}
                  className={
                    selectedTeam?.name === "Customized Team"
                      ? "HireTeamTableToph1Active"
                      : "HireTeamTableToph1"
                  }
                >
                  Customized Team
                </h1>
              </div>

              {selectedTeam && selectedTeam.roles && (
                <table>
                  <thead>
                    <tr className="hireTableHead">
                      <th>Remove</th>
                      <th>Role</th>
                      {/* <th>Responsibility</th> */}
                      <th>Job Description</th>
                      <th>Skills</th>
                      <th>Number of roles</th>
                      <th>Location</th>
                      <th>Years of Experience</th>
                      <th>Availability</th>
                      <th>Mode of engagement</th>
                      <th>Working hours</th>
                      <th>Diversity Filter</th>
                      <th>Budget per role (USD per month)</th>
                      <th></th>
                      <th>Profile 1</th>
                      <th>Profile 2</th>
                      <th>Profile 3</th>
                    </tr>
                  </thead>

                  <tbody className="hireTableBody">
                    {selectedTeam.roles.map((role, roleIndex) => (
                      <tr className="hireTablerow" key={roleIndex}>
                        <td>
                          <div className="flex items-center justify-center">
                            <FaRegCircleXmark
                              className="xIcon"
                              onClick={() => removeRole(teamindex, roleIndex)}
                            />
                            <FiPlusCircle
                              className="plusIcon"
                              onClick={() => addRole(teamindex)}
                            />
                          </div>
                        </td>
                        <td>
                          <h3>
                            <input
                              className="hireTableBodySelect"
                              value={role.role}
                              placeholder="Role"
                              type="text"
                              onChange={(e) =>
                                handleChangeNew(
                                  teamindex,
                                  roleIndex,
                                  "role",
                                  e.target.value
                                )
                              }
                            />
                          </h3>
                        </td>

                        {/* <td>
                          <h4 className="singlelinetext">
                            {role.responsibility}
                            <span
                              onClick={() => {
                                overlayHandlerNew(
                                  teamindex,
                                  roleIndex,
                                  "responsibility",
                                  "readjobdescription",
                                  role.responsibility
                                );
                              }}
                              className="readMore"
                            >
                              Read More..
                            </span>
                          </h4>
                        </td> */}
                        <td>
                          <h3>
                            <button
                              id="jobdescription"
                              onClick={() => {
                                // window.open(role.url, "_blank");
                                overlayHandlerNew(
                                  teamindex,
                                  roleIndex,
                                  "jobDescription",
                                  "jobdescription",
                                  role.jobDescription
                                );
                              }}
                              className="hireTableButton"
                            >
                              view
                            </button>
                          </h3>
                        </td>
                        {/* <td>
                          <h3>
                            <input
                              className="hireTableBodySelect"
                              value={role.skill}
                              placeholder="Skills"
                              type="text"
                              onChange={(e) =>
                                handleChangeNew(
                                  teamindex,
                                  roleIndex,
                                  "skill",
                                  e.target.value
                                )
                              }
                            />
                          </h3>
                        </td> */}
                        <td>
                          <h4
                            className="singlelinetext"
                            contentEditable="true"
                            suppressContentEditableWarning={true}
                            onBlur={(e) =>
                              handleChangeNew(
                                teamindex,
                                roleIndex,
                                "skill",
                                e.currentTarget.textContent
                              )
                            }
                          >
                            {role.skill}
                          </h4>
                        </td>
                        <td>
                          <h3>
                            <select
                              className="hireTableBodySelect"
                              value={role.numberOfRoles}
                              onBlur={(e) =>
                                handleChangeNew(
                                  teamindex,
                                  roleIndex,
                                  "numberOfRoles",
                                  e.currentTarget.textContent
                                )
                              }
                            >
                              <option value="1">1</option>
                              <option value="2">2</option>
                              <option value="3">3</option>
                              <option value="4">4</option>
                              <option value="5">5</option>
                              <option value="6">6</option>
                              <option value="7">7</option>
                              <option value="8">8</option>
                              <option value="9">9</option>
                              <option value="10">10</option>
                            </select>
                          </h3>
                        </td>
                        <td>
                          <h3>
                            <select
                              className="hireTableBodySelect"
                              value={role.location}
                              onBlur={(e) =>
                                handleChangeNew(
                                  teamindex,
                                  roleIndex,
                                  "location",
                                  e.target.value
                                )
                              }
                            >
                              <option value="India">India</option>
                              <option value="Remote">Remote</option>
                            </select>
                          </h3>
                        </td>
                        <td>
                          <h3>
                            <input
                              className="hireTableBodySelect"
                              value={role.yearsOfExperience}
                              placeholder="Years of Experience"
                              type="text"
                              onChange={(e) =>
                                handleChangeNew(
                                  teamindex,
                                  roleIndex,
                                  "yearsOfExperience",
                                  e.target.value
                                )
                              }
                            />
                          </h3>
                        </td>
                        <td>
                          <h3>
                            <select
                              className="hireTableBodySelect"
                              value={role.availability}
                              onBlur={(e) =>
                                handleChangeNew(
                                  teamindex,
                                  roleIndex,
                                  "availability",
                                  e.target.value
                                )
                              }
                            >
                              <option value="Immediate">Immediate</option>
                              <option value="In the next 30 days">
                                In the next 30 days
                              </option>
                              <option value="post 30 days">post 30 days</option>
                            </select>
                          </h3>
                        </td>
                        <td>
                          <h3>
                            <select
                              className="hireTableBodySelect"
                              value={role.modeOfEngagement}
                              onBlur={(e) =>
                                handleChangeNew(
                                  teamindex,
                                  roleIndex,
                                  "modeOfEngagement",
                                  e.target.value
                                )
                              }
                            >
                              <option value="Part Time - 10 hours a week">
                                Part Time - 10 hours a week
                              </option>
                              <option value="Part Time - 20 hours a week">
                                Part Time - 20 hours a week
                              </option>
                              <option value="Full-time">Full-time</option>
                            </select>
                          </h3>
                        </td>
                        <td>
                          <h3>
                            <select
                              className="hireTableBodySelect"
                              value={role.workingHours} // The current value is selected
                              onChange={(e) =>
                                handleChangeNew(
                                  teamindex,
                                  roleIndex,
                                  "workingHours",
                                  e.target.value // Capturing the selected value correctly
                                )
                              }
                            >
                              <option value="05:00 to 14:00 IST">
                                05:00 to 14:00 IST
                              </option>
                              <option value="10:30 to 19:30 IST">
                                10:30 to 19:30 IST
                              </option>
                              <option value="13:30 to 22:30 IST">
                                13:30 to 22:30 IST
                              </option>
                              <option value="Flexible with working hours">
                                Flexible with working hours
                              </option>
                            </select>
                          </h3>
                        </td>
                        {/* <td>
                          <h3
                            contentEditable="true"
                            suppressContentEditableWarning={true}
                            onBlur={(e) =>
                              handleChangeNew(
                                teamindex,
                                roleIndex,
                                "projectDuration",
                                e.currentTarget.textContent
                              )
                            }
                          >
                            {role.projectDuration}
                          </h3>
                        </td> */}
                        <td>
                          <h3>
                            <button
                              id="diversity"
                              onClick={() => {
                                overlayHandlerNew1(
                                  teamindex,
                                  roleIndex,
                                  "diversity",
                                  "diversity",
                                  role.gender,
                                  role.country,
                                  role.city
                                );
                              }}
                              className="hireTableButton"
                            >
                              view
                            </button>
                          </h3>
                        </td>
                        <td>
                          <h3>
                            <input
                              className="hireTableBodySelect"
                              value={role.budgetPerRole}
                              placeholder="Budget per role"
                              type="text"
                              onChange={(e) =>
                                handleChangeNew(
                                  teamindex,
                                  roleIndex,
                                  "budgetPerRole",
                                  e.target.value
                                )
                              }
                            />
                          </h3>
                        </td>
                        <td>
                          <h3>
                            {browseloading == true &&
                            roleindexvalue == roleIndex ? (
                              <button className="hireTableButton marginLeft20">
                                Loading...
                              </button>
                            ) : (
                              <button
                                className="hireTableButton marginLeft20"
                                onClick={() => {
                                  BrowseBtn(teamindex, roleIndex);
                                }}
                              >
                                Browse
                              </button>
                            )}
                          </h3>
                        </td>

                        {/* Render profiles if available */}
                        {role.profiles.length !== 0 ? (
                          <>
                            {role.profiles.map((data, index) => (
                              <td key={index}>
                                <h3>
                                  <div
                                    onClick={() =>
                                      cardHandler(teamindex, roleIndex, data.id)
                                    }
                                    className={`marginLeft20 hireTeamCard cursor-pointer ${
                                      data.active !== undefined &&
                                      data.active == true
                                        ? "hireTeamCardActive"
                                        : ""
                                    }`}
                                  >
                                    <div className="mr-3 relative">
                                      <Avatar
                                        name={data.first_name}
                                        size={50}
                                        round="50px"
                                      />
                                      <p className="percentagetag">
                                        {data.matched_percentage}
                                      </p>
                                    </div>
                                    <div className="hireTeamCardFlex">
                                      <div className="hireTeamCardContent">
                                        <h1 className="singlelinetext">
                                          {data.first_name
                                            .split(" ")
                                            .slice(0, -1)
                                            .join(" ") +
                                            " " +
                                            data.first_name
                                              .split(" ")
                                              .pop()
                                              .replace(/./g, "x")}
                                        </h1>
                                        <h2
                                          className={`singlelinetext ${
                                            data.active !== undefined &&
                                            data.active == true
                                              ? "hireTextColor"
                                              : ""
                                          }`}
                                        >
                                          {data.preference_info.qualification}
                                        </h2>
                                        <div className="flex items-center justify-start mt-1">
                                          <FiBriefcase
                                            className={
                                              data.active !== undefined &&
                                              data.active == true
                                                ? "hirebriefColor"
                                                : ""
                                            }
                                          />
                                          <h5
                                            className={
                                              data.active !== undefined &&
                                              data.active == true
                                                ? "hireTextColor"
                                                : ""
                                            }
                                          >
                                            {
                                              data.preference_info
                                                .year_of_experience
                                            }{" "}
                                            years of experience
                                          </h5>
                                        </div>
                                      </div>
                                      <div className="hireTeamCardContent1">
                                        {data.rate_card_info ? (
                                          <h6>
                                            $
                                            {data.rate_card_info
                                              ? data.rate_card_info
                                                  .remote_hourly
                                              : 0}
                                            /hr
                                          </h6>
                                        ) : (
                                          <h6>UA</h6>
                                        )}

                                        <button
                                          onClick={() => {
                                            setIsPage("page2");
                                            dispatch(
                                              storeAction.singleuserHander({
                                                singleuser: [data],
                                              })
                                            );
                                          }}
                                          className={`hireTableButton ${
                                            data.active !== undefined &&
                                            data.active == true
                                              ? "buttonColor"
                                              : ""
                                          }`}
                                        >
                                          View
                                        </button>
                                      </div>
                                    </div>
                                  </div>
                                </h3>
                              </td>
                            ))}

                            {/* Fill empty cells if less than 3 profiles */}
                            {[...Array(3 - role.profiles.length)].map(
                              (_, i) => (
                                <td key={role.profiles.length + i}>
                                  <h3>
                                    <div className="marginLeft20 hireTeamCard">
                                      <div className="hireTeamCardContent">
                                        {role.browse == "true" ? (
                                          <h1 className="text-center">
                                            No Profile Found...
                                          </h1>
                                        ) : (
                                          <h1 className="text-center">
                                            Click Browse to fetch profiles
                                          </h1>
                                        )}
                                      </div>
                                    </div>
                                  </h3>
                                </td>
                              )
                            )}
                          </>
                        ) : (
                          <>
                            {/* No profiles at all */}
                            {[...Array(3)].map((_, i) => (
                              <td key={i}>
                                <h3>
                                  <div className="marginLeft20 hireTeamCard">
                                    <div className="hireTeamCardContent">
                                      {role.browse == "true" ? (
                                        <h1 className="text-center">
                                          No Profile Found...
                                        </h1>
                                      ) : (
                                        <h1 className="text-center">
                                          Click Browse to fetch profiles
                                        </h1>
                                      )}
                                    </div>
                                  </div>
                                </h3>
                              </td>
                            ))}
                          </>
                        )}
                      </tr>
                    ))}
                  </tbody>
                </table>
              )}
            </div>
          </div>
          <div className="hireModuleCTA paddingRight90">
            <h1>Estimated Budget $ 0</h1>
            <button>Hire A Team</button>
          </div>
          {/* <div className="AdminClientProfileComp paddingRight90">
            <div className="AdminClientProfileCompSearch">
              <input
                type="text"
                // placeholder={!isSearchEnabled ? "Please wait..." : "Search..."}
                // onChange={(e) => searchvalue(e.target.value)}
                // disabled={!isSearchEnabled}
              />
              <img src={search} alt="" />
            </div>
            <div className="AdminClientProfileCompTable">
              <table className="AdminClientTable">
                <thead>
                  <tr className="AdminTableHead">
                    <th>PROJECT NAME</th>
                    <th>PROJECT BUDGET</th>
                    <th>TEAM SIZE</th>
                    <th>START DATE</th>
                    <th>DURATION</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {currentRecords.length !== 0
                    ? currentRecords.map((data, index) => (
                        <tr className="adminTableRow">
                          <td>
                            <h1>Cab Booking App</h1>
                          </td>
                          <td>
                            <h1>530000</h1>
                          </td>
                          <td>
                            <h1>10</h1>
                          </td>
                          <td>
                            <h1>10-Agu-2024</h1>
                          </td>
                          <td>
                            <h1>2 Months</h1>
                          </td>
                          <td>
                            <button className="viewButton">View</button>
                          </td>
                        </tr>
                      ))
                    : null}
                </tbody>
              </table>
            </div>
            {pageNumbers.length !== 0 ? (
              <div className="tablePagination">
                <Pagination
                  nPages={nPages}
                  currentPage={currentPage}
                  setCurrentPage={setCurrentPage}
                />
              </div>
            ) : null}
          </div> */}
          {isPopUp == "diversity" && (
            <div className="diversityContianer overlay">
              <div className="diversityPopUpHead">
                <h1>Diversity Filter</h1>
                <FaRegCircleXmark
                  onClick={exitOverlayHandler}
                  className="xIcon"
                />
              </div>
              <div className="diversityPopUpBody">
                <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h2>
                <div className="HireMainComponentLeftContent">
                  <h3>Gender</h3>
                  <div className="flex">
                    <select
                      className="diversitySelect"
                      onChange={(e) => {
                        setgender(e.target.value);
                      }}
                      defaultValue={
                        responsibilitydata !== null && responsibilitydata.data1
                      }
                    >
                      <option value="">Select</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>
                <div className="HireMainComponentLeftContent">
                  <h3>Nationality</h3>
                  <div className="flex">
                    <select
                      className="diversitySelect"
                      onChange={(e) => {
                        setcountry(e.target.value);
                      }}
                      defaultValue={
                        responsibilitydata !== null && responsibilitydata.data2
                      }
                    >
                      <option value="">Select</option>
                      <option value="India">India</option>
                      <option value="USA">USA</option>
                      <option value="UAE">UAE</option>
                    </select>
                  </div>
                </div>
                <div className="HireMainComponentLeftContent">
                  <h3>Location</h3>
                  <div className="flex">
                    <select
                      className="diversitySelect"
                      onChange={(e) => {
                        setcity(e.target.value);
                      }}
                      defaultValue={
                        responsibilitydata !== null && responsibilitydata.data3
                      }
                    >
                      <option value="">Select</option>
                      <option value="Coimbatore">Coimbatore</option>
                      <option value="Chennai">Chennai</option>
                      <option value="Bangalore">Bangalore</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="overlayBottomButton">
                <button className="Hirecancel" onClick={exitOverlayHandler}>
                  Discard Changes
                </button>
                <button className="Hiresave" onClick={changefilter}>
                  Save & Close
                </button>
              </div>
            </div>
          )}
          {isPopUp == "jobdescription" && (
            <div className="diversityContianer overlay">
              <div className="diversityPopUpHead">
                <h1>Job Description</h1>
                <FaRegCircleXmark
                  onClick={exitOverlayHandler}
                  className="xIcon"
                />
              </div>
              <div className="diversityPopUpBody">
                <h2>
                  {jobdesdata.length !== 0
                    ? jobdesdata
                    : responsibilitydata !== null && responsibilitydata.data}
                </h2>
                <div className="aadhaar cursor-pointer">
                  <h3>Add your Job Description</h3>
                  <div
                    className="aadhaarFront marginBottom20"
                    onClick={() => {
                      uploadHandler();
                    }}
                  >
                    <h3>
                      Drop your Files or <span className="browser">browse</span>
                    </h3>
                    <p>Maximum size: 5MB</p>
                    <p title="">
                      accepted formats: .pdf, .doc, .docx, .txt, .rtf
                    </p>
                  </div>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    name="aadhaarfront"
                    accept=".pdf,.doc"
                    onChange={handlePdfUpload}
                  />
                </div>
              </div>
              <div className="overlayBottomButton">
                <button className="Hirecancel" onClick={exitOverlayHandler}>
                  Discard Changes
                </button>
                <button className="Hiresave" onClick={changeJobdescription}>
                  Save & Close
                </button>
              </div>
            </div>
          )}
          {isPopUp == "readjobdescription" && (
            <div className="diversityContianer overlay">
              <div className="diversityPopUpHead">
                <h1>Responsibility</h1>
                <FaRegCircleXmark
                  onClick={exitOverlayHandler}
                  className="xIcon"
                />
              </div>
              <textarea
                className="border border-gray-500 rounded mx-4 mt-5 p-2 w-[95%]"
                placeholder="Responsibility"
                onChange={(e) => {
                  setnewresponsibilitydata(e.target.value);
                }}
                defaultValue={
                  responsibilitydata !== null && responsibilitydata.data
                }
              ></textarea>
              <div className="readjobdescription py-2">
                <p>
                  Oversees the entire project, manages timelines, coordinates
                  team efforts
                </p>
              </div>
              <div className="overlayBottomButton">
                <button className="Hirecancel" onClick={exitOverlayHandler}>
                  Discard Changes
                </button>
                <button className="Hiresave" onClick={changeresponsibility}>
                  Save & Close
                </button>
              </div>
            </div>
          )}
        </>
      )}

      {/* <div className="paddingRight90 marginBottom35">
        <div className="hireTeamOverview">
          <div className="hireTeamOverviewBack">
            <div className="hireBack">
              <IoMdArrowBack className="backIcon1" />
              <h3>Back to Teams</h3>
            </div>
            <div className="hireOnboard">
              <button className="Hiresave">Onboarded</button>
              <button className="HireDot">
                <BsThreeDots />
              </button>
            </div>
          </div>
          <div className="hireTeamOverviewContent ">
            <h1>Cab Booking App</h1>
            <p>Budget : $30000</p>
            <p>Team Size : 10</p>
          </div>
        </div>
        <div className="ClientProfileViewCard">
          <div className="ClientProfileViewCardEdit">
            <h1>Basic details</h1>
            <button id="adminpersonal">
              <img src={editOutline} alt="" />
              Edit
            </button>
          </div>
          <div className="ClientProfileViewCardBody">
            <div className="ClientProfileViewCardBodyTable">
              <h2>Project Name</h2>
              <h3>Cab Booking App</h3>
            </div>
            <div className="ClientProfileViewCardBodyTable">
              <h2>Duration</h2>
              <h3 className="lowerCase">3 months</h3>
            </div>
            <div className="ClientProfileViewCardBodyTable">
              <h2>Budget</h2>
              <h3>$3000</h3>
            </div>
            <div className="ClientProfileViewCardBodyTable">
              <h2>Description</h2>
              <h3>
                Oversees the entire project, manages timelines, coordinates team
                efforts <span className="readMore">Read More..</span>
              </h3>
            </div>
          </div>
        </div>
      </div>
      <div className="HireTeamTable paddingRight90">
        <div className="HireTeamTableContent1">
          <div className="HireTeamTableTop">
            <button className="hireTableButton">Add a Role</button>
            <h1
              onClick={() => ButtonHandler("Mobile App Development")}
              className={
                button == "Mobile App Development"
                  ? "HireTeamTableToph1Active"
                  : "HireTeamTableToph1"
              }
            >
              Mobile App Development
            </h1>
            <h1
              onClick={() => ButtonHandler("Web App Development")}
              className={
                button == "Web App Development"
                  ? "HireTeamTableToph1Active"
                  : "HireTeamTableToph1"
              }
            >
              Web App Development
            </h1>
            <h1
              onClick={() => ButtonHandler("UI/UX Design")}
              className={
                button == "UI/UX Design"
                  ? "HireTeamTableToph1Active"
                  : "HireTeamTableToph1"
              }
            >
              UI/UX Design
            </h1>
            <h1
              onClick={() => ButtonHandler("Customized Team")}
              className={
                button == "Customized Team"
                  ? "HireTeamTableToph1Active"
                  : "HireTeamTableToph1"
              }
            >
              Customized Team
            </h1>
          </div>
          <table>
            <tr className="hireTableHead">
              <th>Remove</th>
              <th>Role</th>
              <th>Team Members</th>
              <th>Availability</th>
              <th>Duration of hire</th>
              <th>Cost per role (USD per month)</th>
              <th>Book Mark</th>
              <th>Status</th>
              <th>Engagement</th>
            </tr>
            <tbody className="hireTableBody">
              <tr className="hireTablerow">
                <td>
                  <div className="flex items-center justify-center">
                    <FaRegCircleXmark className="xIcon" />
                    <FiPlusCircle className="plusIcon" />
                  </div>
                </td>
                <td>
                  <h3 className="pl-4" contenteditable="true">
                    Project Manager
                  </h3>
                </td>
                <td>
                  <h3>
                    <div
                      onClick={() => cardHandler("profile2")}
                      className={
                        card == "profile2"
                          ? "marginLeft20 hireTeamCard hireTeamCardActive"
                          : "marginLeft20 hireTeamCard"
                      }
                    >
                      <div className="mr-3">
                        <Avatar name="Kaneeshvar" size={50} round="50px" />
                      </div>
                      <div className="hireTeamCardFlex">
                        <div className="hireTeamCardContent">
                          <h1>Kaneeshvar</h1>
                          <h2
                            className={
                              card == "profile2" ? "hireTextColor" : ""
                            }
                          >
                            Java Developer
                          </h2>
                          <div className="flex items-center justify-center mt-1">
                            <FiBriefcase
                              className={
                                card == "profile2" ? "hirebriefColor" : ""
                              }
                            />
                            <h5
                              className={
                                card == "profile2" ? "hireTextColor" : ""
                              }
                            >
                              2 years of experience
                            </h5>
                          </div>
                        </div>
                        <div className="hireTeamCardContent1">
                          <h6>₹4500/hr</h6>
                          <button
                            className={
                              card == "profile2"
                                ? "hireTableButton buttonColor"
                                : "hireTableButton"
                            }
                          >
                            View
                          </button>
                        </div>
                      </div>
                    </div>
                  </h3>
                </td>
                <td>
                  <h3>
                    <select className="hireTableBodySelect" name="" id="">
                      <option value="immediately">immediately</option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3 className="pl-4" contenteditable="true">
                    3 Months
                  </h3>
                </td>
                <td>
                  <h3 className="pl-4" contenteditable="true">
                    3000
                  </h3>
                </td>
                <td>
                  <h3 className="pl-4" contenteditable="true">
                    <IoBookmarkOutline />
                  </h3>
                </td>
                <td>
                  <h3>
                    <select className="hireTableBodySelect" name="" id="">
                      <option value="Hired">Hired</option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3>
                    <select className="hireTableBodySelect" name="" id="">
                      <option value="Disengage">Disengage</option>
                    </select>
                  </h3>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div> */}
      {isPage === "page2" && (
        <>
          <div className="displayHandler">
            <CandidateProfileCard
              main="candidateProfile"
              fun={pageHandler}
              back="candidateBack"
            />
          </div>
          <div className="displayHandlerMob">
            <MobileCandidateProfile />
          </div>
        </>
      )}
    </div>
  );
};

export default HireMainComponent;
