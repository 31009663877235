// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contractCard {
    width: 100%;
    padding: 15px;
    border: 1px solid var(--borderColor);
    background-color: var(--white);
    border-radius: 8px;
}

.contractInner {
    display: flex;
}

.contractInner img {
    margin-right: 15px;
}

.contractInnerDesc {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
}

.contractInnerDesc h2 {
    font-size: 18px;
    font-weight: 600;
}

.contractInnerDesc h6 {
    opacity: 0.7;
    font-size: 15px;
}

.contractCard button {
    padding: 5px;
    width: 100%;
    background-color: var(--primaryColor);
    color: var(--white);
    border-radius: 8px;
    margin-top: 20px;

}

.contractCard button[title] {
    background-color: white;
    border: 1px solid var(--borderColor);
    border-radius: 8px;
    color: black;
    font-weight: 600;
}`, "",{"version":3,"sources":["webpack://./src/Components/Reusable/ContractCard/ContractCard.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,oCAAoC;IACpC,8BAA8B;IAC9B,kBAAkB;AACtB;;AAEA;IACI,aAAa;AACjB;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,uBAAuB;AAC3B;;AAEA;IACI,eAAe;IACf,gBAAgB;AACpB;;AAEA;IACI,YAAY;IACZ,eAAe;AACnB;;AAEA;IACI,YAAY;IACZ,WAAW;IACX,qCAAqC;IACrC,mBAAmB;IACnB,kBAAkB;IAClB,gBAAgB;;AAEpB;;AAEA;IACI,uBAAuB;IACvB,oCAAoC;IACpC,kBAAkB;IAClB,YAAY;IACZ,gBAAgB;AACpB","sourcesContent":[".contractCard {\n    width: 100%;\n    padding: 15px;\n    border: 1px solid var(--borderColor);\n    background-color: var(--white);\n    border-radius: 8px;\n}\n\n.contractInner {\n    display: flex;\n}\n\n.contractInner img {\n    margin-right: 15px;\n}\n\n.contractInnerDesc {\n    display: flex;\n    flex-direction: column;\n    align-items: flex-start;\n    justify-content: center;\n}\n\n.contractInnerDesc h2 {\n    font-size: 18px;\n    font-weight: 600;\n}\n\n.contractInnerDesc h6 {\n    opacity: 0.7;\n    font-size: 15px;\n}\n\n.contractCard button {\n    padding: 5px;\n    width: 100%;\n    background-color: var(--primaryColor);\n    color: var(--white);\n    border-radius: 8px;\n    margin-top: 20px;\n\n}\n\n.contractCard button[title] {\n    background-color: white;\n    border: 1px solid var(--borderColor);\n    border-radius: 8px;\n    color: black;\n    font-weight: 600;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
