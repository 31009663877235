/* eslint-disable eqeqeq */
import React, { useState } from "react";
import Box from "@mui/material/Box";
import Slider from "@mui/material/Slider";

const SliderRange = ({ setdays }) => {
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
    setdays(newValue);
  };
  const valueLabelFormat = (value) => {
    if (value == 1 || value == 0) {
      return `${value} day`;
    } else {
      return `${value} days`;
    }
  };

  return (
    <div>
      <Box sx={{ width: 385 }}>
        <Slider
          value={value}
          min={0}
          max={60}
          valueLabelDisplay="auto"
          valueLabelFormat={valueLabelFormat}
          onChange={handleChange}
          className="text-black"
          getAriaLabel={() => "Temperature range"}
        />
      </Box>
    </div>
  );
};

export default SliderRange;
