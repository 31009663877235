import React, { useState } from "react";
import "./Popup.css";
import success from "../../../assests/Succcess.png";
import { CiWarning } from "react-icons/ci";

import { IoMdCloseCircle } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../../Store/Store";
import axios from "axios";

const Popup = (props) => {
  const userid = useSelector((store) => store.userid);
  const token = useSelector((store) => store.token);
  const dispatch = useDispatch();
  const overLayHandler = () => {
    dispatch(storeAction.isPopUpHander());
  };
  const [formdata, setformdata] = useState({
    mobile_number: "",
    date: "",
    time: "",
    mode: "",
  });
  const [successbtn, setsuccessbtn] = useState(false);
  const [loading, setloading] = useState(false);
  const handlechange = (e) => {
    const { name, value } = e.target;
    setformdata((values) => ({ ...values, [name]: value }));
  };
  const submitbtn = async () => {
    setloading(true);
    var emailobj = {
      email: "sales@hirein5.com",
      subject: "Contact Us for Assistance!",
      message: `<p>Dear Sales Team,</p>
        <p>I have a few questions and need assistance regarding my dashboard. Here are the details for your reference:</p>
        <p>- Mobile Number : ${formdata.mobile_number}</p>
        <p>- Scheduled Date : ${formdata.date}</p>
        <p>- Scheduled Time : ${formdata.time}</p>
        <p>- Mode of Meeting : ${formdata.mode}</p>
        <p>Could you please provide clarification or guidance on the next steps?</p>
        <p>Thank you for your support.</p>
        <p>Best regards,</p>
        <p><b>Team Hirein5</b></p>`,
    };
    await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, emailobj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });

    var updatedata = await axios
      .post(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/contactus/${userid}/`,
        formdata,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (updatedata !== null) {
      setsuccessbtn(true);
      setTimeout(() => {
        setsuccessbtn(false);
        dispatch(storeAction.isPopUpHander());
        setformdata({
          mobile_number: "",
          date: "",
          time: "",
          mode: "",
        });
        setloading(false);
      }, 5000);
    }
  };
  return (
    <div>
      <div className="PopupComp">
        <div className={props.PopupCompSuccess}>
          <CiWarning className={props.waricon} />
          <img className={props.imgClass} src={success} alt="" />
          <h1>{props.Head}</h1>
          <p>{props.desc}</p>
        </div>
        <div className={props.InputContainer}>
          <div className="PopupInput">
            <h2>Number (with country code)</h2>
            <input
              placeholder="+91 9442185881"
              type="number"
              name="mobile_number"
              onChange={handlechange}
            />
          </div>
          <div className="PopupInput">
            <h2>preferred date</h2>
            <input type="date" name="date" onChange={handlechange} />
          </div>
          <div className="PopupInput">
            <h2>preferred time</h2>
            <input type="time" name="time" onChange={handlechange} />
          </div>
          <div className="PopupRadio">
            <div className="PopupRadioLeft">
              <input
                className="pointer"
                type="radio"
                value="Whats App"
                name="mode"
                onChange={handlechange}
                checked={formdata.mode === "Whats App"}
              />
              <h4 className="">Whats App</h4>
            </div>
            <div className="PopupRadioLeft">
              <input
                className="pointer"
                type="radio"
                value="Call"
                name="mode"
                onChange={handlechange}
                checked={formdata.mode === "Call"}
              />
              <h4 className="">Call</h4>
            </div>
          </div>
        </div>
        <div className={props.PopupSingleButton} onClick={props.SingleBtnfun}>
          <button>{props.PopupSingleText}</button>
        </div>
        {successbtn && (
          <p className="successtext">
            We've received your details. Our team will reach out to assist you
            shortly.
          </p>
        )}

        <div className={props.PopupDoubleButton}>
          <button onClick={overLayHandler} className="PopupDoubleButtonSkip">
            {props.DoubleBtn1}
          </button>
          {loading && (
            <button className="PopupDoubleButtonSubmitdisable">
              {props.DoubleBtn2}
            </button>
          )}
          {!loading && (
            <button className="PopupDoubleButtonSubmit" onClick={submitbtn}>
              {props.DoubleBtn2}
            </button>
          )}
        </div>
        <IoMdCloseCircle className={props.closeIcon} onClick={overLayHandler} />
      </div>
    </div>
  );
};

export default Popup;
