// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sectioHead {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.sectioHead h3 {
  font-size: 1.3rem;
  font-weight: 700;
}

.sectioHead p {
  margin-top: 5px;
  font-size: 0.8rem;
  color: var(--textColor);
}

.log {
  color: var(--white);
  font-weight: 600;
  margin-left: 5px;
  background-color: var(--primaryColor);
  padding: 3px 5px;
  border-radius: 4px;
}

/* @media only screen and (max-width: 600px) {
  .sectioHead h3 {
    font-size: 20px;
  }

  .sectioHead p {
    text-align: center;
  }
} */`, "",{"version":3,"sources":["webpack://./src/Components/Reusable/SectionHead/SectionHead.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,iBAAiB;EACjB,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,uBAAuB;AACzB;;AAEA;EACE,mBAAmB;EACnB,gBAAgB;EAChB,gBAAgB;EAChB,qCAAqC;EACrC,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;;;;;;;;GAQG","sourcesContent":[".sectioHead {\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n}\n\n.sectioHead h3 {\n  font-size: 1.3rem;\n  font-weight: 700;\n}\n\n.sectioHead p {\n  margin-top: 5px;\n  font-size: 0.8rem;\n  color: var(--textColor);\n}\n\n.log {\n  color: var(--white);\n  font-weight: 600;\n  margin-left: 5px;\n  background-color: var(--primaryColor);\n  padding: 3px 5px;\n  border-radius: 4px;\n}\n\n/* @media only screen and (max-width: 600px) {\n  .sectioHead h3 {\n    font-size: 20px;\n  }\n\n  .sectioHead p {\n    text-align: center;\n  }\n} */"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
