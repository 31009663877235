import React from "react";
import VendorCandidateView from "../Components/AdminScreen/AdminProfile/ACandidateProfileView/VendorCandidateView";

const ACandidateProfile = () => {
  return (
    <div>
      <VendorCandidateView />
    </div>
  );
};

export default ACandidateProfile;
