// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.head {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.head img {
  object-fit: cover;
  cursor: pointer;
}

.head h1 {
  font-size: 1.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  margin-left: 0.5rem;
}

@media only screen and (max-width: 600px) {
  .head img {
    height: 25px;
  }

  .head h1 {
    font-size: 24px;
  }
}`, "",{"version":3,"sources":["webpack://./src/Components/Reusable/LogoHead/Head.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB;;AAEA;EACE,iBAAiB;EACjB,eAAe;AACjB;;AAEA;EACE,mBAAmB;EACnB,oBAAoB;EACpB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE;IACE,YAAY;EACd;;EAEA;IACE,eAAe;EACjB;AACF","sourcesContent":[".head {\n  width: 100%;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n\n.head img {\n  object-fit: cover;\n  cursor: pointer;\n}\n\n.head h1 {\n  font-size: 1.875rem;\n  line-height: 2.25rem;\n  font-weight: 500;\n  margin-left: 0.5rem;\n}\n\n@media only screen and (max-width: 600px) {\n  .head img {\n    height: 25px;\n  }\n\n  .head h1 {\n    font-size: 24px;\n  }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
