// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.options {
    text-align: center;
    padding: 12px;
    height: 65px;
    /* background-color: burlywood; */
    border-bottom: 1px solid var(--borderColor);
    display: flex;
    align-items: flex-end;
    justify-content: center;
}

.optionsLeft {
    text-align: start;
    padding: 12px 0px;
    /* background-color: burlywood; */
    border-bottom: 1px solid var(--borderColor);
    display: flex;
    align-items: flex-end;
    height: 65px;
}

.optionsLeft p {
    font-size: 14px;
    /* color: var(--textColor); */
}

.options p {
    font-size: 14px;
    /* color: var(--textColor); */
}`, "",{"version":3,"sources":["webpack://./src/Components/Reusable/OptionAvailable/OptionAvailable.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,aAAa;IACb,YAAY;IACZ,iCAAiC;IACjC,2CAA2C;IAC3C,aAAa;IACb,qBAAqB;IACrB,uBAAuB;AAC3B;;AAEA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,iCAAiC;IACjC,2CAA2C;IAC3C,aAAa;IACb,qBAAqB;IACrB,YAAY;AAChB;;AAEA;IACI,eAAe;IACf,6BAA6B;AACjC;;AAEA;IACI,eAAe;IACf,6BAA6B;AACjC","sourcesContent":[".options {\n    text-align: center;\n    padding: 12px;\n    height: 65px;\n    /* background-color: burlywood; */\n    border-bottom: 1px solid var(--borderColor);\n    display: flex;\n    align-items: flex-end;\n    justify-content: center;\n}\n\n.optionsLeft {\n    text-align: start;\n    padding: 12px 0px;\n    /* background-color: burlywood; */\n    border-bottom: 1px solid var(--borderColor);\n    display: flex;\n    align-items: flex-end;\n    height: 65px;\n}\n\n.optionsLeft p {\n    font-size: 14px;\n    /* color: var(--textColor); */\n}\n\n.options p {\n    font-size: 14px;\n    /* color: var(--textColor); */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
