/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./AVendorProfileview.css";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../../Store/Store";
import { FiLoader } from "react-icons/fi";
import Avatar from "react-avatar";
import axios from "axios";
import { IoMdArrowBack } from "react-icons/io";
import Onlywallet from "../../Vendor/Wallet/Onlywallet";
import BillinginfoVendor from "../../NewClientFlow/BillinginfoVendor";

const AVendorProfileview = () => {
  const singleuser = useSelector((store) => store.singlevendor);
  const allcompanydata = useSelector((store) => store.allcompanydata);
  const token = useSelector((store) => store.token);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [isToggle, setIsToggle] = useState("billing");
  const toggleHandler = (e) => {
    setIsToggle(e.target.id);
  };
  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });
  const editHandler1 = (e) => {
    dispatch(storeAction.isPopUpHander(e.target.id));
  };
  const [loading, setIsLoading] = useState(false);

  const getalldata = async (data) => {
    const index = allcompanydata.findIndex((item) => item.id === data.id);
    if (index !== -1) {
      const updatedArray = [...allcompanydata];
      updatedArray[index] = { ...updatedArray[index], ...data };
      dispatch(
        storeAction.allcompanydataHander({ allcompanydata: updatedArray })
      );
    }
  };

  const disablebtn = async (data) => {
    setIsLoading(true);
    var obj = {
      username: data.username,
      dissabled: true,
    };
    var updatedata = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.id}/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (
      updatedata.message === "User and Associated Info updated successfully"
    ) {
      let updatedObject = {
        ...singleuser[0],
        dissabled: true,
      };
      dispatch(storeAction.singleuserHander({ singleuser: [] }));
      getalldata(updatedObject);
      setTimeout(() => {
        dispatch(storeAction.singleuserHander({ singleuser: [updatedObject] }));
      }, 10);
      dispatch(storeAction.isPopUpHander());
      setIsLoading(false);
    }
  };

  const enablebtn = async (data) => {
    setIsLoading(true);
    var obj = {
      username: data.username,
      dissabled: false,
    };
    var updatedata = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.id}/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (
      updatedata.message === "User and Associated Info updated successfully"
    ) {
      let updatedObject = {
        ...singleuser[0],
        dissabled: false,
      };
      dispatch(storeAction.singleuserHander({ singleuser: [] }));
      getalldata(updatedObject);
      setTimeout(() => {
        dispatch(storeAction.singleuserHander({ singleuser: [updatedObject] }));
      }, 10);
      dispatch(storeAction.isPopUpHander());
      setIsLoading(false);
    }
  };

  const [type, settype] = useState("");

  const deletebtn = async (data) => {
    settype("delete");
    setIsLoading(true);
    var arrayOfObjects = allcompanydata.filter((obj) => obj.id !== data.id);
    dispatch(
      storeAction.allcompanydataHander({ allcompanydata: arrayOfObjects })
    );
    await axios.delete(
      `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${data.id}/`,
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      }
    );
    setTimeout(() => {
      setIsLoading(false);
    }, 2000);
    window.location.replace("/#/customerProfile");
  };

  return (
    <div>
      {singleuser.length !== 0 ? (
        <div className="clientProfileOverview paddingLeft100 paddingRight100 ">
          <div className="clientProfileViewHeader">
            <div className="ClientProfileBackButton">
              <span onClick={() => navigate("/vendorlist")}>
                {" "}
                <IoMdArrowBack />
              </span>
              <h5
                className="cursor-pointer"
                onClick={() => navigate("/vendorlist")}
              >
                Back to profile page
              </h5>
            </div>
            <div className="clientProfileViewFlex">
              <div className="clientProfileViewFlexLeft">
                <div className="clientProfileViewFlexLeftImg">
                  {singleuser[0].profile_picture.length !== 0 ? (
                    <img src={singleuser[0].profile_picture} alt="" />
                  ) : singleuser[0].company !== null ? (
                    singleuser[0].company.company_name.length !== 0 ? (
                      <Avatar
                        name={singleuser[0].company.company_name}
                        size={100}
                        round="50px"
                      />
                    ) : (
                      <Avatar
                        name={singleuser[0].first_name}
                        size={100}
                        round="50px"
                      />
                    )
                  ) : (
                    <Avatar
                      name={singleuser[0].first_name}
                      size={100}
                      round="50px"
                    />
                  )}
                </div>
                <div className="clientProfileViewFlexLeftDesc">
                  {singleuser[0].company !== null ? (
                    singleuser[0].company.company_name.length !== 0 ? (
                      <h1>{singleuser[0].company.company_name}</h1>
                    ) : null
                  ) : null}

                  <div className="clientProfileViewFlexLeftDescRole">
                    <h2>{singleuser[0].first_name}</h2>
                  </div>
                  <div className="clientProfileViewFlexLeftDescRole">
                    <h3>{singleuser[0].email}</h3>
                  </div>
                  <div className="clientProfileViewFlexLeftDescLocation">
                    {/* <img src={clientProfile} alt="" /> */}
                    <h2>{singleuser[0].current_place_of_residence}</h2>
                  </div>
                </div>
              </div>
              <div className="clientProfileViewFlexRight">
                {singleuser[0].dissabled == false ? (
                  loading === false ? (
                    <button
                      className="disableProfile"
                      onClick={() => {
                        disablebtn(singleuser[0]);
                      }}
                    >
                      Disable profile
                    </button>
                  ) : (
                    <button className="save w-[10rem] flex justify-center items-center">
                      <FiLoader className="loadingIcon" />
                    </button>
                  )
                ) : loading === false ? (
                  <button
                    className="disableProfile"
                    onClick={() => {
                      enablebtn(singleuser[0]);
                    }}
                  >
                    Activate profile
                  </button>
                ) : type !== "delete" ? (
                  <button className="save w-[10rem] flex justify-center items-center">
                    <FiLoader className="loadingIcon" />
                  </button>
                ) : (
                  <button
                    className="disableProfile"
                    onClick={() => {
                      enablebtn(singleuser[0]);
                    }}
                  >
                    Activate profile
                  </button>
                )}
                {loading === true ? (
                  type === "delete" ? (
                    <button className="save w-[10rem] flex justify-center items-center">
                      <FiLoader className="loadingIcon" />
                    </button>
                  ) : (
                    <button
                      className="delete_btn"
                      onClick={() => {
                        deletebtn(singleuser[0]);
                      }}
                    >
                      Delete Profile
                    </button>
                  )
                ) : (
                  <button
                    className="delete_btn"
                    onClick={() => {
                      deletebtn(singleuser[0]);
                    }}
                  >
                    Delete Profile
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="clientProfileViewHeaderMob">
            <div className="ClientProfileBackButton">
              <span onClick={() => navigate("/customerProfile")}>
                <IoMdArrowBack />
              </span>

              <h5
                className="cursor-pointer"
                onClick={() => navigate("/customerProfile")}
              >
                Back to profile page
              </h5>
            </div>
            <div className="clientProfileViewFlex">
              <div className="clientProfileViewFlexLeft">
                <div className="clientProfileViewFlexLeftImg">
                  {singleuser[0].profile_picture.length !== 0 ? (
                    <img src={singleuser[0].profile_picture} alt="" />
                  ) : (
                    <Avatar
                      name={singleuser[0].first_name}
                      size={100}
                      round="50px"
                    />
                  )}
                </div>
                <div className="clientProfileViewFlexLeftDesc">
                  <div className="clientProfileViewFlexLeftDescHead">
                    <h1>{singleuser[0].first_name}</h1>
                    {/* {singleuser[0].apprual === false ? (
                      <span className="pendingApproval">Approval Pending</span>
                    ) : (
                      <img src={approvedTick} alt="" />
                    )} */}
                  </div>
                  {singleuser[0].preference_info !== null ? (
                    <div className="clientProfileViewFlexLeftDescRole">
                      <h2>{singleuser[0].preference_info.qualification}</h2>
                    </div>
                  ) : null}

                  <div className="clientProfileViewFlexLeftDescLocation">
                    {/* <img src={candidateProfile} alt="" /> */}
                    <h2>{singleuser[0].current_place_of_residence}</h2>
                    {singleuser[0].rate_card_info !== null ? (
                      <h2>
                        USD {singleuser[0].rate_card_info.remote_hourly}/hr
                      </h2>
                    ) : (
                      <h2>Not provided yet</h2>
                    )}
                  </div>
                </div>
              </div>
              <div className="clientProfileViewFlexRightMob">
                {/* <button onClick={overLayHandler} className="editRate">
                  <img src={editOutline} alt="" />
                  Edit Rate (Pricing)
                </button> */}
                <div className="clientProfileViewFlexRightButtonMobClient">
                  <button
                    // id="approveconformation"
                    onClick={editHandler1}
                    className="disableProfile"
                  >
                    Delete profile
                  </button>
                  <button className="disableProfile">Disable Profile</button>
                </div>

                {isPopUp == "approvedropdown" &&
                  (singleuser.length !== 0 ? (
                    <div className="approvalMenu">
                      {singleuser[0].apprual === false ? (
                        <h3
                          id="approveconformation"
                          onClick={editHandler1}
                          className="approvalMenuActive"
                        >
                          Disable profile
                        </h3>
                      ) : null}

                      <h3 className="approvalMenuDisable">Disable Profile</h3>
                    </div>
                  ) : null)}
              </div>
            </div>
          </div>
          <Onlywallet />
          <div className="clientViewTab">
            <h5
              onClick={toggleHandler}
              id="billing"
              className={
                isToggle === "billing"
                  ? "clientViewTabActive"
                  : "clientViewTabInactive"
              }
            >
              Billing
            </h5>
          </div>

          {isToggle === "billing" && <BillinginfoVendor />}
        </div>
      ) : null}
    </div>
  );
};

export default AVendorProfileview;
