// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.contractTableHead {
    padding: 10px 0px;
    text-align: start;
    font-weight: 400;
    font-size: 13px;
    opacity: 0.7;

    border-bottom: 1px solid var(--borderColor);
}

.contactimg {
    width: 30px;
    height: 30px;
    object-fit: cover;
}

.contractInnerTable {
    width: 100%;
    height: 100%;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
    margin-top: 20px;
    padding-left: 15px;
    padding-Right: 15px;
    margin-bottom: 30px;
    background-color: var(--white);
}

.contractTableRow {
    padding-left: 20px;
}

.contractTableRow td {
    padding: 15px 0px;
}

.Contract {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 15px;
}

@media only screen and (max-width: 600px) {
    .Contract {
        grid-template-columns: repeat(1, 1fr);
    }

    .contractInnerTable {
        /* width: 1000px; */
        overflow-x: scroll;

    }

    .table {
        width: 1000px;
        overflow-x: scroll;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Client/ClientScreen/Contracts/ContractComp.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;IACjB,iBAAiB;IACjB,gBAAgB;IAChB,eAAe;IACf,YAAY;;IAEZ,2CAA2C;AAC/C;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,oCAAoC;IACpC,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;IACnB,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,kBAAkB;AACtB;;AAEA;IACI,iBAAiB;AACrB;;AAEA;IACI,aAAa;IACb,qCAAqC;IACrC,SAAS;AACb;;AAEA;IACI;QACI,qCAAqC;IACzC;;IAEA;QACI,mBAAmB;QACnB,kBAAkB;;IAEtB;;IAEA;QACI,aAAa;QACb,kBAAkB;IACtB;AACJ","sourcesContent":[".contractTableHead {\n    padding: 10px 0px;\n    text-align: start;\n    font-weight: 400;\n    font-size: 13px;\n    opacity: 0.7;\n\n    border-bottom: 1px solid var(--borderColor);\n}\n\n.contactimg {\n    width: 30px;\n    height: 30px;\n    object-fit: cover;\n}\n\n.contractInnerTable {\n    width: 100%;\n    height: 100%;\n    border-radius: 8px;\n    border: 1px solid var(--borderColor);\n    margin-top: 20px;\n    padding-left: 15px;\n    padding-Right: 15px;\n    margin-bottom: 30px;\n    background-color: var(--white);\n}\n\n.contractTableRow {\n    padding-left: 20px;\n}\n\n.contractTableRow td {\n    padding: 15px 0px;\n}\n\n.Contract {\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n    gap: 15px;\n}\n\n@media only screen and (max-width: 600px) {\n    .Contract {\n        grid-template-columns: repeat(1, 1fr);\n    }\n\n    .contractInnerTable {\n        /* width: 1000px; */\n        overflow-x: scroll;\n\n    }\n\n    .table {\n        width: 1000px;\n        overflow-x: scroll;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
