import React from "react";
import ContactusTable from "../Components/Client/ClientScreen/Contracts/ContactusTable";

const Contactlist = () => {
  return (
    <div>
      <ContactusTable />
    </div>
  );
};

export default Contactlist;
