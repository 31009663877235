/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import "./PersonalDetails.css";
import user from "../../../../assests/User.svg";
import dropDown from "../../../../assests/arrowDown.svg";
import edit from "../../../../assests/edit.svg";
import dropUp from "../../../../assests/arrowUp.svg";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../../../Store/Store";
import axios from "axios";
import { FiLoader } from "react-icons/fi";
import country_and_states from "../../../../assests/country-states";
import { FiUser } from "react-icons/fi";
import { RxCross1 } from "react-icons/rx";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { IoAlertCircle } from "react-icons/io5";
import Avatar from "react-avatar";
import { MdOutlineModeEdit } from "react-icons/md";
import moment from "moment";
import { firebase } from "../../../../database/firebase";

const PersonalDetails = () => {
  const userdata = useSelector((store) => store.userdata);
  const userid = useSelector((store) => store.userid);
  const token = useSelector((store) => store.token);

  const dispatch = useDispatch();
  const [isArrow, setIsArrow] = useState(false);
  const [loading, setloading] = useState(false);

  const dropDownhandler = () => {
    setIsArrow(!isArrow);
  };

  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });

  const overLayHandler = () => {
    dispatch(storeAction.isPopUpHander("personal"));
  };
  const exitOverLayHandler = () => {
    dispatch(storeAction.isPopUpHander(""));
  };
  useEffect(() => {
    getUserinfo();
  }, [userdata.length !== 0]);

  const [formdata, setformdata] = useState({
    name: "",
    email: "",
    dob: "",
    phone: "",
    current_address: "",
    city: "",
    gender: "",
    source: "",
    state: "",
    pincode: "",
    aadhar: "",
    pan: "",
    country: "",
    bio: "",
    nationality: "",
  });
  const [statelist, setstatelist] = useState([]);
  const [profile, setprofile] = useState("");

  const getUserinfo = async () => {
    if (userdata.length !== 0) {
      setformdata({
        name: userdata[0].first_name,
        email: userdata[0].email,
        dob: userdata[0].date_of_birth,
        phone: userdata[0].phone,
        gender: userdata[0].gender,
        current_address:
          userdata[0].address !== null ? userdata[0].address.address : null,
        city: userdata[0].address !== null ? userdata[0].address.city : null,
        state: userdata[0].address !== null ? userdata[0].address.state : null,
        pincode:
          userdata[0].address !== null ? userdata[0].address.pincode : null,
        aadhar:
          userdata[0].kyc_info !== null
            ? userdata[0].kyc_info.aadhar_number
            : null,
        pan:
          userdata[0].kyc_info !== null
            ? userdata[0].kyc_info.pan_number
            : null,
        country:
          userdata[0].address !== null ? userdata[0].address.country : null,
        bio: userdata[0].bio,
        nationality: userdata[0].nationality,
        source: userdata[0].source,
      });
      if (userdata[0].address !== null) {
        var country = await country_and_states.country.filter((data) => {
          return data.name == userdata[0].address.country;
        });
        setstatelist(country_and_states.states[country[0].code]);
      }
      setprofile(userdata[0].profile_picture);
    } else {
      setTimeout(() => {
        getUserinfo();
      }, 1000);
    }
  };
  const handlechange = async (e) => {
    const { name, value } = e.target;
    if (name === "country") {
      setstatelist([]);
      var country = await country_and_states.country.filter((data) => {
        return data.name == value;
      });
      setformdata((values) => ({ ...values, [name]: country[0].name }));
      setstatelist(country_and_states.states[country[0].code]);
    } else {
      setformdata((values) => ({ ...values, [name]: value }));
    }
  };
  const savebtn = async () => {
    setloading(true);
    var newobj = {
      first_name: formdata.name,
      email: formdata.email,
      date_of_birth: formdata.dob,
      gender: formdata.gender,
      phone: formdata.phone,
      current_place_of_residence: formdata.current_address,
      username: userdata[0].username,
      bio: formdata.bio,
      nationality: formdata.nationality,
      source: formdata.source,
      address: {
        address: formdata.current_address,
        city: formdata.city,
        state: formdata.state,
        country: formdata.country,
        pincode: formdata.pincode,
      },
      kyc_info: {
        aadhar_number: formdata.aadhar,
        pan_number: formdata.pan,
      },
    };
    var updatedata = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userid}/`,
        newobj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (
      updatedata.message === "User and Associated Info updated successfully"
    ) {
      let updatedObject = {
        ...userdata[0],
        first_name: formdata.name,
        email: formdata.email,
        date_of_birth: formdata.dob,
        gender: formdata.gender,
        phone: formdata.phone,
        current_place_of_residence: formdata.current_address,
        username: userdata[0].username,
        bio: formdata.bio,
        nationality: formdata.nationality,
        source: formdata.source,
        address: updatedata.user.address,
        kyc_info: updatedata.user.kyc_info,
      };
      dispatch(storeAction.userdataHander({ userdata: [] }));
      setTimeout(() => {
        dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
      }, 10);
      dispatch(storeAction.isPopUpHander());
      setloading(false);
    }
    getUserinfo();
  };
  const cancelbtn = () => {
    dispatch(storeAction.isPopUpHander());
  };

  const [isComplete, setIsComplete] = useState(false);
  const completeHandler = () => {
    setIsComplete(true);
  };
  const completeHandlerLeave = () => {
    setIsComplete(false);
  };
  const fileInputRef = useRef(null);

  const uploadHandler = (data) => {
    fileInputRef.current.click();
  };
  const [formData] = useState(new FormData());
  const handleFileInputChange = async (e) => {
    formData.append("image", e.target.files[0]);
    formData.append("name", `profile${userid}`);
    let file = e.target.files;
    let file13 = new Promise((resolve, reject) => {
      var storageRef = firebase
        .storage()
        .ref(`profile${userid}` + file[0].name);
      storageRef.put(file[0]).then(function (snapshot) {
        storageRef.getDownloadURL().then(function (url) {
          setTimeout(() => resolve(url), 1000);
        });
      });
    }).catch((err) => {
      console.log(err, "errr");
    });
    var imgurl_new2 = await file13;
    fileInputRef.current.value = "";
    setprofile(imgurl_new2);
    var newobj = {
      username: userdata[0].username,
      profile_picture: imgurl_new2,
    };
    var updatedata = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userid}/`,
        newobj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (
      updatedata.message === "User and Associated Info updated successfully"
    ) {
      let updatedObject = {
        ...userdata[0],
        profile_picture: imgurl_new2,
      };
      let updatedObject1 = {
        ...userdata[0],
        profile_picture: imgurl_new2,
      };
      dispatch(storeAction.singleuserHander({ singleuser: [] }));
      dispatch(storeAction.userdataHander({ userdata: [] }));
      setTimeout(() => {
        dispatch(storeAction.singleuserHander({ singleuser: [updatedObject] }));
        dispatch(storeAction.userdataHander({ userdata: [updatedObject1] }));
      }, 10);
    }
  };
  return (
    <div>
      <div className="personalDetails">
        <div className="innerPersonalDetails">
          <div
            className={
              isArrow === true ? "personalDetailsHead" : "bottomBorder"
            }
          >
            <div className="personalDetailsHeadLeft">
              <span>
                <FiUser />
              </span>
              <h1>Personal Details</h1>
            </div>
            <div className="personalDetailsLeftIcon">
              {userdata.length !== 0 ? (
                userdata[0].address !== null ? (
                  <IoIosCheckmarkCircle className="profileCompleteIcon" />
                ) : (
                  <>
                    <IoAlertCircle
                      onMouseEnter={completeHandler}
                      onMouseLeave={completeHandlerLeave}
                      className="warningIcon"
                    />
                    {isComplete == true && (
                      <div className="InCompleteIndication">
                        <p>Complete Personal Details</p>
                      </div>
                    )}
                  </>
                )
              ) : null}
              {userdata.length !== 0 ? (
                userdata[0].nottify == true ? null : (
                  <img
                    className="personalDetailsLeftIconSvg"
                    onClick={overLayHandler}
                    src={edit}
                    alt=""
                  />
                )
              ) : null}

              {isArrow === true ? (
                <img onClick={dropDownhandler} src={dropUp} alt="" />
              ) : (
                <img onClick={dropDownhandler} src={dropDown} alt="" />
              )}
            </div>
          </div>
          {isArrow === true && (
            <div className="personalDetailsDesc">
              <h1>Tell us about yourself</h1>
              {profile.length == 0 ? (
                <Avatar
                  name={formdata.name}
                  size={100}
                  round="50px"
                  className="profileLeftTopimg"
                />
              ) : (
                <img src={profile} alt="" className="profileLeftTopimg" />
              )}
              <div onClick={uploadHandler} className="editprofilenew">
                <MdOutlineModeEdit className="editicon" />
                <input
                  type="file"
                  ref={fileInputRef}
                  style={{ display: "none" }}
                  name="aadhaarfront"
                  onChange={handleFileInputChange}
                />
              </div>
              {userdata.length !== 0 ? (
                <div className="personalInfo">
                  <div className="personalDetailsDescLeft">
                    <h2>Name</h2>
                    <h3>{userdata[0].first_name}</h3>
                    <h2>Date of Birth</h2>
                    <h3>
                      {moment(userdata[0].date_of_birth).format("DD-MM-YYYY")}
                    </h3>
                    <h2>Phone Number</h2>
                    <h3>{userdata[0].phone}</h3>
                    <h2>Gender</h2>
                    <h3>{userdata[0].gender}</h3>
                    {userdata[0].kyc_info !== null ? (
                      <>
                        <h2>AADHAAR / Govt. Issued ID</h2>
                        <h3 className="personalDetailsDescLeftOpacity">
                          {userdata[0].kyc_info.aadhar_number}
                        </h3>
                        <h2>PAN Card / Govt. Issued TAX ID</h2>
                        <h3 className="personalDetailsDescLeftOpacity">
                          {userdata[0].kyc_info.pan_number}
                        </h3>
                      </>
                    ) : null}
                  </div>
                  <div className="personalDetailsDescRight">
                    <h2>Email ID</h2>
                    <h3>{userdata[0].email}</h3>
                    {userdata[0].address !== null ? (
                      <>
                        <h2>Current Residential Address</h2>
                        <h3>{userdata[0].address.address}</h3>
                        <h2>City</h2>
                        <h3>{userdata[0].address.city}</h3>
                        <h2>PINCODE</h2>
                        <h3>{userdata[0].address.pincode}</h3>
                        <h2>Country</h2>
                        <h3>{userdata[0].address.country}</h3>
                        <h2>Nationality</h2>
                        <h3>{userdata[0].nationality}</h3>
                      </>
                    ) : null}
                  </div>
                </div>
              ) : null}
              {userdata.length !== 0 ? (
                <>
                  <h2 className="bioLabel">Source</h2>
                  <h3 className="biocontent">{userdata[0].source}</h3>
                  <h2 className="bioLabel">Bio</h2>
                  <h3 className="biocontent">{userdata[0].bio}</h3>
                </>
              ) : null}
            </div>
          )}
          {isPopUp === "personal" && (
            <div className="personalOverlay">
              <div className="innerPersonalDetails">
                <div
                  className={
                    isArrow === true ? "personalDetailsHead" : "bottomBorder"
                  }
                >
                  <div className="personalDetailsHeadLeft">
                    <img src={user} alt="" />
                    <h1>Personal Details</h1>
                  </div>
                  <div
                    onClick={exitOverLayHandler}
                    className="personalDetailsLeftIcon"
                  >
                    <RxCross1 />
                    {/* <img
                      className="personalDetailsLeftIconSvg"
                      onClick={overLayHandler}
                      src={edit}
                      alt=""
                    />
                    {isArrow === true ? (
                      <img onClick={dropDownhandler} src={dropUp} alt="" />
                    ) : (
                      <img onClick={dropDownhandler} src={dropDown} alt="" />
                    )} */}
                  </div>
                </div>
              </div>
              <div className="updatePersonalDetails">
                <h1>Tell us about yourself</h1>
                {profile.length == 0 ? (
                  <Avatar name={formdata.name} size={100} round="50px" />
                ) : (
                  <img src={profile} alt="" />
                )}
                <div onClick={uploadHandler} className="editprofilenew">
                  <MdOutlineModeEdit className="editicon" />
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    name="aadhaarfront"
                    onChange={handleFileInputChange}
                  />
                </div>
                <div className="updatePersonalDetailsFlex">
                  <div className="updatePersonalDetailsLeft">
                    <h2>Name</h2>
                    <input
                      placeholder="Yasir Quazi"
                      type="text"
                      name="name"
                      onChange={handlechange}
                      defaultValue={formdata.name}
                    />
                    <h2>Date of Birth</h2>
                    <input
                      className="31 / 05 / 1991"
                      type="date"
                      name="dob"
                      onChange={handlechange}
                      defaultValue={formdata.dob}
                    />
                    <h2>Phone Number</h2>
                    <input
                      placeholder="9876543210"
                      type="text"
                      maxLength={12}
                      name="phone"
                      onChange={handlechange}
                      defaultValue={formdata.phone}
                    />
                    <h2>Gender</h2>
                    <select
                      name="gender"
                      onChange={handlechange}
                      defaultValue={formdata.gender}
                      selected={formdata.gender}
                    >
                      <option value="" disabled>
                        Select
                      </option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Others">Others</option>
                      <option value="Prefer not to say">
                        Prefer not to say
                      </option>
                    </select>
                    <h2>AADHAAR / Govt. Issued ID</h2>
                    <input
                      className="inputColor"
                      placeholder="48XX XXXX XX21"
                      type="text"
                      name="aadhar"
                      onChange={handlechange}
                      defaultValue={formdata.aadhar}
                    />
                    <h2>PAN Card / Govt. Issued TAX ID</h2>
                    <input
                      className="inputColor"
                      placeholder="48XX XXXX XX21"
                      type="text"
                      name="pan"
                      onChange={handlechange}
                      defaultValue={formdata.pan}
                    />
                    <h2>Nationality</h2>
                    <input
                      placeholder="Nationality"
                      type="text"
                      name="nationality"
                      onChange={handlechange}
                      defaultValue={formdata.nationality}
                    />
                  </div>
                  <div className="updatePersonalDetailsRight">
                    <h2>Email ID</h2>
                    <input
                      placeholder="yasirquazi2000@gmail.com"
                      type="text"
                      name="email"
                      onChange={handlechange}
                      defaultValue={formdata.email}
                    />
                    <h2>Current Residential Address</h2>
                    <input
                      placeholder="Lorem ipsum dolor sit amet, Richmond Road"
                      type="text"
                      name="current_address"
                      onChange={handlechange}
                      defaultValue={formdata.current_address}
                    />

                    <h2>Country</h2>
                    <select
                      name="country"
                      onChange={handlechange}
                      defaultValue={formdata.country}
                    >
                      <option value="" disabled>
                        Country
                      </option>
                      {country_and_states.country.length !== 0
                        ? country_and_states.country.map((item, index) => (
                            <option
                              value={item.name}
                              key={index}
                              selected={formdata.country}
                            >
                              {item.name}
                            </option>
                          ))
                        : null}
                    </select>
                    <div className="flex gap-4">
                      <div className="w-full">
                        <h2>State</h2>
                        <select
                          id=""
                          name="state"
                          onChange={handlechange}
                          defaultValue={formdata.state}
                        >
                          <option value="">State</option>
                          {statelist.length !== 0
                            ? statelist.map((data, index) => (
                                <option value={data.name} key={index}>
                                  {data.name}
                                </option>
                              ))
                            : null}
                        </select>
                      </div>
                      <div className="w-full">
                        <h2>City</h2>
                        <input
                          placeholder="Bengaluru"
                          type="text"
                          name="city"
                          onChange={handlechange}
                          defaultValue={formdata.city}
                        />
                      </div>
                    </div>
                    <h2>PINCODE</h2>
                    <input
                      placeholder="560005"
                      type="number"
                      name="pincode"
                      onChange={handlechange}
                      defaultValue={formdata.pincode}
                    />
                    <h2>Source</h2>
                    <select
                      name="source"
                      onChange={handlechange}
                      defaultValue={formdata.source}
                      selected={formdata.source}
                    >
                      <option value="">Select Source</option>
                      <option value="Facebook">Facebook</option>
                      <option value="Vendor">Vendor</option>
                      <option value="Instagram">Instagram</option>
                      <option value="Google">Google</option>
                      <option value="Local Advertisement">
                        Local Advertisement
                      </option>
                      <option value="Recommendation from a friend">
                        Recommendation from a friend
                      </option>
                      <option value="LinkedIn">LinkedIn</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>
                <h2 className="biohead">Bio</h2>
                <textarea
                  className="biotext"
                  name="bio"
                  onChange={handlechange}
                  defaultValue={formdata.bio}
                ></textarea>
              </div>
              <div className="vedioResumeButtons personalbtn">
                <button className="discard" onClick={cancelbtn}>
                  Discard Changes
                </button>
                {loading === false ? (
                  <button className="save" onClick={savebtn}>
                    Save & Close
                  </button>
                ) : (
                  <button className="save w-[10rem] flex justify-center items-center">
                    <FiLoader className="loadingIcon" />
                  </button>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default PersonalDetails;
