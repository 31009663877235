// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.interviewHead {
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 20px;
}

.interviewCard {
    width: 100%;
    height: 100%;
    display: grid;
    grid-template-columns: repeat(2, 1fr);

}

.interviewimg {
    width: 30px;
    height: 30px;
    object-fit: cover;
}

@media only screen and (max-width: 600px) {
    .interviewCard {
        grid-template-columns: repeat(1, 1fr);
        padding-left: 22px;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Client/ClientScreen/InterviewComp/InterviewComp.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,aAAa;IACb,qCAAqC;;AAEzC;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,iBAAiB;AACrB;;AAEA;IACI;QACI,qCAAqC;QACrC,kBAAkB;IACtB;AACJ","sourcesContent":[".interviewHead {\n    font-size: 16px;\n    font-weight: 600;\n    margin-bottom: 20px;\n}\n\n.interviewCard {\n    width: 100%;\n    height: 100%;\n    display: grid;\n    grid-template-columns: repeat(2, 1fr);\n\n}\n\n.interviewimg {\n    width: 30px;\n    height: 30px;\n    object-fit: cover;\n}\n\n@media only screen and (max-width: 600px) {\n    .interviewCard {\n        grid-template-columns: repeat(1, 1fr);\n        padding-left: 22px;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
