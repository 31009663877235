/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-loop-func */
/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import "./HireMainComponent.css";
import DashHead from "../../Reusable/DashBoardReusable/DashHead/DashHead";
import Avatar from "react-avatar";
import { FaRegCircleXmark } from "react-icons/fa6";
import { FiPlusCircle } from "react-icons/fi";
import { storeAction } from "../../../Store/Store";
import { useDispatch, useSelector } from "react-redux";
import Skilllist from "../../../assests/skillsJSON.json";
import { CiSearch } from "react-icons/ci";
import { FaAngleDown } from "react-icons/fa";
import { BsThreeDots } from "react-icons/bs";
import { getDocument } from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker";
import { FiBriefcase } from "react-icons/fi";
import Dragoption from "../../Candidate/CandidateSignUp/CandidateRegistration/Dragoption";
import axios from "axios";
import MobileCandidateProfile from "../../MobileScreens/MobileCandidateProfile/MobileCandidateProfile";
import CandidateProfileCard from "../../Reusable/CandidateProfileCard/CandidateProfileCard";
import { IoMdArrowBack } from "react-icons/io";
import { IoBookmarkOutline } from "react-icons/io5";
import editOutline from "../../../assests/pencil.svg";
import search from "../../../assests/search.png";
import { firebase } from "../../../database/firebase";
import { FaBookmark } from "react-icons/fa";
import { LuTrash2 } from "react-icons/lu";

const HireMainComponent = () => {
  const dispatch = useDispatch();
  const token = useSelector((store) => store.token);
  const userid = useSelector((store) => store.userid);
  const alluserdata = useSelector((store) => store.alluserdata);

  const inputref = useRef("");

  const [card, setCard] = useState("profile1");
  const [isPage, setIsPage] = useState("page1");
  const [button, setbutton] = useState("");
  const [formdata, setformdata] = useState({
    project_name: "",
    duration: "",
    budget: "",
    amount: "",
    description: "",
    skills_required: "",
  });
  const [formdataerror, setformdataerror] = useState({
    project_name: false,
    duration: false,
    budget: false,
    amount: false,
    description: false,
    skills_required: false,
  });
  const [loader, setloader] = useState(false);
  const [skillData, setSkillData] = useState([]);
  const [skilllist, setskilllist] = useState("");
  const [selectedProfiles, setselectedProfiles] = useState([]);
  const [teamid, setteamid] = useState(null);
  const [editteam, seteditteam] = useState(false);
  const [hireloading, sethireloading] = useState(false);
  const [count, setcount] = useState(0);
  const [pagination_status, setpagination_status] = useState(false);
  const [newurl, setnewurl] = useState(null);
  const [candidatenext, setcandidatenext] = useState(null);
  const [candidateprevious, setcandidateprevious] = useState(null);
  const [alldata, setalldata] = useState([]);
  const [viewteam, setviewteam] = useState(false);
  const [displayTeam, setdisplayTeam] = useState([]);
  const [sumvalue, setsumvalue] = useState(0);

  const handlechange = async (e) => {
    const { name, value } = e.target;
    setformdata((values) => ({ ...values, [name]: value }));
  };

  useEffect(() => {
    GetallTeam();
  }, []);

  const GetallTeam = async () => {
    try {
      const response = await axios.get(`https://api.hirein5.com/search_object`);
      setSkillData(response.data.skills.filter((skill) => skill.trim() !== ""));
    } catch (error) {
      console.error("Error fetching skill data:", error);
    }
    var getalldata = await axios
      .get(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/client/hireTeam/${userid}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (getalldata.results.length !== 0) {
      setcount(getalldata.count);
      if (getalldata.count > 20) {
        setpagination_status(true);
      } else {
        setpagination_status(false);
      }
      setalldata(getalldata.results);
      setcandidateprevious(getalldata.previous);
      setcandidatenext(getalldata.next);
    }
  };

  const createTaam = async () => {
    setformdataerror({
      project_name: false,
      duration: false,
      budget: false,
      amount: false,
      description: false,
      skills_required: false,
    });
    // if (formdata.project_name.length == 0) {
    //   setformdataerror((values) => ({ ...values, project_name: true }));
    // } else if (formdata.duration.length == 0) {
    //   setformdataerror((values) => ({ ...values, duration: true }));
    // } else if (formdata.budget.length == 0) {
    //   setformdataerror((values) => ({ ...values, budget: true }));
    // } else if (formdata.amount.length == 0) {
    //   setformdataerror((values) => ({ ...values, amount: true }));
    // } else if (formdata.description.length == 0) {
    //   setformdataerror((values) => ({ ...values, description: true }));
    // } else if (skill.length == 0) {
    //   setformdataerror((values) => ({ ...values, skills_required: true }));
    // } else {
    setloader(true);
    setformdataerror({
      project_name: false,
      duration: false,
      budget: false,
      amount: false,
      description: false,
      skills_required: false,
    });
    var obj = {
      project_name: formdata.project_name,
      duration: formdata.duration,
      budget: formdata.budget,
      amount: formdata.amount,
      description: formdata.description,
      skills_required: skill.toString(),
      client_id: userid,
    };
    var craetedata = await axios
      .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/hireTeam/`, obj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (craetedata.id !== undefined && craetedata.id !== null) {
      setloader(false);
      setteamid(craetedata.id);
    } else {
      setloader(false);
      setteamid(null);
    }
    // }
  };
  const UpdateTaam = async () => {
    setloader(true);
    var obj = {
      project_name: formdata.project_name,
      duration: formdata.duration,
      budget: formdata.budget,
      amount: formdata.amount,
      description: formdata.description,
      skills_required: skill.toString(),
      client_id: userid,
    };
    var updatedata = await axios
      .put(`${process.env.REACT_APP_LOCAL_HOST_URL}/hireTeam/${teamid}/`, obj, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });

    if (updatedata.id !== undefined && updatedata.id !== null) {
      setTimeout(() => {
        setloader(false);
      }, 2000);
      setteamid(updatedata.id);
    } else {
      setTimeout(() => {
        setloader(false);
      }, 2000);
    }
  };

  const cardHandler = (teamIndex, roleIndex, profileIndex, rate) => {
    setselectedProfiles((prevProfiles) => [
      ...prevProfiles.filter(
        (profile) =>
          !(profile.teamIndex === teamIndex && profile.roleIndex === roleIndex)
      ),
      {
        id: profileIndex,
        rate: rate,
        teamIndex: teamIndex,
        roleIndex: roleIndex,
      },
    ]);

    const updatedTeams = teamlist.map((team, tIndex) => {
      if (tIndex === teamIndex) {
        const updatedRoles = team.roles.map((role, rIndex) => {
          if (rIndex === roleIndex) {
            const updatedProfiles = role.profiles.map((profile, pIndex) => {
              return {
                ...profile,
                active: profile.id === profileIndex,
              };
            });
            return {
              ...role,
              profiles: updatedProfiles,
            };
          }
          return role;
        });
        return {
          ...team,
          roles: updatedRoles,
        };
      }
      return team;
    });
    setteamlist(updatedTeams);
    if (teamlist[teamIndex].name === selectedTeam.name) {
      setSelectedTeam((prevState) => ({
        ...prevState,
        roles: prevState.roles.map((role, rIndex) =>
          rIndex === roleIndex
            ? {
                ...role,
                profiles: role.profiles.map((profile, pIndex) => ({
                  ...profile,
                  active: profile.id === profileIndex,
                })),
              }
            : role
        ),
      }));
    }
  };

  const [dropDownList, setdropDownList] = useState([]);
  const [dropDownOpen, setdropDownClose] = useState(dropDownList);
  const [dropDown, setdropDown] = useState("");
  const [recordsPerPage] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [allcandidatedata, setallcandidatedata] = useState([
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
    {},
  ]);
  const [skill, setskill] = useState([]);
  const [responsibilitydata, setresponsibilitydata] = useState(null);
  const [newresponsibilitydata, setnewresponsibilitydata] = useState("");
  const [teamlist, setteamlist] = useState([
    {
      name: "Web App Development",
      roles: [
        {
          possition: "Project Manager",
          responsibility:
            "Oversees the entire project, manages timelines, coordinates team efforts, ensures that the project stays within scope and budget, and serves as a communication link between stakeholders and the development team.",
          job_des: "Responsible for building Manual Testing...",
          skill: "Strong organizational, communication, and leadership skills.	",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
          num_of_roles: "",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "UI/UX Designer",
          responsibility:
            "Focuses on user interface (UI) design and user experience (UX). They create wireframes, mockups, and prototypes, ensuring that the web application is userfriendly, intuitive, and visually appealing.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Graphic design, prototyping, UX research, and knowledge of design tools (e.g., Figma, Adobe XD).",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          num_of_roles: "",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "FrontEnd Developer",
          responsibility:
            "Implements the web application’s visual elements. They translate the UI/UX designs into code using technologies like HTML, CSS, and JavaScript, ensuring that the application is responsive and functions correctly across different devices and browsers.",
          job_des: "Responsible for building Manual Testing...",
          skill: "HTML, CSS, JavaScript, and front-end frameworks.",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Front-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=2e3c372f-bc8a-48ca-b1b4-930854554aef",
          num_of_roles: "",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "BackEnd Developer",
          responsibility:
            "Implements the web application’s visual elements. They translate the UI/UX designs into code using technologies like HTML, CSS, and JavaScript, ensuring that the application is responsive and functions correctly across different devices and browsers.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Server-side languages (e.g., Node.js, Python, Ruby, Java, PHP), databases (SQL, NoSQL), and RESTful API design.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Back-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=381b1558-0698-4875-88ef-388bb709ef3c",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "FullStack Developer (Optional)",
          responsibility:
            "Capable of handling both frontend and backend development. They can bridge the gap between UI design and serverside implementation, providing versatility to the development process.",
          job_des: "Responsible for building Manual Testing...",
          skill: "A combination of front-end and back-end technologies.",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Full-Stack%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=b24b1480-8210-42bf-8637-a66d3a0c60b1",
          num_of_roles: "",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Database Administrator (DBA)",
          responsibility:
            "Manages the database systems, ensures data integrity, optimizes queries, and handles data migration, backups, and recovery processes. They ensure that the database is scalable and secure.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Database Design, SQL Proficiency, Performance Tuning, Backup & Recovery,Database Security, DBMS Familiarity, Data Migration",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Database%20Administrator.docx.pdf?alt=media&token=2fcbb24e-dd8d-47f8-a2e5-b60e6279653b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "DevOps Engineer",
          responsibility:
            "Focuses on the deployment, scaling, and management of the application infrastructure. They set up continuous integration/continuous deployment (CI/CD) pipelines, automate processes, and ensure that the web application is scalable and secure.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Cloud platforms (AWS, Azure, GCP), CI/CD tools (Jenkins, GitLab CI), and containerization (Docker, Kubernetes).",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_DevOps%20Engineer_Web%20App%20Development.docx.pdf?alt=media&token=98e47f7e-242b-4c0e-9476-9c0277e889c6",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Test (QA) Engineer",
          responsibility:
            "Tests the application to identify bugs, usability issues, and performance bottlenecks. They ensure that the application meets the required standards and functions as expected across different environments.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Familiarity with testing frameworks (e.g., Selenium, Jest), and knowledge of test automation.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_QA%20Engineer%20-Tester_Web%20App%20Development.docx.pdf?alt=media&token=09acc7cc-7e68-4f66-af87-4c627997f0c1",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Security Specialist",
          responsibility:
            "Ensures the security of the web application by identifying vulnerabilities, implementing security protocols, and conducting penetration testing. They focus on protecting the application from potential threats.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Vulnerability Assessment Tools, Programming Languages, Security Frameworks and Standards, Web Technologies, Authentication and Authorization Protocols, Cloud Security, Security Testing Techniques, Incident Response Tools,Network Security, Secure Development Practices",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Security%20Specialist_Web%20App%20Development.docx.pdf?alt=media&token=29cbbbc8-c55d-4a0b-aea3-d36c8cebb538",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        // {
        //   possition: "Content Writer/Copywriter",
        //   responsibility:
        //     "Creates and curates the content for the web application. They ensure that the content is clear, engaging, and aligned with the brand’s voice and SEO best practices.",
        //   job_des: "Responsible for building Manual Testing...",
        //   skill:
        //     "Clear and Concise Writing, SEO Knowledge, Adaptability, Familiarity with Web Technologies, User Experience (UX) Writing, Market Research, Competitor Analysis, Storytelling, Brand Voice Development",
        //   num_of_roles: "",
        //   jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_CONTENT%20WRITER.docx.pdf?alt=media&token=5221e14a-9508-4e60-9865-e454de038b3c",
        //   location: "India",
        //   experience: "2",
        //   Availability: "Immediately",
        //   mode: "Full-time",
        //   working_hours: "Flexible with working hours",
        //   // duration: 0,
        //   gender: "",
        //   country: "",
        //   city: "",
        //   budget: "1000",
        // browse:"",
        //   profiles: [],
        // },
        {
          possition: "Business Analyst",
          responsibility:
            "Analyzes business needs and translates them into technical requirements. They work closely with stakeholders to ensure that the web application aligns with business objectives.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Requirements gathering, process mapping, and strong communication skills.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Business%20Analyst.docx.pdf?alt=media&token=9f2155e8-fe06-4427-8dd3-35c0cab54d82",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Product Owner",
          responsibility:
            "Represents the stakeholders and endusers, prioritizes features, and ensures that the development team is working on the most valuable aspects of the application. They manage the product backlog and help with decisionmaking.	",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Agile Methodologies, Product Management Tools, User Story Development, Web Technologies, Data Analysis, Market Research, UI/UX Principles, Stakeholder Management, Basic Technical Understanding",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Product%20Owner%20_Web%20App.docx.pdf?alt=media&token=84236fff-c442-42d0-9b77-cc2c2e1b9c58",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "System Architect",
          responsibility:
            "Designs the overall architecture of the web application, making key decisions about the tech stack, frameworks, and infrastructure. They ensure that the application is scalable, maintainable, and aligned with the business goals.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Architectural Frameworks, Cloud Platforms, Web Technologies, API Design and Management, Database Management, DevOps Practices, Security Architecture, Performance Optimization, System Integration, Monitoring and Logging",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_System%20Architect%20_Web%20App.docx.pdf?alt=media&token=788c0bdb-fec9-4eec-a986-faab13d78f34",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
      ],
    },
    // {
    //   name: "Mobile App Development",
    //   roles: [
    //     {
    //       possition: "Project Manager",
    //       responsibility:
    //         "Oversees the entire project, manages timelines, coordinates team efforts, ensures that the project stays within scope and budget, and serves as a communication link between stakeholders and the development team.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Project management methodologies (Agile, Scrum), communication, and team coordination.",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
    //       num_of_roles: "",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //       budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "UI/UX Designer",
    //       responsibility:
    //         "Focuses on user interface (UI) design and user experience (UX). They create wireframes, mockups, and prototypes, ensuring that the web application is userfriendly, intuitive, and visually appealing.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Graphic design, user experience (UX) research, wireframing, prototyping,and using design tools like Sketch, Figma, or Adobe XD.",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //       budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "FrontEnd Developer",
    //       responsibility:
    //         "Implements the web application’s visual elements. They translate the UI/UX designs into code using technologies like HTML, CSS, and JavaScript, ensuring that the application is responsive and functions correctly across different devices and browsers.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "HTML/CSS, JavaScript, Mobile Frameworks, Responsive Design, UI/UX Principles, Version Control, API Integration, Performance Optimization, Testing Frameworks, Development Tools",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Front-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=2e3c372f-bc8a-48ca-b1b4-930854554aef",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //       budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "BackEnd Developer",
    //       responsibility:
    //         "Implements the web application’s visual elements. They translate the UI/UX designs into code using technologies like HTML, CSS, and JavaScript, ensuring that the application is responsive and functions correctly across different devices and browsers.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Proficiency in server-side languages (Node.js, Python, Ruby, PHP, Java),knowledge of databases (SQL, NoSQL), and experience with cloud services (AWS,Azure, Google Cloud).",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Back-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=381b1558-0698-4875-88ef-388bb709ef3c",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //       budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "FullStack Developer (Optional)",
    //       responsibility:
    //         "Capable of handling both frontend and backend development. They can bridge the gap between UI design and serverside implementation, providing versatility to the development process.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "HTML/CSS, JavaScript, Mobile Frameworks, Server-Side Languages, Back-End Frameworks, SQL Databases, NoSQL Databases, RESTful Services, GraphQL, Mobile Development Tools, Version Control, Deployment Knowledge, Debugging, Performance Optimization, Mobile Security",
    //       num_of_roles: "",
    //       location: "India",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Full-Stack%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=b24b1480-8210-42bf-8637-a66d3a0c60b1",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Database Administrator (DBA)",
    //       responsibility:
    //         "Manages the database systems, ensures data integrity, optimizes queries, and handles data migration, backups, and recovery processes. They ensure that the database is scalable and secure.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Database Design, SQL Proficiency, Performance Tuning, Backup & Recovery,Database Security, DBMS Familiarity, Data Migration",
    //       num_of_roles: "",
    //       location: "India",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Database%20Administrator.docx.pdf?alt=media&token=2fcbb24e-dd8d-47f8-a2e5-b60e6279653b",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "DevOps Engineer",
    //       responsibility:
    //         "Focuses on the deployment, scaling, and management of the application infrastructure. They set up continuous integration/continuous deployment (CI/CD) pipelines, automate processes, and ensure that the web application is scalable and secure.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Cloud platforms (AWS, Azure, GCP), CI/CD tools (Jenkins, GitLab CI), and containerization (Docker, Kubernetes).",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_DevOps%20Engineer_Web%20App%20Development.docx.pdf?alt=media&token=98e47f7e-242b-4c0e-9476-9c0277e889c6",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Test (QA) Engineer",
    //       responsibility:
    //         "Tests the application to identify bugs, usability issues, and performance bottlenecks. They ensure that the application meets the required standards and functions as expected across different environments.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Knowledge of testing frameworks, automated testing tools, and mobile-specific testing practices. Familiarity with manual and automated testing processes.",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_QA%20Engineer%20-Tester_Web%20App%20Development.docx.pdf?alt=media&token=09acc7cc-7e68-4f66-af87-4c627997f0c1",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Security Specialist",
    //       responsibility:
    //         "Ensures the security of the web application by identifying vulnerabilities, implementing security protocols, and conducting penetration testing. They focus on protecting the application from potential threats.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Mobile Security Tools, Mobile Development Frameworks, Programming Languages, Security Standards and Frameworks, Authentication and Authorization, Threat Modeling and Risk Assessment, Incident Response, Secure Coding Practices, Monitoring and Logging, Network Security,",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Security%20Specialist_Web%20App%20Development.docx.pdf?alt=media&token=29cbbbc8-c55d-4a0b-aea3-d36c8cebb538",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Content Writer/Copywriter",
    //       responsibility:
    //         "Creates and curates the content for the web application. They ensure that the content is clear, engaging, and aligned with the brand’s voice and SEO best practices.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Clear and Concise Writing, SEO Knowledge, Adaptability, Familiarity with Web Technologies, User Experience (UX) Writing, Market Research, Competitor Analysis",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_CONTENT%20WRITER.docx.pdf?alt=media&token=5221e14a-9508-4e60-9865-e454de038b3c",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Business Analyst",
    //       responsibility:
    //         "Analyzes business needs and translates them into technical requirements. They work closely with stakeholders to ensure that the web application aligns with business objectives.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Requirement analysis, process modeling, and stakeholder communication.",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Business%20Analyst.docx.pdf?alt=media&token=9f2155e8-fe06-4427-8dd3-35c0cab54d82",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Product Owner",
    //       responsibility:
    //         "Represents the stakeholders and endusers, prioritizes features, and ensures that the development team is working on the most valuable aspects of the application. They manage the product backlog and help with decisionmaking.	",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Agile Methodologies, User Story Development, Roadmapping, Market Research and Analysis, Understanding of Mobile Technologies, API Knowledge, Analytics and Metrics, User Experience (UX) Principles, Stakeholder Management, Problem-Solving Skills",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Product%20Owner%20_Web%20App.docx.pdf?alt=media&token=84236fff-c442-42d0-9b77-cc2c2e1b9c58",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "System Architect",
    //       responsibility:
    //         "Designs the overall architecture of the web application, making key decisions about the tech stack, frameworks, and infrastructure. They ensure that the application is scalable, maintainable, and aligned with the business goals.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Mobile Development Frameworks, Architectural Frameworks, Backend Technologies, Database Management, Cloud Platforms, DevOps Practices, Security Architecture, Performance Optimization, User Experience (UX) Considerations, Monitoring and Logging",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_System%20Architect%20_Web%20App.docx.pdf?alt=media&token=788c0bdb-fec9-4eec-a986-faab13d78f34",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //   ],
    // },
    // {
    //   name: "Web Development",
    //   roles: [
    //     {
    //       possition: "Project Manager",
    //       responsibility:
    //         "Oversees the entire project, manages timelines, coordinates team efforts, ensures that the project stays within scope and budget, and serves as a communication link between stakeholders and the development team.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Web Development Knowledge,content management systems,Stakeholder Management,Project Planning & Management",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "UI/UX Designer",
    //       responsibility:
    //         "Focuses on user interface (UI) design and user experience (UX). They create wireframes, mockups, and prototypes, ensuring that the web application is userfriendly, intuitive, and visually appealing.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "User Research and Analysis,Wireframing and Prototyping,Visual Design",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "FrontEnd Developer",
    //       responsibility:
    //         "Implements the web application’s visual elements. They translate the UI/UX designs into code using technologies like HTML, CSS, and JavaScript, ensuring that the application is responsive and functions correctly across different devices and browsers.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "HTML5, CSS3, JavaScript, frameworks like React, Angular, or Vue.js, knowledge of responsive design principles (e.g., Bootstrap or CSS Grid).",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Front-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=2e3c372f-bc8a-48ca-b1b4-930854554aef",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "BackEnd Developer",
    //       responsibility:
    //         "Implements the web application’s visual elements. They translate the UI/UX designs into code using technologies like HTML, CSS, and JavaScript, ensuring that the application is responsive and functions correctly across different devices and browsers.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Programming languages such as Node.js, Python, Ruby, or PHP, and databases like MySQL, MongoDB, or PostgreSQL.",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Back-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=381b1558-0698-4875-88ef-388bb709ef3c",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "FullStack Developer (Optional)",
    //       responsibility:
    //         "Capable of handling both frontend and backend development. They can bridge the gap between UI design and serverside implementation, providing versatility to the development process.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Front-End Developmen&tBack-End DevelopmenT,Database Management,Agile Development Methodologies,API Design and Architecture",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Full-Stack%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=b24b1480-8210-42bf-8637-a66d3a0c60b1",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Database Administrator (DBA)",
    //       responsibility:
    //         "Manages the database systems, ensures data integrity, optimizes queries, and handles data migration, backups, and recovery processes. They ensure that the database is scalable and secure.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Database Design and Architecture,SQL,Database Scaling and High Availability,Backup and recovery,Database Performance Tuning",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Database%20Administrator.docx.pdf?alt=media&token=2fcbb24e-dd8d-47f8-a2e5-b60e6279653b",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "DevOps Engineer",
    //       responsibility:
    //         "Focuses on the deployment, scaling, and management of the application infrastructure. They set up continuous integration/continuous deployment (CI/CD) pipelines, automate processes, and ensure that the web application is scalable and secure.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Knowledge of cloud platforms (AWS, Azure, Google Cloud), CI/CD pipelines, version control systems (Git), and server management.",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_DevOps%20Engineer_Web%20App%20Development.docx.pdf?alt=media&token=98e47f7e-242b-4c0e-9476-9c0277e889c6",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Test (QA) Engineer",
    //       responsibility:
    //         "Tests the application to identify bugs, usability issues, and performance bottlenecks. They ensure that the application meets the required standards and functions as expected across different environments.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Knowledge of testing frameworks (Selenium, Cypress), manual testing, and understanding of responsive testing tools.",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_QA%20Engineer%20-Tester_Web%20App%20Development.docx.pdf?alt=media&token=09acc7cc-7e68-4f66-af87-4c627997f0c1",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Security Specialist",
    //       responsibility:
    //         "Ensures the security of the web application by identifying vulnerabilities, implementing security protocols, and conducting penetration testing. They focus on protecting the application from potential threats.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Web Application Security Fundamentals,VAPT,Authentication and Authorization,Encryption and Data Protection,SDLC,Firewall and Intrusion Detection,API AND CLOUD Security",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Security%20Specialist_Web%20App%20Development.docx.pdf?alt=media&token=29cbbbc8-c55d-4a0b-aea3-d36c8cebb538",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Content Writer/Copywriter",
    //       responsibility:
    //         "Creates and curates the content for the web application. They ensure that the content is clear, engaging, and aligned with the brand’s voice and SEO best practices.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Content Writing,SEO,CMS,UX Writing and Microcopy,Social Media and Content Distribution,Content Strategy and Planning",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_CONTENT%20WRITER.docx.pdf?alt=media&token=5221e14a-9508-4e60-9865-e454de038b3c",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Business Analyst",
    //       responsibility:
    //         "Analyzes business needs and translates them into technical requirements. They work closely with stakeholders to ensure that the web application aligns with business objectives.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Requirements gathering, process mapping, and strong communication skills.",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Business%20Analyst.docx.pdf?alt=media&token=9f2155e8-fe06-4427-8dd3-35c0cab54d82",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "Product Owner",
    //       responsibility:
    //         "Represents the stakeholders and endusers, prioritizes features, and ensures that the development team is working on the most valuable aspects of the application. They manage the product backlog and help with decisionmaking.	",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "Product Vision and Strategy,. Requirement Gathering and Definition,Backlog ManagemenT, Stakeholder Management,Agile and Scrum Methodologies,Data-Driven Decision Making",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Product%20Owner%20_Web%20App.docx.pdf?alt=media&token=84236fff-c442-42d0-9b77-cc2c2e1b9c58",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //     {
    //       possition: "System Architect",
    //       responsibility:
    //         "Designs the overall architecture of the web application, making key decisions about the tech stack, frameworks, and infrastructure. They ensure that the application is scalable, maintainable, and aligned with the business goals.",
    //       job_des: "Responsible for building Manual Testing...",
    //       skill:
    //         "System Design and Architecture,DevOps and CI/CD,Front-End and Back-End Technologies,Process Mapping and Workflow Design,Agile Methodologie,Wireframing and Prototyping,Stakeholder Management",
    //       num_of_roles: "",
    //       jd_url: "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_System%20Architect%20_Web%20App.docx.pdf?alt=media&token=788c0bdb-fec9-4eec-a986-faab13d78f34",
    //       location: "India",
    //       experience: "2",
    //       Availability: "Immediately",
    //       mode: "Full-time",
    //       working_hours: "Flexible with working hours",
    //       // duration: 0,
    //       gender: "",
    //       country: "",
    //       city: "",
    //        budget: "1000",
    // browse:"",
    //       profiles: [],
    //     },
    //   ],
    // },
    {
      name: "Website Development",
      roles: [
        {
          possition: "Project Manager",
          responsibility:
            "Oversees the entire web development process, from gathering requirements to delivery. Ensures that the project stays on track, within budget, and meets deadlines.	",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Web Development Knowledge,content management systems,Stakeholder Management,Project Planning & Management",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "UI/UX Designer",
          responsibility:
            "Focuses on user interface (UI) and user experience (UX) design. They create wireframes, prototypes, and mockups to ensure the website is intuitive, aesthetically pleasing, and easy to navigate on various devices.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "User Research and Analysis,Wireframing and Prototyping,Visual Design	",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "FrontEnd Developer",
          responsibility:
            "Implements the website’s design using HTML, CSS, and JavaScript. They focus on ensuring the site is responsive, interactive, and compatible across different devices and browsers.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "HTML5, CSS3, JavaScript, frameworks like React, Angular, or Vue.js, knowledge of responsive design principles (e.g., Bootstrap or CSS Grid).",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Front-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=2e3c372f-bc8a-48ca-b1b4-930854554aef",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "BackEnd Developer",
          responsibility:
            "Works on the server side of the website, building databases, APIs, and server-side logic. They ensure that the website functions properly, processes data, and interacts with the front-end seamlessly.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Programming languages such as Node.js, Python, Ruby, or PHP, and databases like MySQL, MongoDB, or PostgreSQL.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Back-End%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=381b1558-0698-4875-88ef-388bb709ef3c",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "FullStack Developer (Optional)",
          responsibility:
            "A full-stack developer has the skills to work on both the front-end and back-end, allowing for a more integrated development process. They can manage the website as a whole and streamline communication between the two areas.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Front-End Developmen&tBack-End DevelopmenT,Database Management,Agile Development Methodologies,API Design and Architecture",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Full-Stack%20Developer_Web%20App%20Development.docx.pdf?alt=media&token=b24b1480-8210-42bf-8637-a66d3a0c60b1",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },

        {
          possition: "DevOps Engineer",
          responsibility:
            "	Focuses on the deployment, management, and scaling of the website. They ensure that the site is secure, efficiently hosted, and runs smoothly under different traffic loads.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Knowledge of cloud platforms (AWS, Azure, Google Cloud), CI/CD pipelines, version control systems (Git), and server management.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_DevOps%20Engineer_Web%20App%20Development.docx.pdf?alt=media&token=98e47f7e-242b-4c0e-9476-9c0277e889c6",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Test (QA) Engineer",
          responsibility:
            "Ensures that the website is free of bugs and performs well across different devices, browsers, and conditions. They conduct manual and automated testing for functionality, usability, security, and performance.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Knowledge of testing frameworks (Selenium, Cypress), manual testing, and understanding of responsive testing tools.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_QA%20Engineer%20-Tester_Web%20App%20Development.docx.pdf?alt=media&token=09acc7cc-7e68-4f66-af87-4c627997f0c1",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Security Specialist",
          responsibility:
            "Ensures that the website is secure from threats like hacking, malware, and data breaches. They set up SSL certificates, firewalls, and securitypatches.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Web Application Security Fundamentals,VAPT,Authentication and Authorization,Encryption and Data Protection,SDLC,Firewall and Intrusion Detection,API AND CLOUD Security",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Security%20Specialist_Web%20App%20Development.docx.pdf?alt=media&token=29cbbbc8-c55d-4a0b-aea3-d36c8cebb538",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },

        {
          possition: "Graphic Designer/Illustrator",
          responsibility:
            "Creates custom graphics, logos, icons, and other visual elements. They ensure that the visual elements align with the website’s branding and are responsive across devices.",
          job_des: "Responsible for building Manual Testing...",
          skill: "",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_System%20Architect%20_Web%20App.docx.pdf?alt=media&token=788c0bdb-fec9-4eec-a986-faab13d78f34",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
      ],
    },
    {
      name: "Mobile App Development",
      roles: [
        {
          possition: "Project Manager",
          responsibility:
            "Oversees the entire project, manages timelines, coordinates team efforts, ensures that the project stays within scope and budget, and serves as a communication link between stakeholders and the development team.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Project management methodologies (Agile, Scrum), communication, and team coordination.",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_Project%20Manager_Web%20App%20Development.docx.pdf?alt=media&token=7c24aae3-eb5b-4f3a-b538-71f3b92e2f7b",
          num_of_roles: "",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "UI/UX Designer",
          responsibility:
            "Focuses on user interface (UI) design and user experience (UX). They create wireframes, mockups, and prototypes, ensuring that the web application is userfriendly, intuitive, and visually appealing.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Graphic design, user experience (UX) research, wireframing, prototyping, and using design tools like Sketch, Figma, or Adobe XD.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Mobile App Developer",
          responsibility:
            "Writes the code to build the application. Depending on the platform (iOS, Android, or cross-platform), different types of developers are required: - iOS Developer: Specializes in building apps for Apple’s iOS platform using Swift or Objective-C. - Android Developer: Specializes in building apps for Android devices using Kotlin or Java. - Cross-Platform Developer: Builds apps that run on both iOS and Android using frameworks like Flutter or React Native.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Proficiency in platform-specific programming languages (Swift, Kotlin, Java) or cross-platform frameworks (Flutter, React Native).",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "BackEnd Developer",
          responsibility:
            "Manages the server-side of the app, including databases, APIs, and server logic that supports the app’s functionality. They handle data storage, authentication, and communication between the app and the server.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Proficiency in server-side languages (Node.js, Python, Ruby, PHP, Java), knowledge of databases (SQL, NoSQL), and experience with cloud services (AWS, Azure, Google Cloud).",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Test (QA) Engineer",
          responsibility:
            "Tests the application to identify bugs, usability issues, and performance bottlenecks. They ensure that the application meets the required standards and functions as expected across different environments.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Knowledge of testing frameworks, automated testing tools, and mobile- specific testing practices. Familiarity with manual and automated testing processes.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "DevOps Engineer",
          responsibility:
            "Focuses on the deployment, scaling, and management of the application infrastructure. They set up continuous integration/continuous deployment (CI/CD) pipelines, automate processes, and ensure that the web application is scalable and secure.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Experience with CI/CD tools (Jenkins, GitLab CI), cloud platforms (AWS,Azure, Google Cloud), and containerization (Docker, Kubernetes).",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Business Analyst",
          responsibility:
            "Analyzes business needs and translates them into technical requirements. They work closely with stakeholders to ensure that the web application aligns with business objectives.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Requirement analysis, process modeling, and stakeholder communication.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Product Manager",
          responsibility:
            "Represents the stakeholders and endusers, prioritizes features, and ensures that the development team is working on the most valuable aspects of the application. They manage the product backlog and help with decision making.",
          job_des: "Responsible for building Manual Testing...",
          skill: "Market research, product strategy, and business acumen.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
        {
          possition: "Security Specialist",
          responsibility:
            "Ensures the security of the mobile application by identifying vulnerabilities, implementing security protocols, and conducting penetration testing. They focus on protecting the application from potential threats.",
          job_des: "Responsible for building Manual Testing...",
          skill:
            "Mobile Security Tools, Mobile Development Frameworks, Programming Languages, Security Standards and Frameworks, Authentication and Authorization, Threat Modeling and Risk Assessment, Incident Response, Secure Coding Practices, Monitoring and Logging, Network Security.",
          num_of_roles: "",
          jd_url:
            "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/JD%2FJD_UX-UI%20Designer_Web%20App%20Development.docx.pdf?alt=media&token=97d746e6-a0dc-4198-85fa-2e366b33f72b",
          location: "India",
          experience: "2",
          Availability: "Immediately",
          mode: "Full-time",
          working_hours: "Flexible with working hours",
          // duration: 0,
          gender: "",
          country: "",
          city: "",
          budget: "1000",
          browse: "",
          profiles: [],
        },
      ],
    },
  ]);
  const [addteam, setaddteam] = useState(false);
  const [jobdesdata, setjobdesdata] = useState("");
  const [gender, setgender] = useState("");
  const [country, setcountry] = useState("");
  const [city, setcity] = useState("");
  const [urlvalue, seturlvalue] = useState("");
  const [urlvaluestatus, seturlvaluestatus] = useState(false);
  const [jdloading, setjdloading] = useState(false);

  const overlayHandlerNew = (teamIndex, roleIndex, field, value, data) => {
    setresponsibilitydata({
      teamIndex: teamIndex,
      roleIndex: roleIndex,
      field: field,
      data: data,
    });
    dispatch(storeAction.isPopUpHander(value));
  };

  const overlayHandlerNew1 = (
    teamIndex,
    roleIndex,
    field,
    value,
    data1,
    data2,
    data3
  ) => {
    setresponsibilitydata({
      teamIndex: teamIndex,
      roleIndex: roleIndex,
      field: field,
      data1: data1,
      data2: data2,
      data3: data3,
    });
    dispatch(storeAction.isPopUpHander(value));
  };

  const changeresponsibility = () => {
    if (newresponsibilitydata.length !== 0) {
      const { teamIndex, roleIndex, field } = responsibilitydata;
      const updatedTeams = teamlist.map((team, tIndex) => {
        if (tIndex === teamIndex) {
          const updatedRoles = team.roles.map((role, rIndex) => {
            if (rIndex === roleIndex) {
              return {
                ...role,
                [field]: newresponsibilitydata,
              };
            }
            return role;
          });

          return {
            ...team,
            roles: updatedRoles,
          };
        }
        return team;
      });
      setteamlist(updatedTeams);

      if (teamlist[teamIndex].name === selectedTeam.name) {
        setSelectedTeam((prevState) => ({
          ...prevState,
          roles: prevState.roles.map((role, rIndex) =>
            rIndex === roleIndex
              ? { ...role, [field]: newresponsibilitydata }
              : role
          ),
        }));
      }
      dispatch(storeAction.isPopUpHander());
    } else {
      alert("Please Enter Responsibility...");
    }
  };

  const changejob_des = () => {
    if (jobdesdata.length !== 0) {
      const { teamIndex, roleIndex, field } = responsibilitydata;
      const updatedTeams = teamlist.map((team, tIndex) => {
        if (tIndex === teamIndex) {
          const updatedRoles = team.roles.map((role, rIndex) => {
            if (rIndex === roleIndex) {
              return {
                ...role,
                job_des: jobdesdata,
                skill: skilllist,
                jd_url: urlvalue,
              };
            }
            return role;
          });

          return {
            ...team,
            roles: updatedRoles,
          };
        }
        return team;
      });
      setteamlist(updatedTeams);

      if (teamlist[teamIndex].name === selectedTeam.name) {
        setSelectedTeam((prevState) => ({
          ...prevState,
          roles: prevState.roles.map((role, rIndex) =>
            rIndex === roleIndex
              ? {
                  ...role,
                  job_des: jobdesdata,
                  skill: skilllist,
                  jd_url: urlvalue,
                }
              : role
          ),
        }));
      }
      dispatch(storeAction.isPopUpHander());
      setjobdesdata("");
      seturlvalue("");
    } else {
      alert("Please Enter Job Description...");
    }
  };

  const changefilter = () => {
    // Validate fields
    if (gender.length === 0) {
      alert("Please Select Gender");
      return; // Exit the function if validation fails
    }
    if (country.length === 0) {
      alert("Please Select Nationality");
      return; // Exit the function if validation fails
    }
    if (city.length === 0) {
      alert("Please Select Location");
      return; // Exit the function if validation fails
    }

    const { teamIndex, roleIndex, field } = responsibilitydata;

    // Update the team list immutably
    const updatedTeams = teamlist.map((team, tIndex) => {
      if (tIndex === teamIndex) {
        const updatedRoles = team.roles.map((role, rIndex) => {
          if (rIndex === roleIndex) {
            return {
              ...role,
              gender: gender, // Update gender
              country: country, // Update country
              city: city, // Update city
              [field]: jobdesdata || "", // Update based on field, default to empty string if jobdesdata is falsy
            };
          }
          return role;
        });

        return {
          ...team,
          roles: updatedRoles,
        };
      }
      return team;
    });

    // Set updated team list
    setteamlist(updatedTeams);
    setgender(gender);
    setcity(city);
    setcountry(country);

    // Synchronize selectedTeam if applicable
    if (selectedTeam && teamlist[teamIndex].name === selectedTeam.name) {
      const updatedSelectedRoles = selectedTeam.roles.map((role, rIndex) =>
        rIndex === roleIndex
          ? { ...role, gender, country, city, [field]: jobdesdata || "" }
          : role
      );

      setSelectedTeam((prevState) => ({
        ...prevState,
        roles: updatedSelectedRoles,
      }));
    }

    // Dispatch any necessary actions
    dispatch(storeAction.isPopUpHander());

    // Clear job description data
    setjobdesdata("");
  };

  const exitOverlayHandler = () => {
    dispatch(storeAction.isPopUpHander());
  };

  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });

  async function dropDownHandler() {
    const inputvalue = inputref.current.value.toLowerCase();
    setdropDown("");
    if (inputvalue.length !== 0) {
      var skillarrray = Skilllist;
      const uniqueSkills = Array.from(
        new Set(skillarrray.map((skill) => skill.Skill))
      );
      const inputvalueLower = inputvalue.toLowerCase();
      const matchingSkills = uniqueSkills.filter(
        (skill) =>
          typeof skill === "string" &&
          skill.toLowerCase().includes(inputvalueLower)
      );
      setdropDownClose(matchingSkills);
      setdropDown(inputvalue.length > 0 && matchingSkills.length > 0);
    } else {
      setdropDown(inputvalue.length > 0);
    }
  }

  function filterdata1() {
    setdropDown(!dropDown);
  }

  function getdata(event) {
    const getvalue = event;
    setdropDown(false);
    const updatedItems = skill.includes(getvalue)
      ? skill.filter((data) => data !== getvalue)
      : [...skill, getvalue];
    setskill(updatedItems);
    inputref.current.value = "";
  }

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;

  const [selectedTeam, setSelectedTeam] = useState(teamlist[0]);
  const [selectedTeamNew, setSelectedTeamNew] = useState({});
  const [teamindex, setteamindex] = useState(0);

  const ButtonHandler = (teamName, index) => {
    const team = teamlist.find((team) => team.name === teamName);
    setSelectedTeam(team);
    setteamindex(index);
  };
  const ButtonHandler1 = (teamName) => {
    const team = displayTeam.find((team) => team.name === teamName);
    setSelectedTeamNew(team);
  };

  const addRole = (teamIndex) => {
    const newRole = {
      possition: "",
      responsibility: "",
      job_des: "",
      jd_url: "",
      num_of_roles: 1,
      location: "",
      experience: "",
      Availability: "",
      mode: "",
      working_hours: "",
      duration: "",
      gender: "",
      country: "",
      city: "",
      budget: "",
      profiles: [],
    };

    const updatedTeams = teamlist.map((team, index) => {
      if (index === teamIndex) {
        return { ...team, roles: [...team.roles, newRole] };
      }
      return team;
    });
    setSelectedTeam((prevState) => ({
      ...prevState,
      roles: [...prevState.roles, newRole],
    }));

    setteamlist(updatedTeams);
  };

  const removeRole = (teamIndex, roleIndex) => {
    const updatedTeams = teamlist.map((team, index) => {
      if (index === teamIndex) {
        return {
          ...team,
          roles: team.roles.filter((_, i) => i !== roleIndex),
        };
      }
      return team;
    });

    setteamlist(updatedTeams);
    setSelectedTeam((prevState) => ({
      ...prevState,
      roles: prevState.roles.filter((_, i) => i !== roleIndex),
    }));
  };

  const handleChangeNew = (teamIndex, roleIndex, field, value) => {
    const updatedTeams = teamlist.map((team, tIndex) => {
      if (tIndex === teamIndex) {
        const updatedRoles = team.roles.map((role, rIndex) => {
          if (rIndex === roleIndex) {
            return {
              ...role,
              [field]: value,
            };
          }
          return role;
        });

        return {
          ...team,
          roles: updatedRoles, // Update the roles in the team
        };
      }
      return team;
    });

    // Update the teamlist and selected team if it matches the currently selected team
    setteamlist(updatedTeams);

    if (teamlist[teamIndex].name === selectedTeam.name) {
      setSelectedTeam((prevState) => ({
        ...prevState,
        roles: prevState.roles.map((role, rIndex) =>
          rIndex === roleIndex ? { ...role, [field]: value } : role
        ),
      }));
    }
  };
  const fileInputRef = useRef(null);

  const uploadHandler = () => {
    fileInputRef.current.click();
  };

  const getTextFromPage = async (page) => {
    const textContent = await page.getTextContent();
    const textItems = textContent.items.map((item) => item.str);
    return textItems.join(" ");
  };

  const handlePdfUpload = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === "application/pdf") {
      setjdloading(true);
      const reader = new FileReader();
      reader.onload = async () => {
        const typedArray = new Uint8Array(reader.result);
        const pdf = await getDocument(typedArray).promise;
        let extractedText = "";
        for (let i = 1; i <= pdf.numPages; i++) {
          const page = await pdf.getPage(i);
          const pageText = await getTextFromPage(page);
          extractedText += pageText + "\n";
        }
        setjobdesdata(extractedText);
        await parseQuery(extractedText);
      };
      let file1 = event.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref(`jd${userid}` + file1[0].name);
        storageRef.put(file1[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      }).catch((err) => {
        console.log(err, "errr");
      });
      var imgurl2 = await file13;
      seturlvalue(imgurl2);
      seturlvaluestatus(true);
      setjdloading(false);
      setTimeout(() => {
        seturlvaluestatus(false);
      }, 2000);
      reader.readAsArrayBuffer(file);
    } else {
      alert("Please upload a valid PDF file.");
    }
  };

  const parseQuery = async (text) => {
    const skills = [];
    skillData.forEach((skill) => {
      const escapedSkill = skill.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
      const regex = new RegExp(`\\b${escapedSkill}\\b`, "i");
      if (regex.test(text)) skills.push(skill);
    });
    const skillsString = skills.join(", ");
    setskilllist(skillsString);
  };

  const [browseloading, setbrowseloading] = useState(false);
  const [roleindexvalue, setroleindexvalue] = useState(null);

  const BrowseBtn = async (teamIndex, roleIndex) => {
    setbrowseloading(true);
    setroleindexvalue(roleIndex);
    const updatedTeams = teamlist.map((team, tIndex) => {
      if (tIndex === teamIndex) {
        const updatedRoles = team.roles.map((role, rIndex) => {
          if (rIndex === roleIndex) {
            return { ...role, browse: "true" };
          }
          return role;
        });

        return { ...team, roles: updatedRoles };
      }
      return team;
    });
    setteamlist(updatedTeams);
    if (teamlist[teamIndex].name === selectedTeam.name) {
      setSelectedTeam((prevState) => ({
        ...prevState,
        roles: prevState.roles.map((role, rIndex) =>
          rIndex === roleIndex ? { ...role, browse: "true" } : role
        ),
      }));
    }
    const updateddata = await Promise.all(
      updatedTeams.map(async (team, tIndex) => {
        if (tIndex === teamIndex) {
          const updatedRoles = [...team.roles];
          const roleToUpdate = updatedRoles[roleIndex];

          const newobj = {
            search: {
              qualification: roleToUpdate.possition,
              experience: roleToUpdate.experience,
              location: roleToUpdate.location,
              skills: roleToUpdate.skill,
            },
          };
          const candidatedata = await axios
            .post(`https://api.hirein5.com/admin/search/`, newobj, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            })
            .then((res) => {
              return res.data;
            });

          if (candidatedata.data.length !== 0) {
            updatedRoles[roleIndex].profiles = candidatedata.data.slice(0, 3);
          }

          return { ...team, roles: updatedRoles };
        }
        return team;
      })
    );

    setteamlist(updateddata);
    setSelectedTeam(updateddata[teamIndex]);

    setbrowseloading(false);
    setroleindexvalue(null);
  };

  const pageHandler = async (event, id) => {
    setIsPage("page1");
  };
  const HireaTeam = async () => {
    if (teamlist.length !== 0) {
      sethireloading(true);
      for (let i = 0; i < teamlist.length; i++) {
        const team = teamlist[i];
        if (team.roles && team.roles.length > 0) {
          team.roles.forEach(async (role) => {
            let obj = {
              role: team.name || "",
              possition: role.possition || "",
              responsibility: role.responsibility || "",
              job_des: role.job_des || "",
              jd_url: role.jd_url || "",
              num_of_roles: role.num_of_roles || "",
              location: role.location || "",
              experience: role.experience || "",
              Availability: role.Availability || "",
              mode: role.mode || "",
              working_hours: role.working_hours || "",
              duration: team.duration || "",
              budget: role.budget || "",
              diversity_filter: role.gender || "",
              candidates:
                role.profiles.map((profile) => profile.id).join(",") || "",
              selected_candidates:
                role.profiles
                  .filter((profile) => profile.active)
                  .map((profile) => profile.id)
                  .join(", ") || "",
              status: "Success",
              engagement: role.mode || "",
              project_id: teamid,
              skill: role.skill || "",
            };
            await axios
              .post(
                `${process.env.REACT_APP_LOCAL_HOST_URL}/developement/`,
                obj,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${token}`,
                  },
                }
              )
              .then((res) => {
                return res.data;
              })
              .catch((err) => {
                return err.response;
              });
            var obj1 = {
              estimated_budget: sumvalue,
            };
            await axios
              .put(
                `${process.env.REACT_APP_LOCAL_HOST_URL}/hireTeam/${teamid}/`,
                obj1,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${token}`,
                  },
                }
              )
              .then((res) => {
                return res.data;
              })
              .catch((err) => {
                return err.response;
              });
          });
        }
      }
      setteamid(null);
      setaddteam(false);
      GetallTeam();
      sethireloading(false);
      // setTimeout(() => {
      //   window.location.reload();
      // }, 2000);
    }
  };
  const UpdateaTeam = async () => {
    if (teamlist.length !== 0) {
      sethireloading(true);
      for (let i = 0; i < teamlist.length; i++) {
        const team = teamlist[i];
        if (team.roles && team.roles.length > 0) {
          team.roles.forEach(async (role) => {
            let obj = {
              role: team.name || "",
              possition: role.possition || "",
              skill: role.skill || "",
              responsibility: role.responsibility || "",
              job_des: role.job_des || "",
              jd_url: role.jd_url || "",
              num_of_roles: role.num_of_roles || "",
              location: role.location || "",
              experience: role.experience || "",
              Availability: role.Availability || "",
              mode: role.mode || "",
              working_hours: role.working_hours || "",
              duration: team.duration || "",
              budget: role.budget || "",
              diversity_filter: role.gender || "",
              candidates:
                role.profiles.map((profile) => profile.id).join(",") || "",
              selected_candidates:
                role.profiles
                  .filter((profile) => profile.active)
                  .map((profile) => profile.id)
                  .join(", ") || "",
              status: "Success",
              engagement: role.mode || "",
              project_id: teamid,
            };
            if (role.id !== undefined) {
              await axios
                .put(
                  `${process.env.REACT_APP_LOCAL_HOST_URL}/update/developement/${role.id}/`,
                  obj,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `JWT ${token}`,
                    },
                  }
                )
                .then((res) => {
                  return res.data;
                })
                .catch((err) => {
                  return err.response;
                });
            } else {
              await axios
                .post(
                  `${process.env.REACT_APP_LOCAL_HOST_URL}/developement/`,
                  obj,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `JWT ${token}`,
                    },
                  }
                )
                .then((res) => {
                  return res.data;
                })
                .catch((err) => {
                  return err.response;
                });
            }
            var obj1 = {
              estimated_budget: sumvalue,
            };
            await axios
              .put(
                `${process.env.REACT_APP_LOCAL_HOST_URL}/hireTeam/${teamid}/`,
                obj1,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${token}`,
                  },
                }
              )
              .then((res) => {
                return res.data;
              })
              .catch((err) => {
                return err.response;
              });
          });
        }
      }
      setTimeout(() => {
        setteamid(null);
        setaddteam(false);
        GetallTeam();
        sethireloading(false);
        // window.location.reload();
      }, 2000);
    }
  };
  const deletebtn = async (data) => {
    var deleteuser = await axios
      .delete(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/delete/developement/${data.id}/`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (deleteuser.success === "Data Deleted Successfully") {
      setTimeout(async () => {
        const teamlist = await axios
          .get(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/get/projects/${data.project_id}`,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => res.data);
        if (teamlist.length !== 0) {
          const transformedData = Object.values(
            teamlist.reduce((result, item) => {
              if (!result[item.role]) {
                result[item.role] = { name: item.role, roles: [] };
              }
              result[item.role].roles.push(item);
              return result;
            }, {})
          );
          const team = transformedData.find((team) => team.name === data.role);
          setdisplayTeam(transformedData);
          setSelectedTeamNew(team);
        } else {
          setdisplayTeam([]);
        }
      }, 2000);
    }
  };
  const deleteBtn = async () => {
    if (singleteam.length !== 0) {
      const deleteteam = await axios
        .delete(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/hireTeam/${singleteam[0].id}/`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => res.data);
      if (deleteteam.success === "Data Deleted Successfully") {
        setteamid(null);
        setaddteam(false);
        GetallTeam();
        setIsPage("page1");
        sethireloading(false);
        setviewteam(true);
        setteamid(null);
        setaddteam(false);
        GetallTeam();
        sethireloading(false);
        window.location.reload();
        // isPage === "page1" && addteam == false && viewteam == true
      }
    }
  };
  const BookmarkBtn = async (data) => {
    var obj = {
      bookmark: "true",
    };
    await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/update/developement/${data.id}/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    const teamlist = await axios
      .get(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/get/projects/${data.project_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => res.data);
    if (teamlist.length !== 0) {
      const transformedData = Object.values(
        teamlist.reduce((result, item) => {
          if (!result[item.role]) {
            result[item.role] = { name: item.role, roles: [] };
          }
          result[item.role].roles.push(item);
          return result;
        }, {})
      );
      const team = transformedData.find((team) => team.name === data.role);
      setdisplayTeam(transformedData);
      setSelectedTeamNew(team);
    } else {
      setdisplayTeam([]);
    }
  };
  const BookmarkBtn1 = async (data) => {
    var obj = {
      bookmark: "false",
    };
    await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/update/developement/${data.id}/`,
        obj,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    const teamlist = await axios
      .get(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/get/projects/${data.project_id}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => res.data);
    if (teamlist.length !== 0) {
      const transformedData = Object.values(
        teamlist.reduce((result, item) => {
          if (!result[item.role]) {
            result[item.role] = { name: item.role, roles: [] };
          }
          result[item.role].roles.push(item);
          return result;
        }, {})
      );
      const team = transformedData.find((team) => team.name === data.role);
      setdisplayTeam(transformedData);
      setSelectedTeamNew(team);
    } else {
      setdisplayTeam([]);
    }
  };
  const [singleteam, setsingleteam] = useState([]);

  const ViewTeam = async (data) => {
    setviewteam(true);
    setsingleteam([data]);
    setSelectedTeamNew([]);

    try {
      const teamlist = await axios
        .get(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/get/projects/${data.id}`,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => res.data);
      if (teamlist.length !== 0) {
        const transformedData = Object.values(
          teamlist.reduce((result, item) => {
            if (!result[item.role]) {
              result[item.role] = { name: item.role, roles: [] };
            }
            result[item.role].roles.push(item);
            return result;
          }, {})
        );
        setdisplayTeam(transformedData);
        setSelectedTeamNew(transformedData[0]);
      } else {
        setdisplayTeam([]);
      }
    } catch (err) {
      console.error("Error fetching team list:", err);
    }
  };
  useEffect(() => {
    getNewData();
  }, [teamlist]);
  const getNewData = () => {
    if (teamlist.length !== 0) {
      const numbers = teamlist.flatMap((team) =>
        team.roles.flatMap((role) =>
          role.profiles
            .filter(
              (profile) =>
                profile.active === true && profile.rate_card_info !== null
            )
            .map((profile) => Number(profile.rate_card_info.remote_monthly))
        )
      );

      const sum = numbers.reduce(
        (accumulator, currentValue) => accumulator + currentValue,
        0
      );
      setsumvalue(sum);
    }
  };

  const editbtn = async (displayTeam, singleteam) => {
    if (displayTeam.length !== 0 && singleteam.length !== 0) {
      setaddteam(true);
      setteamid(singleteam[0].id);
      seteditteam(true);
      setformdata({
        project_name: singleteam[0].project_name,
        duration: singleteam[0].duration,
        budget: singleteam[0].budget,
        amount: singleteam[0].amount,
        description: singleteam[0].description,
      });
      setskill(singleteam[0].skills_required.split(","));
      setSelectedTeam(displayTeam[0]);
      setteamlist(displayTeam);
    }
  };

  const nPages = Math.ceil(count / 20);
  const pageNumbers = Array.from({ length: nPages }, (_, i) => i + 1);

  return (
    <div className="paddingLeft90">
      {isPage == "page1" && (
        <>
          {addteam == true && (
            <>
              <div className="HireMainComponent paddingRight90">
                <DashHead
                  head="Hire a Team"
                  desc="Search and find the right talent for your company. If you need help in shortlisting candidates or do not find a relevent skill match"
                  highLight="Contact Us"
                  descClass="dashBoardMainHeadDescBetween"
                />
                <div
                  className="hireBack1"
                  onClick={() => {
                    setaddteam(false);
                    seteditteam(false);
                    setformdata({
                      project_name: "",
                      duration: "",
                      budget: "",
                      amount: "",
                      description: "",
                      skills_required: "",
                    });
                    setskill([]);
                    setteamid(null);
                  }}
                >
                  <IoMdArrowBack className="backIcon1" />
                  <h3>Back to Teams</h3>
                </div>
                <div className="HireMainComponentFlex">
                  <div className="HireMainComponentLeft">
                    <h2>Project Details</h2>
                    <div className="flex gap-4">
                      <div className="HireMainComponentLeftContent">
                        <h3>Project name</h3>
                        <input
                          className="HireMainComponentLeftContentInput"
                          type="text"
                          name="project_name"
                          onChange={handlechange}
                          defaultValue={formdata.project_name}
                        />
                        {/* {formdataerror.project_name && (
                        <p className="text-red-500 font-medium py-2 text-sm">
                          Please Enter Project name
                        </p>
                      )} */}
                      </div>
                      <div className="HireMainComponentLeftContent">
                        <h3>Project Duration (In Months)</h3>
                        <input
                          className="HireMainComponentLeftContentInput"
                          type="text"
                          name="duration"
                          onChange={handlechange}
                          defaultValue={formdata.duration}
                        />
                        {/* {formdataerror.duration && (
                        <p className="text-red-500 font-medium py-2 text-sm">
                          Please Enter Project Duration
                        </p>
                      )} */}
                      </div>
                      <div className="HireMainComponentLeftContent">
                        <h3>Monthly Project Budget</h3>
                        <div className="flex">
                          <select
                            name="budget"
                            onChange={handlechange}
                            defaultValue={formdata.budget}
                          >
                            <option value="">Select</option>
                            <option value="INR">INR</option>
                            <option value="USD">USD</option>
                            <option value="JPY">JPY</option>
                            <option value="SGD">SGD</option>
                            <option value="GBP">GBP</option>
                            <option value="AED">AED</option>
                            <option value="CAD">CAD</option>
                            <option value="SAR">SAR</option>
                          </select>

                          <input
                            className="HireMainComponentLeftContentInput HireMainComponentLeftContentInput1"
                            type="text"
                            name="amount"
                            onChange={handlechange}
                            defaultValue={formdata.amount}
                          />
                        </div>
                        {/* {formdataerror.budget && (
                        <p className="text-red-500 font-medium py-2 text-sm">
                          Please Select Amount Type
                        </p>
                      )}
                      {formdataerror.amount && (
                        <p className="text-red-500 font-medium py-2 text-sm ml-48">
                          Please Enter Monthly Project Budget
                        </p>
                      )} */}
                      </div>
                    </div>
                    <div className="HireMainComponentLeftContent">
                      <h3>Project Description</h3>
                      <textarea
                        className="HireMainComponentLeftContentInput"
                        name="description"
                        onChange={handlechange}
                        defaultValue={formdata.description}
                      ></textarea>
                      {/* {formdataerror.description && (
                        <p className="text-red-500 font-medium py-2 text-sm">
                          Please Enter Project Description
                        </p>
                      )} */}
                    </div>
                    <div className="HireMainComponentLeftContent">
                      <h3>Technical Skills Required</h3>
                      <div className="searchBar">
                        <CiSearch />
                        <input
                          type="text"
                          ref={inputref}
                          onChange={dropDownHandler}
                        />
                        <FaAngleDown onClick={filterdata1} />
                      </div>
                      {/* {formdataerror.skills_required && (
                        <p className="text-red-500 font-medium py-2 text-sm">
                          Please Select Technical Skills Required
                        </p>
                      )} */}
                      {dropDown && (
                        <div className="searchContent1">
                          <div className="searchContent">
                            {dropDownOpen.map((option, index) => (
                              <h3
                                onClick={() => {
                                  getdata(option);
                                }}
                                key={index}
                              >
                                {option}
                              </h3>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                    <Dragoption skill={skill} getdata={getdata} />
                    {teamid == null ? (
                      loader == true ? (
                        <button className="save buttonWidth100 marginLeft0 marginTop20">
                          Please Wait...
                        </button>
                      ) : (
                        <button
                          className="save buttonWidth100 marginLeft0 marginTop20"
                          onClick={createTaam}
                        >
                          Create Team
                        </button>
                      )
                    ) : editteam == true ? (
                      loader == true ? (
                        <button className="save buttonWidth100 marginLeft0 marginTop20">
                          Please Wait...
                        </button>
                      ) : (
                        <button
                          className="save buttonWidth100 marginLeft0 marginTop20"
                          onClick={UpdateTaam}
                        >
                          Update Team
                        </button>
                      )
                    ) : (
                      <button className="savedisable buttonWidth100 marginLeft0 marginTop10">
                        Create Team
                      </button>
                    )}
                  </div>
                </div>
              </div>
              {teamid !== null && (
                <>
                  <div className="HireTeamTable paddingRight90">
                    <div className="HireTeamTableContent">
                      <div className="HireTeamTableTop">
                        <button className="hireTableButton">Add a Role</button>

                        {/* Display the team names */}
                        {teamlist.length !== 0
                          ? teamlist.map((data, index) => (
                              <h1
                                onClick={() => ButtonHandler(data.name, index)}
                                className={
                                  selectedTeam?.name === data.name
                                    ? "HireTeamTableToph1Active"
                                    : "HireTeamTableToph1"
                                }
                                key={index}
                              >
                                {data.name}
                              </h1>
                            ))
                          : null}

                        <h1
                          onClick={() => ButtonHandler("Customized Team")}
                          className={
                            selectedTeam?.name === "Customized Team"
                              ? "HireTeamTableToph1Active"
                              : "HireTeamTableToph1"
                          }
                        >
                          Customized Team
                        </h1>
                      </div>

                      {selectedTeam && selectedTeam.roles && (
                        <table>
                          <thead>
                            <tr className="hireTableHead">
                              <th>Remove</th>
                              <th>Role</th>
                              {/* <th>Responsibility</th> */}
                              <th>Job Description</th>
                              <th>Skills</th>
                              <th>Number of roles</th>
                              <th>Location</th>
                              <th>Years of Experience</th>
                              <th>Availability</th>
                              <th>Mode of engagement</th>
                              <th>Working hours</th>
                              <th>Diversity Filter</th>
                              <th>Budget per role (USD per month)</th>
                              <th></th>
                              <th>Profile 1</th>
                              <th>Profile 2</th>
                              <th>Profile 3</th>
                            </tr>
                          </thead>

                          <tbody className="hireTableBody">
                            {selectedTeam.roles.map((role, roleIndex) => (
                              <tr className="hireTablerow" key={roleIndex}>
                                <td>
                                  <div className="flex items-center justify-center">
                                    <FaRegCircleXmark
                                      className="xIcon"
                                      onClick={() =>
                                        removeRole(teamindex, roleIndex)
                                      }
                                    />
                                    <FiPlusCircle
                                      className="plusIcon"
                                      onClick={() => addRole(teamindex)}
                                    />
                                  </div>
                                </td>
                                <td>
                                  <h3>
                                    <input
                                      className="hireTableBodySelect"
                                      value={role.possition}
                                      placeholder="Role"
                                      type="text"
                                      onChange={(e) =>
                                        handleChangeNew(
                                          teamindex,
                                          roleIndex,
                                          "possition",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </h3>
                                </td>

                                {/* <td>
                          <h4 className="singlelinetext">
                            {role.responsibility}
                            <span
                              onClick={() => {
                                overlayHandlerNew(
                                  teamindex,
                                  roleIndex,
                                  "responsibility",
                                  "readjob_des",
                                  role.responsibility
                                );
                              }}
                              className="readMore"
                            >
                              Read More..
                            </span>
                          </h4>
                        </td> */}
                                <td>
                                  <h3>
                                    <button
                                      id="job_des"
                                      onClick={() => {
                                        window.open(role.jd_url, "_blank");
                                        // overlayHandlerNew(
                                        //   teamindex,
                                        //   roleIndex,
                                        //   "job_des",
                                        //   "job_des",
                                        //   role.job_des
                                        // );
                                      }}
                                      className="hireTableButton"
                                    >
                                      view
                                    </button>
                                    <button
                                      className="hireTableButton ml-2"
                                      onClick={() => {
                                        // window.open(role.jd_url, "_blank");
                                        overlayHandlerNew(
                                          teamindex,
                                          roleIndex,
                                          "job_des",
                                          "job_des",
                                          role.job_des
                                        );
                                      }}
                                    >
                                      +
                                    </button>
                                  </h3>
                                </td>
                                {/* <td>
                          <h3>
                            <input
                              className="hireTableBodySelect"
                              value={role.skill}
                              placeholder="Skills"
                              type="text"
                              onChange={(e) =>
                                handleChangeNew(
                                  teamindex,
                                  roleIndex,
                                  "skill",
                                  e.target.value
                                )
                              }
                            />
                          </h3>
                        </td> */}
                                <td>
                                  <h4
                                    className="singlelinetext"
                                    contentEditable="true"
                                    suppressContentEditableWarning={true}
                                    onBlur={(e) =>
                                      handleChangeNew(
                                        teamindex,
                                        roleIndex,
                                        "skill",
                                        e.currentTarget.textContent
                                      )
                                    }
                                  >
                                    {role.skill}
                                  </h4>
                                </td>
                                <td>
                                  <h3>
                                    <select
                                      className="hireTableBodySelect"
                                      value={role.num_of_roles}
                                      onBlur={(e) =>
                                        handleChangeNew(
                                          teamindex,
                                          roleIndex,
                                          "num_of_roles",
                                          e.currentTarget.textContent
                                        )
                                      }
                                    >
                                      <option value="1">1</option>
                                      <option value="2">2</option>
                                      <option value="3">3</option>
                                      <option value="4">4</option>
                                      <option value="5">5</option>
                                      <option value="6">6</option>
                                      <option value="7">7</option>
                                      <option value="8">8</option>
                                      <option value="9">9</option>
                                      <option value="10">10</option>
                                    </select>
                                  </h3>
                                </td>
                                <td>
                                  <h3>
                                    <select
                                      className="hireTableBodySelect"
                                      value={role.location}
                                      onBlur={(e) =>
                                        handleChangeNew(
                                          teamindex,
                                          roleIndex,
                                          "location",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="India">India</option>
                                      <option value="Remote">Remote</option>
                                    </select>
                                  </h3>
                                </td>
                                <td>
                                  <h3>
                                    <input
                                      className="hireTableBodySelect"
                                      value={role.experience}
                                      placeholder="Years of Experience"
                                      type="text"
                                      onChange={(e) =>
                                        handleChangeNew(
                                          teamindex,
                                          roleIndex,
                                          "experience",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </h3>
                                </td>
                                <td>
                                  <h3>
                                    <select
                                      className="hireTableBodySelect"
                                      value={role.Availability}
                                      onBlur={(e) =>
                                        handleChangeNew(
                                          teamindex,
                                          roleIndex,
                                          "Availability",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="Immediately">
                                        Immediately
                                      </option>
                                      <option value="In the next 30 days">
                                        In the next 30 days
                                      </option>
                                      <option value="post 30 days">
                                        post 30 days
                                      </option>
                                    </select>
                                  </h3>
                                </td>
                                <td>
                                  <h3>
                                    <select
                                      className="hireTableBodySelect"
                                      value={role.mode}
                                      onBlur={(e) =>
                                        handleChangeNew(
                                          teamindex,
                                          roleIndex,
                                          "mode",
                                          e.target.value
                                        )
                                      }
                                    >
                                      <option value="Part Time - 10 hours a week">
                                        Part Time - 10 hours a week
                                      </option>
                                      <option value="Part Time - 20 hours a week">
                                        Part Time - 20 hours a week
                                      </option>
                                      <option value="Full-time">
                                        Full-time
                                      </option>
                                    </select>
                                  </h3>
                                </td>
                                <td>
                                  <h3>
                                    <select
                                      className="hireTableBodySelect"
                                      value={role.working_hours} // The current value is selected
                                      onChange={(e) =>
                                        handleChangeNew(
                                          teamindex,
                                          roleIndex,
                                          "working_hours",
                                          e.target.value // Capturing the selected value correctly
                                        )
                                      }
                                    >
                                      <option value="05:00 to 14:00 IST">
                                        05:00 to 14:00 IST
                                      </option>
                                      <option value="10:30 to 19:30 IST">
                                        10:30 to 19:30 IST
                                      </option>
                                      <option value="13:30 to 22:30 IST">
                                        13:30 to 22:30 IST
                                      </option>
                                      <option value="Flexible with working hours">
                                        Flexible with working hours
                                      </option>
                                    </select>
                                  </h3>
                                </td>
                                {/* <td>
                          <h3
                            contentEditable="true"
                            suppressContentEditableWarning={true}
                            onBlur={(e) =>
                              handleChangeNew(
                                teamindex,
                                roleIndex,
                                "duration",
                                e.currentTarget.textContent
                              )
                            }
                          >
                            {role.duration}
                          </h3>
                        </td> */}
                                <td>
                                  <h3>
                                    <button
                                      id="diversity"
                                      onClick={() => {
                                        overlayHandlerNew1(
                                          teamindex,
                                          roleIndex,
                                          "diversity",
                                          "diversity",
                                          role.gender,
                                          role.country,
                                          role.city
                                        );
                                      }}
                                      className="hireTableButton"
                                    >
                                      view
                                    </button>
                                  </h3>
                                </td>
                                <td>
                                  <h3>
                                    <input
                                      className="hireTableBodySelect"
                                      value={role.budget}
                                      placeholder="Budget per role"
                                      type="text"
                                      onChange={(e) =>
                                        handleChangeNew(
                                          teamindex,
                                          roleIndex,
                                          "budget",
                                          e.target.value
                                        )
                                      }
                                    />
                                  </h3>
                                </td>
                                <td>
                                  <h3>
                                    {browseloading == true &&
                                    roleindexvalue == roleIndex ? (
                                      <button className="hireTableButton marginLeft20">
                                        Loading...
                                      </button>
                                    ) : (
                                      <button
                                        className="hireTableButton marginLeft20"
                                        onClick={() => {
                                          BrowseBtn(teamindex, roleIndex);
                                        }}
                                      >
                                        Browse
                                      </button>
                                    )}
                                  </h3>
                                </td>

                                {role.profiles.length !== 0 ? (
                                  <>
                                    {role.profiles.map((data, index) => (
                                      <td key={index}>
                                        <h3>
                                          <div
                                            onClick={() =>
                                              cardHandler(
                                                teamindex,
                                                roleIndex,
                                                data.id,
                                                data.rate_card_info
                                                  ? data.rate_card_info
                                                    ? data.rate_card_info
                                                        .remote_monthly
                                                    : 0
                                                  : 0
                                              )
                                            }
                                            className={`marginLeft20 hireTeamCard cursor-pointer ${
                                              data.active !== undefined &&
                                              data.active == true
                                                ? "hireTeamCardActive"
                                                : ""
                                            }`}
                                          >
                                            <div className="mr-3 relative">
                                              <Avatar
                                                name={data.first_name}
                                                size={50}
                                                round="50px"
                                              />
                                              <p className="percentagetag">
                                                {data.matched_percentage}
                                              </p>
                                            </div>
                                            <div className="hireTeamCardFlex">
                                              <div className="hireTeamCardContent">
                                                <h1 className="singlelinetext">
                                                  {data.first_name
                                                    .split(" ")
                                                    .slice(0, -1)
                                                    .join(" ") +
                                                    " " +
                                                    data.first_name
                                                      .split(" ")
                                                      .pop()
                                                      .replace(/./g, "x")}
                                                </h1>
                                                <h2
                                                  className={`singlelinetext ${
                                                    data.active !== undefined &&
                                                    data.active == true
                                                      ? "hireTextColor"
                                                      : ""
                                                  }`}
                                                >
                                                  {
                                                    data.preference_info
                                                      .qualification
                                                  }
                                                </h2>
                                                <div className="flex items-center justify-start mt-1">
                                                  <FiBriefcase
                                                    className={
                                                      data.active !==
                                                        undefined &&
                                                      data.active == true
                                                        ? "hirebriefColor"
                                                        : ""
                                                    }
                                                  />
                                                  <h5
                                                    className={
                                                      data.active !==
                                                        undefined &&
                                                      data.active == true
                                                        ? "hireTextColor"
                                                        : ""
                                                    }
                                                  >
                                                    {
                                                      data.preference_info
                                                        .year_of_experience
                                                    }{" "}
                                                    years of experience
                                                  </h5>
                                                </div>
                                              </div>
                                              <div className="hireTeamCardContent1">
                                                {data.rate_card_info ? (
                                                  <h6>
                                                    $
                                                    {data.rate_card_info
                                                      ? data.rate_card_info
                                                          .remote_hourly
                                                      : 0}
                                                    /hr
                                                  </h6>
                                                ) : (
                                                  <h6>UA</h6>
                                                )}

                                                <button
                                                  onClick={() => {
                                                    setIsPage("page2");
                                                    dispatch(
                                                      storeAction.singleuserHander(
                                                        {
                                                          singleuser: [data],
                                                        }
                                                      )
                                                    );
                                                  }}
                                                  className={`hireTableButton ${
                                                    data.active !== undefined &&
                                                    data.active == true
                                                      ? "buttonColor"
                                                      : ""
                                                  }`}
                                                >
                                                  View
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        </h3>
                                      </td>
                                    ))}

                                    {/* Fill empty cells if less than 3 profiles */}
                                    {[...Array(3 - role.profiles.length)].map(
                                      (_, i) => (
                                        <td key={role.profiles.length + i}>
                                          <h3>
                                            <div className="marginLeft20 hireTeamCard">
                                              <div className="hireTeamCardContent">
                                                {role.browse == "true" ? (
                                                  <h1 className="text-center">
                                                    No Profile Found...
                                                  </h1>
                                                ) : (
                                                  <h1 className="text-center">
                                                    Click Browse to fetch
                                                    profiles
                                                  </h1>
                                                )}
                                              </div>
                                            </div>
                                          </h3>
                                        </td>
                                      )
                                    )}
                                  </>
                                ) : (
                                  <>
                                    {/* No profiles at all */}
                                    {[...Array(3)].map((_, i) => (
                                      <td key={i}>
                                        <h3>
                                          <div className="marginLeft20 hireTeamCard">
                                            <div className="hireTeamCardContent">
                                              {role.browse == "true" ? (
                                                <h1 className="text-center">
                                                  No Profile Found...
                                                </h1>
                                              ) : (
                                                <h1 className="text-center">
                                                  Click Browse to fetch profiles
                                                </h1>
                                              )}
                                            </div>
                                          </div>
                                        </h3>
                                      </td>
                                    ))}
                                  </>
                                )}
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      )}
                    </div>
                  </div>
                  <div className="hireModuleCTA paddingRight90">
                    <h1>Est Budget (Verified User) $ {sumvalue}</h1>

                    {hireloading == true ? (
                      <button>Please Wait...</button>
                    ) : editteam == true ? (
                      <button onClick={UpdateaTeam}>Update A Team</button>
                    ) : (
                      <button onClick={HireaTeam}>Create A Team</button>
                    )}
                  </div>
                </>
              )}
            </>
          )}
          {addteam == false && viewteam == false && (
            <>
              <div className="HireMainComponent1 paddingRight90">
                <div className="flex justify-between items-center">
                  <DashHead
                    head="Hire a Team"
                    desc="Search and find the right talent for your company. If you need help in shortlisting candidates or do not find a relevent skill match"
                    highLight="Contact Us"
                    descClass="dashBoardMainHeadDescBetween"
                  />
                  <button
                    className="viewButton"
                    onClick={() => {
                      setaddteam(true);
                    }}
                  >
                    Add New Team
                  </button>
                </div>
              </div>
              <div className="AdminClientProfileComp paddingRight90">
                <div className="AdminClientProfileCompSearch">
                  <input type="text" />
                  <img src={search} alt="" />
                </div>
                <div className="AdminClientProfileCompTable">
                  <table className="AdminClientTable">
                    <thead>
                      <tr className="AdminTableHead">
                        <th>PROJECT NAME</th>
                        <th>PROJECT BUDGET</th>

                        <th>DURATION</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {alldata.length !== 0
                        ? alldata.map((data, index) => (
                            <tr className="adminTableRow" key={index}>
                              <td>
                                <h1>{data.project_name}</h1>
                              </td>
                              <td>
                                <h1>
                                  {data.budget}-{data.amount}
                                </h1>
                              </td>
                              <td>
                                <h1>{data.duration} Months</h1>
                              </td>

                              <td>
                                <button
                                  className="viewButton"
                                  onClick={() => {
                                    ViewTeam(data);
                                  }}
                                >
                                  View
                                </button>
                              </td>
                            </tr>
                          ))
                        : null}
                    </tbody>
                  </table>
                  {pagination_status && (
                    <div className="tablePagination">
                      <nav>
                        <ul className="pagination">
                          {candidateprevious == null ? (
                            <li className="page-item disabled">
                              <h6 className="page-link">&lt;&lt; Previous</h6>
                            </li>
                          ) : (
                            <li
                              className="page-item active"
                              onClick={() => {
                                setnewurl(candidateprevious);
                              }}
                            >
                              <h6 className="page-link">&lt;&lt; Previous</h6>
                            </li>
                          )}

                          {newurl == null ? (
                            <h6 className="page-link">1</h6>
                          ) : (
                            <h6 className="page-link active">
                              {newurl.includes("/client/hireTeam/") &&
                              newurl
                                .split("/client/hireTeam/")[1]
                                ?.split("?page=")[1] !== undefined
                                ? newurl
                                    .split("/client/hireTeam/")[1]
                                    .split("?page=")[1]
                                : 1}
                            </h6>
                          )}
                          {candidatenext == null ? (
                            <li className="page-item disabled">
                              <h6 className="page-link">Next &gt;&gt;</h6>
                            </li>
                          ) : (
                            <li
                              className="page-item active"
                              onClick={() => {
                                setnewurl(candidatenext);
                              }}
                            >
                              <h6 className="page-link">Next &gt;&gt;</h6>
                            </li>
                          )}
                        </ul>
                      </nav>
                      <p className="text-center mt-4 font-medium text-xs text-[#71717a]">
                        Total No Of Pages : {pageNumbers.length}
                      </p>
                    </div>
                  )}
                </div>
              </div>
            </>
          )}

          {isPopUp == "diversity" && (
            <div className="diversityContianer overlay">
              <div className="diversityPopUpHead">
                <h1>Diversity Filter</h1>
                <FaRegCircleXmark
                  onClick={exitOverlayHandler}
                  className="xIcon"
                />
              </div>
              <div className="diversityPopUpBody">
                <h2>Lorem ipsum dolor sit amet, consectetur adipiscing elit</h2>
                <div className="HireMainComponentLeftContent">
                  <h3>Gender</h3>
                  <div className="flex">
                    <select
                      className="diversitySelect"
                      onChange={(e) => {
                        setgender(e.target.value);
                      }}
                      defaultValue={
                        responsibilitydata !== null && responsibilitydata.data1
                      }
                    >
                      <option value="">Select</option>
                      <option value="Male">Male</option>
                      <option value="Female">Female</option>
                      <option value="Others">Others</option>
                    </select>
                  </div>
                </div>
                <div className="HireMainComponentLeftContent">
                  <h3>Nationality</h3>
                  <div className="flex">
                    <select
                      className="diversitySelect"
                      onChange={(e) => {
                        setcountry(e.target.value);
                      }}
                      defaultValue={
                        responsibilitydata !== null && responsibilitydata.data2
                      }
                    >
                      <option value="">Select</option>
                      <option value="India">India</option>
                      <option value="USA">USA</option>
                      <option value="UAE">UAE</option>
                    </select>
                  </div>
                </div>
                <div className="HireMainComponentLeftContent">
                  <h3>Location</h3>
                  <div className="flex">
                    <select
                      className="diversitySelect"
                      onChange={(e) => {
                        setcity(e.target.value);
                      }}
                      defaultValue={
                        responsibilitydata !== null && responsibilitydata.data3
                      }
                    >
                      <option value="">Select</option>
                      <option value="Coimbatore">Coimbatore</option>
                      <option value="Chennai">Chennai</option>
                      <option value="Bangalore">Bangalore</option>
                    </select>
                  </div>
                </div>
              </div>
              <div className="overlayBottomButton">
                <button className="Hirecancel" onClick={exitOverlayHandler}>
                  Discard Changes
                </button>
                <button className="Hiresave" onClick={changefilter}>
                  Save & Close
                </button>
              </div>
            </div>
          )}
          {isPopUp == "job_des" && (
            <div className="diversityContianer overlay">
              <div className="diversityPopUpHead">
                <h1>Job Description</h1>
                <FaRegCircleXmark
                  onClick={exitOverlayHandler}
                  className="xIcon"
                />
              </div>
              <div className="diversityPopUpBody">
                {/* <h2>
                  {jobdesdata.length !== 0
                    ? jobdesdata
                    : responsibilitydata !== null && responsibilitydata.data}
                </h2> */}
                <div className="aadhaar cursor-pointer">
                  <h3>Add your Job Description</h3>
                  <div
                    className="aadhaarFront marginBottom20"
                    onClick={() => {
                      uploadHandler();
                    }}
                  >
                    <h3>
                      Drop your Files or <span className="browser">browse</span>
                    </h3>
                    <p>Maximum size: 5MB</p>
                    <p title="">accepted formats: .pdf</p>
                  </div>
                  <input
                    type="file"
                    ref={fileInputRef}
                    style={{ display: "none" }}
                    name="aadhaarfront"
                    accept=".pdf"
                    onChange={handlePdfUpload}
                  />
                </div>
                {urlvaluestatus && (
                  <p className="text-green-500 pb-2 font-medium text-md">
                    Job Description Uploaded Successfully...
                  </p>
                )}
              </div>

              <div className="overlayBottomButton">
                <button className="Hirecancel" onClick={exitOverlayHandler}>
                  Discard Changes
                </button>
                {jdloading == true ? (
                  <button className="Hiresave">Please Wait..</button>
                ) : (
                  <button className="Hiresave" onClick={changejob_des}>
                    Save & Close
                  </button>
                )}
              </div>
            </div>
          )}
          {isPopUp == "readjob_des" && (
            <div className="diversityContianer overlay">
              <div className="diversityPopUpHead">
                <h1>Responsibility</h1>
                <FaRegCircleXmark
                  onClick={exitOverlayHandler}
                  className="xIcon"
                />
              </div>
              <textarea
                className="border border-gray-500 rounded mx-4 mt-5 p-2 w-[95%]"
                placeholder="Responsibility"
                onChange={(e) => {
                  setnewresponsibilitydata(e.target.value);
                }}
                defaultValue={
                  responsibilitydata !== null && responsibilitydata.data
                }
              ></textarea>
              <div className="readjob_des py-2">
                <p>
                  Oversees the entire project, manages timelines, coordinates
                  team efforts
                </p>
              </div>
              <div className="overlayBottomButton">
                <button className="Hirecancel" onClick={exitOverlayHandler}>
                  Discard Changes
                </button>
                <button className="Hiresave" onClick={changeresponsibility}>
                  Save & Close
                </button>
              </div>
            </div>
          )}
        </>
      )}
      {isPage === "page1" && addteam == false && viewteam == true && (
        <>
          <div className="paddingRight90 marginBottom35 paddingTop50">
            <div className="hireTeamOverview">
              <div className="hireTeamOverviewBack">
                <div
                  className="hireBack"
                  onClick={() => {
                    setviewteam(false);
                    setformdata({
                      project_name: "",
                      duration: "",
                      budget: "",
                      amount: "",
                      description: "",
                      skills_required: "",
                    });
                    setskill([]);
                    setteamid(null);
                  }}
                >
                  <IoMdArrowBack className="backIcon1" />
                  <h3>Back to Teams</h3>
                </div>
                <div className="hireOnboard">
                  <button className="HireDelete" onClick={deleteBtn}>
                    Delete Project{" "}
                  </button>
                  <button className="Hiresave">Onboarded</button>
                  <button className="HireDot">
                    <BsThreeDots />
                  </button>
                </div>
              </div>
              {singleteam.length !== 0 ? (
                <div className="hireTeamOverviewContent ">
                  <h1>{singleteam[0].project_name}</h1>
                  <p>
                    Budget : {singleteam[0].budget}
                    {singleteam[0].amount}
                  </p>
                  <p>Duration : {singleteam[0].duration} Months</p>
                </div>
              ) : (
                <div className="hireTeamOverviewContent ">
                  <h1>Project Name</h1>
                  <p>Budget : $ 0</p>
                  <p>Team Size : 0</p>
                </div>
              )}
            </div>
            <div className="ClientProfileViewCard">
              <div className="ClientProfileViewCardEdit">
                <h1>Basic details</h1>
                <button
                  id="adminpersonal"
                  onClick={() => {
                    editbtn(displayTeam, singleteam);
                  }}
                >
                  <img src={editOutline} alt="" />
                  Edit
                </button>
              </div>
              {singleteam.length !== 0 && (
                <div className="ClientProfileViewCardBody">
                  <div className="ClientProfileViewCardBodyTable">
                    <h2>Project Name</h2>
                    <h3>{singleteam[0].project_name}</h3>
                  </div>
                  <div className="ClientProfileViewCardBodyTable">
                    <h2>Duration</h2>
                    <h3 className="lowerCase">
                      {singleteam[0].duration} Months
                    </h3>
                  </div>
                  <div className="ClientProfileViewCardBodyTable">
                    <h2>Budget</h2>
                    <h3>
                      {singleteam[0].budget}
                      {singleteam[0].amount}
                    </h3>
                  </div>
                  <div className="ClientProfileViewCardBodyTable">
                    <h2>Description</h2>
                    <h3>{singleteam[0].description}</h3>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="HireTeamTable paddingRight90">
            <div className="HireTeamTableContent1">
              <div className="HireTeamTableTop">
                {displayTeam.length !== 0
                  ? displayTeam.map((data, index) => (
                      <h1
                        onClick={() => ButtonHandler1(data.name)}
                        className={
                          selectedTeamNew?.name === data.name
                            ? "HireTeamTableToph1Active"
                            : "HireTeamTableToph1"
                        }
                        key={index}
                      >
                        {data.name}
                      </h1>
                    ))
                  : null}
              </div>

              {selectedTeamNew && selectedTeamNew.roles && (
                <table>
                  <thead>
                    <tr className="hireTableHead">
                      <th>Remove</th>
                      <th>Role</th>
                      <th>Team Members</th>
                      <th>Availability</th>
                      <th>Working Hours</th>
                      <th>Cost per role (USD per month)</th>
                      <th>Book Mark</th>
                      <th>Status</th>
                      <th>Engagement</th>
                    </tr>
                  </thead>

                  <tbody className="hireTableBody">
                    {selectedTeamNew.roles.length !== 0 &&
                      selectedTeamNew.roles.map(
                        (role, roleIndex) =>
                          role.selected_candidates.length !== 0 && (
                            <tr className="hireTablerow" key={roleIndex}>
                              <td>
                                <div className="flex items-center justify-center">
                                  <FaRegCircleXmark
                                    className="xIcon cursor-pointer"
                                    onClick={() => {
                                      deletebtn(role);
                                    }}
                                  />
                                  {/* <FiPlusCircle className="plusIcon" /> */}
                                </div>
                              </td>
                              <td>
                                <h3 className="pl-4">{role.possition}</h3>
                              </td>
                              <td>
                                <h3>
                                  {role.candidate_details.length !== 0 ? (
                                    role.candidate_details.map(
                                      (item, index) =>
                                        item.id == role.selected_candidates && (
                                          <div
                                            className={
                                              card === "profile2"
                                                ? "marginLeft20 hireTeamCard hireTeamCardActive"
                                                : "marginLeft20 hireTeamCard"
                                            }
                                            key={index}
                                          >
                                            <div className="mr-3">
                                              <Avatar
                                                name={
                                                  item.first_name
                                                    .split(" ")
                                                    .slice(0, -1)
                                                    .join(" ") +
                                                  " " +
                                                  item.first_name
                                                    .split(" ")
                                                    .pop()
                                                    .replace(/./g, "x")
                                                }
                                                size={40}
                                                round="50px"
                                              />
                                            </div>
                                            <div className="hireTeamCardFlex">
                                              <div className="hireTeamCardContent">
                                                <h1>
                                                  {" "}
                                                  {item.first_name
                                                    .split(" ")
                                                    .slice(0, -1)
                                                    .join(" ") +
                                                    " " +
                                                    item.first_name
                                                      .split(" ")
                                                      .pop()
                                                      .replace(/./g, "x")}
                                                </h1>
                                                <h2
                                                  className={
                                                    card ===
                                                    "singlelinetext profile2"
                                                      ? "singlelinetext hireTextColor"
                                                      : "singlelinetext"
                                                  }
                                                >
                                                  {
                                                    item.preference_info
                                                      .qualification
                                                  }
                                                </h2>
                                                <div className="flex items-center justify-center mt-1">
                                                  <FiBriefcase
                                                    className={
                                                      card === "profile2"
                                                        ? "hirebriefColor"
                                                        : ""
                                                    }
                                                  />
                                                  <h5
                                                    className={
                                                      item.active !==
                                                        undefined &&
                                                      item.active == true
                                                        ? "hireTextColor"
                                                        : ""
                                                    }
                                                  >
                                                    {
                                                      item.preference_info
                                                        .year_of_experience
                                                    }{" "}
                                                    years of experience
                                                  </h5>
                                                </div>
                                              </div>
                                              <div className="hireTeamCardContent1">
                                                {item.rate_card_info ? (
                                                  <h6>
                                                    $
                                                    {item.rate_card_info
                                                      ? item.rate_card_info
                                                          .remote_hourly
                                                      : 0}
                                                    /hr
                                                  </h6>
                                                ) : (
                                                  <h6>UA</h6>
                                                )}

                                                <button
                                                  onClick={() => {
                                                    setIsPage("page2");
                                                    dispatch(
                                                      storeAction.singleuserHander(
                                                        {
                                                          singleuser: [item],
                                                        }
                                                      )
                                                    );
                                                  }}
                                                  className={`hireTableButton ${
                                                    item.active !== undefined &&
                                                    item.active == true
                                                      ? "buttonColor"
                                                      : ""
                                                  }`}
                                                >
                                                  View
                                                </button>
                                              </div>
                                            </div>
                                          </div>
                                        )
                                    )
                                  ) : (
                                    <div className="marginLeft20 hireTeamCard">
                                      <div className="hireTeamCardContent">
                                        <h1 className="text-center">
                                          No Profile Found...
                                        </h1>
                                      </div>
                                    </div>
                                  )}
                                </h3>
                              </td>

                              <td>
                                <h3 className="pl-4">{role.Availability}</h3>
                              </td>
                              <td>
                                <h3 className="pl-4">{role.working_hours}</h3>
                              </td>
                              <td>
                                <h3 className="pl-4">{role.budget}</h3>
                              </td>
                              <td>
                                <h3 className="pl-4">
                                  {role.bookmark == "true" ? (
                                    <FaBookmark
                                      className="cursor-pointer"
                                      onClick={() => {
                                        BookmarkBtn1(role);
                                      }}
                                    />
                                  ) : (
                                    <IoBookmarkOutline
                                      className="cursor-pointer"
                                      onClick={() => {
                                        BookmarkBtn(role);
                                      }}
                                    />
                                  )}
                                </h3>
                              </td>
                              <td>
                                <h3>
                                  <select
                                    className="hireTableBodySelect"
                                    name=""
                                    id=""
                                  >
                                    <option value="Hired">Hired</option>
                                  </select>
                                </h3>
                                {/* <h3 className="pl-4">{role.status}</h3> */}
                              </td>
                              <td>
                                <h3>
                                  <select
                                    className="hireTableBodySelect"
                                    name=""
                                    id=""
                                  >
                                    <option value="Disengage">Disengage</option>
                                  </select>
                                </h3>
                                {/* <h3>{role.engagement}</h3> */}
                              </td>
                            </tr>
                          )
                      )}
                  </tbody>
                </table>
              )}
            </div>
          </div>
        </>
      )}

      {isPage === "page2" && (
        <>
          <div className="displayHandler">
            <CandidateProfileCard
              main="candidateProfile"
              fun={pageHandler}
              back="candidateBack"
            />
          </div>
          <div className="displayHandlerMob">
            <MobileCandidateProfile />
          </div>
        </>
      )}
    </div>
  );
};

export default HireMainComponent;
