import React, { useEffect, useState } from "react";

// Function to dynamically load PayPal SDK
const loadPayPalScript = (clientId) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=USD`; // Still using USD for transactions
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
  });
};

// Function to convert INR to USD (you can fetch the real-time conversion rate)
const convertINRtoUSD = (inrAmount) => {
  const conversionRate = 0.012; // Example conversion rate, replace with actual rate
  return (inrAmount * conversionRate).toFixed(2); // Returning USD equivalent
};

const PayPalCheckoutButton = ({ inrAmount }) => {
  const [paypalLoaded, setPaypalLoaded] = useState(false);
  const usdAmount = convertINRtoUSD(inrAmount); // Convert INR to USD

  useEffect(() => {
    // Load PayPal script dynamically
    loadPayPalScript(
      "AeJTWKTHqB1UW75wRDazLMMkP1ZIXuBTrcXOx1tgzzIYD_--MZNad6EXsZGk_LzDGFH5rYuS5JKD_v34"
    ).then((success) => {
      if (success) {
        setPaypalLoaded(true);
        window.paypal
          .Buttons({
            createOrder: (data, actions) => {
              return actions.order.create({
                purchase_units: [
                  {
                    amount: {
                      value: usdAmount, // Amount in USD
                    },
                  },
                ],
              });
            },
            onApprove: (data, actions) => {
              return actions.order.capture().then((details) => {
                alert(
                  `Transaction completed by ${details.payer.name.given_name}`
                );
              });
            },
            onError: (err) => {
              console.error("PayPal Checkout error", err);
            },
          })
          .render("#paypal-buttons-container");
      } else {
        console.error("PayPal SDK failed to load");
      }
    });
  }, [usdAmount]);

  return (
    <div>
      {paypalLoaded ? (
        <div id="paypal-buttons-container" />
      ) : (
        <div>Loading PayPal...</div>
      )}
    </div>
  );
};

const Test = () => {
  const inrAmount = 500; // INR Amount

  return (
    <div>
      <h1>PayPal Integration with INR Pricing</h1>
      <h2>
        Price: ₹{inrAmount} (approx. ${convertINRtoUSD(inrAmount)} USD)
      </h2>
      <PayPalCheckoutButton inrAmount={inrAmount} />
    </div>
  );
};

export default Test;
