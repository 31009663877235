// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.faqheading {
    font-size: 20px;
    color: var(--primaryColor);
    font-weight: 600;
    line-height: 34px;
    margin-bottom: 15px;
    padding-top: 10px;
}

.faqsection {
    font-weight: 400;
    font-size: 17px;
    line-height: 27px;
    color: var(--textColor);
}

.fullsection {
    padding-bottom: 25px;
    border-bottom: 1px solid var(--borderColor);
}

.linktag {
    color: var(--primaryColor);
    text-decoration: underline;
}`, "",{"version":3,"sources":["webpack://./src/Components/Reusable/Faq/faq.css"],"names":[],"mappings":"AAAA;IACI,eAAe;IACf,0BAA0B;IAC1B,gBAAgB;IAChB,iBAAiB;IACjB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,gBAAgB;IAChB,eAAe;IACf,iBAAiB;IACjB,uBAAuB;AAC3B;;AAEA;IACI,oBAAoB;IACpB,2CAA2C;AAC/C;;AAEA;IACI,0BAA0B;IAC1B,0BAA0B;AAC9B","sourcesContent":[".faqheading {\n    font-size: 20px;\n    color: var(--primaryColor);\n    font-weight: 600;\n    line-height: 34px;\n    margin-bottom: 15px;\n    padding-top: 10px;\n}\n\n.faqsection {\n    font-weight: 400;\n    font-size: 17px;\n    line-height: 27px;\n    color: var(--textColor);\n}\n\n.fullsection {\n    padding-bottom: 25px;\n    border-bottom: 1px solid var(--borderColor);\n}\n\n.linktag {\n    color: var(--primaryColor);\n    text-decoration: underline;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
