/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import "./CandidateRegistration.css";
import aadhaarimg from "../../../../assests/info.svg";
import { FaAngleDown } from "react-icons/fa6";
import { PiWarningCircle } from "react-icons/pi";
import { CiSearch } from "react-icons/ci";
import back from "../../../../assests/back.png";
import SuccessResponse from "../../../Reusable/SuccessResponse/SuccessResponse";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { storeAction } from "../../../../Store/Store";
import Dragoption from "./Dragoption";
import { FiLoader } from "react-icons/fi";
import Skilllist from "../../../../assests/skillsJSON.json";
import { FaCheck, FaTrash } from "react-icons/fa";
import Popup from "../../../Reusable/PopUp/Popup";
import { Tooltip } from "@mui/material";
import country_and_states from "../../../../assests/country-states";
import { firebase } from "../../../../database/firebase";
import { RxCross2 } from "react-icons/rx";

const CandidateRegistration = () => {
  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });
  const dispatch = useDispatch();
  const inputref = useRef("");
  const inputref1 = useRef("");
  const userdata = useSelector((store) => store.userdata);
  const signupdata = useSelector((store) => store.signupdata);
  const userid = useSelector((store) => store.userid);
  const token = useSelector((store) => store.token);
  const onboarding_status = useSelector((store) => store.onboarding_status);

  const [isPage, setIsPage] = useState("page1");
  const [dropDown, setdropDown] = useState("");
  const [userdata_new, setuserdata_new] = useState([]);
  const [dropDown1, setdropDown1] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dropDownList, setdropDownList] = useState([]);
  const [dropDownOpen, setdropDownClose] = useState(dropDownList);
  const [dropDownList1, setdropDownList1] = useState([
    "Basic",
    "Conversational",
    "Proficient",
    "Native",
  ]);
  const [relocate_for_work, setrelocate_for_work] = useState({
    willingness: "",
    duration: "",
  });
  const [dropDownOpen1, setdropDownClose1] = useState(dropDownList1);
  const [lanuageerror, setlanuageerror] = useState(false);
  const [levelerror, setlevelerror] = useState(false);
  const [finalerror, setfinalerror] = useState(null);
  const [finalerrorstatus, setfinalerrorstatus] = useState(false);
  const [finalerrortype, setfinalerrortype] = useState(null);
  const [skill, setskill] = useState([]);
  const [indexvalue, setindexvalue] = useState(null);
  const [displaymessages, setdisplaymessages] = useState(false);
  const [DisplayMessage3, setDisplayMessage3] = useState(false);
  const [statelist, setstatelist] = useState([]);
  const [final_error, setfinal_error] = useState(false);

  function displaymsg(params) {
    setdisplaymessages(!displaymessages);
  }
  function displaymsg1(params) {
    setdisplaymessages(false);
  }

  const displayMsg = (event, msg) => {
    setDisplayMessage3(msg);
  };

  const hideMsg = () => {
    setDisplayMessage3("");
  };
  async function dropDownHandler(params) {
    const inputvalue = inputref.current.value.toLowerCase();
    setdropDown("");
    if (inputvalue.length !== 0) {
      var skillarrray = Skilllist;
      const uniqueSkills = Array.from(
        new Set(skillarrray.map((skill) => skill.Skill))
      );
      const inputvalueLower = inputvalue.toLowerCase();
      const matchingSkills = uniqueSkills.filter(
        (skill) =>
          typeof skill === "string" &&
          skill.toLowerCase().includes(inputvalueLower)
      );
      setdropDownClose(matchingSkills);
      setdropDown(inputvalue.length > 0 && matchingSkills.length > 0);
    } else {
      setdropDown(inputvalue.length > 0);
    }
  }
  function filterdata(event, index) {
    setdropDown1(false);
    row[index]["level"] = event;
    setrow([...row]);
  }
  function filterdata1(params) {
    setdropDown(!dropDown);
  }
  function getdata(event) {
    const getvalue = event;
    setdropDown(false);
    const updatedItems = skill.includes(getvalue)
      ? skill.filter((data) => data !== getvalue)
      : [...skill, getvalue];
    setskill(updatedItems);
    inputref.current.value = "";
  }
  const backHandler = (event) => {
    setIsPage(event.target.id);
  };
  const [pageloading, setpageloading] = useState(false);
  const [mainsend, setmailsend] = useState(false);

  const continuebtn = async () => {
    setpageloading(false);
    if (isPage === "page4") {
      if (userdata_new.length !== 0 || userdata.length !== 0) {
        setpageloading(true);
        dispatch(storeAction.issidebarHandler({ issidebar: true }));
        dispatch(storeAction.isloginHandler({ islogin: true }));
        dispatch(
          storeAction.onboarding_statusHander({
            onboarding_status: 4,
          })
        );
        var newobj1 = {
          username:
            userdata.length !== 0 ? userdata[0].username : signupdata.username,
          onboarding_status: 4,
        };
        await axios
          .put(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userid}/`,
            newobj1,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err.response;
          });

        window.location.replace("/#/profile");
      }
    }
    sendmainfun();
  };

  const sendmainfun = async () => {
    if (mainsend == false) {
      var emailobj = {
        email: userdata[0].username,
        subject: "Welcome to HireIn5!",
        message: `
            <p>Welcome to Hirein5!</p>
                <p>We are delighted to note that you have successfully started your application. With Hirein5, you will have the opportunity to present your candidacy to the best of global employers on the platform. The stronger your application, the better your chances to compete for the top jobs, so please ensure that your application is complete and accurate.</p>
                <p>Once you have completed the application and submitted it for review, our team will connect with you for the next steps.</p>
                <p>All the best!</p>
                <p><b>Team Hirein5</b></p>
          `,
      };
      await axios
        .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, emailobj, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      setmailsend(true);
    }
  };

  function getLastWord(text) {
    // Split the text into an array of words
    const words = text.trim().split(/\s+/);
    // Return the last word
    return words.pop();
  }
  function getAllButLastWord(text) {
    // Split the text into an array of words
    const words = text.trim().split(/\s+/);
    // Return all words except the last one
    return words.slice(0, -1).join(" ");
  }

  const [formdata, setformdata] = useState({
    firstname:
      userdata.length !== 0 ? getAllButLastWord(userdata[0].first_name) : "",
    lastname: userdata.length !== 0 ? getLastWord(userdata[0].first_name) : "",
    email:
      userdata.length !== 0
        ? userdata[0].username
        : signupdata !== null
        ? signupdata.username
        : "",
    phone: userdata.length !== 0 ? userdata[0].phone : "",
    dob: userdata.length !== 0 ? userdata[0].date_of_birth : "",
    gender: userdata.length !== 0 ? userdata[0].gender : "",
    address1:
      userdata.length !== 0
        ? userdata[0].address !== null
          ? userdata[0].address.address
          : ""
        : "",
    address2: " ",
    city:
      userdata.length !== 0
        ? userdata[0].address !== null
          ? userdata[0].address.city
          : ""
        : "",
    state:
      userdata.length !== 0
        ? userdata[0].address !== null
          ? userdata[0].address.state
          : ""
        : "",
    title: "null",
    aadhaar_number:
      userdata.length !== 0
        ? userdata[0].kyc_info !== null
          ? userdata[0].kyc_info.aadhar_number
          : ""
        : "",
    aadhaarfront:
      userdata.length !== 0
        ? userdata[0].kyc_info !== null
          ? userdata[0].kyc_info.aadhar_front
          : ""
        : "",
    aadhaarback:
      userdata.length !== 0
        ? userdata[0].kyc_info !== null
          ? userdata[0].kyc_info.aadhar_back
          : ""
        : "",
    pan_number:
      userdata.length !== 0
        ? userdata[0].kyc_info !== null
          ? userdata[0].kyc_info.pan_number
          : ""
        : "",
    pan_front:
      userdata.length !== 0
        ? userdata[0].kyc_info !== null
          ? userdata[0].kyc_info.pan_front
          : ""
        : "",
    passport_no:
      userdata.length !== 0
        ? userdata[0].passport_info !== null
          ? userdata[0].passport_info.passport_number
          : ""
        : "",
    valid_until:
      userdata.length !== 0
        ? userdata[0].passport_info !== null
          ? userdata[0].passport_info.passport_validity
          : ""
        : "",
    country_of_citizenship:
      userdata.length !== 0
        ? userdata[0].passport_info !== null
          ? userdata[0].passport_info.country_of_citizenship
          : ""
        : "",
    country_of_issue:
      userdata.length !== 0
        ? userdata[0].passport_info !== null
          ? userdata[0].passport_info.country_of_issue
          : ""
        : "",
    passport_front:
      userdata.length !== 0
        ? userdata[0].passport_info !== null
          ? userdata[0].passport_info.passport_front
          : ""
        : "",
    passport_back:
      userdata.length !== 0
        ? userdata[0].passport_info !== null
          ? userdata[0].passport_info.passport_back
          : ""
        : "",
    qualification:
      userdata.length !== 0
        ? userdata[0].preference_info !== null
          ? userdata[0].preference_info.qualification
          : ""
        : "",
    experience:
      userdata.length !== 0
        ? userdata[0].preference_info !== null
          ? userdata[0].preference_info.year_of_experience
          : ""
        : "",
    skill: "",
    linkedin:
      userdata.length !== 0
        ? userdata[0].preference_info !== null
          ? userdata[0].preference_info.linkedin
          : userdata[0].new_candidate_info !== null
          ? userdata[0].new_candidate_info.linkedin
          : ""
        : "",
    hackerrank:
      userdata.length !== 0
        ? userdata[0].preference_info !== null
          ? userdata[0].preference_info.hackerrank
          : ""
        : "",
    github:
      userdata.length !== 0
        ? userdata[0].preference_info !== null
          ? userdata[0].preference_info.github
          : ""
        : "",
    website:
      userdata.length !== 0
        ? userdata[0].preference_info !== null
          ? userdata[0].preference_info.personal_website
          : ""
        : "",
    languages: "",
    pincode:
      userdata.length !== 0
        ? userdata[0].address !== null
          ? userdata[0].address.pincode
          : ""
        : "",
    countryaddress:
      userdata.length !== 0
        ? userdata[0].address !== null
          ? userdata[0].address.country
          : ""
        : "",
    countrycode: "",
  });
  const [formdataerror, setformdataerror] = useState({
    firstname: false,
    lastname: false,
    email: false,
    phone: false,
    dob: false,
    gender: false,
    address1: false,
    address2: false,
    city: false,
    state: false,
    title: false,
    aadhaar_number: false,
    aadhaarfront: false,
    aadhaarback: false,
    pan_number: false,
    pan_front: false,
    passport_no: false,
    valid_until: false,
    country: false,
    passport_front: false,
    passport_back: false,
    qualification: false,
    experience: false,
    skill: false,
    linkedin: false,
    hackerrank: false,
    github: false,
    website: false,
    languages: false,
    pincode: false,
    countryaddress: false,
  });
  const [websiterror, setwebsiterror] = useState(false);
  const [githuberror, setgithuberror] = useState(false);
  const [linkedinerror, setlinkedinerror] = useState(false);

  const changerelocatedata = (e) => {
    const { name, value } = e.target;
    setrelocate_for_work((values) => ({ ...values, [name]: value }));
  };

  const validateURL = (url) => {
    const urlPattern = new RegExp(
      "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|" + // domain name and extension
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
        "(\\#[-a-z\\d_]*)?$",
      "i"
    ); // fragment locator
    return !!urlPattern.test(url);
  };
  const handlechange = async (e) => {
    const { name, value } = e.target;
    if (name === "countryaddress") {
      setstatelist([]);
      var country = await country_and_states.country.filter((data) => {
        return data.code == value;
      });
      setformdata((values) => ({ ...values, [name]: country[0].name }));
      setstatelist(country_and_states.states[value]);
    } else if (name === "website") {
      setwebsiterror(!validateURL(value));
      setformdata((values) => ({ ...values, [name]: value }));
    } else if (name === "github") {
      setgithuberror(!validateURL(value));
      setformdata((values) => ({ ...values, [name]: value }));
    } else if (name === "linkedin") {
      setlinkedinerror(!validateURL(value));
      setformdata((values) => ({ ...values, [name]: value }));
    } else if (name === "phone") {
      const newValue = Math.max(1, Math.min(10, value));
      setformdata((values) => ({ ...values, [name]: newValue }));
    } else {
      setformdata((values) => ({ ...values, [name]: value }));
    }
  };
  async function pageHandler(e) {
    setfinalerrorstatus(false);
    if (isPage === "page1") {
      setfinal_error(true);
      setformdataerror({
        firstname: false,
        lastname: false,
        email: false,
        phone: false,
        dob: false,
        gender: false,
        address1: false,
        address2: false,
        city: false,
        state: false,
        title: false,
        aadhaar_number: false,
        aadhaarfront: false,
        aadhaarback: false,
        pan_number: false,
        pan_front: false,
        passport_no: false,
        valid_until: false,
        country: false,
        passport_front: false,
        passport_back: false,
        qualification: false,
        experience: false,
        skill: false,
        linkedin: false,
        hackerrank: false,
        github: false,
        website: false,
        languages: false,
        pincode: false,
        countryaddress: false,
      });
      var validRegex =
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
      if (formdata.firstname.length === 0) {
        setformdataerror((values) => ({
          ...values,
          firstname: true,
        }));
      } else if (formdata.lastname.length === 0) {
        setformdataerror((values) => ({
          ...values,
          firstname: false,
        }));
        setformdataerror((values) => ({
          ...values,
          lastname: true,
        }));
      } else if (formdata.email.length === 0) {
        setformdataerror((values) => ({
          ...values,
          lastname: false,
        }));
        setformdataerror((values) => ({
          ...values,
          email: true,
        }));
      } else if (formdata.email.match(validRegex)) {
        if (formdata.phone.length === 0) {
          setformdataerror((values) => ({
            ...values,
            email: false,
          }));
          setformdataerror((values) => ({
            ...values,
            phone: true,
          }));
        } else if (formdata.dob.length === 0) {
          setformdataerror((values) => ({
            ...values,
            phone: false,
          }));
          setformdataerror((values) => ({
            ...values,
            dob: true,
          }));
        } else if (formdata.address1.length === 0) {
          setformdataerror((values) => ({
            ...values,
            dob: false,
          }));
          setformdataerror((values) => ({
            ...values,
            address1: true,
          }));
        } else if (formdata.city.length === 0) {
          setformdataerror((values) => ({
            ...values,
            address1: false,
          }));
          setformdataerror((values) => ({
            ...values,
            city: true,
          }));
        } else if (formdata.state.length === 0) {
          setformdataerror((values) => ({
            ...values,
            city: false,
          }));
          setformdataerror((values) => ({
            ...values,
            state: true,
          }));
        } else if (formdata.pincode.length === 0) {
          setformdataerror((values) => ({
            ...values,
            state: false,
          }));
          setformdataerror((values) => ({
            ...values,
            pincode: true,
          }));
        } else if (formdata.aadhaar_number.length === 0) {
          setformdataerror((values) => ({
            ...values,
            pincode: false,
          }));
          setformdataerror((values) => ({
            ...values,
            aadhaar_number: true,
          }));
        } else if (formdata.aadhaarfront.length === 0) {
          setformdataerror((values) => ({
            ...values,
            aadhaar_number: false,
          }));
          setformdataerror((values) => ({
            ...values,
            aadhaarfront: true,
          }));
        } else if (formdata.aadhaarback.length === 0) {
          setformdataerror((values) => ({
            ...values,
            aadhaarfront: false,
          }));
          setformdataerror((values) => ({
            ...values,
            aadhaarback: true,
          }));
        } else if (formdata.pan_number.length === 0) {
          setformdataerror((values) => ({
            ...values,
            aadhaarback: false,
          }));
          setformdataerror((values) => ({
            ...values,
            pan_number: true,
          }));
        } else if (formdata.pan_front.length === 0) {
          setformdataerror((values) => ({
            ...values,
            pan_number: false,
          }));
          setformdataerror((values) => ({
            ...values,
            pan_front: true,
          }));
        } else {
          setfinal_error(false);
          setformdataerror((values) => ({
            ...values,
            pan_front: false,
          }));
          setformdataerror({
            firstname: false,
            lastname: false,
            email: false,
            phone: false,
            dob: false,
            gender: false,
            address1: false,
            address2: false,
            city: false,
            state: false,
            title: false,
            aadhaar_number: false,
            aadhaarfront: false,
            aadhaarback: false,
            pan_number: false,
            pan_front: false,
            passport_no: false,
            valid_until: false,
            country: false,
            passport_front: false,
            passport_back: false,
            qualification: false,
            experience: false,
            skill: false,
            linkedin: false,
            hackerrank: false,
            github: false,
            website: false,
            languages: false,
            pincode: false,
            countryaddress: false,
          });
          setIsLoading(true);
          setfileuploadsuccess({
            aadhaarfront: false,
            aadhaarback: false,
            pan_front: false,
            passport_front: false,
            passport_back: false,
          });
          var newobj = {
            username: userdata[0].username,
            phone: formdata.phone,
            title: formdata.title,
            date_of_birth: formdata.dob,
            gender: formdata.gender,
            first_name: `${formdata.firstname} ${formdata.lastname}`,
            role: "3",
            onboarding_status: 2,
            address: {
              address: `${formdata.address1} ${formdata.address2}`,
              city: formdata.city,
              state: formdata.state,
              pincode: formdata.pincode,
              country: formdata.countryaddress,
            },
            kyc_info: {
              aadhar_number: formdata.aadhaar_number,
              aadhar_front: formdata.aadhaarfront,
              aadhar_back: formdata.aadhaarback,
              pan_number: formdata.pan_number,
              pan_front: formdata.pan_front,
            },
            passport_info: {
              passport_number: formdata.passport_no,
              passport_validity: formdata.valid_until,
              country_of_citizenship: formdata.country_of_citizenship,
              country_of_issue: formdata.country_of_issue,
              passport_front: formdata.passport_front,
              passport_back: formdata.passport_back,
            },
          };
          var updatedata = await axios
            .put(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userid}/`,
              newobj,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              return err.response;
            });

          if (
            updatedata.message ===
            "User and Associated Info updated successfully"
          ) {
            setIsPage("page2");
            dispatch(
              storeAction.onboarding_statusHander({
                onboarding_status: 2,
              })
            );
            setfinalerrorstatus(false);
            setfinalerror(null);
            setfinalerrortype(null);
            setIsLoading(false);
          } else {
            if (updatedata.data.code == "token_not_valid") {
              dispatch(storeAction.isPopUpHander("contactus"));
            } else {
              setIsLoading(false);
              if (updatedata.username === undefined) {
                if (updatedata.phone === undefined) {
                  if (updatedata.title === undefined) {
                    if (updatedata.first_name === undefined) {
                      if (updatedata.passport_info === undefined) {
                        if (updatedata.kyc_info === undefined) {
                        } else {
                          setfinalerrorstatus(true);
                          setfinalerror(updatedata.kyc_info.aadhar_number);
                          setfinalerrortype("aadhar_number");
                        }
                      } else {
                        if (
                          updatedata.passport_info.passport_number === undefined
                        ) {
                          if (
                            updatedata.passport_info.passport_validity ===
                            undefined
                          ) {
                            if (
                              updatedata.passport_info
                                .country_of_citizenship === undefined
                            ) {
                              if (
                                updatedata.passport_info.country_of_issue ===
                                undefined
                              ) {
                              } else {
                                setfinalerrorstatus(true);
                                setfinalerror(
                                  updatedata.passport_info.country_of_issue
                                );
                                setfinalerrortype("country_of_issue");
                              }
                            } else {
                              setfinalerrorstatus(true);
                              setfinalerror(
                                updatedata.passport_info.country_of_citizenship
                              );
                              setfinalerrortype("country_of_citizenship");
                            }
                          } else {
                            setfinalerrorstatus(true);
                            setfinalerror(
                              updatedata.passport_info.passport_validity
                            );
                            setfinalerrortype("passport_validity");
                          }
                        } else {
                          setfinalerrorstatus(true);
                          setfinalerror(
                            updatedata.passport_info.passport_number
                          );
                          setfinalerrortype("passport_number");
                        }
                      }
                    } else {
                      setfinalerrorstatus(true);
                      setfinalerror(updatedata.first_name);
                      setfinalerrortype("first_name");
                    }
                  } else {
                    setfinalerrorstatus(true);
                    setfinalerror(updatedata.title);
                    setfinalerrortype("title");
                  }
                } else {
                  setfinalerrorstatus(true);
                  setfinalerror(updatedata.phone);
                  setfinalerrortype("phone");
                }
              } else {
                setfinalerrorstatus(true);
                setfinalerror(updatedata.username);
                setfinalerrortype("username");
              }
            }
          }
        }
        setformdataerror((values) => ({
          ...values,
          email: false,
        }));
      } else {
        setformdataerror((values) => ({
          ...values,
          lastname: false,
        }));
        setformdataerror((values) => ({
          ...values,
          email: true,
        }));
      }
    } else if (isPage === "page2") {
      setfinal_error(true);
      setformdataerror({
        firstname: false,
        lastname: false,
        email: false,
        phone: false,
        dob: false,
        gender: false,
        address1: false,
        address2: false,
        city: false,
        state: false,
        title: false,
        aadhaar_number: false,
        aadhaarfront: false,
        aadhaarback: false,
        pan_number: false,
        pan_front: false,
        passport_no: false,
        valid_until: false,
        country: false,
        passport_front: false,
        passport_back: false,
        qualification: false,
        experience: false,
        skill: false,
        linkedin: false,
        hackerrank: false,
        github: false,
        website: false,
        languages: false,
        skilllength: false,
      });

      if (formdata.qualification.length === 0) {
        setformdataerror((values) => ({
          ...values,
          qualification: true,
        }));
      } else if (formdata.experience.length === 0) {
        setformdataerror((values) => ({
          ...values,
          qualification: false,
        }));
        setformdataerror((values) => ({
          ...values,
          experience: true,
        }));
      } else if (skill.length === 0) {
        setformdataerror((values) => ({
          ...values,
          experience: false,
        }));
        setformdataerror((values) => ({
          ...values,
          skill: true,
        }));
      } else if (skill.length < 5) {
        setformdataerror((values) => ({
          ...values,
          skill: false,
        }));
        setformdataerror((values) => ({
          ...values,
          skilllength: true,
        }));
      } else if (formdata.linkedin.length === 0) {
        setformdataerror((values) => ({
          ...values,
          skilllength: false,
        }));
        setformdataerror((values) => ({
          ...values,
          linkedin: true,
        }));
      } else {
        setfinal_error(false);
        setformdataerror({
          firstname: false,
          lastname: false,
          email: false,
          phone: false,
          dob: false,
          gender: false,
          address1: false,
          address2: false,
          city: false,
          state: false,
          title: false,
          aadhaar_number: false,
          aadhaarfront: false,
          aadhaarback: false,
          pan_number: false,
          pan_front: false,
          passport_no: false,
          valid_until: false,
          country: false,
          passport_front: false,
          passport_back: false,
          qualification: false,
          experience: false,
          skill: false,
          linkedin: false,
          hackerrank: false,
          github: false,
          website: false,
          languages: false,
          skilllength: false,
        });
        setIsLoading(true);
        if (row[0].languages.length === 0) {
          setlanuageerror(true);
          setlevelerror(false);
        } else if (row[0].level.length === 0) {
          setlanuageerror(false);
          setlevelerror(true);
        } else {
          setlanuageerror(false);
          setlevelerror(false);
          const arrayOfStrings = row.map(
            (obj) => `${obj.languages}: ${obj.level}`
          );
          if (
            githuberror === false &&
            linkedinerror === false &&
            websiterror === false
          ) {
            var newObj = {
              username: userdata[0].username,
              onboarding_status: 3,
              preference_info: {
                qualification: formdata.qualification,
                year_of_experience: formdata.experience,
                skills: skill,
                linkedin: formdata.linkedin,
                hackerrank: formdata.hackerrank,
                github: formdata.github,
                personal_website: formdata.website,
                language: arrayOfStrings,
              },
            };
            var update_data = await axios
              .put(
                `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userid}/`,
                newObj,
                {
                  headers: {
                    "Content-Type": "application/json",
                    Authorization: `JWT ${token}`,
                  },
                }
              )
              .then((res) => {
                return res.data;
              })
              .catch((err) => {
                return err.response;
              });
            if (
              update_data.message ===
              "User and Associated Info updated successfully"
            ) {
              dispatch(
                storeAction.onboarding_statusHander({
                  onboarding_status: 3,
                })
              );
              setIsPage("page3");
              setfinalerrorstatus(false);
              setfinalerror(null);
              setfinalerrortype(null);
              setlanuageerror(false);
              setlevelerror(false);
              setIsLoading(false);
            } else {
              if (updatedata !== undefined) {
                if (updatedata.data.code == "token_not_valid") {
                  dispatch(storeAction.isPopUpHander("contactus"));
                }
              } else {
                setfinalerrorstatus(false);
                setfinalerror(null);
                setfinalerrortype(null);
                setlanuageerror(false);
                setlevelerror(false);
                setIsLoading(false);
              }
            }
          }
        }
        setIsLoading(false);
      }
    } else if (isPage === "page3") {
      setIsLoading(true);
      const arrayOfStrings = travelrow.map(
        (obj) =>
          `${obj.country}:${obj.year_of_travel}:${obj.duration}:${obj.purpose}:${obj.type_of_visa}:${obj.validity_of_visa}`
      );
      const arrayOfStrings1 = relocate.map(
        (obj) =>
          `${obj.are_you_willing}:${obj.preferred_countries}:${obj.how_long}`
      );
      const arrayOfStrings2 = travelwork.map(
        (obj) =>
          `${obj.country}:${obj.only_for}:${obj.duration}:${obj.readlines}`
      );
      var valuesArray = [];
      if (selectedOption !== null && selectedOption !== undefined) {
        valuesArray = selectedOption.map((country) => country.value);
      }
      var filter1 = [];
      for (var i = 0; i < selectedcountrylist.length; i++) {
        filter1.push(selectedcountrylist[i]);
      }
      var newobj1 = {
        username: userdata[0].username,
        onboarding_status: 4,
        travel_info: {
          travelled_to: arrayOfStrings,
          relocate_for_work: "",
          travel_to_for_work: arrayOfStrings2,
          willingness: "true",
          duration: relocate_for_work.duration,
          // prefered_countries: valuesArray,
          prefered_countries: filter1,
        },

        current_place_of_residence: travelform.current_place_of_residence,
        lived_at_current_residence: travelform.lived_at_current_residence,
      };
      var update1_data = await axios
        .put(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userid}/`,
          newobj1,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });

      var emailobj = {
        email: userdata[0].username,
        subject: "Welcome to HireIn5!",
        message: `
              <p>Welcome to Hirein5!</p>
                  <p>We are delighted to note that you have successfully started your application. With Hirein5, you will have the opportunity to present your candidacy to the best of global employers on the platform. The stronger your application, the better your chances to compete for the top jobs, so please ensure that your application is complete and accurate.</p>
                  <p>Once you have completed the application and submitted it for review, our team will connect with you for the next steps.</p>
                  <p>All the best!</p>
                  <p><b>Team Hirein5</b></p>
            `,
      };
      await axios
        .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, emailobj, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      // dispatch(storeAction.onboarding_statusHander({ onboarding_status: 4 }));
      if (
        update1_data.message === "User and Associated Info updated successfully"
      ) {
        var new_obj = {
          message: `<p><b>${update1_data.user.first_name}</b> has onboarded as a candidate</p>`,
          status: "false",
          on_type: "Candidate has onboarded",
        };
        await axios
          .post(
            `${process.env.REACT_APP_LOCAL_HOST_URL}/notification/${update1_data.user.id}/`,
            new_obj,
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            }
          )
          .then((res) => {
            return res.data;
          })
          .catch((err) => {
            return err.response;
          });
        let updatedObject = {
          ...userdata[0],
          travel_info: update1_data.user.travel_info,
        };
        setuserdata_new([updatedObject]);
        setIsLoading(false);
        setTimeout(() => {
          dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
        }, 2000);
        setIsPage("page4");
      } else {
        if (updatedata.data.code == "token_not_valid") {
          dispatch(storeAction.isPopUpHander("contactus"));
        }
      }
    }
  }
  const fileInputRef = useRef(null);
  const [formtype, setformtype] = useState(null);

  const handleFileSelectClick = (data) => {
    fileInputRef.current.click();
    setformtype(data);
  };
  const [formData, setFormData] = useState(new FormData());
  const [fileuploadsuccess, setfileuploadsuccess] = useState({
    aadhaarfront: false,
    aadhaarback: false,
    pan_front: false,
    passport_front: false,
    passport_back: false,
  });
  const [fileuploaderror, setfileuploaderror] = useState({
    aadhaarfront: false,
    aadhaarback: false,
    pan_front: false,
    passport_front: false,
    passport_back: false,
  });
  const handleFileInputChange = async (e) => {
    setfileuploaderror({
      aadhaarfront: false,
      aadhaarback: false,
      pan_front: false,
      passport_front: false,
      passport_back: false,
    });
    // setfileuploadsuccess({
    //   aadhaarfront: false,
    //   aadhaarback: false,
    //   pan_front: false,
    //   passport_front: false,
    //   passport_back: false,
    // });
    formData.append("image", e.target.files[0]);
    const selectedImage = e.target.files[0];
    if (selectedImage.size > 5 * 1024 * 1024) {
      fileInputRef.current.value = "";
      setfileuploaderror((values) => ({
        ...values,
        [formtype]: true,
      }));
      fileInputRef.current.value = "";
    } else {
      formData.append("name", `${formtype}_${userid}`);
      let file = e.target.files;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase
          .storage()
          .ref(`${formtype}_${userid}` + file[0].name);
        storageRef.put(file[0]).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      }).catch((err) => {
        console.log(err, "errr");
      });
      var imgurl_new21 = await file13;
      setformdata((values) => ({
        ...values,
        [formtype]: imgurl_new21,
      }));
      setfileuploadsuccess((values) => ({
        ...values,
        [formtype]: true,
      }));
    }

    fileInputRef.current.value = "";
  };
  const [row, setrow] = useState([{ languages: "", level: "" }]);

  const [travelrow, settravelrow] = useState([
    {
      country: "",
      year_of_travel: "",
      duration: "",
      purpose: "",
      type_of_visa: "",
      validity_of_visa: "",
      validity: "",
    },
  ]);

  const [travelform, settravelform] = useState({
    current_place_of_residence: "",
    lived_at_current_residence: "",
  });

  const handlechange_travel = (e) => {
    const { name, value } = e.target;
    settravelform((values) => ({ ...values, [name]: value }));
  };

  const [relocate, setrelocate] = useState([
    {
      are_you_willing: "",
      preferred_countries: "",
      how_long: "",
    },
  ]);
  const [travelwork, settravelwork] = useState([
    {
      country: "",
      only_for: "",
      duration: "",
      readlines: "",
    },
  ]);
  const addcount = () => {
    var newobj = {
      languages: "",
      level: "",
    };
    setrow((prevState) => [...prevState, newobj]);
  };

  const addcounttravel = () => {
    var newobj = {
      country: "",
      year_of_travel: "",
      duration: "",
      purpose: "",
      type_of_visa: "",
      validity_of_visa: "",
      validity: "",
    };
    settravelrow((prevState) => [...prevState, newobj]);
  };
  const addcountwork = () => {
    if (travelwork.length !== 3) {
      var newobj = {
        country: "",
        only_for: "",
        duration: "",
        readlines: "",
      };
      settravelwork((prevState) => [...prevState, newobj]);
    }
  };

  const addcountrelocate = () => {
    var newobj = {
      are_you_willing: "",
      preferred_countries: "",
      how_long: "",
    };
    setrelocate((prevState) => [...prevState, newobj]);
  };

  const get_value = (e, index) => {
    row[index]["languages"] = e;
    setrow([...row]);
  };

  const handlechangetravel = (value, index, name) => {
    travelrow[index][name] = value;
    settravelrow([...travelrow]);
  };
  const changevalidity = (value, index, name) => {
    travelrow[index][name] = value;
    settravelrow([...travelrow]);
  };
  const handlechangerelocate = (value, index, name) => {
    relocate[index][name] = value;
    setrelocate([...relocate]);
  };
  const [selectedcountrylist, setselectedcountrylist] = useState([]);

  const handlechangework = (value, index, name) => {
    travelwork[index][name] = value;
    settravelwork([...travelwork]);
    if (name === "country") {
      selectedcountrylist[index] = value;
      setselectedcountrylist([...selectedcountrylist]);
    }
  };

  useEffect(() => {
    reactselectform();
  }, [selectedcountrylist]);

  const reactselectform = () => {
    if (selectedcountrylist.length !== 0) {
      var filter1 = [];
      for (var i = 0; i < selectedcountrylist.length; i++) {
        filter1.push({
          value: selectedcountrylist[i],
          label: selectedcountrylist[i],
        });
      }
      setskilloption(filter1);
    }
  };
  const [selectedOption, setSelectedOption] = useState(null);
  const [skilloption, setskilloption] = useState([]);

  useEffect(() => {
    CheckStage();
  }, [onboarding_status, userdata]);

  const CheckStage = async () => {
    if (userdata.length !== 0) {
      if (userdata[0].resume_json.length == 0) {
        dispatch(storeAction.isPopUpHander("ResumeParsing"));
      } else {
        dispatch(storeAction.isPopUpHander());
      }
    }
    if (onboarding_status > 3) {
      window.location.replace("/#/profile");
    } else {
      if (onboarding_status == 1) {
        setIsPage("page1");
        dispatch(storeAction.isPopUpHander("Timepopup"));
      } else if (onboarding_status == 2) {
        setIsPage("page2");
        dispatch(storeAction.isPopUpHander("Timepopup"));
      } else if (onboarding_status == 3) {
        if (isPage == "page1" || isPage == "page2") {
          setIsPage("page3");
          dispatch(storeAction.isPopUpHander("Timepopup"));
        } else {
          setIsPage("page4");
        }
      }
    }

    setformdata((values) => ({
      ...values,
      phone: userdata.length !== 0 ? userdata[0].phone : "",
    }));
  };
  const skipbtn = async () => {
    var newobj1 = {
      username: userdata[0].username,
      onboarding_status: 4,
    };
    var update1_data = await axios
      .put(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userid}/`,
        newobj1,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    setIsLoading(false);
    if (
      update1_data.message === "User and Associated Info updated successfully"
    ) {
      var new_obj = {
        message: `<p><b>${update1_data.user.first_name}</b> has onboarded as a candidate</p>`,
        status: "false",
        on_type: "Candidate has onboarded",
      };

      await axios
        .post(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/notification/${update1_data.user.id}/`,
          new_obj,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      let updatedObject = {
        ...userdata[0],
        onboarding_status: update1_data.user.onboarding_status,
      };
      var emailobj = {
        email: userdata[0].username,
        subject: "Welcome to HireIn5!",
        message: `
            <p>Welcome to Hirein5!</p>
                <p>We are delighted to note that you have successfully started your application. With Hirein5, you will have the opportunity to present your candidacy to the best of global employers on the platform. The stronger your application, the better your chances to compete for the top jobs, so please ensure that your application is complete and accurate.</p>
                <p>Once you have completed the application and submitted it for review, our team will connect with you for the next steps.</p>
                <p>All the best!</p>
                <p><b>Team Hirein5</b></p>
          `,
      };
      await axios
        .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/emails/`, emailobj, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });

      setuserdata_new([updatedObject]);
      setIsLoading(false);
      setTimeout(() => {
        dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
      }, 2000);
    } else {
      dispatch(storeAction.isPopUpHander("contactus"));
    }
    setIsPage("page4");
  };
  const deletetravelwork = async (id) => {
    const updatedItems = travelwork.filter((item, index) => index !== id);
    settravelwork([]);
    setTimeout(() => {
      settravelwork(updatedItems);
    }, 1);
  };
  const deletetravelrow = (id) => {
    const updatedItems = travelrow.filter((item, index) => index !== id);
    settravelrow([]);
    setTimeout(() => {
      settravelrow(updatedItems);
    }, 1);
  };
  const logout = async () => {
    dispatch(storeAction.reset());
    localStorage.removeItem("persist:root");
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace("/#/login");
  };
  const closepopup = () => {
    dispatch(storeAction.isPopUpHander());
  };

  const [pdfFiles, setPdfFiles] = useState([]);
  const [uploadedstatus, setuploadedstatus] = useState(false);
  const [loading, setloading] = useState(false);

  const handleFileUpload = async (event) => {
    const uploadedFiles = Array.from(event.target.files);
    const zipFiles = [];
    const newPdfFiles = [];
    for (const file of uploadedFiles) {
      if (
        file.type === "application/zip" ||
        file.type === "application/x-zip-compressed"
      ) {
        zipFiles.push(file);
      } else if (file.type === "application/pdf") {
        newPdfFiles.push(file);
      }
    }
    setPdfFiles((prevPdfFiles) => [...prevPdfFiles, ...newPdfFiles]);
    setTimeout(() => {
      setuploadedstatus(true);
    }, 3000);
  };

  const SubmitBtn = async () => {
    await uploadPdf(pdfFiles[0]);
  };

  const uploadPdf = async (pdfFile) => {
    if (pdfFile) {
      setloading(true);
      const reader = new FileReader();
      reader.onload = async function (event) {
        try {
          const base64String = event.target.result.split(";base64,")[1];
          const sixDigitNumber = Math.floor(100000 + Math.random() * 900000);
          let dataToSend = JSON.stringify({
            index: {
              indexType: "Resume",
              indexKey: "KDJ6NFUC",
            },
            data: {
              id: sixDigitNumber.toString(),
              fileContent: base64String,
              fileName: pdfFile.name,
            },
            commit: true,
            deDuplication: {
              checkDuplicate: true,
              fields: ["Email"],
              update: true,
            },
          });

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: "https://searchengine.rchilli.com/RChilliSearchEngineAPI/RChilli/parseAndIndex",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            data: dataToSend,
          };

          var indexdata = await axios
            .request(config)
            .then((response) => {
              return response.data;
            })
            .catch((error) => {
              throw error;
            });

          if (indexdata && indexdata.status == 200) {
            try {
              const base64EncodedString = indexdata.doc;
              let decodedString = atob(base64EncodedString);
              var resumedata2 = JSON.parse(decodedString);

              var newobj1 = {
                username:
                  userdata.length !== 0
                    ? userdata[0].username
                    : signupdata.username,
                resume_json: JSON.stringify(resumedata2.ResumeParserData),
              };
              var updatedata = await axios
                .put(
                  `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userid}/`,
                  newobj1,
                  {
                    headers: {
                      "Content-Type": "application/json",
                      Authorization: `JWT ${token}`,
                    },
                  }
                )
                .then((res) => {
                  return res.data;
                })
                .catch((err) => {
                  return err.response;
                });
              if (
                updatedata.message ===
                "User and Associated Info updated successfully"
              ) {
                let updatedObject = {
                  ...userdata[0],
                  resume_json: JSON.stringify(resumedata2.ResumeParserData),
                };
                dispatch(storeAction.userdataHander({ userdata: [] }));
                setTimeout(() => {
                  dispatch(
                    storeAction.userdataHander({ userdata: [updatedObject] })
                  );
                  window.location.reload();
                }, 10);
              }
              setloading(false);
            } catch (error) {
              console.error("Error decoding Base64 string:", error);
            }
          }
        } catch (error) {
          console.error("Error processing PDF file:", error);
        }
        setTimeout(() => {
          return true;
        }, 2000);
      };
      reader.readAsDataURL(pdfFile);
    }
  };

  const [timeLeft, setTimeLeft] = useState(25);
  useEffect(() => {
    const timer = setInterval(() => {
      if (isPage === "page4") {
        setIsPage("page4");
        setTimeLeft((prevTime) => prevTime - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  });
  useEffect(() => {
    if (timeLeft === 0) {
      continuebtn();
    }
  }, [timeLeft]);
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  return (
    <>
      {/* {userdata.length !== 0 ? (
        userdata[0].vendor.length === 0 ? (
          <div>old</div>
        ) : userdata[0].video_resume.length == 0 ? (
          <div className="candidateRegistration">
            <h1>addview</h1>
          </div>
        ) : (
          <div>old</div>
        )
      ) : null} */}

      <div className="otherContent">
        <div className="candidateRegistration">
          {isPage === "page1" && (
            <div className="candidateRegistration1">
              <div className="candidateRegistrationInner">
                <div className="CandidatePages">
                  <button className="candidateBtn1">1</button>
                  <p></p>
                  <button className="candidateBtn2">2</button>
                  <p></p>
                  <button className="candidateBtn3">3</button>
                </div>
                <button onClick={logout} className="logoutReg">
                  Log out
                </button>
                <div className="CandidateHead">
                  <h1>Share your Personal Details</h1>
                </div>
                <div className="basicDetails">
                  <h2>BASIC DETAILS</h2>
                  <div className="CandidateDetails">
                    <div className="candidateInfo h-full">
                      <h3>First & Middle Name</h3>
                      <input
                        type="text"
                        placeholder="First Name"
                        name="firstname"
                        onChange={handlechange}
                        defaultValue={formdata.firstname}
                      />
                      {formdataerror.firstname && (
                        <h6 className="text-red-500 text-xs font-semibold mt-2">
                          Please Enter First Name
                        </h6>
                      )}
                    </div>
                    <div className="candidateInfo h-full">
                      <h3>Last Name</h3>
                      <input
                        type="text"
                        placeholder="Last Name"
                        name="lastname"
                        onChange={handlechange}
                        defaultValue={formdata.lastname}
                      />
                      {formdataerror.lastname && (
                        <h6 className="text-red-500 text-xs font-semibold mt-2">
                          Please Enter Last Name
                        </h6>
                      )}
                    </div>
                    <div className="candidateInfo h-full">
                      <h3>Email ID</h3>
                      <input
                        type="text"
                        placeholder="divyagupta@gmail.com"
                        name="email"
                        disabled
                        onChange={handlechange}
                        defaultValue={formdata.email}
                      />
                      {formdataerror.email && (
                        <h6 className="text-red-500 text-xs font-semibold mt-2">
                          Please Enter Valid Email ID
                        </h6>
                      )}
                    </div>
                    <div className="candidateInfo h-full">
                      <h3>Phone no.</h3>
                      <p>
                        <input
                          type="text"
                          placeholder="9876543210"
                          name="phone"
                          defaultValue={formdata.phone}
                          maxLength={12}
                          pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                          onChange={handlechange}
                          disabled
                        />
                      </p>
                      {formdataerror.phone && (
                        <h6 className="text-red-500 text-xs font-semibold mt-2">
                          Please Enter Valid Phone
                        </h6>
                      )}
                    </div>
                  </div>
                  <div className="candidateDetails1">
                    <div className="candidateInfo h-full">
                      <h3>Date of birth</h3>
                      <input
                        type="date"
                        placeholder="DD/MM/YYYY"
                        name="dob"
                        max="2024-12-31"
                        min="1979-12-31"
                        onChange={handlechange}
                        defaultValue={formdata.dob}
                      />
                      {formdataerror.dob && (
                        <h6 className="text-red-500 text-xs font-semibold mt-2">
                          Please Enter Date of birth
                        </h6>
                      )}
                    </div>
                    <div className="candidateInfo h-full">
                      <h3>Gender</h3>
                      <select
                        className="w-[28% !important]"
                        name="gender"
                        onChange={handlechange}
                        defaultValue={formdata.gender}
                      >
                        <option value="" disabled>
                          Select
                        </option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Others">Others</option>
                        <option value="Prefer not to say">
                          Prefer not to answer
                        </option>
                      </select>
                      {formdataerror.gender && (
                        <h6 className="text-red-500 text-xs font-semibold mt-2">
                          Please Select Gender
                        </h6>
                      )}
                    </div>
                    <div className="candidateInfo h-full">
                      <h3>Address line 1</h3>
                      <input
                        type="text"
                        placeholder="e.g. Richmond Par, Avenue 2"
                        name="address1"
                        onChange={handlechange}
                        defaultValue={formdata.address1}
                      />
                      {formdataerror.address1 && (
                        <h6 className="text-red-500 text-xs font-semibold mt-2">
                          Please Enter Address line 1
                        </h6>
                      )}
                    </div>
                    <div className="candidateInfo h-full">
                      <div className="addressLine">
                        <h3>Address line 2</h3>
                        <h3 className="option">Optional</h3>
                      </div>
                      <input
                        type="text"
                        placeholder="Optional"
                        name="address2"
                        onChange={handlechange}
                        defaultValue={formdata.address2}
                      />
                    </div>
                  </div>
                  <div className="candidateAddress">
                    <div className="candidatePlace">
                      <div className="candidateInfo h-full">
                        <h3>Country</h3>
                        <div className="candidateState">
                          <select
                            name="countryaddress"
                            onChange={handlechange}
                            defaultValue={formdata.countryaddress}
                          >
                            <option value="">Country</option>
                            {country_and_states.country.length !== 0
                              ? country_and_states.country.map(
                                  (item, index) => (
                                    <option
                                      value={item.code}
                                      key={index}
                                      selected={
                                        formdata.countryaddress == item.name
                                      }
                                    >
                                      {item.name}
                                    </option>
                                  )
                                )
                              : null}
                          </select>
                        </div>
                        {formdataerror.countryaddress && (
                          <h6 className="text-red-500 text-xs font-semibold mt-2">
                            Please Select country
                          </h6>
                        )}
                      </div>
                      <div className="candidateInfo h-full">
                        <h3>City</h3>
                        <input
                          type="text"
                          placeholder="Bangalore"
                          name="city"
                          onChange={handlechange}
                          defaultValue={formdata.city}
                        />
                        {formdataerror.city && (
                          <h6 className="text-red-500 text-xs font-semibold mt-2">
                            Please Enter City
                          </h6>
                        )}
                      </div>
                    </div>
                    <div className="candidatePlace">
                      <div className="candidateInfo h-full">
                        <h3>State / County</h3>
                        <div className="candidateState">
                          <select
                            id=""
                            name="state"
                            onChange={handlechange}
                            defaultValue={formdata.state}
                          >
                            <option value="">Select</option>
                            {statelist.length !== 0 ? (
                              statelist.map((data, index) => (
                                <option value={data.name} key={index}>
                                  {data.name}
                                </option>
                              ))
                            ) : (
                              <option value="">Select</option>
                            )}
                          </select>
                        </div>
                        {formdataerror.state && (
                          <h6 className="text-red-500 text-xs font-semibold mt-2">
                            Please Select State / County
                          </h6>
                        )}
                      </div>
                      <div className="candidateInfo h-full">
                        <h3>Pincode</h3>
                        <input
                          type="text"
                          placeholder="e.g. 560013"
                          name="pincode"
                          onChange={handlechange}
                          defaultValue={formdata.pincode}
                          maxLength={6}
                        />
                        {formdataerror.pincode && (
                          <h6 className="text-red-500 text-xs font-semibold mt-2">
                            Please Enter Pincode
                          </h6>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="candidateRegistrationInner">
                <div className="DocumentDetails">
                  <h2>DOCUMENTS</h2>

                  <div className="documentDetails1">
                    <div className="candidateInfo h-full">
                      <h3>Aadhaar Number / Nationality ID</h3>
                      <input
                        type="text"
                        placeholder="e.g. 00000000000"
                        name="aadhaar_number"
                        onChange={handlechange}
                        defaultValue={formdata.aadhaar_number}
                      />
                      {formdataerror.aadhaar_number && (
                        <h6 className="text-red-500 text-xs font-semibold mt-2">
                          Please Enter Aadhaar number / ID proof
                        </h6>
                      )}
                    </div>
                    <div className="aadhaarDetails">
                      <div
                        className="aadhaar cursor-pointer"
                        onClick={() => {
                          handleFileSelectClick("aadhaarfront");
                        }}
                      >
                        <h3>
                          Aadhaar Card Front / ID proof
                          <Tooltip
                            title="any Govt. issued ID displaying your photo and name"
                            style={{ color: "black" }}
                            arrow
                            placement="top"
                          >
                            <img
                              src={aadhaarimg}
                              alt=""
                              id="aadhar"
                              onMouseLeave={hideMsg}
                              onMouseOver={(event) =>
                                displayMsg(event, "aadhar")
                              }
                            />
                          </Tooltip>
                        </h3>
                        <div className="aadhaarFront">
                          <h3>
                            Drop your files here or{" "}
                            <span className="browser">browse</span>
                          </h3>
                          <p>Maximum size: 5MB</p>
                          <p title=""> PDF JPEG and PNG accepted</p>
                        </div>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          name="aadhaarfront"
                          accept=".pdf,.jpeg,.jpg,.png"
                          onChange={handleFileInputChange}
                        />
                        {formdataerror.aadhaarfront && (
                          <h6 className="text-red-500 text-xs font-semibold mt-2">
                            Please Upload Aadhaar Card Front / ID proof
                          </h6>
                        )}
                        {fileuploadsuccess.aadhaarfront && (
                          <h6 className="text-green-500 text-xs font-semibold mt-2">
                            Aadhaar Card Front / ID Proof Uploaded Successfully
                          </h6>
                        )}
                        {fileuploaderror.aadhaarfront && (
                          <h6 className="text-red-500 text-xs font-semibold mt-2">
                            Image size exceeds 5 MB limit.
                          </h6>
                        )}
                      </div>
                      <div
                        className="aadhaar cursor-pointer"
                        onClick={() => {
                          handleFileSelectClick("aadhaarback");
                        }}
                      >
                        <h3>
                          Aadhaar Card Back / ID proof{" "}
                          <Tooltip
                            title="Backside of the Govt. issued ID"
                            style={{ color: "black" }}
                            arrow
                            placement="top"
                          >
                            <img
                              src={aadhaarimg}
                              alt=""
                              id="aadhar1"
                              onMouseLeave={hideMsg}
                              onMouseOver={(event) =>
                                displayMsg(event, "aadhar1")
                              }
                            />
                          </Tooltip>
                        </h3>

                        <div className="aadhaarFront">
                          <h3>
                            Drop your files here or{" "}
                            <span className="browser">browse</span>
                          </h3>
                          <p>Maximum size: 5MB</p>
                          <p title=""> PDF JPEG and PNG accepted</p>
                        </div>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          name="aadhaarback"
                          accept=".pdf,.jpeg,.jpg,.png"
                          onChange={handleFileInputChange}
                        />
                        {formdataerror.aadhaarback && (
                          <h6 className="text-red-500 text-xs font-semibold mt-2">
                            Please Upload Aadhaar Card Back / ID Proof
                          </h6>
                        )}
                        {fileuploadsuccess.aadhaarback && (
                          <h6 className="text-green-500 text-xs font-semibold mt-2">
                            Aadhaar Card Back / ID Proof Uploaded Successfully
                          </h6>
                        )}
                        {fileuploaderror.aadhaarback && (
                          <h6 className="text-red-500 text-xs font-semibold mt-2">
                            Image size exceeds 5 MB limit.
                          </h6>
                        )}
                      </div>
                    </div>
                    <div className="candidateInfo h-full">
                      <h3>PAN Number / Tax ID</h3>
                      <input
                        type="text"
                        placeholder="e.g. 00000000000"
                        name="pan_number"
                        onChange={handlechange}
                        defaultValue={formdata.pan_number}
                      />
                      {formdataerror.pan_number && (
                        <h6 className="text-red-500 text-xs font-semibold mt-2">
                          Please Enter PAN number / Tax ID
                        </h6>
                      )}
                    </div>
                    <div
                      className="aadhaar1 cursor-pointer"
                      onClick={() => {
                        handleFileSelectClick("pan_front");
                      }}
                    >
                      <h3>
                        PAN Card / Tax ID (copy){" "}
                        <Tooltip
                          title="incase Pan Card or Tax ID is unavailable, please upload a declaration stating the reason for not having the Tax ID"
                          style={{ color: "black" }}
                          arrow
                          placement="top"
                        >
                          <img
                            src={aadhaarimg}
                            alt=""
                            id="aadhar2"
                            onMouseLeave={hideMsg}
                            onMouseOver={(event) =>
                              displayMsg(event, "aadhar2")
                            }
                          />
                        </Tooltip>
                      </h3>
                      <div className="aadhaarFront">
                        <h3>
                          Drop your files here or{" "}
                          <span className="browser">browse</span>
                        </h3>
                        <p>Maximum size: 5MB</p>
                        <p title=""> PDF JPEG and PNG accepted</p>
                      </div>
                      <input
                        type="file"
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        name="pan_front"
                        accept=".pdf,.jpeg,.jpg,.png"
                        onChange={handleFileInputChange}
                      />
                      {formdataerror.pan_front && (
                        <h6 className="text-red-500 text-xs font-semibold mt-2">
                          Please Upload PAN Front / Tax Id
                        </h6>
                      )}
                      {fileuploadsuccess.pan_front && (
                        <h6 className="text-green-500 text-xs font-semibold mt-2">
                          PAN Card Front / Tax ID Proof Uploaded Successfully
                        </h6>
                      )}
                      {fileuploaderror.pan_front && (
                        <h6 className="text-red-500 text-xs font-semibold mt-2">
                          Image size exceeds 5 MB limit.
                        </h6>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="candidateRegistrationInner">
                <div className="passPortDetails">
                  <div className="documentTitle">
                    <h2>Passport Details</h2>
                    <h2>OPTIONAL</h2>
                  </div>
                  <div className="basicDetails">
                    <div className="CandidateDetails">
                      <div className="candidateInfo h-full">
                        <h3>Passport no.</h3>
                        <input
                          type="text"
                          placeholder="e.g. 000000000"
                          name="passport_no"
                          onChange={handlechange}
                          defaultValue={formdata.passport_no}
                        />
                        {formdataerror.passport_no && (
                          <h6 className="text-red-500 text-xs font-semibold mt-2">
                            Please Enter Passport no
                          </h6>
                        )}
                      </div>
                      <div className="candidateInfo h-full">
                        <h3>Valid until</h3>
                        <input
                          type="date"
                          placeholder="DD/MM/YYYY"
                          name="valid_until"
                          onChange={handlechange}
                          pattern="\d{4}-\d{2}-\d{2}"
                          defaultValue={formdata.valid_until}
                        />
                        {formdataerror.valid_until && (
                          <h6 className="text-red-500 text-xs font-semibold mt-2">
                            Please Enter Valid until
                          </h6>
                        )}
                      </div>

                      <div className="candidateInfo h-full">
                        <h3>Country of Citizenship</h3>

                        <div className="w-full">
                          <select
                            name="country_of_citizenship"
                            onChange={handlechange}
                            className="w-full"
                            defaultValue={formdata.country_of_citizenship}
                          >
                            <option value="">Country</option>
                            {country_and_states.country.length !== 0
                              ? country_and_states.country.map(
                                  (item, index) => (
                                    <option value={item.name} key={index}>
                                      {item.name}
                                    </option>
                                  )
                                )
                              : null}
                          </select>
                        </div>
                        {formdataerror.country_of_citizenship && (
                          <h6 className="text-red-500 text-xs font-semibold mt-2">
                            Please Enter Country of Citizenship
                          </h6>
                        )}
                      </div>
                      <div className="candidateInfo h-full">
                        <h3>Country of Issue</h3>

                        <div className="w-full">
                          {" "}
                          <select
                            name="country_of_issue"
                            className="w-full"
                            onChange={handlechange}
                            defaultValue={formdata.country_of_issue}
                          >
                            <option value="">Country</option>
                            {country_and_states.country.length !== 0
                              ? country_and_states.country.map(
                                  (item, index) => (
                                    <option value={item.name} key={index}>
                                      {item.name}
                                    </option>
                                  )
                                )
                              : null}
                          </select>
                        </div>
                        {formdataerror.country_of_issue && (
                          <h6 className="text-red-500 text-xs font-semibold mt-2">
                            Please Enter Country of Issue
                          </h6>
                        )}
                      </div>
                    </div>
                  </div>

                  <div className="documentDetails1">
                    <div className="aadhaarDetails">
                      <div
                        className="aadhaar1 cursor-pointer"
                        onClick={() => {
                          handleFileSelectClick("passport_front");
                        }}
                      >
                        <h3>
                          Passport Front{" "}
                          <Tooltip
                            title="Max Size 5MB"
                            style={{ color: "black" }}
                            arrow
                            placement="top"
                          >
                            <img
                              src={aadhaarimg}
                              alt=""
                              id="aadhar3"
                              onMouseLeave={hideMsg}
                              onMouseOver={(event) =>
                                displayMsg(event, "aadhar3")
                              }
                            />
                          </Tooltip>
                        </h3>
                        <div className="aadhaarFront">
                          <h3>
                            Drop your files here or{" "}
                            <span className="browser">browse</span>
                          </h3>
                          <p>Maximum size: 5MB</p>
                          <p title=""> PDF JPEG and PNG accepted</p>
                        </div>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          name="passport_front"
                          accept=".pdf,.jpeg,.jpg,.png"
                          onChange={handleFileInputChange}
                        />
                        {fileuploadsuccess.passport_front && (
                          <h6 className="text-green-500 text-xs font-semibold mt-2">
                            Passport Front Uploaded Successfully
                          </h6>
                        )}
                        {fileuploaderror.passport_front && (
                          <h6 className="text-red-500 text-xs font-semibold mt-2">
                            Image size exceeds 5 MB limit.
                          </h6>
                        )}
                      </div>

                      <div
                        className="aadhaar1 cursor-pointer"
                        onClick={() => {
                          handleFileSelectClick("passport_back");
                        }}
                      >
                        <h3>
                          Passport Back{" "}
                          <Tooltip
                            title="Max Size 5MB"
                            style={{ color: "black" }}
                            arrow
                            placement="top"
                          >
                            <img
                              src={aadhaarimg}
                              alt=""
                              id="aadhar4"
                              onMouseLeave={hideMsg}
                              onMouseOver={(event) =>
                                displayMsg(event, "aadhar4")
                              }
                            />
                          </Tooltip>
                        </h3>

                        <div className="aadhaarFront">
                          <h3>
                            Drop your files here or{" "}
                            <span className="browser">browse</span>
                          </h3>
                          <p>Maximum size: 5MB</p>
                          <p title=""> PDF JPEG and PNG accepted</p>
                        </div>
                        <input
                          type="file"
                          ref={fileInputRef}
                          style={{ display: "none" }}
                          name="passport_back"
                          accept=".pdf,.jpeg,.jpg,.png"
                          onChange={handleFileInputChange}
                        />
                        {fileuploadsuccess.passport_back && (
                          <h6 className="text-green-500 text-xs font-semibold mt-2">
                            Passport Back Uploaded Successfully
                          </h6>
                        )}
                        {fileuploaderror.passport_back && (
                          <h6 className="text-red-500 text-xs font-semibold mt-2">
                            Image size exceeds 5 MB limit.
                          </h6>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {finalerrorstatus && (
                  <p className="text-red-500 text-md font-semibold mt-2 capitalize">
                    {finalerrortype} : {finalerror}
                  </p>
                )}
                {final_error && (
                  <p className="text-red-500 text-md font-semibold mt-2 capitalize">
                    Please complete all the mandatory fields.
                  </p>
                )}
              </div>

              <div className="candidateBottom">
                {isLoading === false ? (
                  <button className="nextbtn" id="page2" onClick={pageHandler}>
                    Next
                  </button>
                ) : (
                  <button id="Signup" className="signUpCompBodyButtonLoading">
                    <FiLoader className="loadingIcon" />
                  </button>
                )}
              </div>

              <div className="candidateTermsAndConditions">
                <h6
                  onClick={() => {
                    window.open(
                      "https://app.termly.io/policy-viewer/policy.html?policyUUID=d24970fd-cbb1-45fd-990b-b6fbb3f82dbb",
                      "_blank"
                    );
                  }}
                >
                  Terms & Conditions
                </h6>
                <h6
                  onClick={() => {
                    window.open(
                      "https://app.termly.io/policy-viewer/policy.html?policyUUID=ff795be0-1ecd-4ef8-b9ac-6ee47dc8b29a",
                      "_blank"
                    );
                  }}
                >
                  Privacy Policy
                </h6>
              </div>
            </div>
          )}

          <button
            id="page1"
            onClick={backHandler}
            className={isPage === "page2" ? "backButton" : "backButtonNone"}
          >
            <img className="back" src={back} alt="" />
            Back
          </button>
          {isPage === "page2" && (
            <div className="candidateRegistration1" title="">
              <div className="candidateRegistrationInner">
                <div className="CandidateSkills">
                  <button className="candidateBtn1">
                    <FaCheck className="tickIcon" />
                  </button>
                  <p></p>
                  <button className="candidateBtn2">2</button>
                  <p></p>
                  <button className="candidateBtn3">3</button>
                </div>
                <button onClick={logout} className="logoutReg">
                  Log out
                </button>
                <div className="CandidateHead">
                  <h1>Share your Additional Details</h1>
                </div>
                <div className="basicDetails">
                  <div className="CandidateInformation">
                    <div className="candidateInfo h-full">
                      <h3>Primary Technical Skill</h3>
                      <input
                        type="text"
                        placeholder="e.g. Java Developer"
                        name="qualification"
                        onChange={handlechange}
                        defaultValue={formdata.qualification}
                      />
                      {formdataerror.qualification && (
                        <h6 className="text-red-500 text-xs font-semibold mt-2">
                          Please Enter Your Current Qualification
                        </h6>
                      )}
                    </div>

                    <div className="candidateInfo h-full">
                      <h3>Total years of Experience</h3>
                      <p>
                        <input
                          type="number"
                          placeholder="0-1"
                          name="experience"
                          onChange={handlechange}
                          defaultValue={formdata.experience}
                        />
                      </p>
                      {formdataerror.experience && (
                        <h6 className="text-red-500 text-xs font-semibold mt-2">
                          Please Enter Years of Experience
                        </h6>
                      )}
                    </div>
                  </div>
                  <div className="courseSearch">
                    <div className="search">
                      <h3>
                        List at least 5 Professional skills (highlight your top
                        3) skills.
                      </h3>
                      <div className="searchBar">
                        <CiSearch />
                        <input
                          type="text"
                          ref={inputref}
                          onChange={dropDownHandler}
                        />
                        <FaAngleDown onClick={filterdata1} />
                      </div>
                      {dropDown && (
                        <div className="searchContent1">
                          <div className="searchContent">
                            {dropDownOpen.map((option, index) => (
                              <h3
                                onClick={() => {
                                  getdata(option);
                                }}
                                key={index}
                              >
                                {option}
                              </h3>
                            ))}
                          </div>
                        </div>
                      )}
                    </div>
                  </div>

                  <Dragoption skill={skill} getdata={getdata} />
                  {formdataerror.skill && (
                    <h6 className="text-red-500 text-xs font-semibold mt-2">
                      Please Enter Skills
                    </h6>
                  )}
                  {formdataerror.skilllength && (
                    <h6 className="text-red-500 text-xs font-semibold mt-2">
                      Minimum 5 skills Required
                    </h6>
                  )}
                </div>
              </div>

              <div className="Accounts">
                <div className="candidateInfo h-full">
                  <div className="addressLine">
                    <h3>LinkedIn</h3>
                  </div>
                  <input
                    type="text"
                    placeholder="https://Linkedin.com/in/yourusername"
                    name="linkedin"
                    onChange={handlechange}
                    defaultValue={formdata.linkedin}
                  />
                  {formdataerror.linkedin && (
                    <h6 className="text-red-500 text-xs font-semibold mt-2">
                      Please Enter LinkedIn
                    </h6>
                  )}
                  {linkedinerror && (
                    <h6 className="text-red-500 text-xs font-semibold mt-2">
                      Invalid URL
                    </h6>
                  )}
                </div>
                <div className="candidateInfo h-full">
                  <div className="addressLine">
                    {displaymessages && (
                      <div className="warningmessage">
                        <h6>
                          In case you have not taken a Technical Assessment Test
                          but have undertaken another globally recognised test,
                          please mention the name of the test and the score
                        </h6>
                      </div>
                    )}

                    {displaymessages && (
                      <div className="warningmessage">
                        <h6>
                          In case you have not taken a Technical Assessment Test
                          but have undertaken another globally recognised test,
                          please mention the name of the test and the score
                        </h6>
                      </div>
                    )}
                    <h3>
                      Technical Assessment score
                      <PiWarningCircle
                        className="warningicon"
                        onMouseLeave={displaymsg1}
                        onMouseOver={displaymsg}
                      />
                    </h3>
                    <h3>Optional</h3>
                  </div>
                  <input
                    type="text"
                    placeholder="Your score"
                    name="hackerrank"
                    onChange={handlechange}
                    defaultValue={formdata.hackerrank}
                  />
                </div>
                <div className="candidateInfo h-full">
                  <div className="addressLine">
                    <h3>GitHub</h3>
                    <h3 className="option">Optional</h3>
                  </div>
                  <input
                    type="text"
                    placeholder="https://github.com/yourusername"
                    name="github"
                    onChange={handlechange}
                    defaultValue={formdata.github}
                  />
                  {formdataerror.github && (
                    <h6 className="text-red-500 text-xs font-semibold mt-2">
                      Please Enter GitHub
                    </h6>
                  )}
                  {githuberror && (
                    <h6 className="text-red-500 text-xs font-semibold mt-2">
                      Invalid URL
                    </h6>
                  )}
                </div>
                <div className="candidateInfo h-full">
                  <div className="addressLine">
                    <h3>Personal Website</h3>
                    <h3 className="option">Optional</h3>
                  </div>
                  <input
                    type="text"
                    placeholder="https://hirein5.com"
                    name="website"
                    onChange={handlechange}
                    defaultValue={formdata.website}
                  />
                  {formdataerror.website && (
                    <h6 className="text-red-500 text-xs font-semibold mt-2">
                      Please Enter Personal Website
                    </h6>
                  )}
                  {websiterror && (
                    <h6 className="text-red-500 text-xs font-semibold mt-2">
                      Invalid URL
                    </h6>
                  )}
                </div>
              </div>
              <div className="languages">
                {row.length !== 0
                  ? row.map((datanew, index) => (
                      <div className="addLanguages" key={index}>
                        <div className="addLanguageInner">
                          <h3>Languages</h3>
                          <input
                            type="text"
                            placeholder="e.g. Kannada"
                            name="language"
                            onChange={(e) => {
                              get_value(e.target.value, index);
                            }}
                            defaultValue={datanew.languages}
                          />
                        </div>
                        <div className="selectLanguages">
                          <h3>Proficiency</h3>

                          <div className="candidateState">
                            <select
                              defaultValue={datanew.level}
                              ref={inputref1}
                              onChange={(e) => {
                                filterdata(e.target.value, index);
                              }}
                            >
                              <option value="">Select</option>
                              <option value="Basic">Basic</option>
                              <option value="Conversational">
                                Conversational
                              </option>
                              <option value="Proficient">Proficient</option>
                              <option value="Native">Native</option>
                            </select>
                          </div>
                          {levelerror && (
                            <h6 className="text-red-500 text-xs font-semibold mt-2">
                              Please Enter Proficiency
                            </h6>
                          )}

                          {indexvalue == index
                            ? dropDown1 && (
                                <div className="Level">
                                  {dropDownOpen1.map((option, index1) => (
                                    <h3
                                      onClick={() => {
                                        filterdata(option, index);
                                      }}
                                      key={index1}
                                    >
                                      {option}
                                    </h3>
                                  ))}
                                </div>
                              )
                            : null}
                        </div>
                      </div>
                    ))
                  : null}

                {lanuageerror && (
                  <h6 className="text-red-500 text-xs font-semibold mt-2">
                    Please Enter Languages
                  </h6>
                )}

                <button className="addLanguagesButton" onClick={addcount}>
                  + Add more
                </button>
              </div>
              {final_error && (
                <p className="text-red-500 text-md font-semibold mt-2 capitalize text-start">
                  Please complete all the mandatory fields.
                </p>
              )}
              <div className="candidateBottom mt-5">
                {isLoading === false ? (
                  <button className="nextbtn" id="page3" onClick={pageHandler}>
                    Next
                  </button>
                ) : (
                  <button id="Signup" className="signUpCompBodyButtonLoading">
                    <FiLoader className="loadingIcon" />
                  </button>
                )}
              </div>

              <div className="candidateTermsAndConditions">
                <h6
                  onClick={() => {
                    window.open(
                      "https://app.termly.io/policy-viewer/policy.html?policyUUID=d24970fd-cbb1-45fd-990b-b6fbb3f82dbb",
                      "_blank"
                    );
                  }}
                >
                  Terms & Conditions
                </h6>
                <h6
                  onClick={() => {
                    window.open(
                      "https://app.termly.io/policy-viewer/policy.html?policyUUID=ff795be0-1ecd-4ef8-b9ac-6ee47dc8b29a",
                      "_blank"
                    );
                  }}
                >
                  Privacy Policy
                </h6>
              </div>
            </div>
          )}

          <button
            id="page2"
            onClick={backHandler}
            className={isPage === "page3" ? "backButton" : "backButtonNone"}
          >
            <img className="back" src={back} alt="" />
            Back
          </button>
          {isPage === "page3" && (
            <div className="candidateRegistration1" title="">
              <div className="candidateRegistrationInner">
                <div className="CandidateSkills">
                  <button className="candidateBtn1">
                    <FaCheck className="tickIcon" />
                  </button>
                  <p></p>
                  <button className="candidateBtn2">
                    <FaCheck className="tickIcon" />
                  </button>
                  <p></p>
                  <button className="candidateBtn3" title="">
                    3
                  </button>
                </div>
                <button onClick={logout} className="logoutReg">
                  Log out
                </button>
                <div className="CandidateHead">
                  <h1>Add more Travel History</h1>
                </div>
                <div className="basicDetails">
                  {travelrow.length !== 0
                    ? travelrow.map((data, index) => (
                        <div key={index}>
                          <div className="flexdiv">
                            <div></div>
                            {index !== 0 ? (
                              <button
                                className="deletebtn"
                                onClick={() => {
                                  deletetravelrow(index);
                                }}
                              >
                                <FaTrash className="trashicon" />
                              </button>
                            ) : null}
                          </div>
                          <div className="travelDetails">
                            <div className="candidateInfo h-full">
                              <h3>Countries travel to</h3>
                              <div className="candidateState">
                                <select
                                  id=""
                                  name="country"
                                  onChange={(e) => {
                                    handlechangetravel(
                                      e.target.value,
                                      index,
                                      "country"
                                    );
                                  }}
                                  defaultValue={data.country}
                                >
                                  <option value="">Country</option>
                                  {country_and_states.country.length !== 0
                                    ? country_and_states.country.map(
                                        (item, index) => (
                                          <option value={item.name} key={index}>
                                            {item.name}
                                          </option>
                                        )
                                      )
                                    : null}
                                </select>
                              </div>
                              {formdataerror.country && (
                                <h6 className="text-red-500 text-xs font-semibold mt-2">
                                  Please Select Country
                                </h6>
                              )}
                            </div>
                            <div className="candidateInfo h-full">
                              <h3>Year of travel</h3>
                              <input
                                type="text"
                                placeholder="YYYY"
                                onChange={(e) => {
                                  handlechangetravel(
                                    e.target.value,
                                    index,
                                    "year_of_travel"
                                  );
                                }}
                                defaultValue={data.year_of_travel}
                              />
                            </div>

                            <div className="candidateInfo h-full">
                              <h3>Duration (In Weeks)</h3>

                              <p>
                                <input
                                  type="number"
                                  placeholder=""
                                  onChange={(e) => {
                                    handlechangetravel(
                                      e.target.value,
                                      index,
                                      "duration"
                                    );
                                  }}
                                  defaultValue={data.duration}
                                />
                              </p>
                            </div>
                          </div>
                          <div className="travelInfo">
                            <div className="candidateInfo h-full">
                              <h3>Purpose</h3>
                              <p>
                                <input
                                  type="text"
                                  placeholder="Work"
                                  onChange={(e) => {
                                    handlechangetravel(
                                      e.target.value,
                                      index,
                                      "purpose"
                                    );
                                  }}
                                  defaultValue={data.purpose}
                                />
                              </p>
                            </div>
                            <div className="candidateInfo h-full">
                              <h3>Type of Visa</h3>
                              <input
                                type="text"
                                placeholder="H-1B"
                                onChange={(e) => {
                                  handlechangetravel(
                                    e.target.value,
                                    index,
                                    "type_of_visa"
                                  );
                                }}
                                defaultValue={data.type_of_visa}
                              />
                            </div>
                            <div className="candidateInfo h-full">
                              <h3>Is your Visa valid?</h3>
                              <div className="validityButton">
                                <button
                                  onClick={(e) => {
                                    changevalidity("No", index, "validity");
                                  }}
                                  className={
                                    data.validity == "No"
                                      ? "validityNo"
                                      : "validityNoDisable"
                                  }
                                >
                                  NO
                                </button>
                                <button
                                  onClick={(e) => {
                                    changevalidity("Yes", index, "validity");
                                  }}
                                  className={
                                    data.validity == "Yes"
                                      ? "validityYes"
                                      : "validityYesDisable"
                                  }
                                >
                                  Yes
                                </button>
                              </div>
                            </div>
                            {data.validity == "Yes" && (
                              <div className="candidateInfo h-full">
                                <h3>Validity of Visa</h3>
                                <input
                                  type="date"
                                  placeholder="DD/MM/YYYY"
                                  onChange={(e) => {
                                    handlechangetravel(
                                      e.target.value,
                                      index,
                                      "validity_of_visa"
                                    );
                                  }}
                                  defaultValue={data.validity_of_visa}
                                />
                              </div>
                            )}
                          </div>
                          <hr className="border border-gray-400 my-5" />
                        </div>
                      ))
                    : null}

                  <button className="travelInfoButton" onClick={addcounttravel}>
                    + Add more
                  </button>
                </div>
              </div>

              <div className="travelResidence">
                <h2>RESIDENCY DETAILS</h2>
                <div className="candidateInfo h-full">
                  <h3>Current country of residence</h3>
                  <select
                    name="current_place_of_residence"
                    defaultValue={travelform.current_place_of_residence}
                    onChange={handlechange_travel}
                  >
                    <option value="">Country</option>
                    {country_and_states.country.length !== 0
                      ? country_and_states.country.map((item, index) => (
                          <option value={item.name} key={index}>
                            {item.name}
                          </option>
                        ))
                      : null}
                  </select>
                </div>
                <div className="candidateInfo h-full">
                  <h3>No of months in current residence?</h3>
                  <p>
                    <input
                      type="number"
                      placeholder=""
                      name="lived_at_current_residence"
                      defaultValue={travelform.lived_at_current_residence}
                      onChange={handlechange_travel}
                    />
                  </p>
                </div>
              </div>

              <div className="travelResidence">
                <h2>
                  SELECT UPTO 3 COUNTRIES YOU ARE WILLING TO TRAVEL TO FOR WORK
                </h2>
                {travelwork.length !== 0
                  ? travelwork.map((data, index) => (
                      <>
                        <div className="candidateInfo h-full" key={index}>
                          <div className="flex justify-between">
                            <div className="infoHead">
                              <h3>Country</h3>
                            </div>
                            {index !== 0 ? (
                              <button
                                className="deletebtn"
                                onClick={() => {
                                  deletetravelwork(index);
                                }}
                              >
                                <FaTrash className="trashicon" />
                              </button>
                            ) : null}
                          </div>
                          <select
                            id=""
                            name="country"
                            onChange={(e) => {
                              handlechangework(
                                e.target.value,
                                index,
                                "country"
                              );
                            }}
                            defaultValue={data.country}
                          >
                            <option value="" disabled>
                              Select Country
                            </option>
                            <option
                              value="Japan"
                              disabled={selectedcountrylist.includes("Japan")}
                            >
                              Japan
                            </option>
                            <option
                              value="Dubai"
                              disabled={selectedcountrylist.includes("Dubai")}
                            >
                              Dubai
                            </option>
                            <option
                              value="Saudi Arabia"
                              disabled={selectedcountrylist.includes(
                                "Saudi Arabia"
                              )}
                            >
                              {" "}
                              Saudi Arabia
                            </option>
                            <option
                              value="Singapore"
                              disabled={selectedcountrylist.includes(
                                "Singapore"
                              )}
                            >
                              {" "}
                              Singapore
                            </option>
                            <option
                              value="Malaysia"
                              disabled={selectedcountrylist.includes(
                                "Malaysia"
                              )}
                            >
                              {" "}
                              Malaysia
                            </option>
                          </select>
                        </div>
                        <div className="candidateInfo h-full">
                          <h3>Travel for</h3>
                          <select
                            name="only_for"
                            onChange={(e) => {
                              handlechangework(
                                e.target.value,
                                index,
                                "only_for"
                              );
                            }}
                            defaultValue={data.only_for}
                            className="w-full"
                          >
                            <option value="" disabled>
                              Select
                            </option>
                            <option value="Work Onsite">Work Onsite</option>
                            <option value="Short-term business visit">
                              Short-term business visit
                            </option>
                          </select>
                        </div>
                        <div className="travelDuration">
                          <div className="candidateInfo h-full">
                            <h3>Duration</h3>
                            <select
                              id=""
                              name="duration"
                              onChange={(e) => {
                                handlechangework(
                                  e.target.value,
                                  index,
                                  "duration"
                                );
                              }}
                              defaultValue={data.duration}
                            >
                              <option value="" disabled>
                                Select duration
                              </option>
                              <option value="3-6 months">3-6 months</option>
                              <option value="6-12 months">6-12 months</option>
                              <option value=">12 months">
                                {" "}
                                {">"}12 months
                              </option>
                            </select>
                          </div>
                          <div className="candidateInfo h-full">
                            <h3>Readiness to Travel</h3>
                            <select
                              id=""
                              name="readlines"
                              onChange={(e) => {
                                handlechangework(
                                  e.target.value,
                                  index,
                                  "readlines"
                                );
                              }}
                              defaultValue={data.readlines}
                            >
                              <option value="" disabled>
                                Select Travel Readiness
                              </option>
                              <option value="Immediate">Immediate</option>
                              <option value="In the next 6 months">
                                In the next 6 months
                              </option>
                              <option value="6 months">6 months</option>
                            </select>
                          </div>
                        </div>
                      </>
                    ))
                  : null}
                {travelwork.length !== 3 ? (
                  <button className="travelBottomButton" onClick={addcountwork}>
                    + Add more
                  </button>
                ) : null}
              </div>

              <div className="Bottombtns">
                {isLoading === false ? (
                  <button className="nextbtn" id="page4" onClick={pageHandler}>
                    Next
                  </button>
                ) : (
                  <button id="Signup" className="signUpCompBodyButtonLoading">
                    <FiLoader className="loadingIcon" />
                  </button>
                )}
                <button onClick={skipbtn} id="page4" className="skipbtn">
                  Skip for now
                </button>
              </div>

              <div className="candidateTermsAndConditions">
                <h6
                  onClick={() => {
                    window.open(
                      "https://app.termly.io/policy-viewer/policy.html?policyUUID=d24970fd-cbb1-45fd-990b-b6fbb3f82dbb",
                      "_blank"
                    );
                  }}
                >
                  Terms & Conditions
                </h6>
                <h6
                  onClick={() => {
                    window.open(
                      "https://app.termly.io/policy-viewer/policy.html?policyUUID=ff795be0-1ecd-4ef8-b9ac-6ee47dc8b29a",
                      "_blank"
                    );
                  }}
                >
                  Privacy Policy
                </h6>
              </div>
            </div>
          )}

          {isPage === "page4" && (
            <div className="h-[100vh] flex flex-col items-center justify-center">
              <SuccessResponse
                title="Welcome aboard!"
                des="You are One Step Closer to getting hired in 5. Complete your profile to maximize your chances of landing exciting opportunities!"
              />
              {pageloading == true ? (
                <button id="Signup" className="save mt-5 mb-5">
                  <FiLoader className="loadingIcon" />
                </button>
              ) : (
                <button
                  onClick={() => {
                    continuebtn();
                  }}
                  className="save mt-5 mb-5"
                >
                  Continue
                </button>
              )}

              <h6 className="paymentTimer text-center mt-3">
                Redirecting you to the next screen in{" "}
                <span className="emailDarks">{formattedTime}s</span>
              </h6>
            </div>
          )}
        </div>
      </div>
      {isPopUp == "ResumeParsing" && (
        <>
          <div className="approveCandidateOverlay">
            <div className="candidateRateCardOverlayHead">
              <h1>Upload the Resume for Parsing</h1>
            </div>
            <div className="approveCandidateOverlayBody">
              <div className="px-5 py-5">
                <input
                  type="file"
                  accept=".pdf,.doc,.docx,"
                  onChange={handleFileUpload}
                />
              </div>
              {uploadedstatus && (
                <h6 className="text-green-500 px-5 font-sm font-medium py-2">
                  File uploaded successfully.
                </h6>
              )}
              {uploadedstatus && (
                <div className="approveCandidateOverlayButton">
                  {loading ? (
                    <button className="save">Please wait...</button>
                  ) : (
                    <button className="save" onClick={SubmitBtn}>
                      Send for Resume Parsing
                    </button>
                  )}
                </div>
              )}
            </div>
          </div>
        </>
      )}

      {isPopUp == "contactus" && (
        <div className="contactOverlay">
          <Popup
            imgClass="displayNone"
            PopupCompSuccess="PopupCompSuccess"
            Head="Session Expired"
            desc="Your Session has been Expired Please Login to continue."
            InputContainer="displayNone"
            PopupSingleButton="PopupSingleButton"
            PopupSingleText="Login"
            SingleBtnfun={logout}
            PopupDoubleButton="displayNone"
            DoubleBtn1="Cancel"
            DoubleBtn2="Submit"
            closeIcon="popupClose pointer"
            waricon="waricon"
          />
        </div>
      )}
      {isPopUp == "Timepopup" && (
        <div className="contactOverlay">
          <Popup
            imgClass="displayNone"
            PopupCompSuccess="PopupCompSuccess"
            Head="Attention"
            desc="Kindly complete the registration steps within 45 minutes to avoid getting logged out by the system. Thank you for your prompt attention."
            InputContainer="displayNone"
            PopupSingleButton="PopupSingleButton"
            PopupSingleText="Okay"
            SingleBtnfun={closepopup}
            PopupDoubleButton="displayNone"
            DoubleBtn1="Cancel"
            DoubleBtn2="Submit"
            closeIcon="popupClose pointer"
            waricon="waricon"
          />
        </div>
      )}
    </>
  );
};

export default CandidateRegistration;
