import React from "react";
import "./faq.css";

const Faqsection = ({ question, answer, answer2 }) => {
  return (
    <div className="fullsection">
      <h1 className="faqheading">{question}</h1>
      <div
        dangerouslySetInnerHTML={{ __html: answer }}
        className="faqsection"
      />
      {answer2.length !== 0 ? (
        <h6 className="faqsection marginTop20">{answer2}</h6>
      ) : null}
    </div>
  );
};

export default Faqsection;
