import React from "react";
import "./Foot.css";

const Foot = () => {
  return (
    <div>
      <div className="foot">
        <h4>By creating an account, you accept</h4>
        <div className="footDesc">
          <p
            onClick={() => {
              window.open(
                "https://app.termly.io/policy-viewer/policy.html?policyUUID=d24970fd-cbb1-45fd-990b-b6fbb3f82dbb",
                "_blank"
              );
            }}
          >
            Terms & Conditions
          </p>
          <h6>and</h6>
          <p
            onClick={() => {
              window.open(
                "https://app.termly.io/policy-viewer/policy.html?policyUUID=ff795be0-1ecd-4ef8-b9ac-6ee47dc8b29a",
                "_blank"
              );
            }}
          >
            Privacy Policy
          </p>
        </div>
      </div>
    </div>
  );
};

export default Foot;
