/* eslint-disable eqeqeq */
/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import "./ShareComp.css";
import logo from "../../assests/Logo.svg";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../Store/Store";
import { PiEyeglasses } from "react-icons/pi";
import SuccessResponse from "../Reusable/SuccessResponse/SuccessResponse";
import { IoIosArrowDown } from "react-icons/io";
import { IoIosArrowUp } from "react-icons/io";
import { CiMail } from "react-icons/ci";

const ShareComp = () => {
  const dispatch = useDispatch();
  const [page, setPage] = useState("page1");

  const [dropDown, setDropDown] = useState("page1");
  const dropDownHandler = (e) => {
    setDropDown(e);
  };
  const [dropDown1, setDropDown1] = useState("");
  const dropDownHandler1 = (e) => {
    setDropDown1(e);
  };
  const [dropDown2, setDropDown2] = useState("page1");
  const dropDownHandler2 = (e) => {
    setDropDown2(e);
  };

  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });

  const overlayHandler = (e) => {
    dispatch(storeAction.isPopUpHander(e.target.id));
  };
  return (
    <div>
      <div className="shareTopHead">
        <img src={logo} alt="" />
        <div className="shareTopHeadButton">
          <h5>Log in</h5>
          <button>Sign up</button>
        </div>
      </div>
      {page == "page1" && (
        <>
          <button id="evaluation" onClick={overlayHandler}>
            candidate
          </button>
        </>
      )}
      {isPopUp == "evaluation" && (
        <>
          <div
            className={
              dropDown == "page2"
                ? "candidateEvaluationActive overlay"
                : "candidateEvaluation overlay"
            }
          >
            <div className="candidateEvaluationHead">
              <PiEyeglasses className="candidateEvaluationHeadIcon" />
              <h1>Candidate Evaluation</h1>
            </div>
            <div
              className={
                dropDown == "page2"
                  ? "candidateEvaluationBodyActive"
                  : "candidateEvaluationBody"
              }
            >
              <div className="candidateEvaluationContent">
                <input type="radio" />
                <h2>Hire candidate (No interview needed)</h2>
              </div>
              <div className="candidateEvaluationContent">
                <input type="radio" />
                <h2>Accept candidate (Schedule interview via HR)</h2>
              </div>
              <div className="candidateEvaluationContent">
                <input type="radio" />
                <h2>Schedule interview directly with candidate</h2>
              </div>
              <div>
                <div
                  className={
                    dropDown == "page2"
                      ? "candidateEvaluationContentActive"
                      : "candidateEvaluationContent"
                  }
                >
                  <input
                    onClick={() => dropDownHandler("page2")}
                    type="radio"
                  />
                  <h2>Reject candidate</h2>
                </div>
                {dropDown == "page2" && (
                  <>
                    <div className="candidateEvaluationContentDrop">
                      <h3>Reason for Rejection</h3>
                      <input
                        onClick={() => dropDownHandler1("SubDrop")}
                        placeholder="Select Reason"
                        type="text"
                      />
                      {dropDown1 == "SubDrop" && (
                        <>
                          <div className="candidateEvaluationSubContentDrop">
                            <div className="">
                              <div
                                onClick={() => dropDownHandler2("page3")}
                                className="flex justify-between items-center mb-2"
                              >
                                <h4>Skill set does not match</h4>
                                {dropDown2 == "page3" ? (
                                  <IoIosArrowUp />
                                ) : (
                                  <IoIosArrowDown />
                                )}
                              </div>
                              {dropDown2 == "page3" && (
                                <>
                                  <div className="">
                                    <h5 className="lightText">
                                      Assess the candidate's skill set on a
                                      scale from 1 (lowest) to 10 (highest).
                                    </h5>
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="">
                              <div
                                onClick={() => dropDownHandler2("page4")}
                                className="flex justify-between items-center mb-2"
                              >
                                <h4>Prefer onsite placement </h4>
                                {dropDown2 == "page4" ? (
                                  <IoIosArrowUp />
                                ) : (
                                  <IoIosArrowDown />
                                )}
                              </div>
                              {dropDown2 == "page4" && <></>}
                            </div>
                            <div className="">
                              <div
                                onClick={() => dropDownHandler2("page5")}
                                className="flex justify-between items-center mb-2"
                              >
                                <h4>Not yet ready to hire</h4>
                                {dropDown2 == "page5" ? (
                                  <IoIosArrowUp />
                                ) : (
                                  <IoIosArrowDown />
                                )}
                              </div>{" "}
                              {dropDown2 == "page5" && (
                                <>
                                  <div className="preferContent">
                                    <textarea
                                      id="w3review"
                                      name="w3review"
                                      rows="4"
                                      cols="50"
                                    ></textarea>
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="">
                              <div
                                onClick={() => dropDownHandler2("page6")}
                                className="flex justify-between items-center mb-2"
                              >
                                <h4>Pass through to next interviewer</h4>
                                {dropDown2 == "page6" ? (
                                  <IoIosArrowUp />
                                ) : (
                                  <IoIosArrowDown />
                                )}
                              </div>{" "}
                              {dropDown2 == "page6" && (
                                <>
                                  <div className="">
                                    <h5 className="lightText">
                                      Transfer candidate for further evaluation
                                    </h5>
                                  </div>
                                </>
                              )}
                            </div>
                            <div className="">
                              <div
                                onClick={() => dropDownHandler2("page7")}
                                className="flex justify-between items-center mb-2"
                              >
                                <h4>Leave a comment</h4>
                                {dropDown2 == "page7" ? (
                                  <IoIosArrowUp />
                                ) : (
                                  <IoIosArrowDown />
                                )}
                              </div>{" "}
                              {dropDown2 == "page7" && (
                                <>
                                  <div className="preferContent">
                                    <textarea
                                      id="w3review"
                                      name="w3review"
                                      rows="4"
                                      cols="50"
                                    ></textarea>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="candidateEvaluationButton">
              <button className="candidateEvaluationButtonClose">Close</button>
              <button
                id="hiringprocessinitiated"
                onClick={overlayHandler}
                className="candidateEvaluationButtonConfirm"
              >
                Confirm
              </button>
            </div>
          </div>
        </>
      )}
      {isPopUp == "hiringprocessinitiated" && (
        <>
          <div className="evaluationSuccessPopup overlay">
            <SuccessResponse
              title="Hiring Process Initiated"
              des="Notification has been sent to the hirer to complete the hiring process with Hirein5."
            />
            <button className="candidateEvaluationButtonConfirm">
              Continue
            </button>
          </div>
        </>
      )}
    </div>
  );
};

export default ShareComp;
