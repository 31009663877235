/* eslint-disable react/jsx-pascal-case */
import React from "react";
import Bulke_mail from "../Components/AdminScreen/Bulkemail/Bulkemail";

const Bulkemail = () => {
  return (
    <div>
      <Bulke_mail />
    </div>
  );
};

export default Bulkemail;
