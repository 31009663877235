/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaRegCircleXmark } from "react-icons/fa6";
import { FiPlusCircle } from "react-icons/fi";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

const FinalApproval = () => {
  const token = useSelector((store) => store.token);
  const [teamlist, setteamlist] = useState([
    {
      id: uuidv4(),
      first_name: "",
      last_name: "",
      video_resume: "",
      personal_details: "",
      tech_assessment_report: "",
      tech_assessment_interview_link: "",
      bgv: "",
      work_experience: "",
      projects: "",
      education: "",
      previous_salaries: "",
      certifications: "",
      availability_tab_approved: "",
      compensation_offered: "",
      model_of_engagement: "",
      rate_card_approval: "",
      comments: "",
      approved_by: "",
      date_approval_completed: "",
      date_profile_published: "",
      isNew: true,
    },
  ]);
  const [loading, setloading] = useState(false);
  const [count, setcount] = useState(0);
  const [pagination_status, setpagination_status] = useState(false);
  const [newurl, setnewurl] = useState(null);
  const [candidatenext, setcandidatenext] = useState(null);
  const [candidateprevious, setcandidateprevious] = useState(null);

  const handleChangeNew = (index, field, value) => {
    const updatedTeams = teamlist.map((candidate, cIndex) => {
      if (cIndex === index) {
        return {
          ...candidate,
          [field]: value,
        };
      }
      return candidate;
    });
    setteamlist(updatedTeams);
  };

  const addCandidate = () => {
    const newCandidate = {
      id: uuidv4(),
      first_name: "",
      last_name: "",
      video_resume: "",
      personal_details: "",
      tech_assessment_report: "",
      tech_assessment_interview_link: "",
      bgv: "",
      work_experience: "",
      projects: "",
      education: "",
      previous_salaries: "",
      certifications: "",
      availability_tab_approved: "",
      compensation_offered: "",
      model_of_engagement: "",
      rate_card_approval: "",
      comments: "",
      approved_by: "",
      date_approval_completed: "",
      date_profile_published: "",
      isNew: true,
    };

    setteamlist([...teamlist, newCandidate]);
  };

  const removeCandidate = (candidateId) => {
    const updatedTeamlist = teamlist.filter(
      (candidate) => candidate.id !== candidateId
    );
    setteamlist(updatedTeamlist);
  };

  const submitbtn = async () => {
    if (teamlist.length !== 0) {
      setloading(true);
      for (let i = 0; i < teamlist.length; i++) {
        const candidate = teamlist[i];
        const newCandidateData = {
          first_name: candidate.first_name,
          last_name: candidate.last_name,
          video_resume: candidate.video_resume,
          personal_details: candidate.personal_details,
          tech_assessment_report: candidate.tech_assessment_report,
          tech_assessment_interview_link:
            candidate.tech_assessment_interview_link,
          bgv: candidate.bgv,
          work_experience: candidate.work_experience,
          projects: candidate.projects,
          education: candidate.education,
          previous_salaries: candidate.previous_salaries,
          certifications: candidate.certifications,
          availability_tab_approved: candidate.availability_tab_approved,
          compensation_offered: candidate.compensation_offered,
          model_of_engagement: candidate.model_of_engagement,
          rate_card_approval: candidate.rate_card_approval,
          comments: candidate.comments,
          approved_by: candidate.approved_by,
          date_approval_completed: candidate.date_approval_completed,
          date_profile_published: candidate.date_profile_published,
        };
        if (candidate.isNew) {
          await axios
            .post(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/final-approval/`,
              newCandidateData,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .then((res) => {
              candidate.isNew = false;
            })
            .catch((err) => console.error(err));
        } else {
          await axios
            .put(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/final-approval/${candidate.id}/`,
              newCandidateData,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .catch((err) => console.error(err));
        }
      }
      setloading(false);
    }
  };

  useEffect(() => {
    Getalldata();
  }, []);

  const Getalldata = async () => {
    const Alldata = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/final-approval/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response);

    if (Alldata.results.length !== 0) {
      setcount(Alldata.count);
      if (Alldata.count > 20) {
        setpagination_status(true);
      } else {
        setpagination_status(false);
      }
      setcandidateprevious(Alldata.previous);
      setcandidatenext(Alldata.next);
      const updatedData = Alldata.results.map((candidate) => ({
        ...candidate,
        isNew: false,
      }));
      setteamlist(updatedData);
    }
  };
  const nPages = Math.ceil(count / 20);
  const pageNumbers = Array.from({ length: nPages }, (_, i) => i + 1);
  return (
    <div>
      <table>
        <thead>
          <tr className="hireTableHead">
            <th>Action</th>
            <th>First & Middle Name</th>
            <th>Last Name</th>
            <th>Video Resume</th>
            <th>Personal Details</th>
            <th>Tech Assessment Report</th>
            <th>Tech Assessment Interview Link</th>
            <th>BGV</th>
            <th>Work Exp</th>
            <th>Projects</th>
            <th>Education</th>
            <th>Previous salaries</th>
            <th>Certifications</th>
            <th>Availability Tab Approved</th>
            <th>Compensation Offered</th>
            <th>Model of engagement</th>
            <th>Rate Card Approval</th>
            <th>Comments</th>
            <th>Approved by</th>
            <th>Date Approval Completed</th>
            <th>Date Profile Published on portal</th>
          </tr>
        </thead>
        <tbody className="hireTableBody">
          {teamlist.length !== 0 &&
            teamlist.map((role, roleIndex) => (
              <tr className="hireTablerow" key={roleIndex}>
                <td>
                  <div className="flex items-center justify-center">
                    <FaRegCircleXmark
                      className="xIcon"
                      onClick={() => removeCandidate(0, role.id)}
                    />
                    <FiPlusCircle
                      className="plusIcon"
                      onClick={() => addCandidate(0)}
                    />
                  </div>
                </td>

                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.first_name}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "first_name", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.last_name}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "last_name", e.target.value)
                      }
                    />
                  </h3>
                </td>

                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.video_resume}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "video_resume",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.personal_details}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "personal_details",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.tech_assessment_report}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "tech_assessment_report",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.tech_assessment_interview_link}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "tech_assessment_interview_link",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.bgv}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "bgv", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.work_experience}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "work_experience",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.projects}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "projects", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.education}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "education", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.previous_salaries}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "previous_salaries",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.certifications}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "certifications",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.availability_tab_approved}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "availability_tab_approved",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.compensation_offered}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "compensation_offered",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <select
                      className="hireTableBodySelect"
                      value={role.model_of_engagement}
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "model_of_engagement",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Part Time Consultant">
                        Part Time Consultant
                      </option>
                      <option value="Full Time Consultant">
                        Full Time Consultant
                      </option>
                      <option value="Employee">Employee</option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.rate_card_approval}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "rate_card_approval",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.comments}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "comments", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.approved_by}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "approved_by",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.date_approval_completed}
                      type="date"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "date_approval_completed",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.date_profile_published}
                      type="date"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "date_profile_published",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {loading ? (
        <button className="saveSubmit">Please Wait...</button>
      ) : (
        <button className="saveSubmit" onClick={submitbtn}>
          Save Changes
        </button>
      )}
      {pagination_status && (
        <div className="tablePagination">
          <nav>
            <ul className="pagination">
              {candidateprevious == null ? (
                <li className="page-item disabled">
                  <h6 className="page-link">&lt;&lt; Previous</h6>
                </li>
              ) : (
                <li
                  className="page-item active"
                  onClick={() => {
                    setnewurl(candidateprevious);
                    document.getElementById("seachinput").value = "";
                  }}
                >
                  <h6 className="page-link">&lt;&lt; Previous</h6>
                </li>
              )}

              {newurl == null ? (
                <h6 className="page-link">1</h6>
              ) : (
                <h6 className="page-link active">
                  {newurl.includes("/final-approval/") &&
                  newurl.split("/final-approval/")[1]?.split("?page=")[1] !==
                    undefined
                    ? newurl.split("/final-approval/")[1].split("?page=")[1]
                    : 1}
                </h6>
              )}
              {candidatenext == null ? (
                <li className="page-item disabled">
                  <h6 className="page-link">Next &gt;&gt;</h6>
                </li>
              ) : (
                <li
                  className="page-item active"
                  onClick={() => {
                    setnewurl(candidatenext);
                    document.getElementById("seachinput").value = "";
                  }}
                >
                  <h6 className="page-link">Next &gt;&gt;</h6>
                </li>
              )}
            </ul>
          </nav>
          <p className="text-center mt-4 font-medium text-xs text-[#71717a]">
            Total No Of Pages : {pageNumbers.length}
          </p>
        </div>
      )}
    </div>
  );
};

export default FinalApproval;
