import React from "react";
import DashBody from "../Components/Reusable/DashBoardReusable/DashBody/DashBody";
import setting from "../assests/setting.png";
import logo from "../assests/Logo.svg";
const UnderConstruction = () => {
  const goback = () => {
    window.location.replace("https://hirein5.com");
  };
  return (
    <div className="underConstruction">
      <img className="underConstruction_logo" src={logo} alt="logo" />
      <div>
        {/* <DashBody
          Img={setting}
          head="We will be back"
          desc="Some great work underway! Application will be back at 7 PM."
          button="Back to website !"
          fun={goback}
        /> */}
        <DashBody
          Img={setting}
          head="We will be back"
          desc="Important Updates Underway. Come back in 15 minutes. Regret any inconvenience!"
          button="Back to website !"
          fun={goback}
        />
      </div>
    </div>
  );
};

export default UnderConstruction;
