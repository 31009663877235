/* eslint-disable eqeqeq */
/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import "./DashHead.css";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { storeAction } from "../../../../Store/Store";
import Popup from "../../PopUp/Popup";

const DashHead = (props, fun) => {
  const dispatch = useDispatch();
  const userid = useSelector((store) => store.userid);
  const token = useSelector((store) => store.token);
  useEffect(() => {
    setTimeout(() => {
      getalldata();
    }, 1000);
  }, []);

  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });
  const overLayHandler = (e) => {
    dispatch(storeAction.isPopUpHander(e.target.id));
  };
  const getalldata = () => {
    if (token == null && userid == null) {
      dispatch(storeAction.reset());
      localStorage.removeItem("persist:root");
      localStorage.clear();
      sessionStorage.clear();
      window.location.replace("/#/login");
    }
  };
  return (
    <div>
      <div className="dashBoardMainHead">
        <div className={props.UpgradeTop}>
          <h3 id={props.billingId} onClick={props.fun2}>
            {props.left}
          </h3>{" "}
          <h5>{props.center}</h5>
          <h4 id={props.upgradeId} onClick={props.fun3}>
            {props.right}
          </h4>
        </div>
        <h1>{props.head}</h1>
        <div className={props.descClass}>
          <p>{props.desc}</p>
          {props.highLight == "View here" ? (
            <h6
              href=""
              id="contactus1"
              className="cursor-pointer"
              onClick={() => {
                window.open(
                  "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/Credit%20Usage%20Guide_Hirein5_ver1.0.pdf?alt=media&token=95218900-78eb-477e-9269-2338d6ccd93f"
                );
              }}
            >
              {props.highLight}
            </h6>
          ) : (
            <h6
              href=""
              id="contactus1"
              className="cursor-pointer"
              onClick={overLayHandler}
            >
              {props.highLight}
            </h6>
          )}

          <div className="flex gap-2">
            <button onClick={props.fun1} className={props.btnClass1}>
              {props.button1}
            </button>
            {props.button === "Preview" ? (
              <button
                onClick={() => {
                  window.location.replace("/#/preview");
                }}
                className={props.btnClass}
              >
                <img src={props.btnImg} alt="" />
                {props.button}
              </button>
            ) : props.button === "Upload Resume" ? (
              <button
                onClick={() => {
                  window.location.replace("/#/vendoruploadresume");
                }}
                className={props.btnClass}
              >
                <img src={props.btnImg} alt="" />
                {props.button}
              </button>
            ) : (
              <button onClick={props.fun} className={props.btnClass}>
                <img src={props.btnImg} alt="" />
                {props.button}
              </button>
            )}
          </div>
        </div>
      </div>
      {isPopUp == "contactus1" && (
        <div className="contactOverlay">
          <Popup
            imgClass="displayNone"
            PopupCompSuccess="PopupCompSuccess"
            Head="Contact Us for Assistance"
            desc="Our Sales team will reach you shortly"
            InputContainer="inputContainer"
            PopupSingleButton="displayNone"
            PopupDoubleButton="PopupDoubleButton"
            DoubleBtn1="Cancel"
            DoubleBtn2="Submit"
            waricon="displayNone"
            closeIcon="popupClose pointer"
          />
        </div>
      )}
    </div>
  );
};

export default DashHead;
