/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable eqeqeq */
import React, { useEffect, useState } from "react";
import "./WalletModule.css";
import DashHead from "../../../Reusable/DashBoardReusable/DashHead/DashHead";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../../../Store/Store";
import back from "../../../../assests/billingX.png";
import { RxCross2 } from "react-icons/rx";
import { CiWarning } from "react-icons/ci";
import axios from "axios";
import Pagination from "../../../AdminScreen/AdminProfile/AdminCandidateProfile/Pagination";
import Avatar from "react-avatar";
import RazorpayImg from "../../../../assests/razorpay.png";
import PayPalImg from "../../../../assests/paypal.png";
import Paypal from "./Paypal";

const WalletModule = () => {
  const dispatch = useDispatch();

  const token = useSelector((store) => store.token);
  const userdata = useSelector((store) => store.userdata);
  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });

  const walletHandler = (e) => {
    dispatch(storeAction.isPopUpHander(e));
  };

  const CloseOverlay = () => {
    dispatch(storeAction.isPopUpHander());
  };

  const [amount, setamount] = useState(0);
  const [loading, setloading] = useState(false);
  const [historydata, sethistorydata] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(20);
  const [paymentType, setpaymentType] = useState(false);
  const [paymentmethod, setpaymentmethod] = useState("");

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  const AddCreditBtn = async () => {
    if (amount !== 0) {
      setloading(true);
      const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
      );
      if (!res) {
        alert("Razorpay SDK failed to load. Are you online?");
        return;
      }
      let data = JSON.stringify({
        amount: amount,
      });
      let config = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${process.env.REACT_APP_LOCAL_HOST_URL}/payment/`,
        headers: {
          Authorization: `JWT ${token}`,
          "Content-Type": "application/json",
        },
        data: data,
      };

      var result = await axios
        .request(config)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          return error;
        });
      const orderData = result;
      if (!orderData) {
        alert("Server error. Are you online?");
        return;
      }
      const { id: order_id, currency } = orderData;
      const options = {
        key: "rzp_test_T7iKIDLDxPnTss",
        amount: amount * 100,
        currency: currency,
        name: "HireIn5",
        description: "Payment for Your Service",
        image:
          "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/Group%20(1).png?alt=media&token=c4590405-1567-49d9-8539-eb21f809d889",
        order_id: order_id,
        handler: async function (response) {
          setloading(false);
          var newamount = Number(amount) / 10;
          var obj = {
            client_id: userdata[0].id,
            opening_wallet:
              userdata[0].wallet.length == 0 ? 0 : userdata[0].wallet,
            closing_wallet:
              userdata[0].wallet.length == 0
                ? 0 + Number(newamount)
                : Number(userdata[0].wallet) + Number(newamount),
            activity: "Credit",
            wallet_detection: newamount,
            trasaction_id: response.razorpay_payment_id,
          };
          var newobj = {
            wallet:
              userdata[0].wallet.length == 0
                ? 0
                : Number(userdata[0].wallet) + Number(newamount),
            username: userdata[0].username,
          };
          await axios
            .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/wallet/`, obj, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            })
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              return err.response;
            });
          var updatedata = await axios
            .put(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userdata[0].id}/`,
              newobj,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .then((res) => {
              return res.data;
            })
            .catch((err) => {
              return err.response;
            });
          if (
            updatedata.message ===
            "User and Associated Info updated successfully"
          ) {
            let updatedObject = {
              ...userdata[0],
              wallet:
                userdata[0].wallet.length == 0
                  ? 0
                  : Number(userdata[0].wallet) + Number(newamount),
            };
            dispatch(storeAction.userdataHander({ userdata: [] }));
            dispatch(storeAction.isPopUpHander());
            setpaymentType(false);
            window.location.reload();
            setpaymentmethod("");
            setamount(0);
            setTimeout(() => {
              dispatch(
                storeAction.userdataHander({ userdata: [updatedObject] })
              );
            }, 10);
          }
        },

        theme: {
          color: "#61dafb",
        },
      };
      const paymentObject = new window.Razorpay(options);
      paymentObject.open();
    } else {
      alert("Please enter amount");
    }
  };

  const AddCreditBtnPaypal = () => {
    dispatch(storeAction.isPopUpHander("PaypalTrigger"));
  };

  useEffect(() => {
    Getalldata();
    // dispatch(storeAction.isPopUpHander("FirstPopup"));
  }, []);

  const Getalldata = async () => {
    setloading(true);
    var getwallerhistory = await axios
      .get(
        `${process.env.REACT_APP_LOCAL_HOST_URL}/wallet/${userdata[0].id}/`,

        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        }
      )
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });
    if (getwallerhistory.data.length !== 0) {
      setloading(false);
      const sortedCandidates = [...getwallerhistory.data].sort((a, b) => {
        return new Date(b.created_at) - new Date(a.created_at);
      });
      sethistorydata(sortedCandidates);
    } else {
      setloading(false);
    }
  };

  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = historydata.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(historydata.length / recordsPerPage);
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);
  return (
    <div>
      <div className="dashBoardMain paddingLeft50 paddingRight50">
        <DashHead
          head="Wallet"
          desc="Credit Usage Guidelines for Hirein5 Web Application"
          highLight="View here"
          descClass="dashBoardMainHeadDescBetween"
        />
        <div className="walletBalance">
          <div className="addMoney">
            <h2 onClick={() => walletHandler("addMoney")}>Add Credit +</h2>
          </div>
          <div className="">
            <div className="Balance">
              <span className="dollar">$</span>{" "}
              <h3>
                {userdata.length !== 0
                  ? userdata[0].wallet.length == 0
                    ? 0
                    : userdata[0].wallet
                  : 0}
              </h3>
            </div>
            {userdata.length !== 0 ? (
              userdata[0].wallet.length == 0 ? (
                <h4
                  className="lowBalance"
                  onClick={() => walletHandler("lowBalance")}
                >
                  Low Balance
                </h4>
              ) : (
                userdata[0].wallet < 150 && (
                  <h4
                    className="lowBalance"
                    onClick={() => walletHandler("lowBalance")}
                  >
                    Low Balance
                  </h4>
                )
              )
            ) : (
              <h4
                className="lowBalance"
                onClick={() => walletHandler("lowBalance")}
              >
                Low Balance
              </h4>
            )}
          </div>
        </div>
        <div className="AdminClientProfileCompTable">
          <table className="AdminClientTable">
            <thead>
              <tr className="AdminTableHead">
                <th>RECIPIENT</th>
                <th>AMOUNT</th>
                <th>SERVICE TYPE</th>
                <th>TRANSACTION ID</th>
                <th>STATUS</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {loading == false ? (
                currentRecords.length !== 0 ? (
                  currentRecords.map((data, index) => (
                    <tr className="adminTableRow" key={index}>
                      <td>
                        <div className="tableName">
                          {userdata.length !== 0 ? (
                            userdata[0].profile_picture.length !== 0 ? (
                              <img
                                src={userdata[0].profile_picture}
                                alt=""
                                className="profilepic"
                              />
                            ) : (
                              <Avatar
                                name={userdata[0].first_name}
                                size={40}
                                round="50px"
                              />
                            )
                          ) : (
                            <Avatar
                              name={
                                userdata[0].company.company_name.length !== 0
                                  ? userdata[0].company.company_name
                                  : userdata[0].first_name
                              }
                              size={30}
                              round="50px"
                            />
                          )}
                          {userdata[0].company !== null ? (
                            <h1>{userdata[0].company.company_name}</h1>
                          ) : (
                            <h1>{userdata[0].first_name}</h1>
                          )}
                        </div>
                      </td>
                      <td>
                        $ {""}
                        {data.amount == null
                          ? data.closing_wallet - data.opening_wallet
                          : data.amount}
                      </td>
                      <td>
                        {data.activity == "Credit"
                          ? "Add Credit"
                          : data.activity}
                      </td>
                      <td>{data.trasaction_id}</td>
                      <td>
                        <p className="status Active">Success</p>
                      </td>

                      <td>
                        <button
                          // onClick={() => viewbtn(data)}
                          className="viewButton"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <h6 className="text-center py-8">No data found...</h6>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                )
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <h6 className="text-center py-8">Please wait...</h6>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {pageNumbers.length !== 0 ? (
          <div className="tablePagination">
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        ) : null}
      </div>
      {isPopUp == "addMoney" && (
        <>
          <div className="approveCandidateOverlay">
            <div className="candidateRateCardOverlayHead">
              <h1>Add Credit to your Wallet</h1>
              {paymentType == false ? (
                <span className="cursor" onClick={CloseOverlay}>
                  <RxCross2 />
                </span>
              ) : (
                <span
                  className="cursor"
                  onClick={() => {
                    setpaymentType(false);
                    setpaymentmethod("");
                  }}
                >
                  <RxCross2 />
                </span>
              )}
            </div>
            {paymentType == true ? (
              <>
                <div className="flex gap-4 py-5 justify-center">
                  <div
                    className={
                      paymentmethod == "razorpay"
                        ? "addMoneyNew1"
                        : "addMoneyNew"
                    }
                    onClick={() => {
                      setpaymentmethod("razorpay");
                    }}
                  >
                    <img src={RazorpayImg} alt="" className="h-[50px]" />
                  </div>
                  <div
                    className={
                      paymentmethod == "paypal" ? "addMoneyNew1" : "addMoneyNew"
                    }
                    onClick={() => {
                      setpaymentmethod("paypal");
                    }}
                  >
                    <img src={PayPalImg} alt="" className="h-[50px]" />
                  </div>
                </div>
                <div className="addMoneyPopupBody">
                  {paymentmethod.length !== 0 ? (
                    loading == true ? (
                      <button className="savecredit">Please Wait...</button>
                    ) : paymentmethod == "razorpay" ? (
                      <button className="savecredit" onClick={AddCreditBtn}>
                        Add Money
                      </button>
                    ) : paymentmethod == "paypal" ? (
                      <button
                        className="savecredit"
                        onClick={AddCreditBtnPaypal}
                      >
                        Add Money
                      </button>
                    ) : null
                  ) : null}
                </div>
              </>
            ) : (
              <div className="addMoneyPopup">
                <div className="addMoneyPopupBody">
                  <div className="Balance">
                    <span className="dollar">$</span>{" "}
                    <h3>
                      {" "}
                      {userdata.length !== 0
                        ? userdata[0].wallet.length == 0
                          ? 0
                          : userdata[0].wallet
                        : 0}
                    </h3>
                  </div>
                  <h6
                    className="currentBalancePopup"
                    onClick={() => walletHandler("addMoney")}
                  >
                    Current Balance
                  </h6>
                  <input
                    type="number"
                    onChange={(e) => {
                      setamount(e.target.value);
                    }}
                    value={amount}
                  />
                  <div className="addAmount">
                    <h5
                      className={
                        amount == 500
                          ? "cursor-pointer active"
                          : "cursor-pointer"
                      }
                      onClick={() => {
                        setamount("500");
                      }}
                    >
                      $500
                    </h5>
                    <h5
                      className={
                        amount == 1000
                          ? "cursor-pointer active"
                          : "cursor-pointer"
                      }
                      onClick={() => {
                        setamount("1000");
                      }}
                    >
                      $1000
                    </h5>
                    <h5
                      className={
                        amount == 1500
                          ? "cursor-pointer active"
                          : "cursor-pointer"
                      }
                      onClick={() => {
                        setamount("1500");
                      }}
                    >
                      $1500
                    </h5>
                  </div>

                  {amount.length !== 0 && amount != 0 ? (
                    <button
                      className="savecredit"
                      onClick={() => {
                        setpaymentType(true);
                      }}
                    >
                      Select Payment
                    </button>
                  ) : (
                    <button className="savecreditdisable">
                      {" "}
                      Select Payment
                    </button>
                  )}
                </div>
              </div>
            )}
          </div>
        </>
      )}
      {isPopUp == "lowBalance" && (
        <div className="logoutPopUp">
          <div className="logoutPopUpHead">
            <div className="flex items-center">
              <span>
                <CiWarning className="lowbalanceIcon" />
              </span>
              <h1 className="lowBalanceAlert">Low Balance Alert!</h1>
            </div>
            <img onClick={CloseOverlay} src={back} alt="" />
          </div>
          <h2>
            Your balance is below the minimum required. Please top up to
            continue using the service.
          </h2>
          <div className="addMoneyPopupBody">
            <div className="Balance">
              <span className="dollar lowBalanceAlert">$</span>{" "}
              <h3 className="lowBalanceAlert">
                {" "}
                {userdata.length !== 0
                  ? userdata[0].wallet.length == 0
                    ? 0
                    : userdata[0].wallet
                  : 0}
              </h3>
            </div>
            <h6
              className="currentBalancePopup"
              onClick={() => walletHandler("addMoney")}
            >
              Current Balance
            </h6>
          </div>
        </div>
      )}
      {isPopUp == "PaypalTrigger" && (
        <div className="approveCandidateOverlayPaypal">
          <div className="candidateRateCardOverlayHead">
            <h1>PayPal Integration</h1>
            <span
              className="cursor"
              onClick={() => {
                dispatch(storeAction.isPopUpHander("addMoney"));
              }}
            >
              <RxCross2 />
            </span>
          </div>
          <div className="px-5 py-5">
            <Paypal amount={amount} />
          </div>
        </div>
      )}
      {/* {isPopUp == "FirstPopup" && (
        <div className="approveCandidateOverlayFirst">
          <div className="candidateRateCardOverlayHead">
            <h1>Credit Usage Guidelines for Hirein5 Web Application</h1>
            <span
              className="cursor"
              onClick={() => {
                dispatch(storeAction.isPopUpHander());
              }}
            >
              <RxCross2 />
            </span>
          </div>
          <div className="px-5 py-5">
            <p></p>
            <div className="px-5 py-5">
              <button
                class="savenew flex justify-center items-center m-auto"
                onClick={() => {
                  window.open(
                    "https://firebasestorage.googleapis.com/v0/b/hirein5.appspot.com/o/Credit%20Usage%20Guide_Hirein5_ver1.0.pdf?alt=media&token=95218900-78eb-477e-9269-2338d6ccd93f"
                  );
                }}
              >
                Please link on the link to know about the credit usage
              </button>
            </div>
          </div>
        </div>
      )} */}
    </div>
  );
};

export default WalletModule;
