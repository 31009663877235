/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Pie, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Tooltip,
  Legend
);

const Chart = () => {
  const token = useSelector((store) => store.token);
  const [chartData, setChartData] = useState(null);
  const [lineChartData, setLineChartData] = useState(null);
  const [profilelineChartData, setprofileLineChartData] = useState(null);
  const [onboardingChartData, setOnboardingChartData] = useState(null);

  useEffect(() => {
    Getalldata();
  }, []);

  const Getalldata = async () => {
    const countdata = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/status-count/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      });
    if (countdata.Archived !== undefined) {
      setChartData({
        labels: [
          "Available For Hire",
          "Hired",
          "Reserved",
          "Submitted",
          "Onboarded",
        ],
        datasets: [
          {
            label: "Status Distribution",
            data: [
              countdata.Benched,
              countdata.Hired,
              countdata.Reserved,
              countdata.Submitted,
              countdata.Onboarding,
            ],
            backgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#FFCE56",
              "#4BC0C0",
              "#9966FF",
            ],
            hoverBackgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#FFCE56",
              "#4BC0C0",
              "#9966FF",
            ],
          },
        ],
      });
    }
    const countdata1 = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/count-view/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      });
    if (countdata1.New !== undefined) {
      setOnboardingChartData({
        labels: [
          "In Progress",
          "Processing",
          "Reject",
          "New Candidate",
          "Reviewed",
        ],
        datasets: [
          {
            label: "Onboarding Status",
            data: [
              countdata1.InProgress,
              countdata1.Processing,
              countdata1.OnHold,
              countdata1.New,
              countdata1.Reviewed,
            ],
            backgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#FFCE56",
              "#4BC0C0",
              "#9966FF",
            ],
            hoverBackgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#FFCE56",
              "#4BC0C0",
              "#9966FF",
            ],
          },
        ],
      });
    }
    const monthlyCount = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/joining-count/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      });
    if (monthlyCount !== null) {
      setLineChartData({
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [
          {
            label: "Candidates Created",
            data: monthlyCount.monthly_joining_counts,
            borderColor: "#1D37E7",
            backgroundColor: "rgba(54, 162, 235, 0.2)",
            fill: true,
          },
        ],
      });
      setprofileLineChartData({
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [
          {
            label: "Candidates Created",
            data: monthlyCount.monthly_onboarding_counts,
            borderColor: "#1D37E7",
            backgroundColor: "rgba(54, 162, 235, 0.2)",
            fill: true,
          },
        ],
      });
    }
  };

  return (
    <>
      <div className="paddingLeft50 paddingRight50 flex gap-4">
        <div className="w-[400px] marginBottom20">
          <div className="homeProgressCandidate marginBottom20">
            <h1>Profile Tracker Candidates</h1>
            {chartData ? <Pie data={chartData} /> : <p>Loading Pie chart...</p>}
          </div>
        </div>
        <div className="w-[720px] h-[440px] marginBottom20">
          <div className="homeProgressCandidate marginBottom20">
            <h1>Profile Tracker Candidates Creation by Month</h1>
            {profilelineChartData ? (
              <Line data={profilelineChartData} />
            ) : (
              <p>Loading Line chart...</p>
            )}
          </div>
        </div>
      </div>

      <div className="paddingLeft50 paddingRight50 flex gap-4">
        <div className="w-[400px] marginBottom20">
          <div className="homeProgressCandidate marginBottom20">
            <h1>Onboarding Candidates</h1>
            {onboardingChartData ? (
              <Pie data={onboardingChartData} />
            ) : (
              <p>Loading Pie chart...</p>
            )}
          </div>
        </div>
        <div className="w-[720px] h-[440px] marginBottom20">
          <div className="homeProgressCandidate marginBottom20">
            <h1>Onboarding Candidate Creation by Month</h1>
            {lineChartData ? (
              <Line data={lineChartData} />
            ) : (
              <p>Loading Line chart...</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Chart;
