import React from "react";
import WalletModule from "../Components/Client/ClientSignUp/WalletModule/WalletModule";

const Wallet = () => {
  return (
    <div>
      <WalletModule />
    </div>
  );
};

export default Wallet;
