// ErrorPage.js
import React from "react";

const Construction = () => {
  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <h1 className="text-5xl font-bold text-gray-800">Under Construction</h1>
    </div>
  );
};

export default Construction;
