/* eslint-disable eqeqeq */
/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import "./AdminClientProfileComp.css";
import search from "../../../../assests/search.png";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Avatar from "react-avatar";
import { storeAction } from "../../../../Store/Store";
import Pagination from "../AdminCandidateProfile/Pagination";
import moment from "moment";

const AdminClientProfileComp = ({
  alldata1,
  totaldata1,
  loading,
  setalldata1,
  fullload1,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userid = useSelector((store) => store.userid);
  const userdata = useSelector((store) => store.userdata);
  const viewbtn = (data) => {
    var newobj = {
      admin_id: userid,
      user_id: data.id,
      message: `${
        userdata[0].first_name.length !== 0
          ? userdata[0].first_name
          : "Super Admin"
      } is viewing the detailed profile information for client ${
        data.company.company_name
      }.`,
      date_time: moment().format(),
      activity: "View Client Profile",
    };
    console.log(newobj, "newobj");
    dispatch(storeAction.singleuserHander({ singleuser: [data] }));
    navigate("/adminclientview");
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [recordsPerPage] = useState(20);

  const searchvalue = async (e) => {
    if (e.length !== 0) {
      const matchingSkills = totaldata1.filter((skill) => {
        if (skill.company !== null) {
          return skill.company.company_name
            .toLowerCase()
            .includes(e.toLowerCase());
        }
      });
      setalldata1(matchingSkills);
      setCurrentPage(1);
    } else {
      setalldata1(totaldata1);
    }
  };

  // Filter data with onboarding_status == 4
  const filteredData = alldata1.filter((data) => data.onboarding_status == 4);
  const indexOfLastRecord = currentPage * recordsPerPage;
  const indexOfFirstRecord = indexOfLastRecord - recordsPerPage;
  const currentRecords = filteredData.slice(
    indexOfFirstRecord,
    indexOfLastRecord
  );
  const nPages = Math.ceil(filteredData.length / recordsPerPage);
  const pageNumbers = [...Array(nPages + 1).keys()].slice(1);

  return (
    <div>
      <div className="AdminClientProfileComp">
        <div className="AdminClientProfileCompSearch">
          <input
            type="text"
            placeholder={
              fullload1 ? "Please wait loading data..." : "Search..."
            }
            onChange={(e) => searchvalue(e.target.value)}
            disabled={fullload1}
          />
          <img src={search} alt="" />
        </div>
        <div className="AdminClientProfileCompTable">
          <table className="AdminClientTable">
            <thead>
              <tr className="AdminTableHead">
                <th>COMPANY</th>
                <th>NAME</th>
                <th>SUBSCRIPTION TYPE</th>
                <th>BILLING CYCLE</th>
                <th>STATUS</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {!loading ? (
                currentRecords.length !== 0 ? (
                  currentRecords.map((data, index) => (
                    <tr className="adminTableRow" key={index}>
                      <td>
                        <div className="tableName">
                          {data.company !== null ? (
                            data.profile_picture.length !== 0 ? (
                              <img src={data.profile_picture} alt="" />
                            ) : (
                              <Avatar
                                name={
                                  data.company.company_name.length !== 0
                                    ? data.company.company_name
                                    : data.first_name
                                }
                                size={30}
                                round="50px"
                              />
                            )
                          ) : (
                            <img src={data.profile_picture} alt="" />
                          )}
                          {data.company !== null ? (
                            <h1>{data.company.company_name}</h1>
                          ) : (
                            <h1>{data.first_name}</h1>
                          )}
                        </div>
                      </td>
                      <td>
                        <h1>{data.first_name}</h1>
                      </td>
                      <td>
                        {data.pricing_info.length !== 0 ? (
                          <h1>{data.pricing_info[0].pricing_plan}</h1>
                        ) : (
                          <h1>-</h1>
                        )}
                      </td>
                      <td>
                        {data.pricing_info.length !== 0 ? (
                          <h1>{data.pricing_info[0].plan_duration}</h1>
                        ) : (
                          <h1>-</h1>
                        )}
                      </td>
                      <td>
                        {data.dissabled === true ? (
                          <p className="status inActive">Inactive</p>
                        ) : (
                          <p className="status Active">Active</p>
                        )}
                      </td>
                      <td>
                        <button
                          onClick={() => viewbtn(data)}
                          className="viewButton"
                        >
                          View
                        </button>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td></td>
                    <td></td>
                    <td>
                      <h6 className="text-center py-8">No data found...</h6>
                    </td>
                    <td></td>
                    <td></td>
                  </tr>
                )
              ) : (
                <tr>
                  <td></td>
                  <td></td>
                  <td>
                    <h6 className="text-center py-8">Please wait...</h6>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
        {pageNumbers.length !== 0 ? (
          <div className="tablePagination">
            <Pagination
              nPages={nPages}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default AdminClientProfileComp;
