// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashBoardMainBody {
  width: 100%;
  padding: 120px 0px;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.dashBoardMainBodyInner {
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dashBoardMainBodyInner img {
  width: 150px;
  height: 150px;
  /* border-radius: 100%; */
}

.dashBoardMainBodyInner h1 {
  font-size: 22px;
  font-weight: 600;
}

.dashBoardMainBodyInner p {
  font-size: 14px;
  width: 70%;
  color: var(--textColor);
  margin-top: 5px;
}

.dashBoardMainBodyInnerButton {
  padding: 6px 10px;
  background-color: var(--primaryColor);
  color: var(--white);
  margin-top: 20px;
  border-radius: 8px;
}

@media only screen and (max-width: 600px) {
  .dashBoardMainBodyInner p {
    width: 100%;
  }

  .dashBoardMainBodyInner img {
    width: 130px;
    height: 130px;
  }
}
`, "",{"version":3,"sources":["webpack://./src/Components/Reusable/DashBoardReusable/DashBody/DashBody.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;;EAElB,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,sBAAsB;EACtB,mBAAmB;AACrB;;AAEA;EACE,YAAY;EACZ,aAAa;EACb,yBAAyB;AAC3B;;AAEA;EACE,eAAe;EACf,gBAAgB;AAClB;;AAEA;EACE,eAAe;EACf,UAAU;EACV,uBAAuB;EACvB,eAAe;AACjB;;AAEA;EACE,iBAAiB;EACjB,qCAAqC;EACrC,mBAAmB;EACnB,gBAAgB;EAChB,kBAAkB;AACpB;;AAEA;EACE;IACE,WAAW;EACb;;EAEA;IACE,YAAY;IACZ,aAAa;EACf;AACF","sourcesContent":[".dashBoardMainBody {\n  width: 100%;\n  padding: 120px 0px;\n\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n}\n\n.dashBoardMainBodyInner {\n  width: auto;\n  display: flex;\n  flex-direction: column;\n  align-items: center;\n}\n\n.dashBoardMainBodyInner img {\n  width: 150px;\n  height: 150px;\n  /* border-radius: 100%; */\n}\n\n.dashBoardMainBodyInner h1 {\n  font-size: 22px;\n  font-weight: 600;\n}\n\n.dashBoardMainBodyInner p {\n  font-size: 14px;\n  width: 70%;\n  color: var(--textColor);\n  margin-top: 5px;\n}\n\n.dashBoardMainBodyInnerButton {\n  padding: 6px 10px;\n  background-color: var(--primaryColor);\n  color: var(--white);\n  margin-top: 20px;\n  border-radius: 8px;\n}\n\n@media only screen and (max-width: 600px) {\n  .dashBoardMainBodyInner p {\n    width: 100%;\n  }\n\n  .dashBoardMainBodyInner img {\n    width: 130px;\n    height: 130px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
