/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Pie, Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Tooltip,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  ArcElement,
  Tooltip,
  Legend
);

const Chart = () => {
  const token = useSelector((store) => store.token);
  const [chartData, setChartData] = useState(null);
  const [lineChartData, setLineChartData] = useState(null);
  const [onboardingChartData, setOnboardingChartData] = useState(null);

  useEffect(() => {
    Getalldata();
  }, []);

  const calculateProfileCompletion = (profile) => {
    let count = 0;
    if (profile.address !== null) count += 1;
    if (profile.work_preference_info !== null) count += 1;
    if (
      profile.professional_details_info.length !== 0 ||
      profile.fresher == true ||
      profile.freshers_status == true
    )
      count += 1;
    if (
      profile.project_details_info.length !== 0 ||
      profile.fresher == true ||
      profile.freshers_status == true
    )
      count += 1;
    if (profile.certificate_info.length !== 0 || profile.no_certificate == true)
      count += 1;
    if (profile.travel_info !== null) count += 1;
    if (profile.education_info.length !== 0) count += 1;
    if (profile.video_resume !== null && profile.video_resume.length !== 0)
      count += 1;

    return count;
  };

  const Getalldata = async () => {
    var allfacility = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/getFaculties`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response;
      });

    if (
      allfacility.faculties !== undefined &&
      allfacility.faculties.length !== 0
    ) {
      var filterFinaldata = allfacility.faculties.filter((data) => {
        return data.onboarding_status == 4;
      });

      var checkdata = await allfacility.faculties.filter((data) => {
        return (
          (data.status == "New Candidate" ||
            data.status == "Success" ||
            data.status == "Reject" ||
            data.status == "Mail send" ||
            data.status !== "") &&
          data.nottify !== true
        );
      });

      var benchdata = filterFinaldata.filter(
        (data) =>
          (data.status === "Benched" || data.status === "Success") &&
          data.nottify === true &&
          data.apprual === true
      );
      console.log(benchdata, "benchdata");
      var hireddata = filterFinaldata.filter((data) => data.status === "Hired");
      var reserved = filterFinaldata.filter(
        (data) => data.status === "Reserved"
      );
      var submitteddata = filterFinaldata.filter((data) => {
        const profileCompletion = calculateProfileCompletion(data);
        const percent = Math.round((profileCompletion / 8) * 100);
        return (
          (data.status === "Success" ||
            data.status === "Mail send" ||
            data.status === "Benched") &&
          data.nottify === true &&
          data.apprual === false &&
          percent === 100
        );
      });
      var onboardeddata = filterFinaldata.filter(
        (data) => data.nottify !== true
      );

      // Set Pie chart data for status distribution
      setChartData({
        labels: [
          "Available For Hire",
          "Hired",
          "Reserved",
          "Submitted",
          "Onboarded",
        ],
        datasets: [
          {
            label: "Status Distribution",
            data: [
              benchdata.length,
              hireddata.length,
              reserved.length,
              submitteddata.length,
              onboardeddata.length,
            ],
            backgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#FFCE56",
              "#4BC0C0",
              "#9966FF",
            ],
            hoverBackgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#FFCE56",
              "#4BC0C0",
              "#9966FF",
            ],
          },
        ],
      });

      setOnboardingChartData({
        labels: [
          "In Progress",
          "Processing",
          "Reject",
          "New Candidate",
          "Reviewed",
        ],
        datasets: [
          {
            label: "Onboarding Status",
            data: [
              checkdata.filter(
                (data) => data.status == "Success" && data.onboarding_status > 1
              ).length,
              checkdata.filter((data) => data.status == "Success").length,
              checkdata.filter((data) => data.status == "Reject").length,
              checkdata.filter((data) => data.status == "New Candidate").length,
              checkdata.filter((data) => data.status == "Mail send").length,
            ],
            backgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#FFCE56",
              "#4BC0C0",
              "#9966FF",
            ],
            hoverBackgroundColor: [
              "#FF6384",
              "#36A2EB",
              "#FFCE56",
              "#4BC0C0",
              "#9966FF",
            ],
          },
        ],
      });

      const months = Array(12).fill(0); // Array to store counts for each month
      filterFinaldata.forEach((faculty) => {
        const createdAt = new Date(faculty.created_at); // Replace with actual created date field
        const month = createdAt.getMonth(); // Get month (0 = January, 11 = December)
        months[month] += 1;
      });

      setLineChartData({
        labels: [
          "January",
          "February",
          "March",
          "April",
          "May",
          "June",
          "July",
          "August",
          "September",
          "October",
          "November",
          "December",
        ],
        datasets: [
          {
            label: "Candidates Created",
            data: months,
            borderColor: "#1D37E7",
            backgroundColor: "rgba(54, 162, 235, 0.2)",
            fill: true,
          },
        ],
      });
    }
  };

  return (
    <>
      <div className="paddingLeft50 paddingRight50 flex gap-4">
        <div className="w-[400px] marginBottom20">
          <div className="homeProgressCandidate marginBottom20">
            <h1>Profile Tracker Candidates</h1>
            {chartData ? <Pie data={chartData} /> : <p>Loading Pie chart...</p>}
          </div>
        </div>
        <div className="w-[720px] h-[440px] marginBottom20">
          <div className="homeProgressCandidate marginBottom20">
            <h1>Profile Tracker Candidates Creation by Month</h1>
            {lineChartData ? (
              <Line data={lineChartData} />
            ) : (
              <p>Loading Line chart...</p>
            )}
          </div>
        </div>
      </div>

      <div className="paddingLeft50 paddingRight50 flex gap-4">
        <div className="w-[400px] marginBottom20">
          <div className="homeProgressCandidate marginBottom20">
            <h1>Onboarding Candidates</h1>
            {onboardingChartData ? (
              <Pie data={onboardingChartData} />
            ) : (
              <p>Loading Pie chart...</p>
            )}
          </div>
        </div>
        <div className="w-[720px] h-[440px] marginBottom20">
          <div className="homeProgressCandidate marginBottom20">
            <h1>Onboarding Candidate Creation by Month</h1>
            {lineChartData ? (
              <Line data={lineChartData} />
            ) : (
              <p>Loading Line chart...</p>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Chart;
