import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { Store } from "./Store/Store";
import { PersistGate } from "redux-persist/integration/react";
import { persistor } from "./Store/Store";

// Conditionally include react-error-overlay in development mode
const { startReportingRuntimeErrors } = require("react-error-overlay");
startReportingRuntimeErrors({
  onError: (error) => {
    // Optional: Customize error handling if needed
    console.error("Runtime error:", error);
  },
});
// require("react-error-overlay").startReportingRuntimeErrors();
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <Provider store={Store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

reportWebVitals();
