// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `span.MuiSlider-track.css-1gv0vcd-MuiSlider-track {
    color: var(--primaryColor);
    height: 5px;
}

span.MuiSlider-root.MuiSlider-colorPrimary.MuiSlider-sizeMedium.text-black.css-187mznn-MuiSlider-root {
    color: var(--disableColor);
    height: 5px;
}

span.MuiSlider-thumb.MuiSlider-thumbSizeMedium.MuiSlider-thumbColorPrimary.MuiSlider-thumb.MuiSlider-thumbSizeMedium.MuiSlider-thumbColorPrimary.css-jx1sa5-MuiSlider-thumb {
    color: var(--white);
}

.MuiSlider-valueLabelLabel {
    color: var(--textColorDark);
    background-color: var(--white);
    text-align: center;
    font-weight: 600;
    padding: 6px 10px;
    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);
    font-size: 16px;
}

.MuiSlider-valueLabel {
    background-color: var(--white) !important;
}

.css-v3ate2 {
    width: 100% !important;
}

@media only screen and (max-width: 600px) {
    .css-v3ate2 {
        width: 100%;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/MaterialUi/Range/RangeSlider.css"],"names":[],"mappings":"AAAA;IACI,0BAA0B;IAC1B,WAAW;AACf;;AAEA;IACI,0BAA0B;IAC1B,WAAW;AACf;;AAEA;IACI,mBAAmB;AACvB;;AAEA;IACI,2BAA2B;IAC3B,8BAA8B;IAC9B,kBAAkB;IAClB,gBAAgB;IAChB,iBAAiB;IACjB,2CAA2C;IAC3C,eAAe;AACnB;;AAEA;IACI,yCAAyC;AAC7C;;AAEA;IACI,sBAAsB;AAC1B;;AAEA;IACI;QACI,WAAW;IACf;AACJ","sourcesContent":["span.MuiSlider-track.css-1gv0vcd-MuiSlider-track {\n    color: var(--primaryColor);\n    height: 5px;\n}\n\nspan.MuiSlider-root.MuiSlider-colorPrimary.MuiSlider-sizeMedium.text-black.css-187mznn-MuiSlider-root {\n    color: var(--disableColor);\n    height: 5px;\n}\n\nspan.MuiSlider-thumb.MuiSlider-thumbSizeMedium.MuiSlider-thumbColorPrimary.MuiSlider-thumb.MuiSlider-thumbSizeMedium.MuiSlider-thumbColorPrimary.css-jx1sa5-MuiSlider-thumb {\n    color: var(--white);\n}\n\n.MuiSlider-valueLabelLabel {\n    color: var(--textColorDark);\n    background-color: var(--white);\n    text-align: center;\n    font-weight: 600;\n    padding: 6px 10px;\n    box-shadow: 5px 5px 10px rgba(0, 0, 0, 0.3);\n    font-size: 16px;\n}\n\n.MuiSlider-valueLabel {\n    background-color: var(--white) !important;\n}\n\n.css-v3ate2 {\n    width: 100% !important;\n}\n\n@media only screen and (max-width: 600px) {\n    .css-v3ate2 {\n        width: 100%;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
