import React from "react";
import AdminHome from "../Components/AdminScreen/AdminHome/AdminHome";
import Chart from "../Components/AdminScreen/AdminHome/Chart";

const AdminHomePage = () => {
  return (
    <div>
      <AdminHome />
      <Chart />
    </div>
  );
};

export default AdminHomePage;
