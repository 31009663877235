// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.mobileHeader {
    width: 100%;
    padding: 15px 25px;
    background-color: var(--dropColor);
    border-bottom: 1px solid var(--dropBorderColor);

}

.mobileHeaderFlex {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.mobileHeaderLeft {
    width: 85%;
    position: relative;
}

.mobileHeaderLeft input {
    width: 100%;
    padding: 10px;
    padding-left: 40px;
    font-size: 12px;
    border-radius: 8px;
    border: 1px solid var(--borderColor);
}

.mobSearchIcon {
    position: absolute;
    z-index: 5;
    top: 25%;
    left: 3%;
    font-size: 20px;
}

.mobOptionIcon {
    position: absolute;
    z-index: 5;
    top: 30%;
    font-size: 16px;
    right: 3%;
}

.mobFilters {
    position: absolute;
    background-color: var(--white);
    padding: 10px;
    border-radius: 8px;
    top: 115%;
    right: 0%;
    z-index: 5;
    border: 1px solid var(--borderColor);
}

.mobFilters h5 {
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 5px;
    border-radius: 8px;
    padding: 5px;
}

.mobFilters h5:hover {
    color: var(--white);
    background-color: var(--primaryColor);
}`, "",{"version":3,"sources":["webpack://./src/Components/MobileScreens/MobileHeader/MobileHeader.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,kBAAkB;IAClB,kCAAkC;IAClC,+CAA+C;;AAEnD;;AAEA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,8BAA8B;AAClC;;AAEA;IACI,UAAU;IACV,kBAAkB;AACtB;;AAEA;IACI,WAAW;IACX,aAAa;IACb,kBAAkB;IAClB,eAAe;IACf,kBAAkB;IAClB,oCAAoC;AACxC;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,QAAQ;IACR,eAAe;AACnB;;AAEA;IACI,kBAAkB;IAClB,UAAU;IACV,QAAQ;IACR,eAAe;IACf,SAAS;AACb;;AAEA;IACI,kBAAkB;IAClB,8BAA8B;IAC9B,aAAa;IACb,kBAAkB;IAClB,SAAS;IACT,SAAS;IACT,UAAU;IACV,oCAAoC;AACxC;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,kBAAkB;IAClB,kBAAkB;IAClB,YAAY;AAChB;;AAEA;IACI,mBAAmB;IACnB,qCAAqC;AACzC","sourcesContent":[".mobileHeader {\n    width: 100%;\n    padding: 15px 25px;\n    background-color: var(--dropColor);\n    border-bottom: 1px solid var(--dropBorderColor);\n\n}\n\n.mobileHeaderFlex {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n}\n\n.mobileHeaderLeft {\n    width: 85%;\n    position: relative;\n}\n\n.mobileHeaderLeft input {\n    width: 100%;\n    padding: 10px;\n    padding-left: 40px;\n    font-size: 12px;\n    border-radius: 8px;\n    border: 1px solid var(--borderColor);\n}\n\n.mobSearchIcon {\n    position: absolute;\n    z-index: 5;\n    top: 25%;\n    left: 3%;\n    font-size: 20px;\n}\n\n.mobOptionIcon {\n    position: absolute;\n    z-index: 5;\n    top: 30%;\n    font-size: 16px;\n    right: 3%;\n}\n\n.mobFilters {\n    position: absolute;\n    background-color: var(--white);\n    padding: 10px;\n    border-radius: 8px;\n    top: 115%;\n    right: 0%;\n    z-index: 5;\n    border: 1px solid var(--borderColor);\n}\n\n.mobFilters h5 {\n    font-size: 14px;\n    font-weight: 500;\n    margin-bottom: 5px;\n    border-radius: 8px;\n    padding: 5px;\n}\n\n.mobFilters h5:hover {\n    color: var(--white);\n    background-color: var(--primaryColor);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
