import React from "react";
import BillingComp from "../Components/Client/ClientScreen/BillingComp/BillingComp";
import Billinginfo from "../Components/NewClientFlow/Billinginfo";
import WalletModule from "../Components/Client/ClientSignUp/WalletModule/Onlywallet";

const Billing = () => {
  return (
    <div>
      <BillingComp />
      <WalletModule />
      <Billinginfo />
    </div>
  );
};

export default Billing;
