import React, { useEffect, useState, useRef } from "react";

const loadPayPalScript = (clientId) => {
  return new Promise((resolve) => {
    const script = document.createElement("script");
    script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}&currency=USD`;
    script.onload = () => resolve(true);
    script.onerror = () => resolve(false);
    document.body.appendChild(script);
  });
};

const convertINRtoUSD = (inrAmount) => {
  const conversionRate = 0.012; // Example conversion rate
  return (inrAmount * conversionRate).toFixed(2);
};

const PayPalCheckoutButton = ({ inrAmount }) => {
  const [paypalLoaded, setPaypalLoaded] = useState(false);
  const paypalButtonRef = useRef(null); // Create a ref for PayPal button
  const usdAmount = convertINRtoUSD(inrAmount); // Convert INR to USD

  useEffect(() => {
    loadPayPalScript(
      "AeJTWKTHqB1UW75wRDazLMMkP1ZIXuBTrcXOx1tgzzIYD_--MZNad6EXsZGk_LzDGFH5rYuS5JKD_v34"
    ).then((success) => {
      if (success) {
        setPaypalLoaded(true);
      } else {
        console.error("PayPal SDK failed to load");
      }
    });
  }, []);

  useEffect(() => {
    if (paypalLoaded && paypalButtonRef.current) {
      window.paypal
        .Buttons({
          createOrder: (data, actions) => {
            return actions.order.create({
              purchase_units: [
                {
                  amount: {
                    value: usdAmount, // Amount in USD
                  },
                },
              ],
            });
          },
          onApprove: (data, actions) => {
            return actions.order.capture().then((details) => {
              alert(
                `Transaction completed by ${details.payer.name.given_name}`
              );
            });
          },
          onError: (err) => {
            console.error("PayPal Checkout error", err);
          },
        })
        .render(paypalButtonRef.current); // Use the ref here
    }
  }, [paypalLoaded, usdAmount]);

  return (
    <div>
      {paypalLoaded ? (
        <div ref={paypalButtonRef} /> // Attach the ref to the container
      ) : (
        <div>Loading PayPal...</div>
      )}
    </div>
  );
};

const Paypal = ({ amount }) => {
  const inrAmount = amount; // Example INR amount
  return <PayPalCheckoutButton inrAmount={inrAmount} />;
};

export default Paypal;
