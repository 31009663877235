/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaRegCircleXmark } from "react-icons/fa6";
import { FiPlusCircle } from "react-icons/fi";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";

const AssessmentFlow = () => {
  const token = useSelector((store) => store.token);
  const [teamlist, setteamlist] = useState([
    {
      id: uuidv4(),
      first_name: "",
      last_name: "",
      vendor_name: "",
      date_sent1: "",
      scheduled_date: "",
      scheduled_time: "",
      status: "",
      score: "",
      date_report_received: "",
      final_report_link: "",
      result: "",
      comments: "",
      assessment_date: "",
      conducted_by: "",
      previous_compensation: "",
      ready_to_sign_mou: "",
      engagement_model: "",
      communication: "",
      attitude: "",
      compensation_offered: "",
      mou_signed1: "",
      outcome: "",
      additional_comments: "",
      date_sent: "",
      sent_by: "",
      mou_signed: "",
      mou_signed_date: "",
      vendor_name_initiated: "",
      date_initiated: "",
      initiated_by: "",
      interim_report_received: "",
      bgv_status: "",
      final_report_received: "",
      final_report_comments: "",
      isNew: true,
    },
  ]);
  const [loading, setloading] = useState(false);
  const [count, setcount] = useState(0);
  const [pagination_status, setpagination_status] = useState(false);
  const [newurl, setnewurl] = useState(null);
  const [candidatenext, setcandidatenext] = useState(null);
  const [candidateprevious, setcandidateprevious] = useState(null);

  const handleChangeNew = (index, field, value) => {
    const updatedTeams = teamlist.map((candidate, cIndex) => {
      if (cIndex === index) {
        return {
          ...candidate,
          [field]: value,
        };
      }
      return candidate;
    });
    setteamlist(updatedTeams);
  };

  const addCandidate = () => {
    const newCandidate = {
      id: uuidv4(),
      first_name: "",
      last_name: "",
      vendor_name: "",
      date_sent1: "",
      scheduled_date: "",
      scheduled_time: "",
      status: "",
      score: "",
      date_report_received: "",
      final_report_link: "",
      result: "",
      comments: "",
      assessment_date: "",
      conducted_by: "",
      previous_compensation: "",
      ready_to_sign_mou: "",
      engagement_model: "",
      communication: "",
      attitude: "",
      compensation_offered: "",
      mou_signed1: "",
      outcome: "",
      additional_comments: "",
      date_sent: "",
      sent_by: "",
      mou_signed: "",
      mou_signed_date: "",
      vendor_name_initiated: "",
      date_initiated: "",
      initiated_by: "",
      interim_report_received: "",
      bgv_status: "",
      final_report_received: "",
      final_report_comments: "",
      isNew: true,
    };

    setteamlist([...teamlist, newCandidate]);
  };

  const removeCandidate = (candidateId) => {
    const updatedTeamlist = teamlist.filter(
      (candidate) => candidate.id !== candidateId
    );
    setteamlist(updatedTeamlist);
  };

  const submitbtn = async () => {
    if (teamlist.length !== 0) {
      setloading(true);
      for (let i = 0; i < teamlist.length; i++) {
        const candidate = teamlist[i];
        const newCandidateData = {
          first_name: candidate.first_name,
          last_name: candidate.last_name,
          vendor_name: candidate.vendor_name,
          date_sent1: candidate.date_sent1,
          scheduled_date: candidate.scheduled_date,
          scheduled_time: candidate.scheduled_time,
          status: candidate.status,
          score: candidate.score,
          date_report_received: candidate.date_report_received,
          final_report_link: candidate.final_report_link,
          result: candidate.result,
          comments: candidate.comments,
          assessment_date: candidate.assessment_date,
          conducted_by: candidate.conducted_by,
          previous_compensation: candidate.previous_compensation,
          ready_to_sign_mou: candidate.ready_to_sign_mou,
          engagement_model: candidate.engagement_model,
          communication: candidate.communication,
          attitude: candidate.attitude,
          compensation_offered: candidate.compensation_offered,
          mou_signed1: candidate.mou_signed1,
          outcome: candidate.outcome,
          additional_comments: candidate.additional_comments,
          date_sent: candidate.date_sent,
          sent_by: candidate.sent_by,
          mou_signed: candidate.mou_signed,
          mou_signed_date: candidate.mou_signed_date,
          vendor_name_initiated: candidate.vendor_name_initiated,
          date_initiated: candidate.date_initiated,
          initiated_by: candidate.initiated_by,
          interim_report_received: candidate.interim_report_received,
          bgv_status: candidate.bgv_status,
          final_report_received: candidate.final_report_received,
          final_report_comments: candidate.final_report_comments,
        };
        if (candidate.isNew) {
          await axios
            .post(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/assessment/`,
              newCandidateData,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .then((res) => {
              candidate.isNew = false;
            })
            .catch((err) => console.error(err));
        } else {
          await axios
            .put(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/assessment/${candidate.id}/`,
              newCandidateData,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .catch((err) => console.error(err));
        }
      }
      setloading(false);
    }
  };

  useEffect(() => {
    Getalldata();
  }, []);

  const Getalldata = async () => {
    const Alldata = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/assessment/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response);

    if (Alldata.results.length !== 0) {
      setcount(Alldata.count);
      if (Alldata.count > 20) {
        setpagination_status(true);
      } else {
        setpagination_status(false);
      }
      setcandidateprevious(Alldata.previous);
      setcandidatenext(Alldata.next);
      const updatedData = Alldata.results.map((candidate) => ({
        ...candidate,
        isNew: false,
      }));
      setteamlist(updatedData);
    }
  };
  const nPages = Math.ceil(count / 20);
  const pageNumbers = Array.from({ length: nPages }, (_, i) => i + 1);
  return (
    <div>
      <table>
        <thead>
          <tr className="hireTableHead">
            <th>Action</th>
            <th>First & Middle Name</th>
            <th>Last Name</th>
            <th>Vendor Name</th>
            <th>Date Sent</th>
            <th>Scheduled Date</th>
            <th>Scheduled Time</th>
            <th>Status</th>
            <th>Score</th>
            <th>Date Report received</th>
            <th>Date final report & link received</th>
            <th>Result</th>
            <th>Comments</th>
            <th>Hr Round Date</th>
            <th>Conducted By</th>
            <th>Previous Compensation</th>
            <th>Ready to sign MoU</th>
            <th>Model of engagement</th>
            <th>Communication</th>
            <th>Attitude</th>
            <th>Compensation Offered</th>
            <th>Ready to sign MoU</th>
            <th>Outcome</th>
            <th>Comments</th>
            <th>Date Sent</th>
            <th>Sent by</th>
            <th>MoU Signed</th>
            <th>MoU Signed date</th>
            {/* <th>Comments</th> */}
            <th>Vendor Name</th>
            <th>Date Initiated</th>
            <th>Initiated by</th>
            <th>Interim Report Received</th>
            <th>BGV Status</th>
            <th>Final Report Received</th>
            <th>Comments</th>
          </tr>
        </thead>
        <tbody className="hireTableBody">
          {teamlist.length !== 0 &&
            teamlist.map((role, roleIndex) => (
              <tr className="hireTablerow" key={roleIndex}>
                <td>
                  <div className="flex items-center justify-center">
                    <FaRegCircleXmark
                      className="xIcon"
                      onClick={() => removeCandidate(0, role.id)}
                    />
                    <FiPlusCircle
                      className="plusIcon"
                      onClick={() => addCandidate(0)}
                    />
                  </div>
                </td>

                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.first_name}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "first_name", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.last_name}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "last_name", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.vendor_name}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "vendor_name",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.date_sent1}
                      type="date"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "date_sent1", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.scheduled_date}
                      type="date"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "scheduled_date",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.scheduled_time}
                      type="time"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "scheduled_time",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <select
                      className="hireTableBodySelect"
                      value={role.status}
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "status", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Scheduled">Scheduled</option>
                      <option value="Completed">Completed</option>
                      <option value="Pending">Pending</option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.score}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "score", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.date_report_received}
                      type="date"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "date_report_received",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.final_report_link}
                      type="date"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "final_report_link",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <select
                      className="hireTableBodySelect"
                      value={role.result}
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "result", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Passed">Passed</option>
                      <option value="Failed">Failed</option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.comments}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "comments", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.assessment_date}
                      type="date"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "assessment_date",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.conducted_by}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "conducted_by",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.previous_compensation}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "previous_compensation",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.ready_to_sign_mou}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "ready_to_sign_mou",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <select
                      className="hireTableBodySelect"
                      value={role.engagement_model}
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "engagement_model",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Part time Consultant">
                        Part time Consultant
                      </option>
                      <option value="Full time Consultant">
                        Full time Consultant
                      </option>
                      <option value="Employee">Employee</option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.communication}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "communication",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.attitude}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "attitude", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.compensation_offered}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "compensation_offered",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.mou_signed1}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "mou_signed1",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <select
                      className="hireTableBodySelect"
                      value={role.outcome}
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "outcome", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Approved">Approved</option>
                      <option value="Rejected">Rejected</option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.additional_comments}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "additional_comments",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.date_sent}
                      type="date"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "date_sent", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.sent_by}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "sent_by", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <select
                      className="hireTableBodySelect"
                      value={role.mou_signed}
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "mou_signed", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.mou_signed_date}
                      type="date"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "mou_signed_date",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.vendor_name_initiated}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "vendor_name_initiated",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.date_initiated}
                      type="date"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "date_initiated",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.initiated_by}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "initiated_by",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.interim_report_received}
                      type="date"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "interim_report_received",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <select
                      className="hireTableBodySelect"
                      value={role.bgv_status}
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "bgv_status", e.target.value)
                      }
                    >
                      <option value="">Select</option>
                      <option value="In Progress">In Progress</option>
                      <option value="Completed">Completed</option>
                      <option value="Yet to Initiate">Yet to Initiate</option>
                      <option value="On hold - College not supportive">
                        On hold - College not supportive
                      </option>
                      <option value="On hold - Company not cooperative">
                        On hold - Company not cooperative
                      </option>
                      <option value="On hold - Candidate not okay with Physical Verification">
                        On hold - Candidate not okay with Physical Verification
                      </option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.final_report_received}
                      type="date"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "final_report_received",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.final_report_comments}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "final_report_comments",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {loading ? (
        <button className="saveSubmit">Please Wait...</button>
      ) : (
        <button className="saveSubmit" onClick={submitbtn}>
          Save Changes
        </button>
      )}
      {pagination_status && (
        <div className="tablePagination">
          <nav>
            <ul className="pagination">
              {candidateprevious == null ? (
                <li className="page-item disabled">
                  <h6 className="page-link">&lt;&lt; Previous</h6>
                </li>
              ) : (
                <li
                  className="page-item active"
                  onClick={() => {
                    setnewurl(candidateprevious);
                    document.getElementById("seachinput").value = "";
                  }}
                >
                  <h6 className="page-link">&lt;&lt; Previous</h6>
                </li>
              )}

              {newurl == null ? (
                <h6 className="page-link">1</h6>
              ) : (
                <h6 className="page-link active">
                  {newurl.includes("/assessment/") &&
                  newurl.split("/assessment/")[1]?.split("?page=")[1] !==
                    undefined
                    ? newurl.split("/assessment/")[1].split("?page=")[1]
                    : 1}
                </h6>
              )}
              {candidatenext == null ? (
                <li className="page-item disabled">
                  <h6 className="page-link">Next &gt;&gt;</h6>
                </li>
              ) : (
                <li
                  className="page-item active"
                  onClick={() => {
                    setnewurl(candidatenext);
                    document.getElementById("seachinput").value = "";
                  }}
                >
                  <h6 className="page-link">Next &gt;&gt;</h6>
                </li>
              )}
            </ul>
          </nav>
          <p className="text-center mt-4 font-medium text-xs text-[#71717a]">
            Total No Of Pages : {pageNumbers.length}
          </p>
        </div>
      )}
    </div>
  );
};

export default AssessmentFlow;
