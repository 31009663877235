// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.errortag {
    text-align: center;
    font-size: 18px;
    font-weight: 600;
    line-height: 30px;
}

.linktag {
    color: var(--primaryColor);
}`, "",{"version":3,"sources":["webpack://./src/Components/AdminScreen/AdminLogin/AdminLogin.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,eAAe;IACf,gBAAgB;IAChB,iBAAiB;AACrB;;AAEA;IACI,0BAA0B;AAC9B","sourcesContent":[".errortag {\n    text-align: center;\n    font-size: 18px;\n    font-weight: 600;\n    line-height: 30px;\n}\n\n.linktag {\n    color: var(--primaryColor);\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
