/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
import React, { useCallback, useEffect } from "react";
import "./Layout.css";
import Routing from "../Routing/Routing";
import SideBar from "../Components/Reusable/SideBar/SideBar";
import { useDispatch, useSelector } from "react-redux";
import { LuBookMinus, LuScrollText } from "react-icons/lu";
import { FiUsers } from "react-icons/fi";
import { FiFileText } from "react-icons/fi";
import { IoWalletOutline } from "react-icons/io5";
import { RiGlobalLine } from "react-icons/ri";
import { FiCompass } from "react-icons/fi";
import { TbUsersGroup } from "react-icons/tb";
import { FiHelpCircle } from "react-icons/fi";
import { FiHome } from "react-icons/fi";
import { FaRegCircleUser } from "react-icons/fa6";
import { FaRegUser } from "react-icons/fa";
import { FaUserCog } from "react-icons/fa";
import { LuUsers2 } from "react-icons/lu";
import { FiSettings } from "react-icons/fi";
import { IoMdContacts } from "react-icons/io";
import { IoIosInformationCircleOutline } from "react-icons/io";
import { MdMarkEmailRead } from "react-icons/md";
import UnderConstruction from "../pages/UnderConstruction";
import { storeAction } from "../Store/Store";
import axios from "axios";
import Popup from "../Components/Reusable/PopUp/Popup";
import { useLocation } from "react-router-dom";

const Layout = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const issidebar = useSelector((store) => store.issidebar);
  const loginrole = useSelector((store) => store.loginrole);
  const userid = useSelector((store) => store.userid);
  const token = useSelector((store) => store.token);
  const isPopUp = useSelector((store) => {
    return store.isPopUp;
  });
  useEffect(() => {
    setTimeout(() => {
      getUserinfo();
    }, 1000);
  }, [token, userid, loginrole]);

  const getUserinfo = useCallback(async () => {
    if (token !== null && userid !== null) {
      var userinfo = await axios
        .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userid}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          if (err.response.status === 401) {
            const allowedPaths = [
              "/login",
              "/",
              "/candidateregister",
              "/clientregister",
              "/forgotPassword",
              "/verification/:id",
              "/resetpassword",
              "/shareprofile",
              "/vendorregister",
              "/search",
              "/test",
            ];

            if (
              !allowedPaths.includes(location.pathname) &&
              !location.pathname.includes("@")
            ) {
              dispatch(storeAction.isPopUpHander("contactus"));
            }
          } else {
            const allowedPaths = [
              "/login",
              "/",
              "/candidateregister",
              "/clientregister",
              "/forgotPassword",
              "/verification/:id",
              "/resetpassword",
              "/shareprofile",
              "/vendorregister",
              "/search",
              "/test",
            ];

            if (
              !allowedPaths.includes(location.pathname) &&
              !location.pathname.includes("@")
            ) {
              dispatch(storeAction.isPopUpHander("contactus"));
            }
          }
          return err.response;
        });
      if (userinfo !== undefined) {
        if (userinfo.id !== undefined) {
          dispatch(storeAction.userdataHander({ userdata: [userinfo] }));
        } else {
          if (userinfo.data.code == "token_not_valid") {
            const allowedPaths = [
              "/login",
              "/",
              "/candidateregister",
              "/clientregister",
              "/forgotPassword",
              "/verification/:id",
              "/resetpassword",
              "/shareprofile",
              "/vendorregister",
              "/search",
              "/test",
            ];

            if (
              !allowedPaths.includes(location.pathname) &&
              !location.pathname.includes("@")
            ) {
              dispatch(storeAction.isPopUpHander("contactus"));
            }
          }
        }
      }
    } else {
      const allowedPaths = [
        "/login",
        "/",
        "/candidateregister",
        "/clientregister",
        "/forgotPassword",
        "/verification/:id",
        "/resetpassword",
        "/shareprofile",
        "/vendorregister",
        "/search",
        "/test",
      ];

      if (
        !allowedPaths.includes(location.pathname) &&
        !location.pathname.includes("@")
      ) {
        dispatch(storeAction.isPopUpHander("contactus"));
      }
    }
  }, [token, userid, loginrole]);

  const logout = async () => {
    dispatch(storeAction.reset());
    localStorage.removeItem("persist:root");
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace("/#/login");
  };
  return (
    <div>
      {process.env.REACT_APP_UNDER_CONSTRUCTION == "false" ? (
        <>
          {issidebar && (
            <div className="dashboard">
              <div className="sideNavComp">
                {loginrole == 2 && (
                  <SideBar
                    role={1}
                    menu={[
                      {
                        title: "Discover Candidate",
                        icon: <FiCompass />,
                        router: "discover",
                      },
                      {
                        title: "Hire a Team",
                        icon: <TbUsersGroup />,
                        router: "hireTeam",
                      },
                      {
                        title: "Shortlist & Interviews",
                        icon: <FiUsers />,
                        router: "interview",
                      },
                      {
                        title: "Contracts",
                        icon: <FiFileText />,
                        router: "contract",
                      },
                      {
                        title: "Wallet",
                        icon: <IoWalletOutline />,
                        router: "wallet",
                      },
                      {
                        title: "Billing",
                        icon: <LuBookMinus />,
                        router: "billing",
                      },
                      {
                        title: "Help & FAQs",
                        icon: <FiHelpCircle />,
                        router: "clientfaq",
                      },
                    ]}
                  />
                )}
                {loginrole == 3 && (
                  <SideBar
                    role={2}
                    menu={[
                      {
                        title: "Profile",
                        icon: <FaRegCircleUser />,
                        router: "profile",
                      },
                      {
                        title: "Interview",
                        icon: <FaRegCircleUser />,
                        router: "candidateinterview",
                      },
                      {
                        title: "Help & FAQs",
                        icon: <FiHelpCircle />,
                        router: "candidatefaq",
                      },
                    ]}
                  />
                )}
                {loginrole == 1 && (
                  <SideBar
                    role={2}
                    menu={[
                      {
                        title: "Home",
                        icon: <FiHome />,
                        router: "adminHome",
                      },
                      {
                        title: "Profile Tracker",
                        icon: <FaRegCircleUser />,
                        router: "customerProfile",
                      },
                      {
                        title: "Onboarding Candidate",
                        icon: <FaRegUser />,
                        router: "onboardcandidate",
                      },
                      {
                        title: "Onboarding Client",
                        icon: <FaRegUser />,
                        router: "onboardclient",
                      },
                      {
                        title: "Vendors",
                        icon: <FaUserCog />,
                        router: "vendorlist",
                      },
                      {
                        title: "ATS",
                        icon: <FaUserCog />,
                        router: "ats",
                      },
                      {
                        title: "Contact Us",
                        icon: <IoMdContacts />,
                        router: "contact",
                      },
                      {
                        title: "Bulk Email",
                        icon: <MdMarkEmailRead />,
                        router: "bulkemail",
                      },
                      {
                        title: "Team members",
                        icon: <LuUsers2 />,
                        router: "adminteam",
                      },

                      {
                        title: "Settings",
                        icon: <FiSettings />,
                        router: "settings",
                      },
                    ]}
                  />
                )}
                {loginrole == 7 && (
                  <SideBar
                    role={2}
                    menu={[
                      {
                        title: "Onboarding Candidate",
                        icon: <FaRegUser />,
                        router: "onboardcandidate",
                      },

                      {
                        title: "ATS",
                        icon: <FaUserCog />,
                        router: "ats",
                      },
                    ]}
                  />
                )}
                {loginrole == 5 && (
                  <SideBar
                    role={2}
                    menu={[
                      {
                        title: "Home",
                        icon: <FiHome />,
                        router: "adminHome",
                      },
                      {
                        title: "Profile",
                        icon: <FaRegCircleUser />,
                        router: "customerProfile",
                      },
                      {
                        title: "Onboarding Candidate",
                        icon: <FaRegUser />,
                        router: "onboardcandidate",
                      },
                      {
                        title: "Onboarding Client",
                        icon: <FaRegUser />,
                        router: "onboardclient",
                      },
                      {
                        title: "Contact Us",
                        icon: <IoMdContacts />,
                        router: "contact",
                      },
                      {
                        title: "Bulk Email",
                        icon: <MdMarkEmailRead />,
                        router: "bulkemail",
                      },
                      {
                        title: "Settings",
                        icon: <FiSettings />,
                        router: "settings",
                      },
                    ]}
                  />
                )}
                {loginrole == 6 && (
                  <SideBar
                    role={2}
                    menu={[
                      {
                        title: "Home",
                        icon: <FiHome />,
                        router: "vendordashboard",
                      },
                      {
                        title: "Candidates",
                        icon: <FaRegUser />,
                        router: "vendorcandidatelist",
                      },
                      {
                        title: "Teams Members",
                        icon: <RiGlobalLine />,
                        router: "construction",
                      },
                      {
                        title: "Billing",
                        icon: <LuScrollText />,
                        router: "vendorwallet",
                      },
                      {
                        title: "Settings",
                        icon: <FiSettings />,
                        router: "construction2",
                      },
                      {
                        title: "Vendor Support",
                        icon: <FiHelpCircle />,
                        router: "construction3",
                      },
                      {
                        title: "FAQ’s",
                        icon: <IoIosInformationCircleOutline />,
                        router: "construction4",
                      },
                    ]}
                  />
                )}
              </div>

              <div className="main displayHandler">
                <Routing />
              </div>
            </div>
          )}
          <div className="routing displayHandler">
            {!issidebar && <Routing />}
          </div>
          <div className="MobScreen backGround ">
            <Routing />
            {isPopUp == "navbar" && (
              <div
                className={
                  isPopUp == "navbar"
                    ? "sidebarOverlay fadeIn"
                    : "sidebarOverlay fadeOut"
                }
              >
                {loginrole == 2 && (
                  <SideBar
                    role={1}
                    menu={[
                      {
                        title: "Discover",
                        icon: <FiCompass />,
                        router: "discover",
                      },
                      {
                        title: "Interview",
                        icon: <FiUsers />,
                        router: "interview",
                      },
                      {
                        title: "Contracts",
                        icon: <FiFileText />,
                        router: "contract",
                      },
                      {
                        title: "Wallet",
                        icon: <FiHelpCircle />,
                        router: "wallet",
                      },
                      {
                        title: "Billing",
                        icon: <FiHelpCircle />,
                        router: "billing",
                      },
                      {
                        title: "Help & FAQs",
                        icon: <FiHelpCircle />,
                        router: "faq",
                      },
                    ]}
                  />
                )}
                {loginrole == 3 && (
                  <SideBar
                    role={2}
                    menu={[
                      {
                        title: "Profile",
                        icon: <FaRegCircleUser />,
                        router: "profile",
                      },
                      {
                        title: "Help & FAQs",
                        icon: <FiHelpCircle />,
                        router: "faq",
                      },
                    ]}
                  />
                )}
                {loginrole == 1 && (
                  <SideBar
                    role={2}
                    menu={[
                      {
                        title: "Home",
                        icon: <FiHome />,
                        router: "adminHome",
                      },
                      {
                        title: "Profile",
                        icon: <FaRegCircleUser />,
                        router: "customerProfile",
                      },

                      {
                        title: "Team members",
                        icon: <LuUsers2 />,
                        router: "adminteam",
                      },
                      {
                        title: "Settings",
                        icon: <FiSettings />,
                        router: "settings",
                      },
                    ]}
                  />
                )}
              </div>
            )}
          </div>
        </>
      ) : (
        <UnderConstruction />
      )}
      {isPopUp == "contactus" && (
        <div className="contactOverlay">
          <Popup
            imgClass="displayNone"
            PopupCompSuccess="PopupCompSuccess"
            Head="Session Expired"
            desc="Your Session has been Expired Please Login to continue."
            InputContainer="displayNone"
            PopupSingleButton="PopupSingleButton"
            PopupSingleText="Login"
            SingleBtnfun={logout}
            PopupDoubleButton="displayNone"
            DoubleBtn1="Cancel"
            DoubleBtn2="Submit"
            closeIcon="popupClose pointer"
            waricon="waricon"
          />
        </div>
      )}
    </div>
  );
};

export default Layout;
