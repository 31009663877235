/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import DashHead from "../Components/Reusable/DashBoardReusable/DashHead/DashHead";
import Faqsection from "../Components/Reusable/Faq/Faqsection";
import { useSelector } from "react-redux";

const Candidatefaq = () => {
  const onboarding_status = useSelector((store) => store.onboarding_status);
  useEffect(() => {
    CheckValid();
  }, [onboarding_status]);
  const CheckValid = async () => {
    if (onboarding_status < 3) {
      window.location.replace("/#/registration");
    }
  };
  var faqdata = [
    {
      question:
        "1. My profile is incomplete. Will my candidature still be considered?",
      answer:
        "<p>Incomplete profiles are not published as each candidate profile goes through an approval process that is vetted by our Talent Acquisition Team. If you require any support or have any questions about any of the sections, please contact <a href='mailto:candidatesupport@hirein5.com' class='linktag'>candidatesupport@hirein5.com</a>.</p>",
      answer2:
        "In case you are unable to complete your application within 15 days, your application will automatically be deleted.",
    },
    {
      question:
        "2. I would rather not record my Video Resume. Will that impact my chances of getting placed?",
      link: "",
      answer:
        "Video Resumes are a great way to demonstrate your communication skills, as well as talk about your expertise to prospective clients and employers. It increase your chance of getting noticed by 90%.",
      answer2: "",
    },
    {
      question: "3. How often are staff placed outside the country?",
      link: "",
      answer:
        "At Hirein5, we aim to have a significant team in each of the continents over the next by 2026. The opportunity to get placed in a foreign country typically may materialise any time post 6 months of your engagement with Hirein5, subject to your performance, client demand and global socio-economic conditions, and legal factors.",
      answer2: "",
    },
    {
      question: "4. There are several assessments that need to be taken. Why?",
      link: "",
      answer:
        "Completing your profile and your assessment is an essential part of your evaluation. Please make sure that you take these tests seriously, as these are shared with the clients, who make a large part of their decision based on these third party assessments.",
      answer2: "",
    },
    {
      question:
        "5. I did not fare well in my assessment. Can I retake the assessment?",
      link: "",
      answer:
        "<p>The company spends time and resources in having you take the assessments. If you feel that you may want to retake the assessment if your initial assessment did not go well, please send in your request via email to <a href='mailto:candidatesupport@hirein5.com' class='linktag'>candidatesupport@hirein5.com</a>. In rare cases, the company may allow a reassessment but at your expense.</p>",
      answer2: "",
    },
    {
      question:
        "6. How soon can I expect to hear back from Hirein5 about my placement?",
      link: "",
      answer:
        "As soon as your profile is activated, our team engages with clients to talk about your profile and aims to get an engagement underway in less than 6 weeks. In rare cases does the profile remain on bench for long periods of time.",
      answer2: "",
    },
    {
      question:
        "7. What happens if I am unable to join the company on the committed date?",
      link: "",
      answer:
        "Most of our candidates understand the value that we generate for our staff, and are keen to make a career with us. They keep in touch and ensure that they keep us informed of any changes on their personal and professional front that may impact their ability to join us. Candidates must keep us informed to avoid being put into a no-show list which may impact your hireability in the future.",
      answer2: "",
    },
    {
      question:
        "8. I would like to withdraw my candidature from the Hirein5 portal.",
      link: "",
      answer:
        "<p>In some cases, certain unforeseen circumstances may occur due to which the candidates may not want to be a part of Hirein5. We respect such decisions and would offer you counsel to keep your candidature on hold on the portal for a finite duration or completely withdraw your profile. In such cases, please write to <a href='mailto:candidatesupport@hirein5.com' class='linktag'>candidatesupport@hirein5.com</a> to share your decision, along with the reasons.</p>",
      answer2: "",
    },
    {
      question: "9. How do you store my personal information?",
      link: "",
      answer:
        "Your information is stored on the Cloud with some of the world’s foremost cloud service providers on servers in Singapore. We conduct regular Vulnerability Assessments and Penetration testing to ensure that your data remains secure.",
      answer2: "",
    },
    {
      question: "10. Can I delete my account?",
      link: "",
      answer:
        "<p>Yes, you may write to <a href='mailto:candidatesupport@hirein5.com' class='linktag'>candidatesupport@hirein5.com</a> with “Delete My Account” in the subject line, if you are not actively engaged in any assignment with Hirein5 or its client. We will verify the same and process your request within 5 working days.</p>",
      answer2: "",
    },
    {
      question: "11. Will I receive feedback on my assessments?",
      link: "",
      answer:
        "Yes, candidates typically receive feedback on their assessments. This feedback can help you understand your strengths and areas for improvement, making it valuable for your professional development.",
      answer2: "",
    },
    {
      question: "12. How will my profile be matched with clients?",
      link: "",
      answer:
        "Our platform uses a combination of your skills, assessments, and client requirements to match you with potential opportunities. Keeping your profile up-to-date and highlighting your key skills can improve your chances of being matched with suitable clients.",
      answer2: "",
    },
    {
      question: "13. Who can I contact for questions not covered here?",
      link: "",
      answer:
        "<p>If you have any questions that are not covered in this FAQ, please contact us at <a href='mailto:candidatesupport@hirein5.com' class='linktag'>candidatesupport@hirein5.com</a>. Our support team will be happy to assist you.</p>",
      answer2: "",
    },
  ];
  return (
    <div className="paddingLeft100 paddingRight100 ">
      <DashHead
        head="Frequently Asked Questions"
        desc="Find quick answers to common questions about our services, policies, and how we can assist you better"
      />
      {faqdata.length !== 0
        ? faqdata.map((data, index) => (
            <Faqsection
              key={index}
              question={data.question}
              answer={data.answer}
              answer2={data.answer2}
              link={data.link}
            />
          ))
        : null}
    </div>
  );
};

export default Candidatefaq;
