import React from "react";
import DashHead from "../Components/Reusable/DashBoardReusable/DashHead/DashHead";
import Faqsection from "../Components/Reusable/Faq/Faqsection";

const Clientfaq = () => {
  var faqdata = [
    {
      question: "1. I need help with a few things my account",
      answer:
        "<p>Please reach your account manager for any assistance or write to <a href='mailto:clientrelations@hirein5.com' class='linktag'>clientrelations@hirein5.com</a> and someone will reach out to you shortly.</p>",
      answer2: "",
    },
    {
      question:
        "2. I am looking for certain tech skills that I am unable to find on the portal.",
      link: "",
      answer:
        "We are onboarding new skills all the time and it may be possible that a specific skill may not be readily available. Please connect with your account manager with your requirements, and we should be able to fulfil your requirement within a week.  ",
      answer2: "",
    },
    {
      question:
        "3. I want to speed up the process of hiring a candidate on our payroll directly.  ",
      link: "",
      answer:
        "<p>With our Contract-to-Contract (C2C) model, you can evaluate a candidate's skills remotely before extending a permanent offer. Our contracts may provision for such a transfer to happen post completion of 6 months of the remote contract. If you would like to sponsor his visa to your home country, please contact <a href='mailto:sales@hirein5.com' class='linktag'>sales@hirein5.com</a></p>",
      answer2: "",
    },
    {
      question:
        "4. I found a suitable candidate but his profile is unavailable",
      link: "",
      answer:
        "We add new talent and resources continuously on our platform and would like our clients to benefit from the new talent that comes onboard. Our subscription fee ensures that you continue to have access to new skills and talent.",
      answer2: "",
    },
    {
      question: "5. Can I reserve multiple candidates at the same time?",
      link: "",
      answer:
        "<p>Yes, you can. The reserve fees will be applicable to each candidate. </p>",
      answer2: "",
    },
    {
      question: "6. Are the resource prices fixed?",
      link: "",
      answer:
        "We carefully evaluate our talent’s skills and fix prices accordingly. Our talent is of high calibre and can add significant value to any project. Our prices are therefore transparent and published on the platform against each candidate. ",
      answer2: "",
    },
    {
      question:
        "7. Where can I find all the contracts that I sign with Hirein5?",
      link: "",
      answer:
        "Every contract that you sign is available under your account on the platform for ease of access and reference.",
      answer2: "",
    },
    {
      question:
        "8. What happens if I need to scale up or down my team's resources?",
      link: "",
      answer:
        "<p>Our offering is flexible to accommodate your changing needs. You can easily adjust your team size by adding or removing resources as provisioned in the contract, ensuring you have the right talent for your team at all times.</p>",
      answer2: "",
    },
    {
      question: "9. How many resources can I hire on the platform?",
      link: "",
      answer:
        "As many as you need. There is no restriction on how many resources you can hire.",
      answer2: "",
    },
  ];
  return (
    <div className="paddingLeft100 paddingRight100 ">
      <DashHead
        head="Frequently Asked Questions"
        desc="Find quick answers to common questions about our services, policies, and how we can assist you better"
      />
      {faqdata.length !== 0
        ? faqdata.map((data, index) => (
            <Faqsection
              key={index}
              question={data.question}
              answer={data.answer}
              answer2={data.answer2}
              link={data.link}
            />
          ))
        : null}
    </div>
  );
};

export default Clientfaq;
