import React from "react";
import NewCandidatetable from "../Components/NewCandidateFlow/NewCandidatetable";

const Onboardcandidate = () => {
  return (
    <div>
      <NewCandidatetable />
    </div>
  );
};

export default Onboardcandidate;
