import React from "react";
import ShareComp from "../Components/ShareComponent/ShareComp";

const ShareProfile = () => {
  return (
    <div>
      <ShareComp />
    </div>
  );
};

export default ShareProfile;
