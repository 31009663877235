// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.dashBoardMain {
    /* width: 82%; */
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    background-color: #f8f8f8;
}

.dashBoardMain::-webkit-scrollbar {
    display: none;
}`, "",{"version":3,"sources":["webpack://./src/Components/DashBoardComp/DashBoardComp.css"],"names":[],"mappings":"AAAA;IACI,gBAAgB;IAChB,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,yBAAyB;AAC7B;;AAEA;IACI,aAAa;AACjB","sourcesContent":[".dashBoardMain {\n    /* width: 82%; */\n    width: 100%;\n    height: 100%;\n    overflow-y: scroll;\n    background-color: #f8f8f8;\n}\n\n.dashBoardMain::-webkit-scrollbar {\n    display: none;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
