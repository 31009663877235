/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import "./VerificationComp.css";
import SuccessResponse from "../../Reusable/SuccessResponse/SuccessResponse";
import MobileInput from "../../PrelineComponent/MobileInput/MobileInput";
import Head from "../../Reusable/LogoHead/Head";
import { FiLoader } from "react-icons/fi";
import back from "../../../assests/back.png";
import { firebase, auth } from "../../../database/firebase";
import { useDispatch, useSelector } from "react-redux";
import axios from "axios";
import { storeAction } from "../../../Store/Store";

const VerificationComp = () => {
  const dispatch = useDispatch();
  const userid = useSelector((store) => store.userid);
  const token = useSelector((store) => store.token);
  const signupdata = useSelector((store) => store.signupdata);
  const userdata = useSelector((store) => store.userdata);

  const [isButton, setIsButton] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [inputValues, setInputValues] = useState(["", "", "", "", "", ""]);
  const [isPage, setIsPage] = useState("page2");
  const [final, setfinal] = useState(null);
  const [phone, setphone] = useState("");
  const [countrycode, setcountrycode] = useState("");
  const [show, setshow] = useState(true);
  const [finalerror, setfinalerror] = useState(null);
  const [wrongotp, setwrongotp] = useState(false);
  const [wrongotpcount, setwrongotpcount] = useState(0);
  const [recaptchaId, setRecaptchaId] = useState(
    Array.from({ length: 6 }, () =>
      String.fromCharCode(97 + Math.floor(Math.random() * 26))
    ).join("")
  );
  const PageHandler = (event) => {
    setfinalerror(false);
    if (isPage === "page1") {
      setIsPage(event.target.id);
    } else if (isPage === "page2") {
      setfinalerror(false);
      if (phone.length !== 0) {
        if (phone.length === 10) {
          setIsChange(true);
          setTimeout(() => {
            setshow(false);
          }, 2000);
          setTimeout(() => {
            let verify = new firebase.auth.RecaptchaVerifier(recaptchaId, {
              size: "invisible",
            });
            auth
              .signInWithPhoneNumber(`${countrycode}${phone}`, verify)
              .then((result) => {
                setfinal(result);
                setshow(true);
                setIsChange(false);
                setIsPage("page3");
                setTimeout(() => {
                  setRecaptchaId(
                    Array.from({ length: 6 }, () =>
                      String.fromCharCode(97 + Math.floor(Math.random() * 26))
                    ).join("")
                  );
                }, 5000);
              })
              .catch((err) => {
                window.location.reload();
              });
          }, 100);
        } else {
          setfinalerror(true);
          setIsChange(false);
          setTimeout(() => {
            setRecaptchaId(
              Array.from({ length: 6 }, () =>
                String.fromCharCode(97 + Math.floor(Math.random() * 26))
              ).join("")
            );
          }, 5000);
        }
      } else {
        setfinalerror(true);
        setIsChange(false);
        setTimeout(() => {
          setRecaptchaId(
            Array.from({ length: 6 }, () =>
              String.fromCharCode(97 + Math.floor(Math.random() * 26))
            ).join("")
          );
        }, 5000);
      }
    } else {
      setIsPage(event.target.id);
      setfinalerror(false);
      setIsChange(false);
      setTimeout(() => {
        setRecaptchaId(
          Array.from({ length: 6 }, () =>
            String.fromCharCode(97 + Math.floor(Math.random() * 26))
          ).join("")
        );
      }, 5000);
    }
  };
  const backHandler = (event) => {
    setIsPage("page2");
    setwrongotp(false);
    setwrongotpcount(0);
    setInputValues(["", "", "", "", "", ""]);
  };
  const [isChange, setIsChange] = useState(false);

  const handleInputChange = (index, event) => {
    const value = event.target.value;
    if (value.length === 1) {
      const newInputValues = [...inputValues];
      newInputValues[index] = value;
      setInputValues(newInputValues);
      if (index < inputValues.length - 1) {
        document.getElementById(`digit-input-${index + 1}`).focus();
      }
    } else if (value.length === 0 && index > 0) {
      const newInputValues = [...inputValues];
      newInputValues[index] = "";
      setInputValues(newInputValues);
      document.getElementById(`digit-input-${index - 1}`).focus();
    } else if (index == 0) {
      setInputValues(["", "", "", "", "", ""]);
    }
  };

  const ButtonHandler1 = () => {
    setIsLoading(true);
    setwrongotp(false);
    var otp = inputValues.join("");
    final
      .confirm(otp)
      .then(async (result) => {
        setIsLoading(true);
        setIsPage("page4");
        sessionStorage.setItem("phone", phone);
        let data = JSON.stringify({
          username:
            signupdata !== null ? signupdata.username : userdata[0].username,
          email:
            signupdata !== null ? signupdata.username : userdata[0].username,
          email_verification: true,
          mobile_verification: true,
          phone: phone,
        });
        let config = {
          method: "put",
          maxBodyLength: Infinity,
          url: `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userid}/`,
          headers: {
            Authorization: `JWT ${token}`,
            "Content-Type": "application/json",
          },
          data: data,
        };
        var user_data = await axios
          .request(config)
          .then((response) => {
            return response.data;
          })
          .catch((error) => {
            return error;
          });
        if (
          user_data.message === "User and Associated Info updated successfully"
        ) {
          let updatedObject = {
            ...userdata[0],
            email_verification: true,
            mobile_verification: true,
            phone: phone,
          };
          dispatch(storeAction.userdataHander({ userdata: [] }));
          setTimeout(() => {
            dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
          }, 10);
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 2000);
      })
      .catch((err) => {
        setIsLoading(false);
        setwrongotp(true);
        setwrongotpcount(Number(wrongotpcount) + 1);
      });
  };
  useEffect(() => {
    Updatedata();
  }, []);
  const Updatedata = async () => {
    let data = JSON.stringify({
      username:
        signupdata !== null ? signupdata.username : userdata[0].username,
      email: signupdata !== null ? signupdata.username : userdata[0].username,
      email_verification: true,
      mobile_verification: false,
    });

    let config = {
      method: "put",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userid}/`,
      headers: {
        Authorization: `JWT ${token}`,
        "Content-Type": "application/json",
      },
      data: data,
    };

    var updatedata = await axios
      .request(config)
      .then((response) => {
        return response.data;
      })
      .catch((error) => {
        return error;
      });
    if (
      updatedata.message === "User and Associated Info updated successfully"
    ) {
      let updatedObject = {
        ...userdata[0],
        email_verification: true,
      };
      dispatch(storeAction.userdataHander({ userdata: [] }));
      setTimeout(() => {
        dispatch(storeAction.userdataHander({ userdata: [updatedObject] }));
      }, 10);
    }
  };
  const resendbtn = async () => {
    setfinalerror(false);
    setIsPage("page2");
    setwrongotp(false);
    setwrongotpcount(0);
    setInputValues(["", "", "", "", "", ""]);
    if (phone.length !== 0) {
      if (phone.length === 10) {
        setTimeout(() => {
          setshow(false);
        }, 2000);
        setTimeout(() => {
          let verify = new firebase.auth.RecaptchaVerifier(recaptchaId, {
            size: "invisible",
          });
          auth
            .signInWithPhoneNumber(`${countrycode}${phone}`, verify)
            .then((result) => {
              setfinal(result);
              setshow(false);
              setIsChange(false);
              setIsPage("page3");
              setTimeout(() => {
                setRecaptchaId(
                  Array.from({ length: 6 }, () =>
                    String.fromCharCode(97 + Math.floor(Math.random() * 26))
                  ).join("")
                );
              }, 5000);
            })
            .catch((err) => {
              window.location.reload();
            });
        }, 100);
      } else {
        setfinalerror(true);
        setIsChange(false);
        setTimeout(() => {
          setRecaptchaId(
            Array.from({ length: 6 }, () =>
              String.fromCharCode(97 + Math.floor(Math.random() * 26))
            ).join("")
          );
        }, 5000);
      }
    } else {
      setfinalerror(true);
      setIsChange(false);
      setTimeout(() => {
        setRecaptchaId(
          Array.from({ length: 6 }, () =>
            String.fromCharCode(97 + Math.floor(Math.random() * 26))
          ).join("")
        );
      }, 5000);
    }
  };
  const [timeLeft, setTimeLeft] = useState(25);
  useEffect(() => {
    const timer = setInterval(() => {
      if (isPage === "page4") {
        setTimeLeft((prevTime) => prevTime - 1);
      }
    }, 1000);

    return () => clearInterval(timer);
  });
  useEffect(() => {
    if (timeLeft === 0) {
      window.location.replace("/#/registration");
    }
  }, [timeLeft]);
  const minutes = Math.floor(timeLeft / 60);
  const seconds = timeLeft % 60;
  const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  const logoutbtn = async () => {
    dispatch(storeAction.reset());
    localStorage.removeItem("persist:root");
    localStorage.clear();
    sessionStorage.clear();
    window.location.replace("/#/login");
  };
  return (
    <>
      {isPage === "page2" && (
        <div className="mobileVerification">
          <div className="mobileInner">
            <Head />
            <SuccessResponse
              title="Email verification successful!"
              des="Please verify your mobile number before proceding further!"
            />
            <div className="w-full mt-5">
              <MobileInput
                setphone={setphone}
                phone={phone}
                setcountrycode={setcountrycode}
                countrycode={countrycode}
              />
            </div>
            {finalerror && (
              <p className="text-red-500 text-xs font-semibold mt-2">
                Please Enter valid Phone number
              </p>
            )}
            <div id={recaptchaId} className="forget mt-5"></div>
            {/* <div id="recaptcha-container2" className="forget mt-5"></div> */}

            {show === true ? (
              isChange === false ? (
                <button
                  id="page3"
                  onClick={PageHandler}
                  className="clientLoginCompBodyButtonEnable  buttonwidth60 mx-auto"
                >
                  Verify mobile number
                </button>
              ) : (
                <button className="clientLoginCompBodyButtonLoading mt-5  buttonwidth60 mx-auto">
                  <FiLoader className="loadingIcon" />
                </button>
              )
            ) : null}

            <p
              className="text-[#1D37E7] text-normal font-medium mt-3 hover:underline underline-offset-4 cursor-pointer"
              onClick={logoutbtn}
            >
              Log out
            </p>
          </div>
        </div>
      )}

      {/*======================= otp verification ===================== */}
      {isPage === "page3" && (
        <div className="optVerification">
          <button
            id="page2"
            onClick={backHandler}
            className="backButtonClientReg"
          >
            <img className="back" src={back} alt="" />
            Back
          </button>

          <div className="otpInner">
            <Head />
            <h2>Verify OTP</h2>
            <p>
              Enter the code we’ve sent to your phone number{" "}
              <span>
                {countrycode} {phone}
              </span>
            </p>

            <form className="six-digit-form">
              {inputValues.map((value, index) => (
                <input
                  key={index}
                  id={`digit-input-${index}`}
                  type="number"
                  maxLength="1"
                  value={value}
                  onChange={(event) => handleInputChange(index, event)}
                  class="otpinput block w-[38px] h-[38px] text-center border border-gray-200 rounded-md text-sm placeholder:text-gray-300 focus:border-blue-500 focus:ring-blue-500 disabled:opacity-50 disabled:pointer-events-none "
                />
              ))}
            </form>
            {wrongotpcount !== 5 ? (
              wrongotp && (
                <p className="otperror">
                  The OTP you entered is incorrect. Please check and enter
                  again. Attempts left: {5 - wrongotpcount}.{" "}
                </p>
              )
            ) : (
              <p className="otperror">
                You have exceeded the maximum attempts. Please regenerate a new
                OTP and try again.
              </p>
            )}

            <div className="otpInput">
              <h6>
                {" "}
                <input
                  type="checkbox"
                  onChange={() => {
                    setIsButton(!isButton);
                  }}
                  checked={isButton === true}
                />
                You agree to be contacted by our team. We will not Spam you or
                share your contact details with any 3<sup>rd</sup> party
                providers.
              </h6>
            </div>
            <div className="Btn marginTop15">
              {wrongotpcount !== 5 ? (
                isButton === true ? (
                  <button
                    id="page4"
                    onClick={ButtonHandler1}
                    className={isLoading === true ? "verifyBtn2" : "verifyBtn1"}
                  >
                    {isLoading === true ? (
                      <FiLoader className="loadingIcon" />
                    ) : (
                      "Verify"
                    )}
                  </button>
                ) : (
                  <button disabled className="verifyBtn">
                    Verify
                  </button>
                )
              ) : (
                <button disabled className="verifyBtn">
                  Verify
                </button>
              )}
              <p
                onClick={() => {
                  resendbtn();
                }}
                className="cursor-pointer"
              >
                Didn’t receive OTP? <span>Resend</span>
              </p>
            </div>
            <p
              className="text-[#1D37E7] text-normal font-medium hover:underline underline-offset-4 cursor-pointer"
              onClick={logoutbtn}
            >
              Log out
            </p>
          </div>
        </div>
      )}
      {/* ========================== page4 ================================= */}
      {isPage === "page4" && (
        <>
          <div className="h-[100vh] flex flex-col items-center justify-center">
            <SuccessResponse
              title="Verification successful!"
              des="Thank You! You are One Step Closer to start Hiring in 5. Easy hiring just ahead."
            />
            <button
              onClick={() => window.location.replace("/#/registration")}
              className="save mt-5 mb-5"
            >
              Continue
            </button>
            <h6 className="paymentTimer text-center mt-3">
              Redirecting you to the next screen in{" "}
              <span className="emailDarks">{formattedTime}s</span>
            </h6>
          </div>
        </>
      )}
    </>
  );
};

export default VerificationComp;
