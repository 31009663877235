/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable no-loop-func */
import React, { useRef, useState } from "react";
import "./VendorResumeUploadComp.css";
import DashHead from "../../Reusable/DashBoardReusable/DashHead/DashHead";
import { FiInfo } from "react-icons/fi";
import JSZip from "jszip";
import BlueUser from "../../../assests/BlueUser.svg";
import ErrorResume from "../../../assests/ErrorResume.svg";
import { useDispatch, useSelector } from "react-redux";
import { storeAction } from "../../../Store/Store";
import { IoCloseSharp } from "react-icons/io5";
import axios from "axios";
import { firebase } from "../../../database/firebase";
import { toast, Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const VendorResumeUploadComp = () => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const userdata = useSelector((store) => store.userdata);
  const token = useSelector((store) => store.token);
  const uploadHandler = () => {
    fileInputRef.current.click();
  };
  const customToastStyle = {
    background: "#14B8A6", // Change this to the desired background color
    // color: "#FFFFF", // Text color
    "& .Toastify__toast-body svg": {
      fill: "var(--toastify-color-light)", // Color of the success icon
    },
    color: "white", // Text color
    "--toastify-icon-color-success": "white",
  };

  const [files, setFiles] = useState([]);
  const [pdfFiles, setPdfFiles] = useState([]);
  const [unsupportedFiles, setUnsupportedFiles] = useState([]);
  const [uploadstatus, setuploadstatus] = useState(false);
  const [percentage, setpercentage] = useState(0);
  const [infodiv, setinfodiv] = useState(false);
  const [uploaded, setuploaded] = useState(false);
  const [toastifystatus, settoastifystatus] = useState(false);
  const [uploadedcount, setuploadedcount] = useState(0);

  const handleFileUpload = async (event) => {
    setuploadstatus(true);
    setinfodiv(false);
    setpercentage(0);
    const uploadedFiles = Array.from(event.target.files);
    const zipFiles = [];
    const newPdfFiles = [];
    for (const file of uploadedFiles) {
      if (
        file.type === "application/zip" ||
        file.type === "application/x-zip-compressed"
      ) {
        zipFiles.push(file);
      } else if (file.type === "application/pdf") {
        newPdfFiles.push(file);
      } else {
        // Handle unsupported files
        setUnsupportedFiles((prevUnsupportedFiles) => [
          ...prevUnsupportedFiles,
          file.name,
        ]);
      }
    }
    const extractedFiles = await extractZipFiles(zipFiles);
    setFiles(extractedFiles);
    setPdfFiles((prevPdfFiles) => [...prevPdfFiles, ...newPdfFiles]);
    fileInputRef.current.value = "";

    const intervalId = setInterval(() => {
      setpercentage((prevPercentage) => {
        const newPercentage = prevPercentage + 25;
        return newPercentage <= 100 ? newPercentage : 100;
      });
    }, 1000);

    setTimeout(() => {
      clearInterval(intervalId);
      setuploadstatus(false);
      setinfodiv(true);
    }, 6000);
  };

  const extractZipFiles = async (zipFiles) => {
    const extractedFiles = [];

    for (const file of zipFiles) {
      const zip = new JSZip();
      const zipContents = await zip.loadAsync(file);

      zip.forEach(async (relativePath, file) => {
        if (file.dir) {
          return; // Skip directories
        }

        if (relativePath.endsWith(".pdf")) {
          const fileData = await file.async("blob");
          const extractedFile = new File([fileData], relativePath, {
            type: fileData.type,
          });
          extractedFiles.push(extractedFile);

          setPdfFiles((prevPdfFiles) => [...prevPdfFiles, extractedFile]);
        } else {
          setUnsupportedFiles((prevUnsupportedFiles) => [
            ...prevUnsupportedFiles,
            relativePath,
          ]);
        }
      });
    }

    return extractedFiles;
  };

  const isPopUp = useSelector((store) => store.isPopUp);
  const OverLayHandler = (e) => {
    dispatch(storeAction.isPopUpHander(e.target.id));
  };

  const uploadPdf = async (pdfFile) => {
    var id = Math.floor(100000 + Math.random() * 900000);
    if (pdfFile) {
      let file = pdfFile;
      let file13 = new Promise((resolve, reject) => {
        var storageRef = firebase.storage().ref(`cv/${id}` + file.name);
        storageRef.put(file).then(function (snapshot) {
          storageRef.getDownloadURL().then(function (url) {
            setTimeout(() => resolve(url), 1000);
          });
        });
      }).catch((err) => {
        console.log(err, "errr");
      });
      var imgurl_new2 = await file13;
      setuploadedcount((prevUploaded) => prevUploaded + 1);
      const reader = new FileReader();
      reader.onload = async function (event) {
        try {
          const base64String = event.target.result.split(";base64,")[1];
          const sixDigitNumber = Math.floor(100000 + Math.random() * 900000);
          let dataToSend = JSON.stringify({
            index: {
              indexType: "Resume",
              indexKey: "KDJ6NFUC",
            },
            data: {
              id: sixDigitNumber.toString(),
              fileContent: base64String,
              fileName: pdfFile.name,
            },
            commit: true,
            deDuplication: {
              checkDuplicate: true,
              fields: ["Email"],
              update: true,
            },
          });

          let config = {
            method: "post",
            maxBodyLength: Infinity,
            url: "https://searchengine.rchilli.com/RChilliSearchEngineAPI/RChilli/parseAndIndex",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            data: dataToSend,
          };

          var indexdata = await axios
            .request(config)
            .then((response) => {
              return response.data;
            })
            .catch((error) => {
              throw error;
            });

          if (indexdata && indexdata.status == 200) {
            try {
              const base64EncodedString = indexdata.doc;
              let decodedString = atob(base64EncodedString);
              var resumedata2 = JSON.parse(decodedString);
              const arrayOfStrings =
                resumedata2.ResumeParserData.LanguageKnown.map((obj) =>
                  obj.Language && obj.Language.length
                    ? `${obj.Language}: Proficient`
                    : ""
                ).filter((str) => str !== "");
              var creteobj = {
                email: resumedata2.ResumeParserData.Email[0].EmailAddress,
                username: resumedata2.ResumeParserData.Email[0].EmailAddress,
                password: "Password@123",
                role: 3,
                status: "Pending",
                vendor: userdata[0].id,
              };

              let webSites = resumedata2.ResumeParserData.WebSite;
              var linkedinurl = "";
              var githuburl = "";
              webSites.forEach((site) => {
                if (site.Type === "Github") {
                  githuburl = site.Url;
                } else if (site.Type === "Linkedin") {
                  linkedinurl = site.Url;
                }
              });

              var createuser = await axios
                .post(
                  `${process.env.REACT_APP_LOCAL_HOST_URL}/user/create/`,
                  creteobj
                )
                .then((res) => {
                  return res.data;
                })
                .catch((err) => {
                  return err.response;
                });

              if (createuser.access_token !== undefined) {
                const skills =
                  resumedata2.ResumeParserData.SkillKeywords.length !== 0
                    ? resumedata2.ResumeParserData.SkillKeywords.split(",")
                    : [];
                const firstFiveSkills =
                  skills.length !== 0 ? skills.slice(0, 5) : [];
                var newobj = {
                  first_name: resumedata2.ResumeParserData.Name.FullName,
                  email: resumedata2.ResumeParserData.Email[0].EmailAddress,
                  gender: resumedata2.ResumeParserData.Gender,
                  username: resumedata2.ResumeParserData.Email[0].EmailAddress,
                  phone: resumedata2.ResumeParserData.PhoneNumber[0].Number,
                  title: "Candidate",
                  nottify: false,
                  fresher: false,
                  freshers_status: false,
                  interview_status: "False",
                  role: "3",
                  date_of_birth: resumedata2.ResumeParserData.DateOfBirth,
                  current_place_of_residence:
                    resumedata2.ResumeParserData.Address[0].Country,
                  lived_at_current_residence: 0,
                  email_verification: true,
                  mobile_verification: true,
                  onboarding_status: "1",
                  bio: resumedata2.ResumeParserData.ExecutiveSummary,
                  status: "Pending",
                  apprual: false,
                  dissabled: false,
                  hired_status: "",
                  
                  resume_json: JSON.stringify(resumedata2.ResumeParserData),
                  vendor: userdata[0].id,
                  new_candidate_info: {
                    resume: imgurl_new2,
                    location: resumedata2.ResumeParserData.Address[0].Country,
                  },
                  address: {
                    address: resumedata2.ResumeParserData.Address[0].Street,
                    city: resumedata2.ResumeParserData.Address[0].City,
                    state: resumedata2.ResumeParserData.Address[0].State,
                    pincode:
                      resumedata2.ResumeParserData.Address[0].ZipCode.length !==
                      0
                        ? Number(
                            resumedata2.ResumeParserData.Address[0].ZipCode
                          )
                        : 0,
                    country: resumedata2.ResumeParserData.Address[0].Country,
                  },
                  kyc_info: {
                    pan_number: resumedata2.PanNo,
                  },
                  passport_info: {
                    passport_number:
                      resumedata2.ResumeParserData.PassportDetail
                        .PassportNumber,
                    passport_validity:
                      resumedata2.ResumeParserData.PassportDetail.DateOfExpiry,
                    country_of_citizenship:
                      resumedata2.ResumeParserData.PassportDetail.PlaceOfIssue,
                    country_of_issue:
                      resumedata2.ResumeParserData.PassportDetail.PlaceOfIssue,
                  },
                  preference_info: {
                    year_of_experience:
                      resumedata2.ResumeParserData.WorkedPeriod
                        .TotalExperienceInYear.length !== 0
                        ? Number(
                            Math.round(
                              resumedata2.ResumeParserData.WorkedPeriod
                                .TotalExperienceInYear
                            )
                          )
                        : 0,
                    skills:
                      resumedata2.ResumeParserData.SkillKeywords.split(","),
                    linkedin: linkedinurl ? linkedinurl : undefined,
                    github: githuburl ? githuburl : undefined,
                    language:
                      resumedata2.ResumeParserData.LanguageKnown.length !== 0
                        ? arrayOfStrings
                        : undefined,
                  },
                  work_preference_info: {
                    key_skills: firstFiveSkills,
                    github_url: githuburl ? githuburl : undefined,
                  },
                };
                await axios
                  .put(
                    `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${createuser.id}/`,
                    newobj,
                    {
                      headers: {
                        "Content-Type": "application/json",
                        Authorization: `JWT ${createuser.access_token}`,
                      },
                    }
                  )
                  .then((res) => {
                    return res.data;
                  })
                  .catch((err) => {
                    return err.response;
                  });

                var wallet =
                  userdata[0].wallet.length == 0
                    ? 0
                    : Number(userdata[0].wallet);
                if (wallet > 10) {
                  var newobj_wallet = {
                    wallet:
                      userdata[0].wallet.length == 0
                        ? 0
                        : Number(userdata[0].wallet) - 10,
                    username: userdata[0].username,
                  };
                  const chars =
                    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
                  const randomValue =
                    "pay_" +
                    Array.from(
                      { length: 14 },
                      () => chars[Math.floor(Math.random() * chars.length)]
                    ).join("");
                  var obj_wallet = {
                    client_id: userdata[0].id,
                    opening_wallet:
                      userdata[0].wallet.length == 0 ? 0 : userdata[0].wallet,
                    closing_wallet:
                      userdata[0].wallet.length == 0
                        ? 0 - 50
                        : Number(userdata[0].wallet) - 10,
                    activity: "Upload Resume",
                    wallet_detection: "10",
                    trasaction_id: randomValue,
                  };
                  await axios
                    .post(
                      `${process.env.REACT_APP_LOCAL_HOST_URL}/wallet/`,
                      obj_wallet,
                      {
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: `JWT ${token}`,
                        },
                      }
                    )
                    .then((res) => {
                      return res.data;
                    })
                    .catch((err) => {
                      return err.response;
                    });
                  var updatedata = await axios
                    .put(
                      `${process.env.REACT_APP_LOCAL_HOST_URL}/user/update/${userdata[0].id}/`,
                      newobj_wallet,
                      {
                        headers: {
                          "Content-Type": "application/json",
                          Authorization: `JWT ${token}`,
                        },
                      }
                    )
                    .then((res) => {
                      return res.data;
                    })
                    .catch((err) => {
                      return err.response;
                    });
                  if (
                    updatedata.message ===
                    "User and Associated Info updated successfully"
                  ) {
                    let updatedObject = {
                      ...userdata[0],
                      wallet:
                        userdata[0].wallet.length == 0
                          ? 0
                          : Number(userdata[0].wallet) - 10,
                    };
                    dispatch(
                      storeAction.userdataHander({ userdata: [updatedObject] })
                    );
                    dispatch(storeAction.isPopUpHander());
                  }
                }

                if (
                  resumedata2.ResumeParserData.SegregatedExperience.length !== 0
                ) {
                  for (
                    var i = 0;
                    i <
                    resumedata2.ResumeParserData.SegregatedExperience.length;
                    i++
                  ) {
                    var profesionaldata = {
                      username:
                        resumedata2.ResumeParserData.Email[0].EmailAddress,
                      professional_details_info: {
                        user: createuser.id,
                        title:
                          resumedata2.ResumeParserData.SegregatedExperience[i]
                            .JobProfile.Title,
                        years_active: `${
                          resumedata2.ResumeParserData.SegregatedExperience[i]
                            .StartDate.length !== 0
                            ? resumedata2.ResumeParserData.SegregatedExperience[
                                i
                              ].StartDate.split("/")[2] -
                              resumedata2.ResumeParserData.SegregatedExperience[
                                i
                              ].StartDate.split("/")[1] -
                              resumedata2.ResumeParserData.SegregatedExperience[
                                i
                              ].StartDate.split("/")[0]
                            : ""
                        },${
                          resumedata2.ResumeParserData.SegregatedExperience[i]
                            .EndDate.length !== 0
                            ? resumedata2.ResumeParserData.SegregatedExperience[
                                i
                              ].EndDate.split("/")[2] -
                              resumedata2.ResumeParserData.SegregatedExperience[
                                i
                              ].EndDate.split("/")[1] -
                              resumedata2.ResumeParserData.SegregatedExperience[
                                i
                              ].EndDate.split("/")[0]
                            : ""
                        }`,
                        company_name:
                          resumedata2.ResumeParserData.SegregatedExperience[i]
                            .Employer.EmployerName,
                        location:
                          resumedata2.ResumeParserData.SegregatedExperience[i]
                            .Location.City,
                        description:
                          resumedata2.ResumeParserData.SegregatedExperience[i]
                            .JobDescription,
                      },
                    };
                    await axios
                      .post(
                        `${process.env.REACT_APP_LOCAL_HOST_URL}/getProffessionalDetails/${createuser.id}/`,
                        profesionaldata,
                        {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `JWT ${createuser.access_token}`,
                          },
                        }
                      )
                      .then((res) => {
                        return res.data;
                      })
                      .catch((err) => {
                        return err.response;
                      });
                  }
                }

                if (
                  resumedata2.ResumeParserData.SegregatedQualification
                    .length !== 0
                ) {
                  for (
                    var j = 0;
                    j <
                    resumedata2.ResumeParserData.SegregatedQualification.length;
                    j++
                  ) {
                    var education_infodata = {
                      username:
                        resumedata2.ResumeParserData.Email[0].EmailAddress,
                      education_info: {
                        user: createuser.id,
                        degree:
                          resumedata2.ResumeParserData.SegregatedQualification[
                            j
                          ].Degree.DegreeName,
                        year_of_graduation:
                          resumedata2.ResumeParserData.SegregatedQualification[
                            j
                          ].EndDate.split("/")[2],
                        university_name:
                          resumedata2.ResumeParserData.SegregatedQualification[
                            j
                          ].Institution.Name,
                        education_level:
                          resumedata2.ResumeParserData.SegregatedQualification[
                            j
                          ].Institution.Type,
                      },
                    };
                    await axios
                      .post(
                        `${process.env.REACT_APP_LOCAL_HOST_URL}/getEducations/${createuser.id}/`,
                        education_infodata,
                        {
                          headers: {
                            "Content-Type": "application/json",
                            Authorization: `JWT ${createuser.access_token}`,
                          },
                        }
                      )
                      .then((res) => {
                        return res.data;
                      })
                      .catch((err) => {
                        return err.response;
                      });
                  }
                }
              } else {
              }
            } catch (error) {
              console.error("Error decoding Base64 string:", error);
            }
          }
        } catch (error) {
          console.error("Error processing PDF file:", error);
        }
        setTimeout(() => {
          return true;
        }, 2000);
      };
      reader.readAsDataURL(pdfFile);
    }
  };

  const submitresume = async () => {
    if (pdfFiles.length !== 0) {
      setuploaded(true);
      dispatch(storeAction.isPopUpHander("loader"));
      for (var i = 0; i < pdfFiles.length; i++) {
        await uploadPdf(pdfFiles[i]);
      }
      settoastifystatus(true);
      setTimeout(() => {
        toast.success("Resume Uploaded Successfully", {
          autoClose: 2000,
          transition: Slide,
          style: customToastStyle,
        });
      }, 3000);
      setTimeout(() => {
        settoastifystatus(true);
      }, 5000);
      var newobj1 = {
        vendor: userdata[0].id,
      };
      var candidatedata1 = await axios
        .post(
          `${process.env.REACT_APP_LOCAL_HOST_URL}/vendor/users/`,
          newobj1,
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `JWT ${token}`,
            },
          }
        )
        .then((res) => {
          return res.data;
        })
        .catch((err) => {
          return err.response;
        });
      if (candidatedata1.length !== 0) {
        candidatedata1.sort((a, b) => b.created_at - a.created_at);
        dispatch(
          storeAction.vendorcandidateHander({
            vendorcandidate: candidatedata1,
          })
        );
      }
      setTimeout(() => {
        setuploaded(false);
        dispatch(storeAction.isPopUpHander());
        window.location.replace("/#/vendorcandidatelist");
      }, 10000);
    }
  };
  var newpercentage = Math.round(
    (100 / Number(pdfFiles.length)) * uploadedcount
  );
  return (
    <div>
      <div className="vendorResumeUploadComp paddingLeft100 paddingRight100">
        <div className="vendorResumeUploadCompHead">
          <DashHead
            head="Upload Resume"
            desc="Lorem Ipsum is simply dummy text of the printing and typesetting industry"
            descClass="dashBoardMainHeadDesc"
          />
        </div>
        <div className="vendorResumeUploadCompBody">
          <h1 className="vendorResumeUploadCompBodyH1">
            Please drop single or multiple resumes here.
          </h1>
          <div className="vendorResumeUploadCompContent">
            <div
              className="vendorResumeUploadCompContentDropFile"
              onClick={uploadHandler}
            >
              <h2>
                Drop your files here or <span>browse</span>
              </h2>
              <p>Maximum size: 5MB</p>
              <p>PDF or zip accepted</p>
            </div>
            <input
              type="file"
              ref={fileInputRef}
              style={{ display: "none" }}
              accept=".zip,.pdf"
              multiple
              onChange={handleFileUpload}
            />
            <div className="vendorResumeUploadCompInfo">
              <FiInfo />
              <h5>
                After uploading your resume, it will undergo parsing, which may
                take some time.
              </h5>
            </div>
          </div>
        </div>
        {uploadstatus && (
          <>
            <div className="candidateProgressHead mt-5">
              <h1>Resume Uploaded</h1>
              <h2>{percentage}%</h2>
            </div>
            <div className="progress-container">
              <progress
                id="file"
                value={percentage}
                max="100"
                className="progress_bar1 bg-primaryColor"
              >
                {percentage}%
              </progress>
            </div>
          </>
        )}
        {infodiv && (
          <div className="resumeStatus">
            <div className="totalResumeConatiner">
              <div className="totalResumeBody">
                <div className="totalResumeConatinerContent">
                  <p>Total Resumes</p>
                  <h1>{pdfFiles.length}</h1>
                </div>
                <div className="totalResumeConatinerContentImg">
                  <img src={BlueUser} alt="" />
                </div>
              </div>
              {uploaded == true ? (
                <button className="totalResumeButton">Please Wait...</button>
              ) : (
                <button className="totalResumeButton" onClick={submitresume}>
                  Populate Candidate Profiles
                </button>
              )}
            </div>
            <div className="totalResumeConatiner">
              <div className="totalResumeBody">
                <div className="totalResumeConatinerContent">
                  <p>Format Error</p>
                  <h1>{unsupportedFiles.length}</h1>
                </div>
                <div className="totalResumeConatinerContentImg1">
                  <img src={ErrorResume} alt="" />
                </div>
              </div>
              <button
                id="resumeError"
                onClick={OverLayHandler}
                className="totalResumeButton"
              >
                View Resumes
              </button>
            </div>
          </div>
        )}
      </div>
      {isPopUp == "resumeError" && (
        <>
          <div className="resumeErrorConatiner overlay">
            <div className="resumeErrorConatinerHead">
              <h1>Format Error Resumes</h1>
              <IoCloseSharp onClick={OverLayHandler} />
            </div>
            <div className="resumeErrorContent">
              {unsupportedFiles.length !== 0
                ? unsupportedFiles.map((data, index) => (
                    <li key={index}>{data}</li>
                  ))
                : null}

              <div className="resumeErrorButton">
                <button
                  onClick={() => {
                    setUnsupportedFiles([]);
                    dispatch(storeAction.isPopUpHander(""));
                  }}
                >
                  Upload Again
                </button>
              </div>
            </div>
          </div>
        </>
      )}
      {isPopUp == "loader" && (
        <>
          <div className="resumeErrorConatiner overlay">
            <div className="px-8 py-5">
              <div className="candidateProgressHead mt-5">
                <h1>Parsing Resume</h1>
                <h2>{newpercentage}%</h2>
              </div>
              <progress
                id="file"
                value={newpercentage}
                max="100"
                className="progress_bar1 bg-primaryColor"
              >
                {newpercentage}%
              </progress>
            </div>
          </div>
        </>
      )}
      {toastifystatus && <ToastContainer />}
    </div>
  );
};

export default VendorResumeUploadComp;
