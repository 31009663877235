// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.successResponse {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.successResponseInner {
    width: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.successResponseInner img {
    width: 88px;
    height: 88px;
    border-radius: 100%;
}

.successResponseInner h1 {
    font-size: 22px;
    font-weight: 600;
    margin-top: 0px !important;

}

.successResponseInner p {
    font-size: 14px;
    color: var(--textColor);
    margin-top: 5px;
    text-align: center;
}

@media only screen and (max-width: 600px) {
    .successResponseInner img {
        width: 50px;
        height: 50px;
    }

    .successResponseInner h1 {
        font-size: 18px;
    }

    .successResponseInner p {
        font-size: 13px;
        text-align: center;
        width: 90%;
        margin-left: auto;
        margin-right: auto;
    }
}`, "",{"version":3,"sources":["webpack://./src/Components/Reusable/SuccessResponse/SuccessResponse.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,aAAa;IACb,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,WAAW;IACX,aAAa;IACb,sBAAsB;IACtB,mBAAmB;AACvB;;AAEA;IACI,WAAW;IACX,YAAY;IACZ,mBAAmB;AACvB;;AAEA;IACI,eAAe;IACf,gBAAgB;IAChB,0BAA0B;;AAE9B;;AAEA;IACI,eAAe;IACf,uBAAuB;IACvB,eAAe;IACf,kBAAkB;AACtB;;AAEA;IACI;QACI,WAAW;QACX,YAAY;IAChB;;IAEA;QACI,eAAe;IACnB;;IAEA;QACI,eAAe;QAIf,kBAAkB;QAClB,UAAU;QACV,iBAAiB;QACjB,kBAAkB;IANtB;AAQJ","sourcesContent":[".successResponse {\n    width: 100%;\n    display: flex;\n    align-items: center;\n    justify-content: center;\n}\n\n.successResponseInner {\n    width: auto;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n}\n\n.successResponseInner img {\n    width: 88px;\n    height: 88px;\n    border-radius: 100%;\n}\n\n.successResponseInner h1 {\n    font-size: 22px;\n    font-weight: 600;\n    margin-top: 0px !important;\n\n}\n\n.successResponseInner p {\n    font-size: 14px;\n    color: var(--textColor);\n    margin-top: 5px;\n    text-align: center;\n}\n\n@media only screen and (max-width: 600px) {\n    .successResponseInner img {\n        width: 50px;\n        height: 50px;\n    }\n\n    .successResponseInner h1 {\n        font-size: 18px;\n    }\n\n    .successResponseInner p {\n        font-size: 13px;\n    }\n\n    .successResponseInner p {\n        text-align: center;\n        width: 90%;\n        margin-left: auto;\n        margin-right: auto;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
