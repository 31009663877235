import React from "react";

const Error = () => {
  return (
    <div className="text-center items-center flex px-80 text-xl text-red-500 font-medium py-12 h-full">
      The access to this table is Restricted
    </div>
  );
};

export default Error;
