import React from "react";

const data = {
  status1: 1,
  status2: 2,
  status3: 3,
};

const getTotal = () => {
  return Object.values(data).reduce((acc, curr) => acc + curr, 0);
};

const CircularChart = () => {
  const total = getTotal();
  const radius = 50;
  const strokeWidth = 10;
  let angle = -50; // Start angle at the top (-90 degrees)

  const segments = Object.keys(data).map((status, index) => {
    const value = data[status];
    const percentage = (value / total) * 100;
    const color = index === 0 ? "#223EF2" : index === 1 ? "#7797F7" : "#C7D2FC";

    const startAngle = angle;
    const endAngle = angle + (percentage / 100) * 360;

    const startX = radius + radius * Math.cos((startAngle * Math.PI) / 180);
    const startY = radius + radius * Math.sin((startAngle * Math.PI) / 180);
    const endX = radius + radius * Math.cos((endAngle * Math.PI) / 180);
    const endY = radius + radius * Math.sin((endAngle * Math.PI) / 180);

    const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

    const pathData = `
      M ${startX} ${startY}
      A ${radius} ${radius} 0 ${largeArcFlag} 1 ${endX} ${endY}
    `;

    angle = endAngle;

    return (
      <path
        key={status}
        d={pathData}
        fill="transparent"
        stroke={color}
        strokeWidth={strokeWidth}
      />
    );
  });

  // Calculate text position
  const textX = radius;
  const textY = radius;

  return (
    <svg
      width={2 * radius}
      height={2 * radius}
      viewBox={`0 0 ${2 * radius} ${2 * radius}`}
      xmlns="http://www.w3.org/2000/svg"
      style={{ overflow: "visible" }} // Ensure overflow is visible
    >
      <g transform={`translate(1, 1)`}>
        {segments}
        <text
          x={textX}
          y={textY}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="16"
        >
          {total}
        </text>
        <text
          x={textX}
          y={textY + 20}
          textAnchor="middle"
          dominantBaseline="middle"
          fontSize="12"
          fill="#71717a"
        >
          Total
        </text>
      </g>
    </svg>
  );
};

export default CircularChart;
