/* eslint-disable no-unused-vars */
/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import axios from "axios";
import React, { useEffect, useState } from "react";
import { FaRegCircleXmark } from "react-icons/fa6";
import { FiPlusCircle } from "react-icons/fi";
import { IoEye } from "react-icons/io5";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { storeAction } from "../../../Store/Store";
import * as XLSX from "xlsx";

const CandidateApplicationFlow = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const token = useSelector((store) => store.token);
  const [teamlist, setteamlist] = useState([
    {
      id: uuidv4(),
      first_name: "",
      last_name: "",
      candidate_registered: "",
      sent_application_link: "",
      approved_recruiter: "",
      chase_date1: "",
      chase_date2: "",
      comments: "",
      resons_for_decline: "",
      date_commenced: "",
      app_completion_status: "",
      isNew: true,
      move: "true",
    },
  ]);
  const [loading, setloading] = useState(false);
  const [loading1, setloading1] = useState(false);
  const [count, setcount] = useState(0);
  const [pagination_status, setpagination_status] = useState(false);
  const [newurl, setnewurl] = useState(null);
  const [candidatenext, setcandidatenext] = useState(null);
  const [candidateprevious, setcandidateprevious] = useState(null);
  const [assessmentdata, setassessmentdata] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");

  const handleChangeNew = (index, field, value) => {
    const updatedTeams = teamlist.map((candidate, cIndex) => {
      if (cIndex === index) {
        return {
          ...candidate,
          [field]: value,
        };
      }
      return candidate;
    });
    setteamlist(updatedTeams);
  };

  // const addCandidate = () => {
  //   const newCandidate = {
  //     id: uuidv4(),
  //     first_name: "",
  //     last_name: "",
  //     candidate_registered: "",
  //     sent_application_link: "",
  //     approved_recruiter: "",
  //     chase_date1: "",
  //     chase_date2: "",
  //     comments: "",
  //     resons_for_decline: "",
  //     date_commenced: "",
  //     app_completion_status: "",
  //     isNew: true,
  //   };

  //   setteamlist([...teamlist, newCandidate]);
  // };

  // const removeCandidate = (candidateId) => {
  //   const updatedTeamlist = teamlist.filter(
  //     (candidate) => candidate.id !== candidateId
  //   );
  //   setteamlist(updatedTeamlist);
  // };

  const submitbtn = async () => {
    if (teamlist.length !== 0) {
      setloading(true);
      for (let i = 0; i < teamlist.length; i++) {
        const candidate = teamlist[i];
        const newCandidateData = {
          first_name: candidate.first_name,
          last_name: candidate.last_name,
          candidate_registered: candidate.candidate_registered,
          sent_application_link: candidate.sent_application_link,
          approved_recruiter: candidate.approved_recruiter,
          chase_date1: candidate.chase_date1,
          chase_date2: candidate.chase_date2,
          comments: candidate.comments,
          resons_for_decline: candidate.resons_for_decline,
          date_commenced: candidate.date_commenced,
          app_completion_status: candidate.app_completion_status,
          email: candidate.email,
        };
        if (candidate.isNew) {
          await axios
            .post(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/candidate-appflow/`,
              newCandidateData,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .then((res) => {
              candidate.isNew = false;
            })
            .catch((err) => console.error(err));
        } else {
          await axios
            .put(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/candidate-appflow/${candidate.id}/`,
              newCandidateData,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .catch((err) => console.error(err));
        }
      }
      setloading(false);
    }
  };

  useEffect(() => {
    Getalldata();
  }, []);

  const Getalldata = async () => {
    const Alldata = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/list/candidate-appflow/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response);

    if (Alldata.results.length !== 0) {
      setcount(Alldata.count);
      if (Alldata.count > 20) {
        setpagination_status(true);
      } else {
        setpagination_status(false);
      }
      setcandidateprevious(Alldata.previous);
      setcandidatenext(Alldata.next);
      const updatedData = Alldata.results.map((candidate) => ({
        ...candidate,
        isNew: candidate.id ? false : true,
        move: "false",
      }));
      setteamlist(updatedData);
    }
    const AllAsseData = await axios
      .get(`${process.env.REACT_APP_LOCAL_HOST_URL}/assessment/`, {
        headers: {
          "Content-Type": "application/json",
          Authorization: `JWT ${token}`,
        },
      })
      .then((res) => res.data)
      .catch((err) => err.response);
    if (AllAsseData.results.length !== 0) {
      setassessmentdata(AllAsseData.results);
    }
  };
  const moveToass = async () => {
    setloading1(true);
    teamlist.forEach(async (teamMember) => {
      const matchedAssessment = assessmentdata.find(
        (assessment) => assessment.email === teamMember.email
      );
      if (teamMember.move == "true") {
        if (matchedAssessment) {
          const obj = {
            email: teamMember.email,
            first_name: teamMember.first_name,
            last_name: teamMember.last_name,
          };
          await axios
            .put(
              `${process.env.REACT_APP_LOCAL_HOST_URL}/assessment/${matchedAssessment.id}/`,
              obj,
              {
                headers: {
                  "Content-Type": "application/json",
                  Authorization: `JWT ${token}`,
                },
              }
            )
            .catch((err) => console.error(err));
        } else {
          const obj1 = {
            email: teamMember.email,
            first_name: teamMember.first_name,
            last_name: teamMember.last_name,
          };
          await axios
            .post(`${process.env.REACT_APP_LOCAL_HOST_URL}/assessment/`, obj1, {
              headers: {
                "Content-Type": "application/json",
                Authorization: `JWT ${token}`,
              },
            })
            .catch((err) => console.error(err));
        }
      }
    });
    Getalldata();
    setTimeout(() => {
      setloading1(false);
    }, 2000);
  };

  const exportBtn = async () => {
    if (filteredData.length !== 0) {
      const manipulatedData = filteredData.map((item) => {
        return {
          First_Name: item.first_name,
          Last_Name: item.last_name,
          Candidate_Registered: item.candidate_registered,
          Sent_Application_Link: item.sent_application_link,
          Approved_Recruiter: item.approved_recruiter,
          Chase_Date1: item.chase_date1,
          Chase_Date2: item.chase_date2,
          Comments: item.comments,
          Resons_For_Decline: item.resons_for_decline,
        };
      });
      const worksheet = XLSX.utils.json_to_sheet(manipulatedData);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
      XLSX.writeFile(workbook, "Candidate Application Flow.xlsx");
    }
  };
  const handleDateChange = (type, value) => {
    if (type === "from") {
      setFromDate(value);
    } else {
      setToDate(value);
    }
  };

  const filterByDate = (data) => {
    if (!fromDate && !toDate) return data; // If no dates are selected, return all data

    const from = fromDate ? new Date(fromDate) : null;
    const to = toDate ? new Date(toDate) : null;

    return data.filter((candidate) => {
      const callDate = new Date(candidate.chase_date1 || candidate.chase_date2);
      return (!from || callDate >= from) && (!to || callDate <= to);
    });
  };

  useEffect(() => {
    GetnewCandidate();
  }, [newurl]);

  const GetnewCandidate = async () => {
    if (newurl !== null) {
      dispatch(storeAction.isPopUpHander("loading"));
      const response = await axios
        .get(`${newurl}`, {
          headers: {
            "Content-Type": "application/json",
            Authorization: `JWT ${token}`,
          },
        })
        .then((res) => {
          return res.data;
        });
      if (response.results.length !== 0) {
        setcount(response.count);
        if (response.count > 20) {
          setpagination_status(true);
        } else {
          setpagination_status(false);
        }
        setcandidateprevious(response.previous);
        setcandidatenext(response.next);
        const updatedData = response.results.map((candidate) => ({
          ...candidate,
          isNew: candidate.id ? false : true,
          move: "false",
        }));
        setteamlist(updatedData);
        dispatch(storeAction.isPopUpHander());
      }
    }
  };

  const filteredData = filterByDate(teamlist);

  const nPages = Math.ceil(count / 20);
  const pageNumbers = Array.from({ length: nPages }, (_, i) => i + 1);
  return (
    <div>
      <div className="flex gap-2 items-center">
        <div className="flex gap-2">
          <div className="flex flex-col">
            <input
              type="date"
              className="border border-[#7e3794] rounded p-1"
              value={fromDate}
              onChange={(e) => handleDateChange("from", e.target.value)}
            />
          </div>
          <div className="flex flex-col">
            <input
              type="date"
              className="border border-[#7e3794] rounded p-1"
              value={toDate}
              onChange={(e) => handleDateChange("to", e.target.value)}
            />
          </div>
        </div>
        <button className="importbtn" onClick={exportBtn}>
          Export
        </button>
      </div>
      <table>
        <thead>
          <tr>
            <th colSpan={7} className="bg-blue-500 text-white py-2">
              Reviewed under onboarding
            </th>
            <th colSpan={4} className="bg-green-500 text-white py-2">
              If Candidate not registered
            </th>
            <th colSpan={5} className="bg-orange-300 text-white py-2">
              If candidate has registered and started application
            </th>
          </tr>
          <tr className="hireTableHead">
            <th>Action</th>
            <th>View</th>
            <th>First & Middle Name</th>
            <th>Last Name</th>
            <th>Candidate Registered </th>
            <th>Application Link Status</th>
            <th>Profile Approved by</th>
            <th>Chase Date 1</th>
            <th>Chase Date 2</th>
            <th>Comments</th>
            <th>Reasons for Decline</th>
            <th>Date Commenced Application</th>
            <th>completion %</th>
            <th>Show application status</th>
          </tr>
        </thead>

        <tbody className="hireTableBody">
          {filteredData.length !== 0 &&
            filteredData.map((role, roleIndex) => (
              <tr className="hireTablerow" key={roleIndex}>
                {/* <td>
                  <div className="flex items-center justify-center">
                    <FaRegCircleXmark
                      className="xIcon"
                      onClick={() => removeCandidate(0, role.id)}
                    />
                    <FiPlusCircle
                      className="plusIcon"
                      onClick={() => addCandidate(0)}
                    />
                  </div>
                </td> */}
                <td>
                  <h3>
                    <input
                      type="checkbox"
                      checked={role.move == "true"}
                      onChange={() => {
                        handleChangeNew(
                          roleIndex,
                          "move",
                          role.move == "true" ? "false" : "true"
                        );
                      }}
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    {role.staff_data !== null ? (
                      <IoEye
                        className="plusIcon cursor-pointer"
                        onClick={() => {
                          dispatch(
                            storeAction.singleuserHander({
                              singleuser: [role.staff_data],
                            })
                          );
                          navigate("/admincandidateview");
                        }}
                      />
                    ) : null}
                  </h3>
                </td>

                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.first_name}
                      disabled
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "first_name", e.target.value)
                      }
                    />
                    {/* {role.first_name} */}
                  </h3>
                </td>
                <td>
                  <h3 className="cursor-pointer">
                    <input
                      className="hireTableBodySelect"
                      value={role.last_name}
                      type="text"
                      disabled
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "last_name", e.target.value)
                      }
                    />
                    {/* {role.last_name} */}
                  </h3>
                </td>
                <td>
                  <h3>
                    {/* <select
                      className="hireTableBodySelect"
                      value={role.candidate_registered}
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "candidate_registered",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select> */}
                    {role.candidate_registered}
                  </h3>
                </td>
                <td>
                  <h3>
                    {/* <select
                      className="hireTableBodySelect"
                      value={role.sent_application_link}
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "sent_application_link",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select> */}
                    {role.sent_application_link}
                  </h3>
                </td>
                <td>
                  <h3>
                    <select
                      className="hireTableBodySelect"
                      value={role.approved_recruiter}
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "approved_recruiter",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Shikha">Shikha</option>
                      <option value="Sanskriti">Sanskriti</option>
                      <option value="Sanchan">Sanchan</option>
                      <option value="Divya">Divya</option>
                      <option value="Priya">Priya</option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      type="date"
                      value={role.chase_date1}
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "chase_date1",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      type="date"
                      value={role.chase_date2}
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "chase_date2",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      value={role.comments}
                      type="text"
                      onChange={(e) =>
                        handleChangeNew(roleIndex, "comments", e.target.value)
                      }
                    />
                  </h3>
                </td>
                <td>
                  <h3>
                    <select
                      className="hireTableBodySelect"
                      value={role.resons_for_decline}
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "resons_for_decline",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Currently not seeking for a change">
                        Currently not seeking for a change
                      </option>
                      <option value="Candidate not interested">
                        Candidate not interested
                      </option>
                      <option value="Recently switched jobs">
                        Recently switched jobs
                      </option>
                      <option value="Prefer to work in an MNC">
                        Prefer to work in an MNC
                      </option>
                      <option value="Seeking WFO but not willing to relocate">
                        Seeking WFO but not willing to relocate
                      </option>
                      <option value="Onboarding process on Hirein5 platform is too lengthy">
                        Onboarding process on Hirein5 platform is too lengthy
                      </option>
                      <option value="Candidates are not comfortable sharing their Aadhaar or PAN card details.">
                        Candidates are not comfortable sharing their Aadhaar or
                        PAN card details.
                      </option>
                      <option value="Lack of Required Skills">
                        Lack of Required Skills
                      </option>
                      <option value="Insufficient Experience">
                        Insufficient Experience
                      </option>
                      <option value="Poor Interview Performance">
                        Poor Interview Performance
                      </option>
                      <option value="Incomplete Application">
                        Incomplete Application
                      </option>
                      <option value="ackground Check Issues">
                        Background Check Issues
                      </option>
                    </select>
                  </h3>
                </td>
                <td>
                  <h3>
                    <input
                      className="hireTableBodySelect"
                      type="date"
                      value={role.date_commenced}
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "date_commenced",
                          e.target.value
                        )
                      }
                    />
                  </h3>
                </td>
                <td>
                  {role.staff_data !== undefined && role.staff_data !== null ? (
                    <h3>{role.staff_data.onboarding_status * 25} %</h3>
                  ) : (
                    <h3>0 %</h3>
                  )}
                </td>
                <td>
                  <h3>
                    {role.staff_data !== undefined &&
                    role.staff_data !== null ? (
                      role.staff_data.status == "Success" ? (
                        <p className="reserving px-2 rounded-full">
                          In Progress
                        </p>
                      ) : role.staff_data.status == "Success" ? (
                        <p className="hiringActive px-2 rounded-full">
                          Processing
                        </p>
                      ) : role.staff_data.status == "Reject" ? (
                        <p className="rejectstaus px-2 rounded-full">On Hold</p>
                      ) : role.staff_data.status == "New Candidate" ? (
                        <p className="contracted px-2 rounded-full">New</p>
                      ) : role.staff_data.status == "Mail send" ? (
                        <p className="processingstaus px-2 rounded-full">
                          Reviewed
                        </p>
                      ) : (
                        <p className="hiringActive px-2 rounded-full">NA</p>
                      )
                    ) : (
                      <p className="rejectstaus px-2 rounded-full">NA</p>
                    )}

                    {/* <select
                      className="hireTableBodySelect"
                      value={role.app_completion_status}
                      onChange={(e) =>
                        handleChangeNew(
                          roleIndex,
                          "app_completion_status",
                          e.target.value
                        )
                      }
                    >
                      <option value="">Select</option>
                      <option value="Completed">Completed</option>
                      <option value="Failed">Failed</option>
                      <option value="Proessing">Proessing</option>
                    </select> */}
                  </h3>
                </td>
              </tr>
            ))}
        </tbody>
      </table>

      {loading ? (
        <button className="saveSubmit">Please Wait...</button>
      ) : (
        <button className="saveSubmit" onClick={submitbtn}>
          Save Changes
        </button>
      )}
      {loading1 ? (
        <button className="saveSubmit1">Please Wait...</button>
      ) : (
        <button className="saveSubmit1" onClick={moveToass}>
          Move to Assessment Flow
        </button>
      )}
      {pagination_status && (
        <div className="tablePagination">
          <nav>
            <ul className="pagination">
              {candidateprevious == null ? (
                <li className="page-item disabled">
                  <h6 className="page-link">&lt;&lt; Previous</h6>
                </li>
              ) : (
                <li
                  className="page-item active"
                  onClick={() => {
                    setnewurl(candidateprevious);
                  }}
                >
                  <h6 className="page-link">&lt;&lt; Previous</h6>
                </li>
              )}

              {newurl == null ? (
                <h6 className="page-link">1</h6>
              ) : (
                <h6 className="page-link active">
                  {newurl.includes("/candidate-appflow/") &&
                  newurl.split("/candidate-appflow/")[1]?.split("?page=")[1] !==
                    undefined
                    ? newurl.split("/candidate-appflow/")[1].split("?page=")[1]
                    : 1}
                </h6>
              )}
              {candidatenext == null ? (
                <li className="page-item disabled">
                  <h6 className="page-link">Next &gt;&gt;</h6>
                </li>
              ) : (
                <li
                  className="page-item active"
                  onClick={() => {
                    setnewurl(candidatenext);
                  }}
                >
                  <h6 className="page-link">Next &gt;&gt;</h6>
                </li>
              )}
            </ul>
          </nav>
          <p className="text-center mt-4 font-medium text-xs text-[#71717a]">
            Total No Of Pages : {pageNumbers.length}
          </p>
        </div>
      )}
    </div>
  );
};

export default CandidateApplicationFlow;
